import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  cleanupGooglePayScript,
  injectGooglePayScript,
} from 'helpers/Google/GooglePay';

const GooglePayContext = createContext();

/**
 *
 * @param {*} param0
 * @returns {React.ReactElement}
 */
export function GooglePayProvider({ children }) {
  const [googlePay, setGooglePay] = useState(null);

  useEffect(() => {
    injectGooglePayScript()
      .then((googlePay) => {
        setGooglePay(googlePay);
      })
      .catch(console.error);

    return () => {
      cleanupGooglePayScript();
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      googlePay,
    }),
    [googlePay]
  );

  return (
    <GooglePayContext.Provider value={contextValue}>
      {children}
    </GooglePayContext.Provider>
  );
}

GooglePayProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * @typedef {object} GooglePayContext
 * @property {GooglePay} googlePay
 *
 * @typedef {object} GooglePay
 * @property {boolean} statusChecked
 * @property {boolean} available
 * @property {any} googlePaymentInstance
 * @property {any} googlePaymentClient
 *
 * @returns {GooglePayContext|undefined}
 */
export function useGooglePay() {
  return useContext(GooglePayContext);
}

export function withGooglePay(Component) {
  const WithGooglePayComponent = (props) => (
    <GooglePayContext.Consumer>
      {(contextValue) => <Component {...props} {...contextValue} />}
    </GooglePayContext.Consumer>
  );

  const displayName = Component.displayName || Component.name || 'Component';
  WithGooglePayComponent.displayName = `withGooglePay(${displayName})`;

  return WithGooglePayComponent;
}
