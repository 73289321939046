import { create as braintreeClientCreate } from 'braintree-web/client';
import { create as googlePayCreate } from 'braintree-web/google-payment';

import config from 'config/config';
import { ENV } from 'config/environments';

const GOOGLE_PAY_SRC = 'https://pay.google.com/gp/p/js/pay.js';
const GOOGLE_PAY_SCRIPT_ID = 'google-pay-script';

export const GOOGLE_PAY_ERRORS = {
  BRAINTREE_TOKENIZE_FAIL: 'GOOGLE_PAY_ERROR/BRAINTREE_TOKENIZE_FAIL',
};

/**
 * @param {PaymentsClient} googlePaymentClient
 * @returns {Promise<{statusChecked: boolean, available: boolean, googlePaymentInstance: any, googlePaymentClient: any}>}
 */
const createGooglePay = (googlePaymentClient) => {
  return new Promise((resolve, reject) =>
    braintreeClientCreate(
      {
        authorization: config.BRAINTREE_CLIENT_TOKEN,
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          reject(
            new Error(
              'Something went wrong when creating Braintree client for Googlepay',
              { cause: clientErr }
            )
          );
          return;
        }

        googlePayCreate({
          client: clientInstance,
          googlePayVersion: 2,
          googleMerchantId: config.GOOGLE_MERCHANT_ID,
        })
          .then((googlePaymentInstance) => {
            googlePaymentClient
              .isReadyToPay({
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods:
                  googlePaymentInstance.createPaymentDataRequest()
                    .allowedPaymentMethods,
              })
              .then((response) => {
                if (response.result) {
                  resolve({
                    statusChecked: true,
                    available: true,
                    googlePaymentInstance,
                    googlePaymentClient,
                  });
                } else {
                  resolve({ statusChecked: false, available: false });
                }
              })
              .catch((err) => {
                console.error('Error setting up googlePaymentClient', err);
                resolve({ statusChecked: false, available: false });
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
    )
  );
};

/**
 * @returns {Promise<{statusChecked: boolean, available: boolean, googlePaymentInstance: any, googlePaymentClient: any}>}
 */
export function injectGooglePayScript() {
  if (typeof window === 'undefined') {
    throw new Error(
      'Google Pay cannot be configured outside a browser environment'
    );
  }

  const script = document.createElement('script');
  script.id = GOOGLE_PAY_SCRIPT_ID;
  script.src = GOOGLE_PAY_SRC;
  script.async = true;
  script.defer = true;

  return new Promise((resolve, reject) => {
    script.onerror = () => {
      reject(new Error(`Error loading Google Pay from ${GOOGLE_PAY_SRC}`));
    };

    script.onload = () => {
      const isProduction =
        config.env === ENV.PRE_PRODUCTION || config.env === ENV.PRODUCTION;
      const googlePayAPI = window.google?.payments?.api;

      if (!googlePayAPI) {
        console.error('Google Pay API is not available');
        return;
      }

      const googlePaymentClient = new googlePayAPI.PaymentsClient({
        environment: isProduction ? 'PRODUCTION' : 'TEST',
      });

      if (!googlePaymentClient) {
        console.error('Google Pay Client is not available');
        return;
      }

      createGooglePay(googlePaymentClient)
        .then((googlePay) => {
          resolve(googlePay);
        })
        .catch(reject);
    };

    document.head.append(script);
  });
}

export function cleanupGooglePayScript() {
  if (typeof document !== 'undefined') {
    const script = document.querySelector(`#${GOOGLE_PAY_SCRIPT_ID}`);
    if (script) {
      script.remove();
    }
  }
}
