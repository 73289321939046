import { Spot as SpotType } from 'types';

import Position from './Position';

export default class Spot {
  readonly position: SpotType['position'];
  readonly disclosures: SpotType['disclosures'];
  readonly row: SpotType['row'];
  readonly section: SpotType['section'];
  readonly sectionGroup: SpotType['section_group'];
  readonly viewUrl: SpotType['view_url'];

  constructor(spot: SpotType) {
    this.position = new Position(spot.position);
    this.disclosures = spot.disclosures;
    this.row = spot.row;
    this.section = spot.section;
    this.sectionGroup = spot.section_group;
    this.viewUrl = spot.view_url;
  }
}
