import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { GuaranteeShield } from 'icons';

import styles from './Guarantee.module.scss';
import parentStyles from '/ui/Layout/CheckoutContainer/CheckoutContainer.module.scss';

export const text = {
  header: 'Your tickets are 100% covered by our Gametime Guarantee',
  bullets: [
    'Lowest price guarantee',
    'Event cancellation protection',
    'On-time ticket delivery',
  ],
};

export default function Guarantee(): React.JSX.Element {
  const ref = useRef(null);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- This is fired in the `useEffect` and therefore will not always be truthy
    if (ref.current === null) {
      setIsDarkTheme(true);
      return;
    }

    const themeColor = getComputedStyle(ref.current)
      .getPropertyValue('--color-text-default-primary')
      .trim();

    setIsDarkTheme(themeColor === '#f9f9fa');
  }, []);

  return (
    <aside
      ref={ref}
      className={classNames(styles.component, parentStyles.guarantee)}
      data-testid="checkout-guarantee"
    >
      <section
        className={styles.content}
        data-testid="checkout-guarantee-content"
      >
        <h2 className={styles.heading} data-testid="checkout-guarantee-header">
          {text.header}
        </h2>

        <ul
          className={styles.checklist}
          data-testid="checkout-guarantee-checklist"
        >
          {text.bullets.map((item, index) => (
            <li
              className={styles['list-item']}
              data-testid={`checkout-guarantee-list-item-${index}`}
              key={item}
            >
              {item}
            </li>
          ))}
        </ul>
      </section>

      <figure className={styles.icon} data-testid="checkout-guarantee-icon">
        <GuaranteeShield height="auto" width="100%" isDark={isDarkTheme} />
      </figure>
    </aside>
  );
}
