import { Banner as BannerType } from 'types';

export default class Banner {
  headline: BannerType['headline'];
  subtitle: BannerType['subtitle'];
  emoji: BannerType['emoji'];

  constructor(banner: BannerType) {
    this.headline = banner.headline;
    this.subtitle = banner.subtitle;
    this.emoji = banner.emoji;
  }
}
