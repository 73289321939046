import { FullDisclosure } from 'types';

export default class Disclosure {
  slug: FullDisclosure['slug'];
  title: FullDisclosure['title'];
  description: FullDisclosure['description'];
  resourceType: FullDisclosure['resource_type'];
  iconURL: FullDisclosure['icon_url'];
  color: FullDisclosure['color'];
  bestDisplayType?: FullDisclosure['display_types'][0];
  isHighlightedDisplay?: boolean;

  constructor(disc: FullDisclosure) {
    this.slug = disc.slug;
    this.title = disc.title;
    this.description = disc.description;
    this.resourceType = disc.resource_type;
    this.iconURL = disc.icon_url;
    this.color = disc.color;

    /**
     * `display_types` is an array sorted by all display_types that could render that disclosure.
     * It's there mostly for the apps so that old client don't break if a `display_type` changes.
     * For us, we just care about the first one
     */
    if (disc.display_types.length) {
      const [best] = disc.display_types;
      this.bestDisplayType = best;
      this.isHighlightedDisplay = disc.display_types.includes('highlighted');
    }
  }
}
