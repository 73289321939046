import { getExternalAccountType } from 'store/modules/user/utils';

import {
  BETMGM_ELIGIBLE_CATEGORIES,
  BETMGM_ELIGIBLE_STATES,
  ORDER_STEP_STATUS,
} from './constants';

const PERFORMERS_TICKET_RELEASE = {
  mlbnym: 24,
  mlbnyy: 48,
  mlbbos: 48,
};

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60;

function _hoursToMessage(hours) {
  if (hours >= 72) {
    return `${hours / 24} days`;
  }

  return `${hours} hours`;
}

function _getHoursToEvent(dateTimeLocal) {
  const eventTime = new Date(dateTimeLocal).getTime();
  const now = Date.now();
  const hours = Math.floor((eventTime - now) / MILLISECONDS_IN_A_DAY);

  return hours;
}

export function getPerformerMessage(
  fullEvent,
  isPending,
  showConfirmation,
  transferComplete
) {
  if (!fullEvent) {
    return null;
  }

  if (!showConfirmation && (isPending || transferComplete)) {
    return null;
  }

  const { event } = fullEvent;
  const isTbd = event.date_tbd || event.time_tbd;

  const performer = fullEvent.getPrimaryPerformer();
  const performerReleaseTime = PERFORMERS_TICKET_RELEASE[performer.slug];
  let returnMessage = null;

  if (performerReleaseTime) {
    const hours = _getHoursToEvent(event.datetimeLocal);
    if (hours && hours > performerReleaseTime) {
      const message = _hoursToMessage(performerReleaseTime);

      returnMessage = `Heads up: ${performer.shortName} tickets are released to fans ${message} before the event.
      We'll shoot you an email as soon as your tickets are ready!`;
    }

    return returnMessage;
  }

  if (isTbd) {
    returnMessage =
      "Your tickets are on the way, and we'll shoot you an email when they're ready.";

    return returnMessage;
  }

  const hours = _getHoursToEvent(event.datetimeLocal);
  switch (!!hours) {
    case hours <= 3: {
      returnMessage =
        "Hang tight - we're working on delivering your tickets ASAP, and we'll shoot you an email when they're ready.";
      break;
    }
    case hours > 3 && hours <= 24: {
      returnMessage =
        'Hang tight - your tickets are on the way and will arrive within two hours of your event.';
      break;
    }
    case hours > 24: {
      returnMessage =
        "Your tickets are on the way, and we'll shoot you an email when they're ready.";
      break;
    }
    default:
      returnMessage = '';
      break;
  }

  return returnMessage;
}

export const getBetMGMEligibility = (state, category) => {
  return (
    BETMGM_ELIGIBLE_STATES.includes(state) &&
    BETMGM_ELIGIBLE_CATEGORIES.includes(category)
  );
};

export const areNonTransferTicketsReady = (tickets) => {
  return tickets.every(
    (ticket) =>
      ((ticket.type_info?.display_pdf_first && ticket.url) ||
        ticket.barcode !== 'pending') &&
      !getExternalAccountType(ticket.type)
  );
};

/**
 * When the back end returns a pending orcanceled order (rejected, system error, etc.), a "cancelled" step is added to
 * the purchase object and returned immediately. If an order is complete, the steps of "placed," "confirmed,"
 * and "completed" are added.
 *
 * @param {Array<Object>} purchaseSteps an array of purchase steps from a completePurchase
 */
export const getOrderStatus = (purchaseSteps = []) => {
  if (purchaseSteps.length === 0) {
    return null;
  }

  for (let index = 0; index < purchaseSteps.length; index++) {
    if (purchaseSteps[index].status === ORDER_STEP_STATUS.CANCELLED) {
      return ORDER_STEP_STATUS.CANCELLED;
    }

    if (purchaseSteps[index].status === ORDER_STEP_STATUS.PENDING) {
      return ORDER_STEP_STATUS.PENDING;
    }

    if (purchaseSteps[index].status === ORDER_STEP_STATUS.CONFIRMED) {
      return ORDER_STEP_STATUS.CONFIRMED;
    }
  }
};
