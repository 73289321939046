const eventPathRegex = /.*\/events\/\w+(|\?.*)$/;
const performerPathRegex = /^.*\/performers\/\w+(|\?.*)$/;
const venuePathRegex = /^.*\/venues\/\w+(|\?.*)$/;
const checkoutPathRegex = /\/checkout/;

export const isListingPage = (pathname = '') => pathname.includes('listings');
export const isBuyRoute = (pathname = '') => pathname.includes('buy');
export const isOrderRoute = (pathname = '') => pathname.includes('order');
export const isEventPage = (pathname) => eventPathRegex.test(pathname);
export const isVenuePage = (pathname) => venuePathRegex.test(pathname);
export const isSearchPage = (pathname = '') => pathname.includes('/search');
export const isCollectionPage = (pathname = '') =>
  pathname.includes('/collection');
export const isCheckoutPage = (pathname) => checkoutPathRegex.test(pathname);
export const isNewSportsMetro = (pathname = '') => pathname.includes('sport/');
export const isPurchaseRoute = (pathname = '') => pathname.includes('buy');
export const isPerformerPage = (pathname) => performerPathRegex.test(pathname);
export const isHomepage = (pathname) => pathname === '/';
export const isMetroPerformersPage = (pathname = '') =>
  pathname.includes('metros');
export const isMusicShowsPage = (pathname = '') => {
  return pathname.includes('/c/concert') || pathname.includes('/c/theater');
};
export const isGTPicksPage = (pathname = '') => pathname.includes('/picks');

export const shouldShowListingDetailsOverlay = (
  currentLocation,
  previousLocation
) => {
  const currRoute = currentLocation.pathname;

  if (
    isCheckoutPage(currRoute) ||
    isPurchaseRoute(currRoute) ||
    isOrderRoute(currRoute)
  ) {
    return false;
  }

  if (previousLocation?.pathname) {
    const prevRoute = previousLocation.pathname;
    const samePage = isListingPage(currRoute) && isListingPage(prevRoute);

    if (isCheckoutPage(prevRoute) && isListingPage(currRoute)) {
      // checkout to listing details
      return true;
    }

    if (samePage && !isCheckoutPage(currRoute)) {
      return true;
    }

    if (isEventPage(prevRoute) && isListingPage(currRoute)) {
      // event to listing details
      return true;
    }

    if (
      isEventPage(currRoute) &&
      isListingPage(prevRoute) &&
      !isBuyRoute(prevRoute)
    ) {
      // listing details to event
      return true;
    }
  }

  return false;
};

export const isEventPagePreListings = (pathname = '') => {
  return isEventPage(pathname) && !isListingPage(pathname);
};

export const isListingDetailsPage = (pathname = '') => {
  return (
    isListingPage(pathname) &&
    !isCheckoutPage(pathname) &&
    !isPurchaseRoute(pathname)
  );
};

export const isEventSubRoute = (pathname) =>
  isEventPage(pathname) ||
  isListingPage(pathname) ||
  isCheckoutPage(pathname) ||
  isBuyRoute(pathname);
