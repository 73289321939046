import React from 'react';
import classNames from 'classnames';

import { formatPriceWithComma } from 'utils/number';

import LineItemContainer from '../LineItemContainer/LineItemContainer';

import styles from '../Cart.module.scss';

function Multiplier({ quantity }: { quantity: number }) {
  return (
    <span
      className={styles.quantity}
      data-testid="checkout-cart-line-item-quantity"
    >
      <span aria-hidden="true">&times;</span>
      <span className="visually-hidden">times</span>
      <span className={styles['quantity-value']}>{quantity}</span>
    </span>
  );
}

export default function LineItem({
  currency = '$',
  title,
  value,
  quantity,
  showInsuranceTag,
  insuranceLabel,
  tagClass = '',
  titleClass = '',
}: {
  currency?: string;
  quantity?: number;
  title?: string;
  value: number;
  showInsuranceTag?: boolean;
  insuranceLabel?: 'pending' | 'cancelled' | null | undefined;
  tagClass?: string;
  titleClass?: string;
}) {
  return (
    <LineItemContainer>
      <dt
        className={styles[titleClass]}
        data-testid="checkout-cart-line-item-title"
      >
        {title}
      </dt>

      <dd
        className={classNames(styles.value, styles[titleClass])}
        data-testid="checkout-cart-line-item-value"
      >
        <span
          className={styles.price}
          data-testid="checkout-cart-line-item-price"
        >
          {showInsuranceTag && (
            <span className={tagClass}>{insuranceLabel}</span>
          )}
          {currency + formatPriceWithComma(value, true)}
        </span>

        {quantity && <Multiplier quantity={quantity} />}
      </dd>
    </LineItemContainer>
  );
}
