import React, { useEffect, useRef } from 'react';

import PayPal from './PayPal';

import styles from './PayPalPurchaseButton.module.scss';

export interface PaymentSuccessData {
  nonce: string;
}

export interface PayPalPurchaseButtonProps {
  total: number;
  onPaymentSuccess: (data: PaymentSuccessData) => void;
  onClick?: () => void;
}

function PayPalPurchaseButton({
  total,
  onPaymentSuccess,
  onClick,
}: PayPalPurchaseButtonProps) {
  const paypalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    PayPal(paypalRef, total, onClick)
      .then((payload) => {
        onPaymentSuccess(payload);
      })
      .catch((err) => {
        console.error('Error: ', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return <div id="paypal-button" ref={paypalRef} className={styles.button} />;
}

export default PayPalPurchaseButton;
