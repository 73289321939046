import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button, { TYPES } from 'components/Buttons/SimpleButton';
import CardDetails from 'components/CardDetails/CardDetails';
import GTAnimationModal from 'components/GTAnimationModal';
import Link from 'components/Link/Link';
import Spinner, { VARIANTS } from 'components/Spinner/Spinner';
import { useAppDispatch } from 'store';

import Dialog from '../../../ui/Dialog/Dialog';

import defaultStyles from './EditCardModal.module.scss';
import checkoutV3Styles from './EditCardModalV3.module.scss';

/** @typedef {import('../../../types/mobileApi').CreditCard} CreditCard */

/**
 * @param {{ children: React.ReactNode, isCheckoutV3?: boolean, isOpen: boolean, onClose: () => {}}} props
 * @returns {React.JSX.Element}
 */
function Wrapper({ children, isCheckoutV3 = false, isOpen, onClose }) {
  const title = 'Edit Payment Method';

  if (isCheckoutV3) {
    return (
      <Dialog title={title} isOpen={isOpen} onClose={onClose}>
        {children}
      </Dialog>
    );
  }

  return (
    <GTAnimationModal
      headerTheme="dark"
      className="edit-card-modal"
      show={isOpen}
      onHide={onClose}
      headerTitle={title}
    >
      {children}
    </GTAnimationModal>
  );
}

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isCheckoutV3: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

/**
 * @typedef {object} ContentProps
 * @property {readonly CreditCard[]} cards
 * @property {{ [key: string]: any }} clickTracker
 * @property {(card: Readonly<CreditCard>) => any} onDelete
 * @property {boolean} [isLoading]
 */

/**
 * @param {Readonly<ContentProps & { onClose: () => any }>} props
 * @returns {React.JSX.Element}
 */
function Content({
  cards: cardList,
  clickTracker,
  isLoading = false,
  onClose: handleClose,
  onDelete: handleDelete,
}) {
  const styles = defaultStyles;

  return (
    <>
      <div
        className={classNames(styles['card-list'], {
          [styles.loading]: isLoading,
        })}
      >
        {Object.values(cardList).map((card) => {
          return (
            <div key={card.token} className={styles['card-item']}>
              <CardDetails card={card} />

              <Link
                className={styles['delete-button']}
                clickTracker={clickTracker}
                disabled={isLoading}
                onClick={() => handleDelete(card)}
              >
                Delete
              </Link>
            </div>
          );
        })}
      </div>

      <div className={styles['edit-card-footer']}>
        <Button
          fullWidth
          disabled={isLoading}
          onClick={handleClose}
          text="save"
          type={TYPES.GREEN_SOLID}
        />
      </div>

      {isLoading && (
        <Spinner
          style={{ height: 120 }}
          format={VARIANTS.GAMETIME_GREEN_LIGHT}
        />
      )}
    </>
  );
}

Content.propTypes = {
  cards: PropTypes.object,
  clickTracker: PropTypes.object,
  isLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

/**
 * @param {Readonly<ContentProps>} props
 * @returns {React.JSX.Element}
 */
function ContentV3({
  cards: cardList,
  clickTracker,
  onDelete: handleDelete,
  isLoading = false,
}) {
  const styles = checkoutV3Styles;

  if (isLoading) {
    return (
      <Spinner style={{ height: 120 }} format={VARIANTS.GAMETIME_GREEN_LIGHT} />
    );
  }

  return (
    <div
      className={classNames(styles['card-list'], {
        [styles.loading]: isLoading,
      })}
    >
      {Object.values(cardList).map((card) => {
        return (
          <div key={card.token} className={styles['card-item']}>
            <CardDetails card={card} />

            <Link
              className={styles['delete-button']}
              clickTracker={clickTracker}
              disabled={isLoading}
              onClick={() => handleDelete(card)}
            >
              Delete
            </Link>
          </div>
        );
      })}
    </div>
  );
}

ContentV3.propTypes = Content.propTypes;

/**
 * @typedef Props
 * @property {{ [key: string]: CreditCard[] }} cards
 * @property {boolean} isCheckoutV3
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {{ [key: string]: any }} clickTracker
 * @property {(card: Readonly<CreditCard>) => any} deleteCard
 *
 * @param {Readonly<Props>} props
 * @returns {React.JSX.Element}
 */
const EditCardModal = ({
  cards: cardList,
  clickTracker,
  deleteCard,
  isCheckoutV3 = false,
  isOpen = false,
  onClose,
}) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  /**
   * @type {typeof deleteCard}
   */
  const handleDelete = (card) => {
    setLoading(true);

    dispatch(deleteCard(card)).finally(() => {
      setLoading(false);

      if (Object.keys(cardList).length <= 1) {
        onClose();
      }
    });
  };

  return (
    <Wrapper isOpen={isOpen} onClose={onClose} isCheckoutV3={isCheckoutV3}>
      {isCheckoutV3 ? (
        <ContentV3
          cards={cardList}
          clickTracker={clickTracker}
          isLoading={isLoading}
          onDelete={handleDelete}
        />
      ) : (
        <Content
          cards={cardList}
          clickTracker={clickTracker}
          isLoading={isLoading}
          onClose={onClose}
          onDelete={handleDelete}
        />
      )}
    </Wrapper>
  );
};

EditCardModal.propTypes = {
  cards: PropTypes.object,
  clickTracker: PropTypes.object,
  deleteCard: PropTypes.func.isRequired,
  isCheckoutV3: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditCardModal;
