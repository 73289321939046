import React from 'react';

import PurchaseHeading from 'pages/PurchaseV3/components/PurchaseHeading';
import { denormalizePhoneNumber } from 'utils/phone';

import {
  Form,
  FormSection,
  Input,
  PhoneNumber,
  Validators,
} from '../../../CheckoutV3/components/Forms';

interface PurchaseUserTextMessageProps {
  onSubmit: (form: Values) => void;
}

interface Values {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const UserPhoneFormValidationSchema = Validators.schema({
  firstName: Validators.string({ label: 'first name', required: true }),
  lastName: Validators.string({ label: 'last name', required: true }),
  phoneNumber: Validators.phoneNumber({
    label: 'phone number',
    required: true,
  }),
});

function PurchaseUserTextMessage({ onSubmit }: PurchaseUserTextMessageProps) {
  const formId = 'user-text-message-form';

  function handleSubmit(form: Values) {
    const denormalizedForm = {
      ...form,
      phoneNumber: denormalizePhoneNumber(form.phoneNumber),
    };

    onSubmit(denormalizedForm);
  }

  return (
    <>
      <PurchaseHeading
        heading="Transfer Required?"
        subheading="This event requires your tickets to be transferred to you via text message."
      />

      <Form
        id={formId}
        initialValues={{ firstName: '', lastName: '', phoneNumber: '' }}
        onSubmit={handleSubmit}
        submitText="Continue"
        validationSchema={UserPhoneFormValidationSchema}
      >
        <FormSection>
          <Input
            id="user-first-name"
            name="firstName"
            label="First Name"
            required
            type="text"
          />
          <Input
            id="user-last-name"
            name="lastName"
            label="Last Name"
            required
            type="text"
          />
        </FormSection>

        <PhoneNumber id="user-phone" name="phoneNumber" />
      </Form>
    </>
  );
}

export default PurchaseUserTextMessage;
