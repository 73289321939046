import React from 'react';
import {
  StatsigProvider,
  useClientBootstrapInit,
} from '@statsig/react-bindings';
import Cookies from 'js-cookie';
import { ClientInitializeResponse } from 'statsig-node/dist/Evaluator';

import commonConfig from '../../config/common.config';
import config from '../../config/variants';

type Props = Readonly<{
  children: React.ReactNode;
  initialValues?: {
    statsig?: ClientInitializeResponse;
  } | null;
}>;

function Statsig({
  children,
  initialValues,
  token,
}: Readonly<{
  children: Props['children'];
  initialValues?: ClientInitializeResponse | null;
  token: string;
  userId?: string;
}>): React.JSX.Element {
  if (initialValues) {
    if (config.debug) {
      console.info('🥾 Bootstrapping Statsig from server values.');
    }

    const client = useClientBootstrapInit(
      token,
      initialValues.user,
      JSON.stringify(initialValues)
    );

    return <StatsigProvider client={client}>{children}</StatsigProvider>;
  }

  if (config.debug) {
    console.info('🚧 No initial values, initializing Statsig on client.');
  }

  const userId = Cookies.get(commonConfig.cookiesConfig.WEB_USER_ID.name);

  const user: {
    userID?: string;
    userAgent?: string;
  } = {
    userID: userId,
  };

  if (typeof window !== 'undefined' && window.navigator) {
    user.userAgent = window.navigator.userAgent;
  }

  return (
    <StatsigProvider sdkKey={token} user={user}>
      {children}
    </StatsigProvider>
  );
}

export default function VariantProvider({
  children,
  initialValues,
}: Props): React.JSX.Element {
  const token = config[config.provider]?.credentials.client_token;

  if (token === undefined) {
    throw new Error(`No ${config.provider} client token defined.`);
  }

  switch (config.provider) {
    case 'statsig':
      const initialState = initialValues?.[config.provider];

      return (
        <Statsig token={token} initialValues={initialState}>
          {children}
        </Statsig>
      );

    default:
      return <>{children}</>;
  }
}
