import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { mapListingTrackingData, TRACK, TrackPageView, View } from 'analytics';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import Spinner, { VARIANTS } from 'components/Spinner/Spinner';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import { Listing } from 'models';

import styles from './PurchaseSecure.module.scss';

@TrackPageView(({ listing }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.PROCESS_PURCHASE(
    listing.eventId,
    listing.id
  ),
  payload: mapListingTrackingData(listing),
}))
export default class PurchaseSecure extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    listing: PropTypes.instanceOf(Listing).isRequired, // Used in TrackPageView decorator
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <InfoHeader headerContent="Securing Your Tickets" isDarkTheme bold />
        <ThemedCard
          title="We're Working Our Magic"
          desc="Please don't refresh the page – your order is processing."
        >
          <div className={styles['spinner-container']}>
            <Spinner
              style={{ height: 120 }}
              format={VARIANTS.GAMETIME_GREEN_LIGHT}
            />
          </div>
        </ThemedCard>
      </div>
    );
  }
}
