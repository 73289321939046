import React from 'react';

import { PerformerClickTracker } from 'analytics';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import Performer from 'models/Performer';

import styles from './PerformerCard.module.scss';

type PerformerCardProps = {
  performer: Performer;
  clickTracker: PerformerClickTracker;
};

const PerformerCard = ({ performer, clickTracker }: PerformerCardProps) => {
  const eventCount = performer.stats.event_count
    ? `${performer.stats.event_count} Event${performer.stats.event_count === 1 ? '' : 's'}`
    : 'No Events';
  return (
    <Link
      to={performer.getPath()}
      key={performer.id}
      clickTracker={clickTracker}
    >
      <div className={styles['concert-performer-item']}>
        <div className={styles['img-zoom-container']}>
          <Image
            className={styles['performer-img']}
            src={performer.heroImageUrl}
            alt={performer.name}
            quality="80,65"
          />
        </div>
        <div className={styles['performer-details']}>
          <span className={styles['performer-name']}>{performer.name}</span>
          <span className={styles['performer-event-count']}>{eventCount}</span>
        </div>
      </div>
    </Link>
  );
};

export default PerformerCard;
