import React from 'react';

export default ({fill = '#FFFFFF', width = 24, height = 24}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M3 4C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4H3ZM19.5 11C19.7761 11 20 10.7761 20 10.5V7.5C20 7.22386 19.7761 7 19.5 7H16.5C16.2239 7 16 7.22386 16 7.5V10.5C16 10.7761 16.2239 11 16.5 11H19.5ZM12 12.5C12 12.7761 11.7761 13 11.5 13H4.5C4.22386 13 4 12.7761 4 12.5V11.5C4 11.2239 4.22386 11 4.5 11H11.5C11.7761 11 12 11.2239 12 11.5V12.5ZM8.5 17C8.77614 17 9 16.7761 9 16.5V15.5C9 15.2239 8.77614 15 8.5 15H4.5C4.22386 15 4 15.2239 4 15.5V16.5C4 16.7761 4.22386 17 4.5 17H8.5Z"
    />
  </svg>
);
