import React, { useState } from 'react';
import { FormikHelpers } from 'formik';
import Mailcheck from 'mailcheck';

import {
  Form,
  Input,
  Validators,
} from '../../../../pages/CheckoutV3/components/Forms';
import { DISPLAY_TEXT_V3 as content } from '../constants';

const UserEmailFormValidationSchema = Validators.schema({
  email: Validators.email({ label: 'email address', required: true }),
});

type Props = {
  hasError?: boolean;
  handleEmailLogin: (email: string) => void;
};

type Values = {
  email: string;
};

function EmailFormV3({ hasError = false, handleEmailLogin }: Props) {
  Mailcheck.defaultDomains.push('gametime.co');
  const key = 'email';
  const [emailSuggestion, setEmailSuggestion] = useState('');

  const onSubmitHandler = async (
    values: Values,
    { setSubmitting, setFieldError }: FormikHelpers<Values>
  ) => {
    setSubmitting(true);

    try {
      if (emailSuggestion) {
        await handleEmailLogin(values.email);
      } else {
        await Mailcheck.run({
          email: values.email.trim(),
          suggested: (suggestion: { full: string }) => {
            setEmailSuggestion(suggestion.full);
          },
          empty: async () => {
            await handleEmailLogin(values.email);
          },
        });
      }
    } catch (err) {
      setFieldError(key, content.ERROR.title);
      console.error('Error submitting email login form: ', err);
    }

    if (hasError) {
      setFieldError(key, content.ERROR.title);
    }

    setSubmitting(false);
  };

  return (
    <Form
      id="login-by-email"
      initialValues={{ email: '' }}
      onSubmit={(values: Values, helpers: FormikHelpers<Values>) =>
        onSubmitHandler(values, helpers)
      }
      submitText="Continue"
      validationSchema={UserEmailFormValidationSchema}
    >
      <Input id="email" name="email" label="Email Address" type="email" />
    </Form>
  );
}

export default EmailFormV3;
