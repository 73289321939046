import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  mapListingTrackingData,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import { ACTIONS as T_ACTIONS } from 'components/Trackable/TrackingHelper';
import UserPhoneForm from 'components/UserPhoneForm/UserPhoneForm';
import { Listing } from 'models';

@TrackPageView(({ listing }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.ADD_PHONE_NUMBER(),
  payload: mapListingTrackingData(listing),
}))
@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.ADD_PHONE_NUMBER(),
  payload: mapListingTrackingData(listing),
}))
export default class PurchaseUserPhone extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    listing: PropTypes.instanceOf(Listing).isRequired, // Used in tracking decorators
  };

  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(form) {
    await this.props.onSubmit(form.phone);
  }

  render() {
    return (
      <div>
        <InfoHeader headerContent="Securing Your Tickets" isDarkTheme bold />
        <ThemedCard
          title="What's Your Phone Number?"
          desc="We'll only call in cases of emergency and we won't share your information."
        >
          <UserPhoneForm
            handleUserPhoneFormSubmit={this.handleSubmit}
            ctaText="confirm"
            mpActions={{ action: T_ACTIONS.SUBMIT_PURCHASE_PHONE }}
            clickTracker={new ClickTracker().interaction(
              Click.INTERACTIONS.CONTINUE_BUTTON()
            )}
          />
        </ThemedCard>
      </div>
    );
  }
}
