import React, { useEffect } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import CheckoutContainer from 'ui/Layout/CheckoutContainer/CheckoutContainer';

import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { usePurchaseFlow } from 'routes/PurchaseFlowContext';

import { PURCHASE_STEPS } from './constants';
import {
  PurchaseSecure,
  PurchaseUserAddress,
  PurchaseUserPhone,
  PurchaseUserTextMessage,
  PurchaseUserTransfer,
} from './steps';

function PurchaseV3() {
  const {
    purchaseStep,
    handleSubmitTransferEmail,
    isMLBInfoCollection,
    handleSubmitUserPhone,
    handleSubmitTransferPhone,
    handleSubmitVerifyBillingAddress,
    handleSubmitUserDeliveryAddress,
  } = usePurchaseFlow();

  const location = useLocation();
  const navigate = useNavigate();

  const { listing, event } = useOutletContext<{
    listing: Listing;
    event: FullEvent;
  }>();

  const listingPath = listing.getPath(event);

  useEffect(() => {
    if (!purchaseStep) {
      navigate(
        {
          pathname: listingPath + '/checkout',
          search: location.search,
        },
        { replace: true }
      );
    }
  }, [purchaseStep, location.search, listingPath, navigate]);

  switch (purchaseStep) {
    // pre-purchase:
    case PURCHASE_STEPS.USER_TEXT: {
      return (
        <CheckoutContainer hideDetailsOnMobile>
          <PurchaseUserTextMessage onSubmit={handleSubmitTransferPhone} />
        </CheckoutContainer>
      );
    }
    case PURCHASE_STEPS.USER_TRANSFER: {
      return (
        <CheckoutContainer hideDetailsOnMobile>
          <PurchaseUserTransfer
            onSubmit={handleSubmitTransferEmail}
            isMLBInfoCollection={isMLBInfoCollection}
          />
        </CheckoutContainer>
      );
    }
    case PURCHASE_STEPS.SECURE: {
      return (
        <CheckoutContainer hideDetailsOnMobile>
          <PurchaseSecure />
        </CheckoutContainer>
      );
    }

    // post-purchase:
    case PURCHASE_STEPS.USER_PHONE: {
      return (
        <CheckoutContainer hideDetailsOnMobile>
          <PurchaseUserPhone onSubmit={handleSubmitUserPhone} />
        </CheckoutContainer>
      );
    }
    case PURCHASE_STEPS.USER_VERIFY: {
      return (
        <CheckoutContainer hideDetailsOnMobile>
          <PurchaseUserAddress onSubmit={handleSubmitVerifyBillingAddress} />
        </CheckoutContainer>
      );
    }
    case PURCHASE_STEPS.USER_ADDRESS: {
      return (
        <CheckoutContainer hideDetailsOnMobile>
          <PurchaseUserAddress onSubmit={handleSubmitUserDeliveryAddress} />
        </CheckoutContainer>
      );
    }

    // should not be reachable
    default: {
      return null;
    }
  }
}

export default PurchaseV3;
