import { CATEGORIES } from 'store/modules/categories/category.helpers';

export const SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS = [
  CATEGORIES.NFL,
  CATEGORIES.NBA,
  CATEGORIES.MLB,
  CATEGORIES.NHL,
  CATEGORIES.MLS,
];

export const PERFORMERS_CATEGORIES_FOR_SITEMAPS = [
  CATEGORIES.NFL,
  CATEGORIES.NBA,
  CATEGORIES.MLB,
  CATEGORIES.NHL,
  CATEGORIES.MLS,
  CATEGORIES.BOWL,
  CATEGORIES.CBB,
  CATEGORIES.CHO,
  CATEGORIES.CBS,
  CATEGORIES.WCBB,
  CATEGORIES.MUSIC,
  CATEGORIES.THEATER,
  CATEGORIES.COMEDY,
];
