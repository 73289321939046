import React from 'react';
import { useSelector } from 'react-redux';
import _merge from 'lodash/merge';
import Tag from 'ui/Tag/Tag';

import {
  Click,
  ClickTracker,
  FullEventClickTracker,
  useAnalyticsContext,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import xcoverLogoDark from 'assets/powered-by-xcover-logo-dark.png';
import xcoverLogoLight from 'assets/powered-by-xcover-logo-light.png';
import BulletList from 'components/BulletList/BulletList';
import Emoji from 'components/Emoji';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import { DetailBetMGMOffer } from 'components/OrderDetail';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import { device, useMediaQuery } from 'hooks';
import { CarIcon, CheckShieldIcon } from 'icons';
import { FullEvent } from 'models';
import { selectPopularCollectionByMetro } from 'store/modules/data/Collections/selectors';
import colors from 'styles/colors.constants';
import { InsuranceInfo, Purchase } from 'types';
import { formatPriceWithComma } from 'utils/number';

import { useVariantService } from '../../../../services/variants';

import styles from './OrderConfirmationDetails.module.scss';

type Props = {
  parkingEvent?: FullEvent;
  fullEvent: FullEvent;
  transactionId: string;
  insuranceInfo?: InsuranceInfo;
  purchase: Purchase;
  isBetMGMEligible: boolean;
};

const OrderConfirmationExtras = ({
  parkingEvent,
  fullEvent,
  transactionId,
  insuranceInfo,
  purchase,
  isBetMGMEligible,
}: Props) => {
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const variantService = useVariantService();

  const isLightTheme =
    variantService.getExperiment('checkout_v3').theme === 'light';
  const isMobile = useMediaQuery(device.down.md);
  const popularCollection = useSelector(selectPopularCollectionByMetro);

  if (!parkingEvent && !popularCollection && !insuranceInfo) {
    return null;
  }

  const trackParkingClick = () => {
    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.PARKING_MODULE(), {
        eventId: fullEvent.event.id,
        transactionId,
      })
      .targetPageType(Click.TARGET_PAGE_TYPES.EVENT(fullEvent.event.id));

    analytics.track(
      new Click(_merge({}, clickContext, tracker.json() as Click))
    );
  };

  const renderPopularItem = (event: FullEvent) => {
    const title = event.getShortName('@');
    const imageSrc = event.getPrimaryPerformer().heroImageUrl;
    const price = `From $${formatPriceWithComma(event.getPrice())}`;
    const bulletList = <BulletList listItems={event.dateTimeVenue} />;
    const tagData = event.getTagData();

    const tracker = new FullEventClickTracker(event)
      .sourcePageType(Click.SOURCE_PAGE_TYPES.ORDER_CONFIRMATION_V3())
      .interaction(Click.INTERACTIONS.TILE(), {
        collection: "Don't Miss Out",
        get_in_price: event.getPrice(),
      });

    const tagElement = tagData ? (
      <Tag
        title={tagData.title}
        icon={<Emoji emoji={tagData.emoji} variant="0xFE0F" />}
        background={tagData.background}
        color={colors.black}
        type="event"
      />
    ) : null;

    return (
      <Link clickTracker={tracker} to={event.getPath()}>
        <div className={styles['card-container']}>
          <Image
            className={styles['event-img']}
            src={imageSrc}
            alt={event.getAltText()}
          />

          <div className={styles['tag-container']}>{tagElement}</div>

          <div className={styles['event-details']}>
            <span className={styles['event-name']}>{title}</span>
            <span className={styles['event-date-time']}>{bulletList}</span>
            <span className={styles['event-amount']}>{price}</span>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className={styles['order-confirmation-extras']}>
      <div className={styles['cards-container']}>
        {parkingEvent && fullEvent.isValid() && (
          <div className={styles['extra-card-container']}>
            <div className={styles['extra-card-details']}>
              <figure className={styles.icon}>
                <CarIcon height="32" width="32" withGradient={false} />
              </figure>

              <div className={styles['extra-card-text-container']}>
                <span className={styles['extra-card-title']}>
                  Need a parking pass for this event?
                </span>

                <p className={styles['extra-card-text']}>
                  We’ve got you covered — find your spot!
                </p>
              </div>

              <a
                onClick={trackParkingClick}
                href={parkingEvent.getPath()}
                className={styles['extra-card-button']}
              >
                Find Parking
              </a>
            </div>

            <Image
              className={styles['parking-image']}
              src={parkingEvent.event.mapUrl}
              alt={parkingEvent.event.name}
            />
          </div>
        )}
        {insuranceInfo && insuranceInfo.status === 'confirmed' && (
          <div className={styles['extra-card-container']}>
            <div className={styles['extra-card-details']}>
              <figure className={styles.icon}>
                <CheckShieldIcon height="32" width="32" />
              </figure>

              <div className={styles['extra-card-text-container']}>
                <span className={styles['extra-card-title']}>
                  Your Purchase Protection
                </span>

                <p className={styles['extra-card-text']}>
                  Your purchase is protected. Check your email for details about
                  your booking from XCover.
                </p>
              </div>

              <div className={styles['extra-card-footer']}>
                <a
                  href="https://www.xcover.com/en-us"
                  className={styles['extra-card-button']}
                >
                  Learn More
                </a>

                <img
                  className={styles['xcover-logo']}
                  src={isLightTheme ? xcoverLogoLight : xcoverLogoDark}
                  alt="xcover logo"
                />
              </div>
            </div>
          </div>
        )}
        {isBetMGMEligible && (
          <div className={styles['bet-mgm-container']}>
            <DetailBetMGMOffer
              eventId={purchase.event_id}
              transactionId={purchase.id}
            />
          </div>
        )}
      </div>

      {popularCollection?.eventsList &&
        popularCollection.eventsList.length > 0 && (
          <div className={styles['miss-out-carousel']}>
            <ReactSlickCarousel
              title="Don't Miss Out"
              items={popularCollection.eventsList}
              renderItem={renderPopularItem}
              renderHeader={() => (
                <span className={styles['carousel-header']}>
                  Don't Miss Out
                </span>
              )}
              spacing={16}
              slideWidth={217}
              trackMargin={isMobile ? 16 : 0}
              className={styles['space-between']}
              topArrows
              // arrowClass is a wip, we need a clean solution for changing the arrow appearance
              arrowClass={styles['top-arrow-override']}
              getItemKey={(fullEvent: FullEvent) => fullEvent.event.id}
            />
          </div>
        )}
    </div>
  );
};

export default OrderConfirmationExtras;
