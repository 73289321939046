import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import {
  Click,
  FullEventClickTracker,
  PAYLOAD,
  PerformerClickTracker,
  SearchPageClickTracker,
  TRACK,
  VenueClickTracker,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { EVENT_CELL_TYPES } from 'components/Event/EventCell/EventCell';
import EventContainer from 'components/Event/EventContainer';
import Link from 'components/Link/Link';
import {
  selectIsHomepageRedesignV1Experiment,
  selectIsShowEventNamesForNonSportsExperiment,
} from 'experiments';
import CircleArrowIcon from 'icons/CircleArrowIcon';
import { getCategoryName } from 'store/modules/categories/category.helpers';
import { selectSearchTestData } from 'store/modules/data/Search/selectors';
import colors from 'styles/colors.constants';
import { isObjectEmpty } from 'utils/objects';
import { addQuery } from 'utils/url';

import { getSearchPath, SEARCH_PAGE_TYPES } from '../Search.constants';
import SearchRecommended from '../SearchRecommended/SearchRecommended';

import MiniCard from './MiniCard/MiniCard';

import styles from './SearchResults.module.scss';

const propTypes = {
  searchText: PropTypes.string,
  searchResults: PropTypes.array.isRequired,
  searchResultsLength: PropTypes.number,
  show: PropTypes.bool.isRequired,
  noResults: PropTypes.bool.isRequired,
  handleActiveIndexChange: PropTypes.func,
  onResultClick: PropTypes.func,
  className: PropTypes.string,
  showMoreResults: PropTypes.bool,
  activeIndex: PropTypes.number,
  searchSessionID: PropTypes.string,
  keystrokeSequence: PropTypes.number,
  apiSequence: PropTypes.number,
  pageType: PropTypes.oneOf(Object.values(SEARCH_PAGE_TYPES)).isRequired,
  isMobile: PropTypes.bool,
  isSearchBar: PropTypes.bool,
  recommended: PropTypes.object,
  location: PropTypes.object.isRequired,
  searchTestData: PropTypes.shape({
    testId: PropTypes.string,
    variantId: PropTypes.string,
  }),
  isHomeRedesign: PropTypes.bool,
  isShowEventNamesForNonSportsExperiment: PropTypes.bool.isRequired,
};

@withClickContext(() => ({
  [TRACK.INTERACTION]: Click.INTERACTIONS.SEARCH_TILE(),
}))
class SearchResults extends Component {
  constructor(props) {
    super(props);
  }

  renderResultsSections() {
    const {
      searchResults,
      onResultClick = () => {},
      activeIndex,
      handleActiveIndexChange = () => {},
      searchText = '',
      searchSessionID,
      pageType,
      isSearchBar,
      location,
      searchTestData,
      isShowEventNamesForNonSportsExperiment,
    } = this.props;

    const results = searchResults.map((section, sectionIndex) => {
      const sectionTitle = section.info.display_name || '';

      const ret =
        section.items.length > 0 ? (
          <div key={section.info.slug}>
            <div className={styles['section-title']}>{sectionTitle}</div>

            {section.items.map((item, itemIndex) => {
              const sectionBaseIndex = searchResults.reduce(
                (acc, cur, idx) =>
                  idx >= sectionIndex ? acc : cur.items.length + acc,
                0
              );

              const resultIndex = sectionBaseIndex + itemIndex;
              const active = activeIndex === resultIndex;

              if (item.event) {
                const fullEvent = item.event;
                const { queryId, resultPosition, searchIndex } =
                  fullEvent.algoliaFields;
                const clickTracker = new FullEventClickTracker(
                  fullEvent
                ).payload({
                  [PAYLOAD.SECTION_TITLE]: sectionTitle,
                  [PAYLOAD.SECTION_INDEX]: sectionIndex,
                  [PAYLOAD.ITEM_INDEX]: itemIndex,
                  [PAYLOAD.ABSOLUTE_INDEX]: resultIndex,
                  [PAYLOAD.SEARCH_TERM]: searchText,
                  [PAYLOAD.UUID]: searchSessionID,
                  [PAYLOAD.PAGE_TYPE]: pageType,
                  [PAYLOAD.QUERY_ID]: queryId,
                  [PAYLOAD.RESULT_POSITION]: resultPosition,
                  [PAYLOAD.SEARCH_INDEX]: searchIndex,
                  [PAYLOAD.SEARCH_SESSION_ID]: searchSessionID,
                  [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
                  [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
                });

                return (
                  <div
                    key={resultIndex}
                    className={classNames([
                      styles.event,
                      styles.result,
                      { active: styles.active },
                    ])}
                    onMouseOver={() => handleActiveIndexChange(resultIndex)}
                    onFocus={() => handleActiveIndexChange(resultIndex)}
                  >
                    <EventContainer
                      fullEvent={fullEvent}
                      type={
                        isSearchBar
                          ? EVENT_CELL_TYPES.SHORT
                          : EVENT_CELL_TYPES.DEFAULT
                      }
                      isShowEventNamesForNonSportsExperiment={
                        isShowEventNamesForNonSportsExperiment
                      }
                      onClick={onResultClick}
                      clickTracker={clickTracker}
                      isActive={active}
                      isSearchBar={isSearchBar}
                    />
                  </div>
                );
              }

              if (item.venue) {
                const { venue } = item;
                const { queryId, resultPosition, searchIndex } =
                  venue.algoliaFields;
                const clickTracker = new VenueClickTracker(venue).payload({
                  [PAYLOAD.SECTION_TITLE]: sectionTitle,
                  [PAYLOAD.SECTION_INDEX]: sectionIndex,
                  [PAYLOAD.ITEM_INDEX]: itemIndex,
                  [PAYLOAD.ABSOLUTE_INDEX]: resultIndex,
                  [PAYLOAD.SEARCH_TERM]: searchText,
                  [PAYLOAD.UUID]: searchSessionID,
                  [PAYLOAD.PAGE_TYPE]: pageType,
                  [PAYLOAD.QUERY_ID]: queryId,
                  [PAYLOAD.RESULT_POSITION]: resultPosition,
                  [PAYLOAD.SEARCH_INDEX]: searchIndex,
                  [PAYLOAD.SEARCH_SESSION_ID]: searchSessionID,
                  [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
                  [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
                });

                const venueUrl = venue.algoliaFields
                  ? addQuery(
                      venue.getPath(),
                      location.search,
                      venue.algoliaFields
                    )
                  : venue.getPath();

                return (
                  <div
                    key={resultIndex}
                    className={classNames([
                      styles.result,
                      { active: styles.active },
                    ])}
                    onMouseOver={() => handleActiveIndexChange(resultIndex)}
                    onFocus={() => handleActiveIndexChange(resultIndex)}
                  >
                    <MiniCard
                      key={venue.name}
                      onClick={onResultClick}
                      imageProps={venue.getImageOptions()}
                      title={venue.name}
                      subtitle={<span>{venue.getLocation()}</span>}
                      to={venueUrl}
                      clickTracker={clickTracker}
                      isActive={active}
                      isSearchBar={isSearchBar}
                    />
                  </div>
                );
              }

              if (item.performer) {
                const { performer } = item;
                const { queryId, resultPosition, searchIndex } =
                  performer.algoliaFields;
                const clickTracker = new PerformerClickTracker(
                  performer
                ).payload({
                  [PAYLOAD.SECTION_TITLE]: sectionTitle,
                  [PAYLOAD.SECTION_INDEX]: sectionIndex,
                  [PAYLOAD.ITEM_INDEX]: itemIndex,
                  [PAYLOAD.ABSOLUTE_INDEX]: resultIndex,
                  [PAYLOAD.SEARCH_TERM]: searchText,
                  [PAYLOAD.UUID]: searchSessionID,
                  [PAYLOAD.PAGE_TYPE]: pageType,
                  [PAYLOAD.QUERY_ID]: queryId,
                  [PAYLOAD.RESULT_POSITION]: resultPosition,
                  [PAYLOAD.SEARCH_INDEX]: searchIndex,
                  [PAYLOAD.SEARCH_SESSION_ID]: searchSessionID,
                  [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
                  [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
                });

                const performerUrl = performer.algoliaFields
                  ? addQuery(
                      performer.getPath(),
                      location.search,
                      performer.algoliaFields
                    )
                  : performer.getPath();

                return (
                  <div
                    key={resultIndex}
                    className={classNames([
                      styles.result,
                      { active: styles.active },
                    ])}
                    onMouseOver={() => handleActiveIndexChange(resultIndex)}
                    onFocus={() => handleActiveIndexChange(resultIndex)}
                  >
                    <MiniCard
                      key={performer.name}
                      onClick={onResultClick}
                      imageProps={performer.getImageOptions()}
                      title={performer.displayName}
                      subtitle={
                        <span>{getCategoryName(performer.category)}</span>
                      }
                      to={performerUrl}
                      clickTracker={clickTracker}
                      isActive={active}
                      isSearchBar={isSearchBar}
                      emoji={performer.isSportsCategoryGroup && performer.emoji}
                      primaryColor={performer.primaryColor}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : null;

      return ret;
    });

    return <div>{results}</div>;
  }

  renderMoreResultsSection() {
    const {
      searchText = '',
      onResultClick = () => {},
      activeIndex,
      handleActiveIndexChange = () => {},
      searchSessionID,
      keystrokeSequence,
      apiSequence,
      searchResultsLength,
    } = this.props;
    const searchSessionData = {
      searchSessionID,
      keystrokeSequence,
      apiSequence,
    };
    const active = activeIndex === searchResultsLength;
    const fillColor = active ? colors.gray800 : colors.gray243;
    return (
      <div
        className={classNames([
          styles['more-results-container'],

          { [styles.active]: active },
        ])}
        onMouseOver={() => handleActiveIndexChange(searchResultsLength)}
        onFocus={() => handleActiveIndexChange(searchResultsLength)}
      >
        <Link
          className={styles['more-results']}
          to={getSearchPath(searchText, searchSessionData)}
          onClick={onResultClick}
          clickTracker={new SearchPageClickTracker().interaction(
            Click.INTERACTIONS.SEE_MORE(),
            {
              [PAYLOAD.SEARCH_TERM]: searchText,
              [PAYLOAD.UUID]: searchSessionID,
            }
          )}
        >
          <div className={styles['more-copy']}>See more results</div>
          <CircleArrowIcon fill={fillColor} />
        </Link>
      </div>
    );
  }

  renderMobileSearchResults() {
    const { show, className, noResults, showMoreResults } = this.props;

    return (
      <div
        className={classNames([
          styles['search-results'],
          styles['search-mobile'],
          {
            [styles['search-overlay']]: !show,
          },
          className,
        ])}
      >
        {show && noResults && <div className={styles.title}>No results</div>}
        {show && !noResults && this.renderResultsSections()}
        {show && showMoreResults && this.renderMoreResultsSection()}
      </div>
    );
  }

  renderDesktopSearchResults() {
    const { show, className, noResults, showMoreResults } = this.props;

    return show ? (
      <div
        className={classNames(className, [
          styles['search-results'],
          styles['search-desktop'],
        ])}
      >
        {noResults && <div className={styles.title}>No results</div>}
        {!noResults && this.renderResultsSections()}
        {showMoreResults && this.renderMoreResultsSection()}
      </div>
    ) : null;
  }

  renderFullPageSearchResults() {
    const { className, noResults, showMoreResults } = this.props;
    return (
      <div>
        <div
          className={classNames([
            styles['search-results'],
            styles['full-page'],
            className,
          ])}
        >
          {noResults && <div className={styles.title}>No results</div>}
          {!noResults && this.renderResultsSections()}
          {showMoreResults && this.renderMoreResultsSection()}
        </div>
      </div>
    );
  }

  getContent() {
    const {
      show,
      isSearchBar,
      isMobile,
      recommended,
      searchResults,
      onResultClick = () => {},
    } = this.props;

    if (show && searchResults.length === 0 && !isObjectEmpty(recommended)) {
      return <SearchRecommended onClick={onResultClick} isMobile={isMobile} />;
    }

    if (!isSearchBar) {
      return this.renderFullPageSearchResults();
    }

    if (isMobile) {
      return this.renderMobileSearchResults();
    }

    return this.renderDesktopSearchResults();
  }

  render() {
    const { isHomeRedesign, isSearchBar } = this.props;
    return (
      <div
        className={classNames({
          [styles['home-redesign']]: isHomeRedesign && isSearchBar,
        })}
      >
        {this.getContent()}
      </div>
    );
  }
}

SearchResults.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    searchTestData: selectSearchTestData(state),
    isHomeRedesign: selectIsHomepageRedesignV1Experiment(state),
    isShowEventNamesForNonSportsExperiment:
      selectIsShowEventNamesForNonSportsExperiment(state),
  };
}

export default withRouter(connect(mapStateToProps)(SearchResults));
