import React from 'react';
import { useAppContext } from 'contexts/AppContext';

import config from 'config/config';

import styles from './GetAppPromo.module.scss';

type Props = {
  title: string;
  text: string;
};

const GetAppPromo = ({ title, text }: Props) => {
  const { isAndroid } = useAppContext().state;

  return (
    <div className={styles['get-app-container']}>
      <div className={styles['get-app-card']}>
        <h4 className={styles['get-app-card-title']}>{title}</h4>
        <p className={styles['get-app-card-text']}>{text}</p>
        <div className={styles['get-app-card-link']}>
          <a
            href={isAndroid ? config.PLAY_STORE_URL : config.APP_STORE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.cta}
          >
            Download the App
          </a>
        </div>
        <span className={styles['get-app-available']}>
          available on ios & android
        </span>
      </div>
    </div>
  );
};

export default GetAppPromo;
