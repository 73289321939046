import React from 'react';
import classNames from 'classnames';

import { CloseLineIcon } from '../../icons';

import styles from './Dialog.module.scss';

export default function Dialog({
  children,
  isBottomDrawerOnMobile = true,
  isOpen,
  onClose,
  title,
}: {
  children?: React.ReactNode;
  isBottomDrawerOnMobile?: boolean;
  isOpen: boolean;
  title: string;
  onClose: () => void;
}): React.JSX.Element {
  return (
    <dialog
      open={isOpen}
      className={classNames(styles.component, {
        [styles['bottom-drawer']]: isBottomDrawerOnMobile,
      })}
      data-testid="dialog"
    >
      <div className={styles.inner}>
        <header className={styles.header} data-testid="dialog-header">
          <h1 className={styles.headline} data-testid="dialog-headline">
            {title}
          </h1>
          <button
            autoFocus
            aria-label="Close dialog"
            className={styles['button-close']}
            data-testid="dialog-button-close"
            onClick={onClose}
          >
            <CloseLineIcon fill="currentColor" />
          </button>
        </header>
        {children && (
          <section className={styles.content} data-testid="dialog-content">
            {children}
          </section>
        )}
      </div>
    </dialog>
  );
}
