import React from 'react';

export default ({fill = '#FFFFFF', width = 24, height = 24}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19,2H5C3.3,2,2,3.3,2,5v14c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3V5C22,3.3,20.7,2,19,2z M17,18.5	c0,0.3-0.2,0.5-0.5,0.5h-9C7.2,19,7,18.8,7,18.5v-1C7,17.2,7.2,17,7.5,17h9c0.3,0,0.5,0.2,0.5,0.5V18.5z M16.9,10.6l-4.1,4.1	c-0.4,0.4-1,0.4-1.4,0l-4.1-4.1C7,10.4,7,10,7.1,9.9l0.7-0.7C8,9,8.4,9,8.6,9.1l2.4,2.4V5.5C11,5.2,11.2,5,11.5,5h1	C12.8,5,13,5.2,13,5.5v6.1l2.4-2.4C15.6,9,16,9,16.1,9.1l0.7,0.7C17,10,17,10.4,16.9,10.6z"
      fill={fill}
    />
  </svg>
);
