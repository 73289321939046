import React from 'react';

type Props = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'aria-hidden' | 'fill'
>;

export default function PhoneSendIcon({
  width = '32',
  height = '32',
  'aria-hidden': ariaHidden = true,
  fill = '#fff',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={ariaHidden}
    >
      <g>
        <path
          d="M22.6667 21.3335L20.8001 19.4668L22.8667 17.3335H16.0001V14.6668H22.8667L20.8001 12.5335L22.6667 10.6668L28.0001 16.0002L22.6667 21.3335ZM9.33341 30.6668C8.60008 30.6668 7.9723 30.4057 7.45008 29.8835C6.92786 29.3613 6.66675 28.7335 6.66675 28.0002V4.00016C6.66675 3.26683 6.92786 2.63905 7.45008 2.11683C7.9723 1.59461 8.60008 1.3335 9.33341 1.3335H22.6667C23.4001 1.3335 24.0279 1.59461 24.5501 2.11683C25.0723 2.63905 25.3334 3.26683 25.3334 4.00016V9.3335H22.6667V8.00016H9.33341V24.0002H22.6667V22.6668H25.3334V28.0002C25.3334 28.7335 25.0723 29.3613 24.5501 29.8835C24.0279 30.4057 23.4001 30.6668 22.6667 30.6668H9.33341Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
