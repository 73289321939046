import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { FullEvent, Listing } from 'models';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import { updateMapHarmony } from 'store/modules/data/Listings/actions';
import { updatingMapHarmonySelector } from 'store/modules/data/Listings/selectors';
import {
  selectIsListingsLoading,
  selectIsListMapHarmonyEnabled,
} from 'store/modules/listingsV3/selectors';

import styles from '../ListingCard/ListingCard.module.scss';

const Listings = ({
  fullEvent,
  listings,
  onListingHover,
  allDisclosures,
  allDeals,
  isUpdatingMapHarmony,
  dispatch,
  isListingsLoading,
  isListMapHarmonyEnabled,
}) => {
  const timer = useRef(null);
  const isDeviceLargeUp = useMediaQuery(device.up.lg);

  useEffect(() => {
    const handleUpdate = () => {
      if (isUpdatingMapHarmony) {
        timer.current = setTimeout(() => {
          dispatch(updateMapHarmony(false));
        }, 500);
      } else if (isListingsLoading) {
        // handles omnibar re-fetching harmony
        if (isListMapHarmonyEnabled) {
          dispatch(updateMapHarmony());
        }
      }
    };
    if (isDeviceLargeUp) {
      handleUpdate();
    }

    if (!isDeviceLargeUp && isUpdatingMapHarmony) {
      dispatch(updateMapHarmony(false));
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [
    isUpdatingMapHarmony,
    dispatch,
    isListingsLoading,
    isListMapHarmonyEnabled,
    isDeviceLargeUp,
    fullEvent,
  ]);

  if (!listings?.length) {
    return null;
  }

  const listingRows = listings.map((listing, i) => {
    if (!listing.isVisibleInMap && isListMapHarmonyEnabled) {
      return null;
    }

    return (
      <ListingCard
        key={listing.id}
        listing={listing}
        fullEvent={fullEvent}
        onHover={onListingHover}
        listingIndex={i}
        allDisclosures={allDisclosures}
        allDeals={allDeals}
      />
    );
  });

  return (
    <div className={classNames({ [styles.disabled]: isListingsLoading })}>
      {listingRows}
    </div>
  );
};

Listings.propTypes = {
  onListingHover: PropTypes.func,
  fullEvent: PropTypes.instanceOf(FullEvent),
  listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  isUpdatingMapHarmony: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isListingsLoading: PropTypes.bool.isRequired,
  isListMapHarmonyEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isListingsLoading: selectIsListingsLoading(state),
    isUpdatingMapHarmony: updatingMapHarmonySelector(state),
    isListMapHarmonyEnabled: selectIsListMapHarmonyEnabled(state),
  };
};

export default connect(mapStateToProps)(Listings);
