import React from 'react';
import PropTypes from 'prop-types';

import { VariantDriverContract } from '../../drivers/variants';

import useVariantService from './useVariantService';

export default function withVariantContext<
  Props extends Record<string, unknown>,
>(
  Component: React.ComponentType<
    Props & { variantContext: VariantDriverContract }
  >
) {
  const WithVariantContextComponent = (props: Props) => {
    const context = useVariantService();

    return <Component {...props} variantContext={context} />;
  };

  return WithVariantContextComponent;
}

export const PropType = PropTypes.shape({
  capture: PropTypes.func,
  getConfig: PropTypes.func,
  getExperiment: PropTypes.func,
  getParameter: PropTypes.func,
  hasFeature: PropTypes.func,
  updateUser: PropTypes.func,
});
