import React from 'react';
import classNames from 'classnames';

import { PrivacyOptionsIcon } from 'icons';
import { LogoLarge } from 'logos';

import styles from './CheckoutFooter.module.scss';

const navLinkList = [
  ['privacy-policy', 'Privacy Policy'],
  ['terms-of-service', 'Terms of Service'],
  ['html-sitemap', 'Site Map'],
];

const handlePrivacyChoicesClick = (e: React.MouseEvent) => {
  e.preventDefault();

  if (typeof window !== 'undefined' && Array.isArray(window.semaphore)) {
    window.semaphore.push(['showPreferences']);
  }
};

const CheckoutFooter = () => (
  <footer className={styles.footer}>
    <section className={styles['footer-inner']}>
      <figure className={styles.logo}>
        <a
          href="/"
          className={styles['logo-link']}
          aria-label="Gametime Homepage"
        >
          <LogoLarge
            primaryColor="currentColor"
            secondaryColor="currentColor"
            width="125"
          />
        </a>
      </figure>

      <span className={styles.copyright}>
        {`© ${new Date().getFullYear()} Gametime United, Inc.`}
      </span>

      <nav className={styles['footer-nav']}>
        <ul className={styles['footer-nav-list']}>
          <li className={styles['footer-nav-item']}>
            <button
              type="button"
              className={classNames(
                styles['footer-link'],
                styles['privacy-link']
              )}
              onClick={handlePrivacyChoicesClick}
            >
              <span className={styles['privacy-content']}>
                Your Privacy Choices
              </span>
              <PrivacyOptionsIcon />
            </button>
          </li>
          {navLinkList.map(([path, title]) => (
            <li className={styles['footer-nav-item']} key={path}>
              <a href={`/${path}`} className={styles['footer-link']}>
                {title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </section>
  </footer>
);

export default CheckoutFooter;
