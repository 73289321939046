import React from 'react';
import PropTypes from 'prop-types';

import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import colors from 'styles/colors.constants';

import EventInfo from '../../components/EventInfo';
import { OmnibarControls } from '../../components/Omnibar';

import styles from './EventHeader.module.scss';

const EventHeader = ({
  fullEvent,
  quantity,
  noInventory,
  isAllInPriceActive,
  sortId,
  view,
  isListing,
  isPurchaseRoute,
  onBack,
  handleOpenSBModal,
  handleOmnibarControls,
  showSidebarControls,
  variant,
  persistListings,
  isGTPicksFilterActive,
}) => {
  const isDesktop = useMediaQuery(device.up.lg);
  const showListingsForOverlay = persistListings && isDesktop;

  if (
    (variant === 'top' && isDesktop) ||
    (variant === 'sidebar' && !isDesktop)
  ) {
    return null;
  }

  if (showListingsForOverlay) {
    /* Persists Listings on view when overlay is active */
    isListing = false;
    showSidebarControls = true;
  }

  return (
    <>
      <div className={styles['header-section-container']}>
        {isListing && <div className={styles['image-gradient']} />}

        {showSidebarControls && (
          <>
            <InfoHeader
              headerContent={
                <EventInfo
                  fullEvent={fullEvent}
                  overlay={isListing}
                  venueNameSmall
                />
              }
              onBack={onBack}
              showBack={!isPurchaseRoute}
              backIconColor={isListing ? colors.baseLight : colors.white}
              onInfoClick={handleOpenSBModal}
            />
            <OmnibarControls
              view={view}
              sortId={sortId}
              fullEvent={fullEvent}
              seatCount={quantity}
              noInventory={noInventory}
              isAllInPriceActive={isAllInPriceActive}
              onClick={handleOmnibarControls}
              isGTPicksFilterActive={isGTPicksFilterActive}
            />
          </>
        )}
      </div>
    </>
  );
};

EventHeader.propTypes = {
  fullEvent: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  sortId: PropTypes.string.isRequired,
  isAllInPriceActive: PropTypes.bool.isRequired,
  noInventory: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  isListing: PropTypes.bool.isRequired,
  isPurchaseRoute: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  handleOpenSBModal: PropTypes.func.isRequired,
  handleOmnibarControls: PropTypes.func.isRequired,
  showSidebarControls: PropTypes.bool,
  variant: PropTypes.oneOf(['sidebar', 'top']),
  persistListings: PropTypes.bool,
  isGTPicksFilterActive: PropTypes.bool.isRequired,
};

export default EventHeader;
