import React from 'react';

const CarIcon = ({ width = '32', height = '24', withGradient = true, fill = 'currentColor'}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.27234 8.57305C5.92126 7.15353 6.52962 5.69346 7.34078 4.07115C7.78691 3.30055 8.2736 2.61107 9.16587 2.52996H22.8338C23.7261 2.61107 24.2128 3.30055 24.6589 4.07115C25.4701 5.69346 26.0784 7.15353 26.7273 8.57305H5.27234ZM11.6804 14.4134H20.2787C20.8059 14.4134 21.2115 14.8595 21.2115 15.3867C21.2115 15.8734 20.8059 16.3196 20.2787 16.3196H11.6804C11.1938 16.3196 10.7476 15.8734 10.7476 15.3867C10.7476 14.8595 11.1938 14.4134 11.6804 14.4134ZM9.16587 0.339844C7.7058 0.339844 6.81353 0.867093 5.5968 2.57052L3.56891 7.15353H0.486531C-0.000160711 7.15353 -0.892429 9.66811 2.35218 9.74922L1.25713 12.3855C1.29768 15.7518 1.29768 19.0775 1.29768 22.4032C1.29768 23.0927 1.86549 23.6605 2.55497 23.6605H6.77297C7.46245 23.6605 7.9897 23.0927 7.9897 22.4032V19.6453H23.9694V22.4032C23.9694 23.0927 24.5372 23.6605 25.2267 23.6605H29.4447C30.1342 23.6605 30.6614 23.0927 30.6614 22.4032C30.6614 19.0775 30.702 15.7518 30.702 12.3855L29.6069 9.74922C32.8921 9.66811 31.9593 7.15353 31.5131 7.15353H28.4308L26.4029 2.57052C25.1861 0.867093 24.2939 0.339844 22.8338 0.339844H9.16587ZM26.119 12.6288C25.0239 12.6288 24.0911 13.5211 24.0911 14.6567C24.0911 15.7518 25.0239 16.6846 26.119 16.6846C27.2546 16.6846 28.1469 15.7518 28.1469 14.6567C28.1469 13.5211 27.2546 12.6288 26.119 12.6288ZM5.8807 12.6288C6.97576 12.6288 7.90858 13.5211 7.90858 14.6567C7.90858 15.7518 6.97576 16.6846 5.8807 16.6846C4.74509 16.6846 3.85282 15.7518 3.85282 14.6567C3.85282 13.5211 4.74509 12.6288 5.8807 12.6288Z"
        fill={withGradient ? 'url(#paint0_linear)' : fill}
      />
      {withGradient && <defs>
        <linearGradient
          id="paint0_linear"
          x1="-0.00512695"
          y1="0.339844"
          x2="-0.00512695"
          y2="23.6605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65FFB5" />
          <stop offset="1" stopColor="#00D5E6" />
        </linearGradient>
      </defs>}
    </svg>
  );
};

export default CarIcon;
