import React from 'react';

import { HomeClickTracker } from 'analytics';
import Link from 'components/Link/Link';
import { PrivacyOptionsIcon } from 'icons';
import { LogoLarge } from 'logos';
import colorsConstants from 'styles/colors.constants';

import {
  generateFooterLinks,
  LEGAL_ROUTES,
  Section,
} from '../footer.constants';

import styles from './GTFooter.module.scss';

export default function GTFooter() {
  const renderLinks = () => {
    return generateFooterLinks().map((section: Section) => {
      return (
        <div key={section.title}>
          <div className={styles['link-block']}>
            <h5 className={styles['link-title']}>{section.title}</h5>
            <ul className={styles['link-list']}>
              {section.links.map(({ title, clickTracker, ...props }) => {
                return (
                  <li key={title} className={styles['link-list-item']}>
                    <Link
                      clickTracker={clickTracker}
                      className={styles['footer-link']}
                      {...props}
                    >
                      {title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    });
  };

  const handlePrivacyChoicesClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (typeof window !== 'undefined' && Array.isArray(window.semaphore)) {
      window.semaphore.push(['showPreferences']);
    }
  };

  return (
    <footer className={styles['main-container']}>
      <div className={styles.content}>
        <div className={styles['links-container']}>{renderLinks()}</div>
        <div className={styles['legal-container']}>
          <div className={styles['logo-rights-container']}>
            <Link
              className={styles['footer-link']}
              to="/"
              clickTracker={new HomeClickTracker()}
            >
              <LogoLarge
                className={styles.logo}
                primaryColor={colorsConstants.gray200}
                secondaryColor={colorsConstants.gray200}
                height={40}
              />
            </Link>
            <span className={styles.copyright}>
              {'© '}
              {new Date().getFullYear()}
              {' Gametime United, Inc.'}
            </span>
          </div>
          <div className={styles['privacy-container']}>
            <div className={styles['privacy-choices']}>
              <button
                type="button"
                className={styles['privacy-link']}
                onClick={handlePrivacyChoicesClick}
              >
                Your Privacy Choices
              </button>
              <PrivacyOptionsIcon />
            </div>
            <Link
              className={styles['footer-link']}
              to={`/${LEGAL_ROUTES.PRIVACY.path}`}
            >
              Privacy Policy
            </Link>
            <Link
              className={styles['footer-link']}
              to={`/${LEGAL_ROUTES.TOS.path}`}
            >
              Terms of Service
            </Link>
            <Link
              className={styles['footer-link']}
              to={`/${LEGAL_ROUTES.SITEMAP.path}`}
            >
              Sitemap
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
