import React, { useEffect } from 'react';

interface Props {
  total: number;
}

export default function AffirmPromoMessage({ total }: Props) {
  useEffect(() => {
    window.affirm?.ui.ready(() => {
      window.affirm?.ui.refresh();
    });
  }, [total]);

  return (
    <div
      className="affirm-as-low-as"
      data-page-type="cart"
      data-amount={total * 100}
      data-affirm-color="white"
      data-learnmore-show={false}
    />
  );
}
