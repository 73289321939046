import { storage } from 'utils/storage';

const SB_EVENT_STORAGE_KEY = 'SB_EVENT_STORAGE_KEY';

/**
 * @deprecated just use the Listing model dealType
 */
export function isListingZoneDeal(listing) {
  return listing?.dealType === 'zone';
}

export function hasSeenSBModal(eventId) {
  const superBowlId = storage(SB_EVENT_STORAGE_KEY).getItem();
  return superBowlId === eventId;
}

export function setSBModalSeen(eventId) {
  const { setItem } = storage(SB_EVENT_STORAGE_KEY);

  setItem(eventId);
}
