import React from 'react';

import {
  Form,
  Input,
  Validators,
} from '../../../../pages/CheckoutV3/components/Forms';

export const codeLength = 6;

const validationSchema = Validators.schema({
  code: Validators.oneTimePassCode({ label: 'SMS code', length: codeLength }),
});

type Props = {
  email: string;
  hasError?: boolean;
  handleSMSCodeLogin: (
    email: string,
    code: string,
    isPasted?: boolean,
    isResent?: boolean
  ) => void;
  isResent?: boolean;
};

type FormValues = {
  code: string;
};

function SMSVerificationFormV3({
  email,
  handleSMSCodeLogin,
  hasError = false,
  isResent = false,
}: Props) {
  const [isPasted, setIsPasted] = React.useState(false);

  return (
    <Form
      autoSubmitWhenValid
      id="sms-verification-form"
      initialValues={{ code: '' }}
      onSubmit={async (
        values: FormValues,
        { setSubmitting, setFieldError }
      ) => {
        setSubmitting(true);
        await handleSMSCodeLogin(email, values.code, isPasted, isResent);

        if (hasError) {
          setFieldError(
            'code',
            'The confirmation code is invalid or has expired'
          );
        }

        setSubmitting(false);
      }}
      submitText="Verify"
      validateOnChange
      validationSchema={validationSchema}
    >
      {/* TODO: Should this use Maskito to disable characters other than numbers? */}
      <Input
        autoComplete="one-time-code"
        id="code"
        label="Confirmation Code"
        maxLength={codeLength}
        minLength={codeLength}
        name="code"
        pattern="\d{6}"
        onPaste={(): void => setIsPasted(true)}
        type="text"
      />
    </Form>
  );
}

export default SMSVerificationFormV3;
