import React from 'react';

export default ({fill = '#FFFFFF', width = 24, height = 24}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 7C20.5523 7 21 7.44772 21 8V20C21 20.5523 20.5523 21 20 21H15V7H20Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H5V17.5C5 17.2239 5.22386 17 5.5 17H7.5C7.77614 17 8 17.2239 8 17.5V22H15C16.1046 22 17 21.1046 17 20V4C17 2.89543 16.1046 2 15 2H4ZM5.5 5C5.22386 5 5 5.22386 5 5.5V7.5C5 7.77614 5.22386 8 5.5 8H7.5C7.77614 8 8 7.77614 8 7.5V5.5C8 5.22386 7.77614 5 7.5 5H5.5ZM11 5.5C11 5.22386 11.2239 5 11.5 5H13.5C13.7761 5 14 5.22386 14 5.5V7.5C14 7.77614 13.7761 8 13.5 8H11.5C11.2239 8 11 7.77614 11 7.5V5.5ZM5.5 11C5.22386 11 5 11.2239 5 11.5V13.5C5 13.7761 5.22386 14 5.5 14H7.5C7.77614 14 8 13.7761 8 13.5V11.5C8 11.2239 7.77614 11 7.5 11H5.5ZM11 11.5C11 11.2239 11.2239 11 11.5 11H13.5C13.7761 11 14 11.2239 14 11.5V13.5C14 13.7761 13.7761 14 13.5 14H11.5C11.2239 14 11 13.7761 11 13.5V11.5Z"
      fill={fill}
    />
  </svg>
);
