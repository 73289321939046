import React from 'react';

import { Listing } from '../../../../models';
import { URGENCY_MESSAGING_THRESHOLD as threshold } from '../../../../pages/Listing/constants';

import styles from './UrgencyMesage.module.scss';

function canShow(lotSize: number): boolean {
  // TODO: This is replicated logic from the existing component, confirm this is right.
  if (lotSize === 0) {
    return false;
  }

  if (lotSize <= threshold) {
    return true;
  }

  return false;
}

// Note, this will need to be reworked to account for other languages
function pluralize(word: string, condition: boolean) {
  return condition ? `${word}s` : word;
}

export default function UrgencyMessage({
  listing,
}: {
  listing?: Listing;
}): React.JSX.Element | null {
  const lotSize = listing?.getMaxLotSize();
  if (!lotSize) {
    return null;
  }

  if (!canShow(lotSize)) {
    return null;
  }

  const word = pluralize('ticket', lotSize > 1);

  return (
    <aside className={styles.component} role="alert">
      {`Only ${lotSize} ${word} left at this price!`}
    </aside>
  );
}
