import { Performer, Venue } from 'models';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';
import colors from 'styles/colors.constants';
import { Metro } from 'types';

import { SitemapURLItem } from './components/SitemapURLsList/SitemapURLsList';
import { SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS } from './constants';

export const getPerformersSitemapURLItems = (performers: Performer[]) => {
  return performers.map((performer) => ({
    name: performer.name,
    path: performer.getPath(),
  }));
};

export const getVenuesSitemapURLItems = (venues: Venue[]) => {
  return venues.map((venue) => ({
    name: venue.name,
    path: venue.getPath(),
  }));
};

export const getMetrosSitemapURLItems = (metros: Metro[]) => {
  return metros.map((metro) => ({
    name: `Events in ${metro.name}`,
    path: getMetroPerformersPathByMetro(metro),
  }));
};

export const addMoreTicketsLink = ({
  sitemapURLItems,
  name,
  path,
}: {
  sitemapURLItems: SitemapURLItem[];
  name: string;
  path: string;
}) => {
  sitemapURLItems.push({
    name,
    path,
    color: colors.white,
  });
};

export const addMatchupsLink = ({
  sitemapURLItems,
  category,
}: {
  sitemapURLItems: SitemapURLItem[];
  category: string;
}) => {
  if (
    SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS.includes(category) &&
    sitemapURLItems.length
  ) {
    sitemapURLItems.push({
      name: `${category.toUpperCase()} Matchups`,
      path: `/html-sitemap/matchups/${category}`,
      color: colors.brightPink,
    });
  }
};
