export const DISPLAY_KEYS = {
  NEW: 'new',
  RETURNING: 'returning',
};

export const DISPLAY_TEXT = {
  SMS_CODE: {
    title: 'We sent a confirmation code\n to xxx-xxx-',
    desc: 'Enter confirmation code:',
  },
  USER_EMAIL: {
    title: 'Your Account Info',
    desc: 'Enter the email associated with your Gametime account to log in and continue to checkout.',
  },
  ERROR: {
    title: 'Something went wrong. Please try again!',
  },
  [DISPLAY_KEYS.NEW]: {
    title: 'Continue With Email',
    desc: 'Your tickets will be delivered to the email address provided below.',
  },
};
DISPLAY_TEXT[DISPLAY_KEYS.RETURNING] = DISPLAY_TEXT.USER_EMAIL;

export const DISPLAY_TEXT_V3 = {
  SMS_CODE: {
    title: 'We sent a confirmation code\n to xxx-xxx-',
    desc: 'Enter confirmation code:',
  },
  USER_EMAIL: {
    title: 'Your Account Info',
    desc: 'Enter the email associated with your Gametime account to log in and continue to checkout.',
  },
  ERROR: {
    title: 'Something went wrong. Please try again!',
  },
  [DISPLAY_KEYS.NEW]: {
    title: 'Let us know where to send your tickets',
    desc: "We'll use the email address provided below to send your tickets and keep you updated on your order.",
  },
  [DISPLAY_KEYS.RETURNING]: {
    title: 'Your Account Info',
    desc: 'Enter the email associated with your Gametime account to log in and continue to checkout.',
  },
};
