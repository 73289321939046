import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

const UserIcon = ({
  width = '28',
  height = '28',
  fill = colors.white,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.53366 21.2665C6.84477 20.3849 8.18921 19.7091 9.56699 19.2388C10.9448 18.7684 12.4225 18.5332 14.0003 18.5332C15.5781 18.5332 17.0595 18.7684 18.4447 19.2388C19.83 19.7091 21.1782 20.3849 22.4893 21.2665C23.4004 20.1627 24.0652 19.0108 24.4837 17.8108C24.9021 16.6108 25.1113 15.3405 25.1113 13.9998C25.1113 10.8665 24.0429 8.23128 21.906 6.09417C19.7689 3.95728 17.1337 2.88884 14.0003 2.88884C10.867 2.88884 8.23177 3.95728 6.09466 6.09417C3.95777 8.23128 2.88933 10.8665 2.88933 13.9998C2.88933 15.3405 3.10221 16.6108 3.52799 17.8108C3.95399 19.0108 4.62255 20.1627 5.53366 21.2665ZM13.9963 15.1108C12.7028 15.1108 11.6133 14.6669 10.728 13.7792C9.84288 12.8912 9.40033 11.8004 9.40033 10.5068C9.40033 9.21328 9.84433 8.12395 10.7323 7.23884C11.6201 6.3535 12.7108 5.91084 14.0043 5.91084C15.2979 5.91084 16.3873 6.35484 17.2727 7.24284C18.1578 8.13084 18.6003 9.22162 18.6003 10.5152C18.6003 11.8087 18.1563 12.8981 17.2683 13.7832C16.3805 14.6683 15.2899 15.1108 13.9963 15.1108ZM13.9913 27.3332C12.1447 27.3332 10.4093 26.9832 8.78533 26.2832C7.1611 25.5832 5.74833 24.6295 4.54699 23.4222C3.34544 22.2146 2.39844 20.8009 1.70599 19.1812C1.01333 17.5614 0.666992 15.8306 0.666992 13.9888C0.666992 12.1471 1.01699 10.4162 1.71699 8.79617C2.41699 7.17639 3.37066 5.7665 4.57799 4.5665C5.78555 3.3665 7.19921 2.4165 8.81899 1.7165C10.4388 1.0165 12.1695 0.666504 14.0113 0.666504C15.8531 0.666504 17.584 1.0165 19.204 1.7165C20.8238 2.4165 22.2337 3.3665 23.4337 4.5665C24.6337 5.7665 25.5837 7.17762 26.2837 8.79984C26.9837 10.4221 27.3337 12.1534 27.3337 13.9938C27.3337 15.8345 26.9837 17.5642 26.2837 19.1828C25.5837 20.8015 24.6337 22.2146 23.4337 23.4222C22.2337 24.6295 20.8215 25.5832 19.1973 26.2832C17.5733 26.9832 15.838 27.3332 13.9913 27.3332Z"
      fill={fill}
    />
  </svg>
);

export default UserIcon;
