import React from 'react';
import PropTypes from 'prop-types';

import BuyWithGooglePayAsset from 'assets/buy-with-google-pay-button-label.svg';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import colors from 'styles/colors.constants';

export default function GooglePayButton({ onClick }) {
  return (
    <ThemedButtonLoader backgroundColor={colors.white} onClick={onClick}>
      <img
        src={BuyWithGooglePayAsset}
        style={{ height: '25px' }}
        alt="Buy With Google Pay"
      />
    </ThemedButtonLoader>
  );
}
GooglePayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
