module.exports = {
  domain: 'gametime.co',
  API_URL: 'https://production.gametime.co',
  MOBILE_API_URL: 'https://mobile.gametime.co',
  appleAppAssociationConstants: {
    universalLinkSuffix: '',
  },
  BRAINTREE_CLIENT_TOKEN: 'production_kk2jmrbb_89r5jg4596vmnyrt',
  AFFIRM_API_KEY: '2Y6VRQM8U9H8XYVG',
  AFFIRM_CDN: 'https://cdn1.affirm.com',
  FORTER_SITE_ID: 'd5a4aea896be',
  PUSHER_KEY: 'da1045f3e7ef2fb5075e',
  GOOGLE_RE_CAPTCHA_KEY: '6LcflKoiAAAAACrZKiV2WHCzQ4QwJSpJVLENl4jk',
  BRAZE: {
    API_KEY: '5b59703b-00e7-42ea-bd7a-8b38a20386b3',
    BASE_URL: 'sdk.iad-05.braze.com',
  },
  STATSIG_CLIENT_TOKEN: 'client-31Ztl8pMEm1NhtuoF4BnxkXkz6wcqG39Wbw0Kygqzco',
  DEEP_LINKS: {
    homepage: 'https://gametime.go.link/FkSnt',
    metro: 'https://gametime.go.link/2xtrj',
    picks: 'https://gametime.go.link/7cIxh',
    order_confirmation: 'https://gametime.go.link/7a8hY',
    why_gametime: 'https://gametime.go.link/ieXhA',
  },
};
