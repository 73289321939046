import React from 'react';
import classNames from 'classnames';

import { OptionalDictionary } from '../../../Dictionaries';

import styles from '../Form.module.scss';

export default function FormSection({
  children,
  classNameList,
  title,
}: {
  children: React.ReactNode;
  classNameList?: OptionalDictionary<'fieldset' | 'title'>;
  title?: string;
}): React.JSX.Element {
  return (
    <fieldset
      className={classNames(styles['form-section'], classNameList?.fieldset)}
    >
      {title && (
        <legend
          className={classNames(
            styles['form-section-title'],
            classNameList?.title
          )}
        >
          {title}
        </legend>
      )}
      {children}
    </fieldset>
  );
}
