import React from 'react';

import { CircleInfoIcon } from 'icons';

import styles from './MLBCollectionAlertV2.module.scss';

const MLBCollectionAlert = () => {
  return (
    <aside className={styles['mlb-account-alert']}>
      <figure className={styles['mlb-account-alert-icon']} aria-hidden="true">
        <CircleInfoIcon fill="currentColor" height="16" width="16" />
      </figure>
      <p className={styles['mlb-info-collection-text']}>
        <span className={styles.note}>Note:</span> If you already have an MLB
        Ballpark account, please use that email address.
      </p>
    </aside>
  );
};

export default MLBCollectionAlert;
