import { Price as PriceData } from 'types';

export default class Price {
  public readonly prefee: number;
  public readonly salesTax: number;
  public readonly total: number;
  public readonly faceValue?: number;

  constructor(price: PriceData) {
    this.prefee = price.prefee;
    this.salesTax = price.sales_tax;
    this.total = price.total;
    this.faceValue = price.face_value;
  }
}
