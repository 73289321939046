import * as Yup from 'yup';

// Define regex patterns for postal codes
const postalCodeRegex = {
  ca: /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, // Canadian postal codes
  us: /^\d{5}(?:-\d{4})?$/, // US ZIP codes
};

type Options<Properties extends object = object> = Properties & {
  label: string;
  required?: boolean;
};

function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function email(options: Options): Yup.StringSchema {
  return string(options).trim().email('Please enter a valid email address');
}

export function postalCode(
  options: Options<{ country?: keyof typeof postalCodeRegex }>
): Yup.StringSchema {
  let validator = Yup.string();

  if (options.required) {
    validator = required(validator, options);
  }

  if (options.country) {
    return validator.matches(
      postalCodeRegex[options.country],
      `Please enter a valid ${options.country.toUpperCase()} postal code`
    );
  }

  const validCountries = Object.keys(postalCodeRegex)
    .map((key) => key.toUpperCase())
    .join(', ')
    .replace(/, ([^,]*)$/, ' or $1');

  return validator.test(
    'postal-code',
    `Please enter a valid ${validCountries} postal code`,
    (value) => {
      if (!value) {
        return true;
      }

      return Object.values(postalCodeRegex).some((regex) => regex.test(value));
    }
  );
}

function required(validator: Yup.AnySchema, options: Options) {
  return validator.required(`${capitalize(options.label)} is required`);
}

export function schema(schema: { [key: string]: Yup.AnySchema }): Yup.Schema {
  return Yup.object().shape(schema);
}

export function string(options: Options): Yup.StringSchema {
  const validator = Yup.string().trim();

  if (options.required) {
    return required(validator, options);
  }

  return validator;
}

export function oneTimePassCode(
  options: Omit<Options, 'required'> & { length?: number }
) {
  const length = options.length ?? 6;
  const label = capitalize(options.label);

  return required(Yup.string(), options)
    .length(length, `${label} must be ${length} digits long`)
    .matches(
      new RegExp(`^\\d{${length}}`),
      `${label} must only contain numbers`
    );
}

export function phoneNumber(options: Options): Yup.StringSchema {
  return string(options)
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      `Please enter a valid ${options.label}`
    )
    .trim();
}
