module.exports = {
  domain: 'localhost',
  port: 3000,
  API_URL: 'https://staging.gametime.co',
  MOBILE_API_URL: 'https://mobile-staging.gametime.co',
  APPTIMIZE_APP_KEY: 'C449rzDcB9a7tFMdQpKtyzqWvXXe984',
  PUSHER_KEY: 'c1e7843da68a3ece6450',
  BRAZE: {
    API_KEY: '5d58f8d7-3186-4377-b3de-2a8d8c0e00b9',
    BASE_URL: 'sdk.iad-05.braze.com',
  },
  DEEP_LINKS: {
    homepage: 'https://gametime-beta.go.link/crZn8',
    metro: 'https://gametime-beta.go.link/8r6kh',
    picks: 'https://gametime-beta.go.link/9ExK6',
    order_confirmation: 'https://gametime-beta.go.link/7jjbG',
    why_gametime: 'https://gametime-beta.go.link/bMhDP',
  },
  STATSIG_CLIENT_TOKEN: 'client-YmdgdZNa2r3hK6vdbFNW4KDxK1dZU2YrzeQZ3uw3PKo',
  STATSIG_SERVER_TOKEN: 'secret-FLRvnjjsXVstOcTq5rQu4COczbhrTXDBjKU6RMfpRgP',
};
