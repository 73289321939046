import {
  selectUserDetails,
  userPromosForListingPriceSelector,
  userPromosForListingPromoCodeSelector,
} from 'store/modules/user/user.selectors';
import {
  createUserPurchase,
  pollFetchUserPurchaseResult,
  removeUserCardSuccessAction,
  updateUserDefaultPayment,
} from 'store/modules/userPurchases/actions';
import {
  createUserCard,
  deleteUserCard,
  fetchUserCards,
} from 'store/modules/userPurchases/creditCard/creditCard';
import { userPurchaseZipSelector } from 'store/modules/userPurchases/creditCard/creditCard.selectors';

/**
 * creates and adds a credit card to the GT user
 */
export const addUserCreditCard =
  (braintreeNonce, reCaptchaToken) => async (dispatch, getState) => {
    if (
      __CLIENT__ &&
      typeof window !== 'undefined' &&
      typeof window.fbq === 'function'
    ) {
      window.fbq('track', 'AddPaymentInfo');
    }

    const user = selectUserDetails(getState());
    try {
      const { payment_method } = await dispatch(
        createUserCard(user, braintreeNonce, reCaptchaToken)
      );
      await dispatch(updateUserDefaultPayment(payment_method.Token));
      await dispatch(fetchUserCards(user, { force: true }));
      return payment_method.Token;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

/**
 * @param {import('../../../types/mobileApi').CreditCard} card
 * @returns {import('redux-thunk').ThunkAction<Promise<void>, any, null, any>}
 */
export const deleteUserCreditCard = (card) => (dispatch, getState) => {
  const user = selectUserDetails(getState());

  return dispatch(deleteUserCard(user, card.object_id))
    .then(() => {
      dispatch(fetchUserCards(user, { force: true }));
      dispatch(removeUserCardSuccessAction(card.token));
    })
    .catch((err) => console.error(err));
};

export const submitPurchaseRequest =
  (listing, paymentOptions, insurance) => (dispatch, getState) => {
    const globalState = getState();

    const purchaseRequest = {
      offer: {
        prefee_price: listing.price.prefee,
        price: listing.price.total,
        signature: listing.signature,
      },
      payment: {
        postal_code: userPurchaseZipSelector(globalState),
        ...paymentOptions,
      },
      quantity: listing.quantity,
      listing_id: listing.id,
      insurance,
    };

    const listingPromoPrice = userPromosForListingPriceSelector(globalState);
    const listingPromoCode = userPromosForListingPromoCodeSelector(globalState);
    if (listingPromoPrice) {
      purchaseRequest.promo_code = listingPromoCode;
      purchaseRequest.discounted_price = listingPromoPrice * 100;
    }

    const user = selectUserDetails(globalState);
    return dispatch(createUserPurchase(user, purchaseRequest))
      .then((result) =>
        pollFetchUserPurchaseResult(dispatch, user, {
          transaction_id: result.purchase_id,
          // see: https://gametime.atlassian.net/browse/MWEB-5697
          log_stream_id: result.log_stream_id,
          supplier_log_id: result.supplier_log_id,
        })
      )
      .catch((error) => {
        console.error('submitPurchaseRequest error', error);
      });
  };
