import React from 'react';

import styles from './PurchaseHeading.module.scss';

interface PurchaseHeadingProps {
  heading: string | React.ReactNode;
  subheading: string;
}

function PurchaseHeading({ heading, subheading }: PurchaseHeadingProps) {
  return (
    <header className={styles['purchase-heading']}>
      <h1 className={styles.heading}>{heading}</h1>
      <h2 className={styles.subheading}>{subheading}</h2>
    </header>
  );
}

export default PurchaseHeading;
