import { createSelector } from 'reselect';
import type { RootState } from 'store';

import { Listing } from 'models';

type Props = {
  listing: Listing;
};

export const extendedPurchaseSelector = createSelector(
  [(_: RootState, props: Props) => props.listing],
  (listing) => ({
    totalPrefeePrice: listing.quantity * listing.prefeePrice,
    fees: listing.quantity * listing.fees,
    totalPrice: listing.quantity * listing.totalPrice,
    discount: {
      available: listing.isDiscounted(),
      prefeeDiscount: listing.getSavingsAmount(),
      percentDiscountPercentage: listing.getDiscountPercent(),
      showLabel: false,
    },
  })
);
