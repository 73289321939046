import React from 'react';

export default function SelectArrow({
  'aria-hidden': ariaHidden = true,
  fill = 'currentColor',
  height = 24,
  width = 24,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'fill' | 'aria-hidden'
>) {
  return (
    <svg
      aria-hidden={ariaHidden}
      fill="none"
      height={height}
      viewBox="0 0 10 10"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Downward facing chevron arrow</title>
      <g>
        <path
          d="M8.5 4L5.17678 7.32322C5.07915 7.42085 4.92085 7.42085 4.82322 7.32322L1.5 4"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.4"
        />
      </g>
    </svg>
  );
}
