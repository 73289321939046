import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import { PlusIcon } from 'icons';
import colors from 'styles/colors.constants';

import { OptionalDictionary } from '../../pages/CheckoutV3/Dictionaries';

import styles from './RadioCardButton.module.scss';

interface RadioCardButtonProps {
  addIconColor?: string;
  classNameList?: OptionalDictionary<
    'add-icon' | 'content' | 'icon' | 'radio-card-button' | 'title'
  >;
  icon?: React.ReactNode;
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  title: React.ReactNode;
}

/**
 * Button that looks like a radio card, meant to be used with a radio group to
 * add additional options or set some related value.
 */
export default function RadioCardButton({
  addIconColor = colors.black,
  classNameList,
  icon,
  title,
  onClick,
}: RadioCardButtonProps) {
  return (
    <button
      className={classNames(
        styles['radio-card-button'],
        classNameList?.['radio-card-button']
      )}
      onClick={onClick}
    >
      <div className={classNames(styles.content, classNameList?.content)}>
        {icon && (
          <div className={classNames(styles.icon, classNameList?.icon)}>
            {icon}
          </div>
        )}
        <div className={classNames(styles.title, classNameList?.title)}>
          {title}
        </div>
      </div>
      <div
        className={classNames(styles['add-icon'], classNameList?.['add-icon'])}
      >
        <PlusIcon color={addIconColor} />
      </div>
    </button>
  );
}
