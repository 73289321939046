import Banner from './Banner';
import CenterCourt from './CenterCourt';
import MinPrice from './MinPrice';
import NameOverride from './NameOverride';
import PerformerRef from './PerformerRef';
import Price from './Price';

export default class Event {
  constructor(event = {}) {
    this.banner = new Banner(event.banner);
    this.category = event.category;
    this.centerCourt = new CenterCourt(event.center_court);
    this.datetimeLocal = event.datetime_local;
    this.datetimeUtc = event.datetime_utc;
    this.id = event.id;
    this.mapUrl = event.map_url;
    this.minPrice = new MinPrice(event.min_price);
    this.name = event.name;
    this.performersList = event.performers
      ? event.performers.map((performerRef) => new PerformerRef(performerRef))
      : [];
    this.salesCutOff = event.sales_cut_off;
    this.sectionMapId = event.section_map_id;
    this.venueId = event.venue_id;
    this.venueConfig = event.venue_config;
    this.tbd = event.tbd;
    this.date_tbd = event.date_tbd;
    this.time_tbd = event.time_tbd;
    this.nameOverride =
      event.name_override && new NameOverride(event.name_override);
    this.relatedEvents = event.related_events;
    this.hasExclusives = event.has_exclusives;
    this._highPrice = null;
  }

  get highPrice() {
    return this._highPrice;
  }

  set highPrice(price) {
    this._highPrice = price ? new Price(price) : null;
  }
}
