import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { Listing } from 'models';
import { isGTPicksFilterSelector } from 'store/modules/app/app.ui';
import { createTypedSelector } from 'store/utils';
import { ListingV3, ListingV3DisplayGroup } from 'types';

const selectListings = (state: RootState) => state.listings.listings;
export const selectListingsParams = (state: RootState) => state.listings.params;
export const selectListingQuantity = (state: RootState) =>
  state.listings.params?.quantity ?? 0;
/**
 * Selects the display groups for the listings. This is used as the top level
 * configuration for how display groups are configured. Listings will
 * individually reference these display groups by slug.
 */
const selectDisplayGroups = (state: RootState) => state.listings.displayGroups;
export const selectZoomLevel = (state: RootState) => state.listings.zoomLevel;
export const selectAvailableLots = (state: RootState) =>
  state.listings.availableLots;
export const selectHasInventory = (state: RootState) =>
  state.listings.listings.length > 0;
export const selectIsAllInPricing = (state: RootState) =>
  !!state.listings.params?.all_in_pricing;
export const selectIsListingsLoading = (state: RootState) =>
  state.listings.status === 'loading';
export const selectIsListMapHarmonyEnabled = (state: RootState) =>
  state.listings.isListMapHarmonyEnabled;

/**
 * Constructs a map of ListingV3 models from the raw listings in the store.
 * This is a memoized selector that will only create the map once and return
 * the same map whenever the selector is called with the same quantity. This
 * should be used wherever we need to access the listings as models, including
 * selecting a single listing by ID.
 */
export const selectListingsV3Models = createTypedSelector(
  [selectListings, selectListingQuantity],
  (listings, quantity) => {
    const listingModels: Record<string, Listing> = {};

    if (!quantity) {
      return listingModels;
    }

    listings.forEach((listing) => {
      listingModels[listing.id] = new Listing(listing, quantity);
    });

    return listingModels;
  }
);

export interface ListingV3DisplayGroupCollection extends ListingV3DisplayGroup {
  listings: Listing[];
}

export const selectListingById = (state: RootState, listingId: string) => {
  return selectListingsV3Models(state)[listingId] as Listing | undefined;
};

export const selectListingV3DisplayGroups = createTypedSelector(
  [
    selectListingsV3Models,
    selectDisplayGroups,
    selectZoomLevel,
    isGTPicksFilterSelector,
  ],
  (
    listingsModels,
    displayGroups,
    zoom,
    isGTPicksFilterActive
  ): ListingV3DisplayGroupCollection[] => {
    const listingsByDisplayGroupSlug: Partial<
      Record<string, { sort_order: number; listing: Listing }[]>
    > = {};

    Object.values(listingsModels).forEach((listing) => {
      const hasPricePinConfig = Object.entries(listing.data.zoom_configs).some(
        ([zoomKey, config]) =>
          Number(zoomKey) <= zoom && config.pin_type === 'price'
      );

      if (!hasPricePinConfig) {
        // if no price pin config is found at or below the current zoom level,
        // the listing should not be shown on the map or in the list
        return;
      }

      if (isGTPicksFilterActive && !listing.dealType) {
        // if the GT Picks filter is active, only show listings with a deal
        return;
      }

      // each listing has a `display_groups` map, which contains one or more
      // DisplayGroupConfig objects, keyed by the slug of each display group
      // it belongs to.
      Object.entries(listing.data.display_groups).forEach(
        ([displayGroupSlug, displayGroupConfig]) => {
          if (!listingsByDisplayGroupSlug[displayGroupSlug]) {
            listingsByDisplayGroupSlug[displayGroupSlug] = [];
          }

          // avoid the temptation of inserting by sort_order to prevent
          // sorting later as there can be gaps and duplicates in the
          // sort_order values
          listingsByDisplayGroupSlug[displayGroupSlug].push({
            sort_order: displayGroupConfig.sort_order,
            listing,
          });
        }
      );
    });

    return displayGroups
      .slice()
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((displayGroup) => {
        const listings = (listingsByDisplayGroupSlug[displayGroup.slug] || [])
          .slice()
          .sort((a, b) => a.sort_order - b.sort_order)
          .map(({ listing }) => listing);

        return {
          ...displayGroup,
          listings,
        };
      });
  }
);

/**
 * Selector to return a list of all unique IDs of listings in display groups
 */
export const selectDisplayedV3ListingIds = createSelector(
  selectListingV3DisplayGroups,
  (displayGroups) =>
    Array.from(
      displayGroups.reduce<Set<string>>((listingIds, displayGroup) => {
        displayGroup.listings.forEach((listing) => {
          listingIds.add(listing.id);
        });
        return listingIds;
      }, new Set<string>())
    )
);

/**
 * Selector to return a flat list of all unique listing models in display groups
 */
export const selectDisplayedV3Listings = createSelector(
  selectDisplayedV3ListingIds,
  selectListingsV3Models,
  (uniqueListingIds, listingsModels) =>
    uniqueListingIds.map((listingId) => listingsModels[listingId])
);

/**
 * Selector to return the listing with the highest price in the displayed
 * listings. This is used for setting the highest price on the FullEvent model
 * to use when creating the Event Schema.
 */
export const selectHighestPriceListingV3 = createSelector(
  selectDisplayedV3Listings,
  (listings) =>
    listings.reduce<Listing | undefined>(
      (a, b) => ((a?.price.prefee || 0) > b.price.prefee ? a : b),
      undefined
    )
);

/**
 * Selector to return a unique list of dealType values from the displayed listings
 */
export const selectDisplayedListingV3Deals = createSelector(
  selectDisplayedV3Listings,
  (listings) =>
    Array.from(
      listings.reduce((deals, listing) => {
        if (listing.dealType) {
          deals.add(listing.dealType);
        }
        return deals;
      }, new Set<NonNullable<ListingV3['deal']>>())
    )
);
