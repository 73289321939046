import React, { useEffect } from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useAnalyticsContext, View } from 'analytics';
import {
  countryOptions,
  PickerOption,
  provinceOptions,
  stateOptions,
} from 'components/Inputs/constants';

import { isBillingAddressPropertyName } from '../../../../../components/Modals/AddCardModal/AddCardForm/AddCardForm';
import {
  BraintreeBillingAddress,
  BraintreeTokenize,
  HostedFieldsHandler,
} from '../../../../../helpers/Braintree';
import { Paypal } from '../../../../../icons';
import Form from '../Form';
import { Address, FormSection, Input, PaymentInputs, Select } from '../Inputs';
import * as Validators from '../validators';

import styles from './NewCardForm.module.scss';

type Countries = 'US' | 'CA';
type PickerOptions = (PickerOption & { group?: string })[];

const FormValidationSchema = Validators.schema({
  firstName: Validators.string({ label: 'first name', required: true }),
  lastName: Validators.string({ label: 'last name', required: true }),
  streetAddress: Validators.string({ label: 'address', required: true }),
  extendedAddress: Validators.string({ label: 'extended address' }),
  locality: Validators.string({ label: 'city', required: true }),
  postalCode: Validators.postalCode({ label: 'postal code', required: true }),
  countryCodeAlpha2: Validators.string({
    label: 'country',
    required: true,
  }).length(2, 'Country is required'),
  region: Validators.string({ label: 'State/Province', required: true }).when(
    'countryCodeAlpha2',
    {
      is: (countryCodeAlpha2: Countries) => countryCodeAlpha2 === 'CA',
      then: (schema: Yup.StringSchema) =>
        schema
          .oneOf(
            provinceOptions.map((option) => option.value).filter(Boolean),
            'Please select a Canadian province'
          )
          .required('Province is required'),
      otherwise: (schema: Yup.StringSchema) =>
        schema.when('countryCodeAlpha2', {
          is: (countryCodeAlpha2: Countries) => countryCodeAlpha2 === 'US',
          then: (schema: Yup.StringSchema) =>
            schema
              .oneOf(
                stateOptions.map((option) => option.value).filter(Boolean),
                'Please select a region within the United States'
              )
              .required('State is required'),
        }),
    }
  ),
}) as Yup.ObjectSchema<BraintreeBillingAddress>;

type Props = {
  theme?: 'dark' | 'light';
  onSubmit: (
    braintreeTokenize: BraintreeTokenize,
    billingAddress: BraintreeBillingAddress
  ) => Promise<void>;
};

type Analytics = {
  track: (arg: View) => void;
};

const initialValues: BraintreeBillingAddress = {
  countryCodeAlpha2: 'US',
  extendedAddress: '',
  firstName: '',
  lastName: '',
  locality: '',
  postalCode: '',
  region: '',
  streetAddress: '',
};

function groupRegionOptions(
  optionList: typeof provinceOptions | typeof stateOptions,
  group: (typeof countryOptions)[0]['label']
): PickerOptions {
  return optionList
    .filter((option) => option.value !== '')
    .map((option) => ({ ...option, group }))
    .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
}

function getRegionOptions(country: string): PickerOptions {
  if (country === 'US') {
    return stateOptions;
  }

  if (country === 'CA') {
    return provinceOptions;
  }

  return [
    { label: 'Please select a region', value: '' },
    ...groupRegionOptions(stateOptions, 'United States'),
    ...groupRegionOptions(provinceOptions, 'Canada'),
  ];
}

function getRegionLabel(country?: string): 'Province' | 'Region' | 'State' {
  switch (country) {
    case 'CA':
      return 'Province';
    case 'US':
      return 'State';
  }

  return 'Region';
}

export default function NewCardForm({
  theme,
  onSubmit,
}: Props): React.JSX.Element {
  const [filteredRegionOptions, setFilteredRegionOptions] = React.useState(
    getRegionOptions(initialValues.countryCodeAlpha2)
  );
  const [regionLabel, setRegionLabel] = React.useState(
    getRegionLabel(initialValues.countryCodeAlpha2)
  );
  const analytics: Analytics = useAnalyticsContext();
  useEffect(() => {
    analytics.track(new View(View.PAGE_TYPES.CREDIT_CARD_DETAILS()));
  }, []);

  function handleCountryChange(country: string): void {
    setFilteredRegionOptions(getRegionOptions(country));
    setRegionLabel(getRegionLabel(country));
  }

  const hostedFieldsRef = React.useRef<HostedFieldsHandler | null>(null);

  return (
    <Form
      id="add-new-card"
      initialValues={initialValues}
      submitText="Save"
      onSubmit={async (values, formik) => {
        if (!hostedFieldsRef.current) {
          formik.setSubmitting(false);

          return;
        }

        if (!hostedFieldsRef.current.validate()) {
          formik.setSubmitting(false);

          return;
        }

        await onSubmit(hostedFieldsRef.current.tokenize, values);

        hostedFieldsRef.current.clear('number');
        hostedFieldsRef.current.clear('expirationDate');
        hostedFieldsRef.current.clear('cvv');
        formik.resetForm();
        formik.setSubmitting(false);
      }}
      validate={(values) => {
        const errorList: Partial<BraintreeBillingAddress> = {};

        // we need to validate both hosted fields and Formik-managed fields.
        // since they are separate validation mechanisms, we need to check both
        // independently.
        try {
          FormValidationSchema.validateSync(values, { abortEarly: false });
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            error.inner.forEach((message) => {
              if (isBillingAddressPropertyName(message.path)) {
                errorList[message.path] = message.message;
              }
            });
          }
        }

        hostedFieldsRef.current?.validate();

        return errorList;
      }}
      validateOnBlur
      theme={theme}
    >
      <FormSection classNameList={{ fieldset: styles.fieldset }}>
        <PaymentInputs
          classNameList={{
            field: classNames(styles.field, styles['payment-field']),
          }}
          ref={hostedFieldsRef}
        />

        <p className={styles['secured-by']}>
          <span>Secured by</span>
          <Paypal height={12} width={45.26} />
        </p>
      </FormSection>

      <FormSection
        title="Billing Information"
        classNameList={{
          fieldset: classNames(styles['billing-info'], styles.fieldset),
        }}
      >
        <Input
          className={classNames(styles.field, styles['cols-2'])}
          id="billing-first-name"
          label="First Name"
          name="firstName"
          type="text"
        />

        <Input
          className={classNames(styles.field, styles['cols-2'])}
          id="billing-last-name"
          label="Last Name"
          name="lastName"
          type="text"
        />

        <Address
          className={styles.field}
          id="billing-street-address"
          label="Street Address"
          name="streetAddress"
        />

        <Input
          className={styles.field}
          id="billing-extended-address"
          label="Apt/Unit (Optional)"
          name="extendedAddress"
          type="text"
        />

        <Input
          className={styles.field}
          id="billing-locality"
          label="City"
          name="locality"
          type="text"
        />

        <Select
          className={classNames(styles.field, styles['cols-2'])}
          id="billing-country"
          label="Country"
          name="countryCodeAlpha2"
          options={countryOptions}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>): void => {
            const selectedCountry = event.target.value;
            handleCountryChange(selectedCountry);
          }}
        />

        <Select
          className={classNames(styles.field, styles['cols-2'])}
          id="billing-region"
          name="region"
          label={regionLabel}
          options={filteredRegionOptions}
        />

        <Input
          className={styles.field}
          id="billing-postal-code"
          label="Postal Code"
          name="postalCode"
          type="text"
        />
      </FormSection>
    </Form>
  );
}
