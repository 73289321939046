import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import { REDUCERS } from 'store/modules/reducer.constants';

const APP_UI_ACTION_TYPES = generateActionTypes(
  [
    'UPDATE_UI_STATE',
    'UPDATE_ZOOM_LEVEL',
    'MOBILE_HERO_SEARCHBOX',
    'SET_FORM_SUBMITTING',
    'SET_FORM_SUBMITTED',
    'SET_GT_PICKS_FILTER',
  ],
  REDUCERS.APP_UI
);

const initialState = {
  showToast: true,
  mobileHeroSearchBox: false,
  isFormSubmitting: false,
  isFormSubmitted: false,
  gtPicksFilter: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case APP_UI_ACTION_TYPES.UPDATE_UI_STATE:
      return {
        ...state,
        ...action.result,
      };
    case APP_UI_ACTION_TYPES.MOBILE_HERO_SEARCHBOX:
      return {
        ...state,
        mobileHeroSearchBox: !state.mobileHeroSearchBox,
      };
    case APP_UI_ACTION_TYPES.SET_FORM_SUBMITTING:
      return {
        ...state,
        isFormSubmitting: action.result,
      };
    case APP_UI_ACTION_TYPES.SET_FORM_SUBMITTED:
      return {
        ...state,
        isFormSubmitted: action.result,
      };

    case APP_UI_ACTION_TYPES.SET_GT_PICKS_FILTER:
      return {
        ...state,
        gtPicksFilter: action.value,
      };
    default:
      return state;
  }
}

export const updateLastVisitedListingId = (lastVisitedListingId) => ({
  type: APP_UI_ACTION_TYPES.UPDATE_UI_STATE,
  result: { lastVisitedListingId },
});

export const showMobileHeroSearchBox = () => {
  return {
    type: APP_UI_ACTION_TYPES.MOBILE_HERO_SEARCHBOX,
  };
};

export const setGTPicksFilter = (value = true) => {
  return {
    type: APP_UI_ACTION_TYPES.SET_GT_PICKS_FILTER,
    value,
  };
};

// Global form submission indicators
export const setFormSubmitting = (isFormSubmitting) => ({
  type: APP_UI_ACTION_TYPES.SET_FORM_SUBMITTING,
  result: isFormSubmitting,
});

export const setFormSubmitted = (isFormSubmitted) => ({
  type: APP_UI_ACTION_TYPES.SET_FORM_SUBMITTED,
  result: isFormSubmitted,
});

export const mobileHeroSearchBoxSelector = (state) =>
  state[REDUCERS.APP_UI].mobileHeroSearchBox;

export const isFormSubmittingSelector = (state) =>
  state[REDUCERS.APP_UI].isFormSubmitting;

export const isFormSubmittedSelector = (state) =>
  state[REDUCERS.APP_UI].isFormSubmitted;

export const isGTPicksFilterSelector = (state) =>
  !!state[REDUCERS.APP_UI].gtPicksFilter;
