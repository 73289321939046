import _get from 'lodash/get';

import { CategoryClickTracker } from 'analytics/ClickTracker';
import { formatUrl } from 'utils/format';

export const CATEGORY_GROUPS = ['sports', 'concerts', 'shows'];

export const CATEGORY_GROUP_IDS = {
  sports: 'sport',
  concerts: 'concert',
  shows: 'theater',
};

export const CATEGORIES = {
  // other categories
  AFL: 'afl',
  AHL: 'ahl',
  ATP: 'atp',
  AUTO: 'auto',
  BOXING: 'boxing',
  CA: 'ca',
  CFL: 'cfl',
  CL: 'cl',
  CPL: 'cpl',
  GL: 'gl',
  GYMNASTICS: 'gymnastics',
  HORSES: 'horses',
  INTERNATIONAL: 'international',
  MILB: 'milb',
  MILS: 'mils',
  MLG: 'mlg',
  NASCAR: 'nascar',
  NWSL: 'nwsl',
  PBR: 'pbr',
  PGA: 'pga',
  RUGBY: 'rugby',
  SOCCER: 'soccer',
  UFC: 'ufc',
  WHL: 'whl',
  WNBA: 'wnba',
  WRES: 'wres',
  XFL: 'xfl',
  F1: 'f1',
  USL: 'usl',
  // major categories
  MLB: 'mlb',
  MLS: 'mls',
  MUSIC: 'music',
  NBA: 'nba',
  NFL: 'nfl',
  NHL: 'nhl',
  THEATER: 'theater',
  COMEDY: 'comedy',
  // NCAA categories
  BOWL: 'bowl',
  CBB: 'cbb',
  CBS: 'cbs',
  CFB: 'cfb',
  CHO: 'cho',
  CLAX: 'clax',
  WCBB: 'wcbb',
  // categories added from product
  AAF: 'aaf',
  ECHL: 'echl',
  NASL: 'nasl',
  NBAGL: 'nbagl',
  NLL: 'nll',
  VBALL: 'vball',
};

export const COLLEGE_SPORTS = [
  CATEGORIES.CBB,
  CATEGORIES.CBS,
  CATEGORIES.CFB,
  CATEGORIES.CHO,
  CATEGORIES.CLAX,
  CATEGORIES.WCBB,
  CATEGORIES.BOWL,
];

export const SPORTS_NAMES = {
  BASEBALL: 'Baseball',
  BASKETBALL: 'Basketball',
  FOOTBALL: 'Football',
  HOCKEY: 'Hockey',
  LACROSSE: 'Lacrosse',
  SOCCER: 'Soccer',
  TENNIS: 'Tennis',
  ESPORTS: 'eSports',
  BULL_RIDING: 'Bull Riding',
  HORSE_RACING: 'Horse Racing',
  MMA: 'Mixed Martial Arts',
};

export const COLLEGE_SPORTS_TO_SPORT_NAME_MAP = {
  [CATEGORIES.CBB]: SPORTS_NAMES.BASKETBALL,
  [CATEGORIES.CBS]: SPORTS_NAMES.BASEBALL,
  [CATEGORIES.CFB]: SPORTS_NAMES.FOOTBALL,
  [CATEGORIES.CHO]: SPORTS_NAMES.HOCKEY,
  [CATEGORIES.CLAX]: SPORTS_NAMES.LACROSSE,
  [CATEGORIES.WCBB]: SPORTS_NAMES.BASKETBALL,
};

export const CATEGORY_TO_SPORT_NAME = {
  ...COLLEGE_SPORTS_TO_SPORT_NAME_MAP,
  [CATEGORIES.MLB]: SPORTS_NAMES.BASEBALL,
  [CATEGORIES.NFL]: SPORTS_NAMES.FOOTBALL,
  [CATEGORIES.NBA]: SPORTS_NAMES.BASKETBALL,
  [CATEGORIES.NHL]: SPORTS_NAMES.HOCKEY,
  [CATEGORIES.MLS]: SPORTS_NAMES.SOCCER,
  [CATEGORIES.AFL]: SPORTS_NAMES.FOOTBALL,
  [CATEGORIES.AHL]: SPORTS_NAMES.HOCKEY,
  [CATEGORIES.ATP]: SPORTS_NAMES.TENNIS,
  [CATEGORIES.CFL]: SPORTS_NAMES.FOOTBALL,
  [CATEGORIES.CPL]: SPORTS_NAMES.SOCCER,
  [CATEGORIES.INTERNATIONAL]: SPORTS_NAMES.SOCCER,
  [CATEGORIES.MILB]: SPORTS_NAMES.BASEBALL,
  [CATEGORIES.MILS]: SPORTS_NAMES.SOCCER,
  [CATEGORIES.MLG]: SPORTS_NAMES.ESPORTS,
  [CATEGORIES.NWSL]: SPORTS_NAMES.SOCCER,
  [CATEGORIES.PBR]: SPORTS_NAMES.BULL_RIDING,
  [CATEGORIES.WHL]: SPORTS_NAMES.HOCKEY,
  [CATEGORIES.HORSES]: SPORTS_NAMES.HORSE_RACING,
  [CATEGORIES.BOWL]: SPORTS_NAMES.FOOTBALL,
  [CATEGORIES.CBB]: SPORTS_NAMES.BASKETBALL,
  [CATEGORIES.CBS]: SPORTS_NAMES.BASEBALL,
  [CATEGORIES.CHO]: SPORTS_NAMES.HOCKEY,
  [CATEGORIES.CLAX]: SPORTS_NAMES.LACROSSE,
  [CATEGORIES.WCBB]: SPORTS_NAMES.BASKETBALL,
  [CATEGORIES.PGA]: SPORTS_NAMES.GOLF,
  [CATEGORIES.MMA]: SPORTS_NAMES.MMA,
  [CATEGORIES.WNBA]: SPORTS_NAMES.BASKETBALL,
  [CATEGORIES.USL]: SPORTS_NAMES.SOCCER,
};

// If we change these then add the past urls to a key called redirectUrls and the server will redirect to the proper url
export const CATEGORY_URLS = {
  [CATEGORIES.MLB]: {
    id: CATEGORIES.MLB,
    title: 'MLB Baseball',
    desc: 'MLB baseball',
    url: 'mlb-baseball',
    emoji: '⚾️',
  },
  [CATEGORIES.NFL]: {
    id: CATEGORIES.NFL,
    title: 'NFL Football',
    desc: 'NFL football',
    url: 'nfl-football',
    emoji: '🏈',
  },
  [CATEGORIES.CFB]: {
    id: CATEGORIES.CFB,
    title: 'College Football',
    desc: 'college football',
    url: 'college-football',
    emoji: '🏈',
  },
  [CATEGORIES.MLS]: {
    id: CATEGORIES.MLS,
    title: 'MLS Soccer',
    desc: 'MLS soccer',
    url: 'mls-soccer',
    emoji: '⚽️',
  },
  [CATEGORIES.NBA]: {
    id: CATEGORIES.NBA,
    title: 'NBA Basketball',
    desc: 'NBA basketball',
    url: 'nba-basketball',
    emoji: '🏀',
  },
  [CATEGORIES.NHL]: {
    id: CATEGORIES.NHL,
    title: 'NHL Hockey',
    desc: 'NHL hockey',
    url: 'nhl-hockey',
    emoji: '🏒',
  },
  [CATEGORIES.MUSIC]: {
    id: CATEGORIES.MUSIC,
    shortTitle: 'Concerts',
    title: 'Concert Tickets',
    desc: 'concert',
    url: 'concert',
    emoji: '🎸',
  },
  [CATEGORIES.WRES]: {
    id: CATEGORIES.WRES,
    title: 'WWE Tickets & Wrestling',
    shortTitle: 'WWE',
    desc: 'WWE & other wrestling',
    url: 'wrestling',
  },
  [CATEGORIES.XFL]: {
    id: CATEGORIES.XFL,
    title: 'XFL Football',
    desc: 'XFL football',
    url: CATEGORIES.XFL,
  },
  [CATEGORIES.THEATER]: {
    id: CATEGORIES.THEATER,
    title: 'Shows',
    desc: 'theater',
    url: 'theater',
    emoji: '🎭',
  },
  [CATEGORIES.COMEDY]: {
    id: CATEGORIES.COMEDY,
    title: 'Comedy',
    desc: 'comedy',
    url: 'comedy',
  },
  [CATEGORIES.GL]: {
    id: CATEGORIES.GL,
    title: 'Grapefruit League',
    desc: 'Grapefruit League',
    url: 'grapefruit-league-spring-training',
  },
  [CATEGORIES.CL]: {
    id: CATEGORIES.CL,
    title: 'Cactus League',
    desc: 'Cactus League',
    url: 'cactus-league-spring-training',
  },
  [CATEGORIES.CA]: {
    id: CATEGORIES.CA,
    title: 'Copa America',
    desc: 'Copa America',
    url: 'copa-america',
  },
  [CATEGORIES.AFL]: {
    id: CATEGORIES.AFL,
    title: 'IFL Football',
    desc: 'indoor football',
    url: 'ifl',
    emoji: '🏈',
  },
  [CATEGORIES.AHL]: {
    id: CATEGORIES.AHL,
    title: 'AHL',
    desc: 'AHL',
    url: CATEGORIES.AHL,
    emoji: '🏒',
  },
  [CATEGORIES.ATP]: {
    id: CATEGORIES.ATP,
    title: 'Tennis',
    desc: 'tennis',
    url: 'tennis',
    emoji: '🎾',
  },
  [CATEGORIES.CFL]: {
    id: CATEGORIES.CFL,
    title: 'CFL',
    desc: 'CFL',
    url: CATEGORIES.CFL,
    emoji: '🏈',
  },
  [CATEGORIES.CPL]: {
    id: CATEGORIES.CPL,
    title: 'CPL',
    desc: 'CPL',
    url: CATEGORIES.CPL,
    emoji: '⚽️',
  },
  [CATEGORIES.INTERNATIONAL]: {
    id: CATEGORIES.INTERNATIONAL,
    title: 'International Soccer',
    desc: 'soccer',
    url: CATEGORIES.INTERNATIONAL,
    emoji: '⚽️',
  },
  [CATEGORIES.MILB]: {
    id: CATEGORIES.MILB,
    title: 'MILB',
    desc: 'Minor League Baseball',
    url: CATEGORIES.MILB,
    emoji: '⚾️',
  },
  [CATEGORIES.MILS]: {
    id: CATEGORIES.MILS,
    title: 'USL & Minor League Soccer',
    shortTitle: 'USL',
    desc: 'Minor League Soccer',
    url: CATEGORIES.MILS,
    emoji: '⚽️',
  },
  [CATEGORIES.MLG]: {
    id: CATEGORIES.MLG,
    title: 'eSports',
    desc: 'eSports',
    url: CATEGORIES.MLG,
    emoji: '🎮',
  },
  [CATEGORIES.NWSL]: {
    id: CATEGORIES.NWSL,
    title: `NWSL`,
    desc: `women's soccer`,
    url: CATEGORIES.NWSL,
    emoji: '⚽️',
  },
  [CATEGORIES.PBR]: {
    id: CATEGORIES.PBR,
    title: 'PBR',
    desc: 'bull riding',
    url: CATEGORIES.PBR,
  },
  [CATEGORIES.WHL]: {
    id: CATEGORIES.WHL,
    title: 'WHL hockey',
    desc: 'WHL',
    url: CATEGORIES.WHL,
    emoji: '🏒',
  },
  [CATEGORIES.HORSES]: {
    id: CATEGORIES.HORSES,
    title: 'Horse Racing',
    desc: 'horse racing',
    url: CATEGORIES.HORSES,
    emoji: '🏇',
  },
  [CATEGORIES.NASCAR]: {
    id: CATEGORIES.NASCAR,
    title: 'NASCAR',
    desc: 'NASCAR',
    url: CATEGORIES.NASCAR,
    emoji: '🏁',
  },
  // NCAA sports
  [CATEGORIES.BOWL]: {
    id: CATEGORIES.BOWL,
    title: 'CFB Bowl Games',
    desc: 'CFB Bowl Games',
    url: 'college-football-bowl',
    emoji: '🏈',
  },
  [CATEGORIES.CBB]: {
    id: CATEGORIES.CBB,
    title: 'College Basketball',
    desc: 'college basketball',
    url: 'college-basketball',
    emoji: '🏀',
  },
  [CATEGORIES.CBS]: {
    id: CATEGORIES.CBS,
    title: 'College Baseball',
    desc: 'college baseball',
    url: 'college-baseball',
    emoji: '⚾️',
  },
  [CATEGORIES.CHO]: {
    id: CATEGORIES.CHO,
    title: 'College Hockey',
    desc: 'college hockey',
    url: 'college-hockey',
    emoji: '🏒',
  },
  [CATEGORIES.CLAX]: {
    id: CATEGORIES.CLAX,
    title: 'College Lacrosse',
    desc: 'college lacrosse',
    url: 'college-lacrosse',
    emoji: '🥍',
  },
  [CATEGORIES.WCBB]: {
    id: CATEGORIES.WCBB,
    title: `Women's College Basketball`,
    desc: `women's college basketball`,
    url: CATEGORIES.WCBB,
    emoji: '🏀',
  },
  [CATEGORIES.AUTO]: {
    id: CATEGORIES.AUTO,
    title: 'Auto',
    desc: 'auto',
    url: CATEGORIES.AUTO,
  },
  [CATEGORIES.BOXING]: {
    id: CATEGORIES.BOXING,
    title: 'Boxing',
    desc: 'Boxing',
    url: CATEGORIES.BOXING,
    emoji: '🥊',
  },
  [CATEGORIES.GYMNASTICS]: {
    id: CATEGORIES.GYMNASTICS,
    title: 'Gymnastics',
    desc: 'Gymnastics',
    url: CATEGORIES.GYMNASTICS,
    emoji: '🤸',
  },
  [CATEGORIES.PGA]: {
    id: CATEGORIES.PGA,
    title: 'PGA Golf',
    shortTile: 'PGA',
    desc: 'golf',
    url: CATEGORIES.PGA,
    emoji: '⛳️',
  },
  [CATEGORIES.RUGBY]: {
    id: CATEGORIES.RUGBY,
    title: 'Rugby',
    desc: 'Rugby',
    url: CATEGORIES.RUGBY,
    emoji: '🏉',
  },
  [CATEGORIES.SOCCER]: {
    id: CATEGORIES.SOCCER,
    title: 'Soccer',
    desc: 'Soccer',
    url: CATEGORIES.SOCCER,
    emoji: '⚽️',
  },
  [CATEGORIES.UFC]: {
    id: CATEGORIES.UFC,
    title: 'UFC',
    desc: 'UFC & other MMA',
    url: CATEGORIES.UFC,
    emoji: '🥊',
  },
  [CATEGORIES.WNBA]: {
    id: CATEGORIES.WNBA,
    title: 'WNBA',
    desc: 'WNBA',
    url: CATEGORIES.WNBA,
    emoji: '⛹️‍♀️',
  },
};

export const SELECTOR_CATEGORIES_LINKS = [
  CATEGORIES.MLB,
  CATEGORIES.NFL,
  CATEGORIES.NBA,
  CATEGORIES.NHL,
  CATEGORIES.CFB,
  CATEGORIES.BOWL,
  CATEGORIES.CBB,
  CATEGORIES.MLS,
  CATEGORIES.MUSIC,
  CATEGORIES.THEATER,
];

export const HEADER_CATEGORIES = [
  CATEGORIES.MLB,
  CATEGORIES.MLS,
  CATEGORIES.MUSIC,
  CATEGORIES.NBA,
  CATEGORIES.NFL,
  CATEGORIES.NHL,
  CATEGORIES.THEATER,
];

export const MAJOR_LEAGUE_SPORTS_PERFORMERS = [
  CATEGORIES.MLB,
  CATEGORIES.NFL,
  CATEGORIES.NHL,
  CATEGORIES.NBA,
  CATEGORIES.MLS,
];

export const HOME_AWAY_FILTER_CATEGORIES = [
  CATEGORIES.AFL,
  CATEGORIES.AHL,
  CATEGORIES.CFL,
  CATEGORIES.CL,
  CATEGORIES.CPL,
  CATEGORIES.GL,
  CATEGORIES.INTERNATIONAL,
  CATEGORIES.MILB,
  CATEGORIES.MILS,
  CATEGORIES.NWSL,
  CATEGORIES.RUGBY,
  CATEGORIES.SOCCER,
  CATEGORIES.WHL,
  CATEGORIES.WNBA,
  CATEGORIES.XFL,
  CATEGORIES.USL,
  CATEGORIES.MLB,
  CATEGORIES.MLS,
  CATEGORIES.NBA,
  CATEGORIES.NFL,
  CATEGORIES.NHL,
  CATEGORIES.BOWL,
  CATEGORIES.CBB,
  CATEGORIES.CBS,
  CATEGORIES.CHO,
  CATEGORIES.CLAX,
  CATEGORIES.WCBB,
  CATEGORIES.AAF,
  CATEGORIES.ECHL,
  CATEGORIES.NASL,
  CATEGORIES.NBAGL,
  CATEGORIES.NLL,
  CATEGORIES.VBALL,
];

const CATEGORY_URL_FORMATS = [
  {
    url: 'f-1',
    format: CATEGORIES.F1,
  },
];

const BASE_CATEGORY_IMG_URL = 'https://assets.gametime.co/categories';

export const getPillDisplayName = (name, abbrev, category) => {
  let displayName = name;
  if (COLLEGE_SPORTS_TO_SPORT_NAME_MAP[category]) {
    displayName = `${abbrev} ${COLLEGE_SPORTS_TO_SPORT_NAME_MAP[category]}`;
  }
  return displayName;
};

export const generateSimpleCategoryUrl = (category) =>
  `/c/${formatUrl(category)}`;

export const getCategoryPrefix = (category) => {
  if (CATEGORY_URLS[category] && CATEGORY_URLS[category].url) {
    return CATEGORY_URLS[category].url;
  }
  return category;
};

export const getCategoryPath = (category) => {
  if (!category) {
    return '';
  }
  return generateSimpleCategoryUrl(getCategoryPrefix(category));
};

export const urlCategoryFormatter = (url) => {
  const categoryFormat = CATEGORY_URL_FORMATS.find(
    (format) => format.url === url
  );
  return categoryFormat ? categoryFormat.format : url;
};

export const urlCategorySelector = (url) => {
  let selectedCategory = urlCategoryFormatter(url);

  Object.entries(CATEGORY_URLS).forEach(([category, categoryConfig]) => {
    if (categoryConfig.url === url) {
      selectedCategory = category;
      return false;
    }
  });
  return selectedCategory;
};

export const urlConfigSelector = (url) =>
  Object.values(CATEGORY_URLS).find((category) => category.url === url);

export const isInvalidCategoryPageSelector = (url) =>
  CATEGORY_URLS[url] &&
  CATEGORY_URLS[url].url &&
  CATEGORY_URLS[url].url !== url;

export const getCategoryName = (category) =>
  CATEGORY_URLS[category]?.shortTitle ||
  CATEGORY_URLS[category]?.title ||
  category.toUpperCase();

export const getSportNameFromCategory = (category) =>
  CATEGORY_TO_SPORT_NAME[category] || category;

export const getCategorySelectorName = (categoryUrl) =>
  _get(categoryUrl, 'shortTitle', _get(categoryUrl, 'title'));

export const generateCategoryLinks = (categories) =>
  categories.map((category) => ({
    title: getCategoryName(category),
    to: getCategoryPath(category),
    clickTracker: new CategoryClickTracker(category),
  }));

export const getCategoryImgUrl = (category) =>
  `${BASE_CATEGORY_IMG_URL}/${category}.jpg`;
