import React from 'react';

import BreadCrumbs from 'components/Breadcrumbs/Breadcrumbs';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { DataLoader } from 'routes/routes.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchPerformersData } from 'store/modules/performers/actions';
import {
  selectFetchPerformersData,
  selectIsPerformersLoading,
} from 'store/modules/performers/selectors';

import SitemapURLsList from '../components/SitemapURLsList';
import { getPerformersSitemapURLItems } from '../helpers';

import styles from '../Sitemap.module.scss';

const PERFORMERS_PER_PAGE = 200;

const PerformersSitemap = () => {
  const dispatch = useAppDispatch();
  const performerData = useAppSelector(selectFetchPerformersData);
  const isPerformersLoading = useAppSelector(selectIsPerformersLoading);
  const title = 'Performers Sitemap';
  const breadcrumbs = [
    { name: 'Gametime', url: '/' },
    { name: 'Sitemap', url: '/html-sitemap' },
  ];

  const handleClickPaginationButton = () => {
    dispatch(
      fetchPerformersData({
        page: performerData.page + 1,
        per_page: PERFORMERS_PER_PAGE,
      })
    );
  };

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={<MinimalHeader search showAccount showHamburger showCategories />}
      footer={<GTFooter />}
    >
      <HeadTitle title={title} />

      <div className={styles.container}>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <h1 className={styles.title}>{title}</h1>
        {performerData.performers.length > 0 && (
          <SitemapURLsList
            urlItems={getPerformersSitemapURLItems(performerData.performers)}
          />
        )}
        {performerData.more && (
          <nav aria-label="Pagination">
            <PaginationButton
              onClick={handleClickPaginationButton}
              loading={isPerformersLoading}
              showSeparator
            />
          </nav>
        )}
      </div>
    </ContainerTemplate>
  );
};

const loader: DataLoader =
  ({ store }) =>
  async () => {
    await store.dispatch(
      fetchPerformersData({ page: 1, per_page: PERFORMERS_PER_PAGE })
    );

    return null;
  };

PerformersSitemap.loader = loader;

export default PerformersSitemap;
