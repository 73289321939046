import React, { createContext, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetSet } from 'react-use';
import { Loader } from '@googlemaps/js-api-loader';
import { useBraze } from 'contexts/BrazeContext';
import PropTypes from 'prop-types';

import {
  mapListingTrackingData,
  PAYLOAD,
  Purchase,
  useAnalyticsContext,
} from 'analytics';
import config from 'config/config';
import { selectIsMLBIntegrationEnabled } from 'featureFlags';
import useQueryParams from 'hooks/useQueryParams';
import { useQueue } from 'hooks/useQueue';
import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { ERRORS } from 'pages/Errors/errors.constants';
import { PURCHASE_STEPS } from 'pages/Purchase/constants';
import {
  DELIVERY_TYPES,
  deliveryTypeFor,
  isThirdPartyDelivery,
} from 'store/datatypes/DELIVERY_TYPES';
import {
  TRANSFER_TYPES,
  transferTypeFor,
} from 'store/datatypes/TRANSFER_TYPES';
import { selectSearchTestData } from 'store/modules/data/Search/selectors';
import { MODALS, showModal } from 'store/modules/modals/modals';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import { submitPurchaseRequest } from 'store/modules/purchase/purchaseFlow';
import {
  createUserExternalAccount,
  fetchUserExternalAccount,
  updateUser,
} from 'store/modules/user/actions';
import {
  selectUserDetails,
  userPromosForListingPromoCodeSelector,
} from 'store/modules/user/user.selectors';
import { getExternalAccountType } from 'store/modules/user/utils';
import {
  updateUserDeliveryAddress,
  updateUserPaymentAddress,
} from 'store/modules/userPurchases/actions';
import { selectIsInitialUserPurchase } from 'store/modules/userPurchases/userPurchases.selectors';
import { parseGeoCodeAddressComponents } from 'utils/geo';
import { denormalizePhoneNumber } from 'utils/phone';
import { isSuperBowl } from 'utils/superBowl';
import { queryObjectToSearchString } from 'utils/url';

// RESULT ACTION HELPER: https://github.com/gametimesf/customer_users/blob/master/lib/mobile/result_action_helper.rb#L71
const PURCHASE_RESULT_ACTIONS = {
  RETRY: 'retry',
  ENTER_CARD: 'enter_card',
  CALL_SUPPORT: 'call_support',
  COLLECT_ADDRESS: 'collect_address',
  SHOW_LISTING_DETAIL: 'show_listing_detail',
  SHOW_TEAM_LISTINGS: 'show_team_listings',
};

const prePurchaseSteps = [
  PURCHASE_STEPS.USER_TRANSFER,
  PURCHASE_STEPS.USER_TEXT,
];
const postPurchaseSteps = [
  PURCHASE_STEPS.USER_PHONE,
  PURCHASE_STEPS.USER_VERIFY,
  PURCHASE_STEPS.USER_ADDRESS,
];

export const PurchaseFlowContext = createContext();

function PurchaseFlowProvider({
  children,
  listing,
  event,
  dispatch,
  user,
  userHasPhoneNumber,
  promoCode,
  isInitialUserPurchase,
  isMLBIntegrationEnabled,
  searchTestData,
}) {
  const analytics = useAnalyticsContext();
  const navigate = useNavigate();
  const [query] = useQueryParams();
  const currentLocation = useLocation();
  const algoliaFields = {
    queryId: query.queryId,
    resultPosition: query.resultPosition,
    searchIndex: query.searchIndex,
    searchSessionId: query.searchSessionId,
  };
  const isMLBMarketingOptIn = query.mlbOptedIn;

  const [purchaseStep, setPurchaseStep] = useState(null);
  const [getTransactionId, setTransactionId] = useGetSet(null);
  const [getPurchaseType, setPurchaseType] = useGetSet(null);

  const externalAccountType = isMLBIntegrationEnabled
    ? getExternalAccountType(listing.ticketType)
    : undefined;

  const loader = new Loader({
    apiKey: config.GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });

  const { logPurchaseToBraze } = useBraze();
  const trackPurchase = (purchaseResult, insuranceTracking = {}) => {
    const purchasePollingDone = purchaseResult?.action !== 'retry';
    const purchasePollingResultFailure =
      purchaseResult?.reason !== 'successful' &&
      purchaseResult?.reason !== 'verify_address';

    const performer = event.getPrimaryPerformer();
    const paymentMethodTracker = Purchase.PAYMENT_METHODS[getPurchaseType()]();

    if (purchasePollingDone && !purchasePollingResultFailure) {
      const purchaseValue = listing.quantity * listing.totalPrice;
      const isPromoEligible = isInitialUserPurchase && purchaseValue >= 150;

      // the Purchase event payload we stream to mparticle and eventually to GTM and other 3rd parties
      analytics.track(
        new Purchase({
          paymentMethod: paymentMethodTracker,
          transactionId: purchaseResult.transaction_id,
          logStreamId: purchaseResult.log_stream_id, // see: https://gametime.atlassian.net/browse/MWEB-5697
          supplierLogId: purchaseResult.supplier_log_id, // see: https://gametime.atlassian.net/browse/MWEB-5697
          listingId: listing.id,
          eventId: event.id,
          performerId: performer.team_id,
          venueId: event.venue.id,
          dev: {
            event_id: event.id,
            price: listing.totalPrice,
            quantity: listing.quantity,
            purchase_value: purchaseValue,
          },
          payload: {
            [PAYLOAD.QUERY_ID]: algoliaFields.queryId,
            [PAYLOAD.RESULT_POSITION]: algoliaFields.resultPosition,
            [PAYLOAD.SEARCH_INDEX]: algoliaFields.searchIndex,
            [PAYLOAD.SEARCH_SESSION_ID]: algoliaFields.searchSessionId,
            [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
            [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
            [PAYLOAD.PROMO_ELIGIBLE]: isPromoEligible,
            [PAYLOAD.MLB_MARKETING_OPT_IN]:
              isMLBIntegrationEnabled && isMLBMarketingOptIn,
            ...insuranceTracking,
            ...mapListingTrackingData(listing),
          },
        })
      );

      if (logPurchaseToBraze) {
        logPurchaseToBraze({
          fullEvent: event,
          listing,
          promoCode,
          transactionId: purchaseResult.transaction_id,
        });
      }

      if (__CLIENT__ && typeof window !== 'undefined') {
        if (typeof window.fbq === 'function') {
          window.fbq('track', 'Purchase', {
            value: listing.quantity * listing.totalPrice,
            currency: 'USD',
          });
        }

        if (Array.isArray(window.mp_data_layer)) {
          const variantName = event.isSportsCategoryGroup()
            ? event.getName()
            : event.getDate();
          const deliveryType = listing.transferType
            ? `${listing.transferType}_transfer`
            : listing.deliveryType;

          /**
           * The purchase event we send to mParticle has its attributes flattened which cannot be read in GA so we have to send
           * the event manually to GTM in their correct format. mParticle supports GTM eCommerce event via the mParticle.eCommerce API,
           * however that API does not currently support product-level custom dimensions/metrics. Because of this, we instead send a
           * custom event from the mParticle data layer that GTM then picks us up as the eCommerce event for the purchase. If mParticle
           * supports product-level custom dimensions in the future and/or we don't need product-level dimensions in the future, this
           * code can be changed to use their native API instead of a custom GTM event.
           */
          const gtmPurchase = {
            purchase: {
              actionField: {
                id: purchaseResult.transaction_id, // Transaction ID. Required for purchases and refunds.
                affiliation: '', // optional. we don't have access to these
                revenue: listing.quantity * listing.totalPrice, // Total transaction value (incl. tax and shipping)
                tax: listing.salesTax * listing.quantity,
                shipping: '',
                coupon: promoCode,
              },
              products: [
                {
                  // List of productFieldObjects.
                  name: event.getPrimaryPerformer().name, // Name or ID is required.
                  id: listing.id,
                  price: listing.totalPrice,
                  brand: 'Gametime Tickets', // required field
                  category: event.getPrimaryPerformer().category,
                  variant: variantName, // '{{date}} or {{performer 1 vs performer 2}}', // date for music / theater and pvp for sports
                  quantity: listing.quantity,
                  coupon: '', // Optional, promo code set on transaction level
                  dimension1: event.getPrimaryPerformer().categoryGroup,
                  dimension2: event.metro, // Miami, Dallas, etc.
                  dimension3: event.venueName, // American Airlines Arena, Club Dada, Gas Monkey, etc.
                  dimension4: deliveryType, // Transfer, Mobile Delivery, Shipping, etc.
                  dimension5: listing.sectionGroup, // Upper, Middle, Lower, General Admission, etc.
                  dimension7: listing.source, // eventellect, etc.
                  metric1: listing.fees * listing.quantity, // our per ticket fee e.g. 15.00
                },
              ],
            },
          };
          window.mp_data_layer.push({
            event: 'gtm_purchase',
            ecommerce: gtmPurchase,
          });
        }
      }
    } else {
      /* Purchase Failed or TimedOut */
      const purchasePollingResultExplanation = purchaseResult?.reason;
      const purchasePollingResultAction = purchaseResult?.action;

      analytics.track('purchase_error', {
        polling_done: purchasePollingDone,
        paymentMethod: getPurchaseType(),
        result_explanation: purchasePollingResultExplanation,
        result_failure: purchasePollingResultFailure,
        result_action: purchasePollingResultAction,
      });
    }
  };

  const prePurchaseStepQueue = useQueue();
  const postPurchaseStepQueue = useQueue();

  const enqueuePrePurchaseStep = (step) => {
    if (!prePurchaseSteps.includes(step)) {
      throw new Error('Unrecognized pre-purchase step: ', step);
    }
    prePurchaseStepQueue.add(step);
  };

  const enqueuePostPurchaseStep = (step) => {
    if (!postPurchaseSteps.includes(step)) {
      throw new Error('Unrecognized post-purchase step: ', step);
    }
    postPurchaseStepQueue.add(step);
  };

  const showErrorModal = (purchaseError, error) => {
    if (error) {
      console.error(error);
    }
    dispatch(
      showModal(MODALS.ERROR, {
        currentLocation,
        listing,
        purchaseError,
      })
    );
  };

  const goToNextPostPurchaseStep = () => {
    const nextStep = postPurchaseStepQueue.remove();
    if (nextStep) {
      return setPurchaseStep(nextStep);
    }

    navigate(`/order/${getTransactionId()}/?confirm=1`);
  };

  const initPostPurchase = (purchaseResult) => {
    if (!purchaseResult) {
      return;
    }

    const resultAction = purchaseResult.action;
    const resultReason = purchaseResult.reason;

    switch (purchaseResult.action) {
      case PURCHASE_RESULT_ACTIONS.RETRY: {
        return showErrorModal(ERRORS.TRANSACTION_INCOMPLETE_CALL);
      }
      case PURCHASE_RESULT_ACTIONS.ENTER_CARD: {
        return showErrorModal(ERRORS.TRANSACTION_UNSUCCESSFUL_CARD);
      }
      case PURCHASE_RESULT_ACTIONS.SHOW_LISTING_DETAIL: {
        return showErrorModal(ERRORS.TRANSACTION_UNSUCCESSFUL_RETURN_LISTING);
      }
      case PURCHASE_RESULT_ACTIONS.SHOW_TEAM_LISTINGS: {
        // User is banned from purchasing
        if (resultReason === 'unable') {
          return showErrorModal(ERRORS.TRANSACTION_UNSUCCESSFUL_CALL);
        }

        return showErrorModal(ERRORS.TRANSACTION_UNSUCCESSFUL_RETURN_BROWSE);
      }
      case PURCHASE_RESULT_ACTIONS.CALL_SUPPORT: {
        return showErrorModal(ERRORS.TRANSACTION_UNSUCCESSFUL_CALL);
      }
      default: {
        break;
      }
    }

    if (!['successful', 'verify_address'].includes(resultReason)) {
      // purchase failed
      return showErrorModal(ERRORS.TRANSACTION_UNSUCCESSFUL_RETURN_LISTING);
    }

    if (!userHasPhoneNumber) {
      enqueuePostPurchaseStep(PURCHASE_STEPS.USER_PHONE);
    }

    if (resultAction === PURCHASE_RESULT_ACTIONS.COLLECT_ADDRESS) {
      enqueuePostPurchaseStep(PURCHASE_STEPS.USER_VERIFY);
    }

    const deliveryType = deliveryTypeFor(listing);
    if (deliveryType === DELIVERY_TYPES.hard && !isSuperBowl(listing.eventId)) {
      enqueuePostPurchaseStep(PURCHASE_STEPS.USER_ADDRESS);
    }

    goToNextPostPurchaseStep();
  };

  const resolvePurchaseOptionsRef = React.useRef(null);

  const goToNextPrePurchaseStep = () => {
    const nextStep = prePurchaseStepQueue.remove();
    if (nextStep) {
      return setPurchaseStep(nextStep);
    }

    setPurchaseStep(PURCHASE_STEPS.SECURE);

    // it is possible that the purchase options resolver is either a Promise
    // or a synchronous function, using Promise.resolve allows us to chain it
    // either way. if we "fix" the way we use Apple Pay, this will all be a
    // little more straightforward.
    // see: https://gametime.atlassian.net/browse/MWEB-5107
    Promise.resolve()
      // TODO: use something safer than a ref... callbacks are closures, though,
      // so will need to sort that out...
      .then(() => resolvePurchaseOptionsRef.current())
      .then(({ paymentOptions, insuranceOptions, insuranceTracking }) => {
        // sets a minimum time for the purchase fulfillment animation
        // to run before subsequent callbacks are executed
        const MIN_INTERVAL = 2000;
        const MIN_INTERVAL_THRESHOLD = 200;
        const startRequest = Date.now();
        const executeCallback = (callback) => {
          const endRequest = Date.now();
          const elapsed = endRequest - startRequest;
          const remaining = MIN_INTERVAL - elapsed;
          if (remaining > MIN_INTERVAL_THRESHOLD) {
            setTimeout(callback, remaining);
          } else {
            callback();
          }
        };

        dispatch(
          submitPurchaseRequest(listing, paymentOptions, insuranceOptions)
        )
          .then((purchaseResult) => {
            executeCallback(() => {
              setTransactionId(purchaseResult?.transaction_id);
              trackPurchase(purchaseResult, insuranceTracking);
              initPostPurchase(purchaseResult);
            });
          })
          .catch((error) => {
            executeCallback(() => {
              throw error;
            });
          });
      })
      .catch((error) => {
        if (error?.message !== 'USER_CANCELLED') {
          showErrorModal(ERRORS.CREATE_USER_PURCHASE_FAILED, error);
        }
      });
  };

  const initPurchaseFlow = async (
    purchaseType,
    resolvePurchaseOptions,
    isMLBMarketingOptIn = false
  ) => {
    // in case the user used the "back" button on a previous purchase
    // or the attempt failed, we should always start with a fresh queue:
    prePurchaseStepQueue.clear();
    postPurchaseStepQueue.clear();

    if (!Object.values(PURCHASE_TYPE).includes(purchaseType)) {
      throw new Error('Unrecognized purchase type: ', purchaseType);
    }

    if (typeof resolvePurchaseOptions !== 'function') {
      throw new Error('resolvePurchaseOptions must be a function');
    }

    setPurchaseType(purchaseType);

    // use something more secure than a ref
    resolvePurchaseOptionsRef.current = resolvePurchaseOptions;

    if (isThirdPartyDelivery(deliveryTypeFor(listing)) || externalAccountType) {
      const transferType = transferTypeFor(listing);
      const transferMessage =
        transferType && TRANSFER_TYPES[transferType.toUpperCase()];

      const thirdPartyTransferStep =
        transferMessage === TRANSFER_TYPES.TEXT
          ? PURCHASE_STEPS.USER_TEXT
          : PURCHASE_STEPS.USER_TRANSFER;

      if (externalAccountType) {
        try {
          await dispatch(fetchUserExternalAccount(externalAccountType));
        } catch (err) {
          enqueuePrePurchaseStep(thirdPartyTransferStep);
        }
      } else {
        enqueuePrePurchaseStep(thirdPartyTransferStep);
      }
    }

    goToNextPrePurchaseStep();

    const listingPath = listing.getPath(event);
    navigate({
      pathname: `${listingPath}/buy`,
      search: queryObjectToSearchString({
        ...query,
        ...algoliaFields,
        mlbOptedIn: isMLBMarketingOptIn,
      }),
    });
  };

  const handleSubmitTransferEmail = async ({ firstName, lastName, email }) => {
    try {
      // if there is an external account type for the listing, the user info
      // collection step will only show up if the account does not exist yet
      if (externalAccountType) {
        await dispatch(
          createUserExternalAccount(externalAccountType, {
            first_name: firstName,
            last_name: lastName,
            email,
          })
        );
      } else {
        await dispatch(
          updateUser({
            ...user,
            tmmobile_first_name: firstName,
            tmmobile_last_name: lastName,
            tmmobile_email: email,
          })
        );
      }

      goToNextPrePurchaseStep();
    } catch (error) {
      showErrorModal(ERRORS.THIRD_PARTY_COLLECTION_FAILED, error);
    }
  };

  const handleSubmitTransferPhone = async ({ firstName, lastName, phone }) => {
    try {
      await dispatch(
        updateUser({
          ...user,
          tmmobile_first_name: firstName,
          tmmobile_last_name: lastName,
          // TODO: phone should be denormalized at the form
          transfer_phone: denormalizePhoneNumber(phone),
        })
      );
      goToNextPrePurchaseStep();
    } catch (error) {
      showErrorModal(ERRORS.TEXT_MESSAGE_COLLECTION_FAILED, error);
    }
  };

  const handleSubmitUserPhone = async (phone) => {
    try {
      await dispatch(
        updateUser({
          ...user,
          // TODO: phone should be denormalized at the form
          phone: denormalizePhoneNumber(phone),
        })
      );
      goToNextPostPurchaseStep();
    } catch (error) {
      showErrorModal(ERRORS.UPDATE_PHONE_FAILED, error);
    }
  };

  const handleSubmitUserDeliveryAddress = async (form) => {
    try {
      const [firstName, lastName] = form.name.split(' ');
      const { Geocoder } = await loader.importLibrary('geocoding');
      const Geo = new Geocoder();
      const { results } = await Geo.geocode({ address: form.address });
      const parsedAddress = {
        ...parseGeoCodeAddressComponents(results[0].address_components),
        extended_address: form.address2,
      };

      const transactionId = getTransactionId();

      const paymentData = {
        address: {
          first_name: firstName,
          last_name: lastName,
          ...parsedAddress,
          country_code: parsedAddress.country_code_alpha2,
          id: transactionId,
        },
        session_token: user.session_token,
        user_id: user.id,
      };
      await dispatch(
        updateUserDeliveryAddress(user, transactionId, paymentData)
      );
      goToNextPostPurchaseStep();
    } catch (error) {
      showErrorModal(ERRORS.UPDATE_DELIVERY_ADDRESS_FAILED, error);
    }
  };

  const handleSubmitVerifyBillingAddress = async (form) => {
    try {
      const { Geocoder } = await loader.importLibrary('geocoding');
      const Geo = new Geocoder();
      const addressInputs = await Geo.geocode({ address: form.address })
        .then(({ results }) => ({
          ...parseGeoCodeAddressComponents(results[0].address_components),
          address2: form.address2,
        }))
        .catch(() => ({
          street_address: '',
          postal_code: '00000',
          country_code_alpha2: 'US',
          locality: '',
          region: '',
          address2: '',
        }));

      await dispatch(
        updateUserPaymentAddress(user, getTransactionId(), {
          billing_address: {
            name: form.name,
            ...addressInputs,
          },
        })
      );
      goToNextPostPurchaseStep();
    } catch (error) {
      showErrorModal(ERRORS.UPDATE_PAYMENT_ADDRESS_FAILED, error);
    }
  };

  return (
    <PurchaseFlowContext.Provider
      value={{
        initPurchaseFlow,
        initPostPurchase,
        purchaseStep,
        purchaseType: getPurchaseType(),
        handleSubmitTransferEmail,
        handleSubmitTransferPhone,
        handleSubmitUserPhone,
        handleSubmitUserDeliveryAddress,
        handleSubmitVerifyBillingAddress,
        isMLBInfoCollection: !!externalAccountType,
      }}
    >
      {children}
    </PurchaseFlowContext.Provider>
  );
}
PurchaseFlowProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  listing: PropTypes.instanceOf(Listing).isRequired,
  event: PropTypes.instanceOf(FullEvent).isRequired,
  user: PropTypes.object.isRequired,
  userHasPhoneNumber: PropTypes.bool.isRequired,
  promoCode: PropTypes.string,
  isInitialUserPurchase: PropTypes.bool,
  isMLBIntegrationEnabled: PropTypes.bool,
  searchTestData: PropTypes.shape({
    testId: PropTypes.string,
    variantId: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    user: selectUserDetails(state),
    userHasPhoneNumber: !!selectUserDetails(state).phone,
    promoCode: userPromosForListingPromoCodeSelector(state),
    isInitialUserPurchase: selectIsInitialUserPurchase(state),
    isMLBIntegrationEnabled: selectIsMLBIntegrationEnabled(state),
    searchTestData: selectSearchTestData(state),
  };
}

export default connect(mapStateToProps)(PurchaseFlowProvider);

export function usePurchaseFlow() {
  const context = useContext(PurchaseFlowContext);
  if (!context) {
    throw new Error(
      'usePurchaseFlow may only be used within PurchaseFlowProvider'
    );
  }
  return context;
}
/**
 * HOC to inject PurchaseFlowContext values into wrapped class components. Prefer
 * usePurchaseFlow for function components.
 */
export function withPurchaseFlow(Component) {
  const WithPurchaseFlow = (props) => (
    <PurchaseFlowContext.Consumer>
      {(contextValue) => <Component {...props} {...contextValue} />}
    </PurchaseFlowContext.Consumer>
  );

  const displayName = Component.displayName || Component.name || 'Component';
  WithPurchaseFlow.displayName = `withPurchaseFlow(${displayName})`;

  return WithPurchaseFlow;
}
