import { ValueOf } from 'type-fest';

export const experiments = {
  INSURANCE_WEB_PILOT: {
    name: 'INSURANCE_WEB_PILOT',
    default_variant: 'CONTROL',
    variant: 'INSURANCE_ENABLED',
  },
  PERCENTAGE_VS_DOLLARS: {
    name: 'PERCENTAGE_VS_DOLLARS',
    default_variant: 'CONTROL',
    variant: 'DOLLARS_OFF',
  },
  PERFORMER_INLINE_PRICING_MWEB: {
    name: 'PERFORMER_INLINE_PRICING_MWEB',
    default_variant: 'CONTROL',
    variant: 'PERFORMER_INLINE_PRICING_MWEB',
  },
  APP_REVIEWS: {
    name: 'APP_REVIEWS',
    default_variant: 'CONTROL',
    variant: 'SHOW_APP_REVIEWS',
  },
  NO_NFL_AUTOZOOM_DESKTOP: {
    name: 'NO_NFL_AUTOZOOM_DESKTOP',
    default_variant: 'CONTROL',
    variant: 'REMOVE_NFL_AUTOZOOM',
  },
  MLB_TEAM_LOGOS_PERFORMER: {
    name: 'MLB_TEAM_LOGOS_PERFORMER',
    default_variant: 'CONTROL',
    variant: 'MLB_TEAM_LOGOS_PERFORMER',
  },
  WEB_EXCLUSIVES_V3: {
    name: 'WEB_EXCLUSIVES_V3',
    default_variant: 'CONTROL',
    variant: 'EXCLUSIVES_V3',
  },
  HOMEPAGE_REDESIGN_V1: {
    name: 'HOMEPAGE_REDESIGN_V1',
    default_variant: 'CONTROL',
    variant: 'REDESIGN',
  },
  GALLERY_VIEW_V3: {
    name: 'GALLERY_VIEW_V3',
    default_variant: 'CONTROL',
    variant: 'GALLERY_VIEW_V3',
  },
  CHECKOUT_REDESIGN: {
    name: 'CHECKOUT_REDESIGN',
    default_variant: 'CONTROL',
    variant1: 'LIGHT_THEME',
    variant2: 'DARK_THEME',
  },
  EVENT_NAMES_NON_SPORTS_SEARCH: {
    name: 'EVENT_NAMES_NON_SPORTS_SEARCH',
    default_variant: 'SHOW_PERFORMER_NAMES',
    variant: 'SHOW_EVENT_NAMES',
  },
} as const;

export type Experiment = ValueOf<typeof experiments>;
