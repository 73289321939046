import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import { motion } from 'framer-motion';
import withOutletContext from 'hoc/withOutletContext';
import withRouter from 'hoc/withRouter';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';
import Alert from 'ui/Alert';

import {
  Click,
  ClickTracker,
  mapListingTrackingData,
  PAYLOAD,
  PurchaseListing,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import {
  generateBreadcrumbSchema,
  getCategoryBreadcrumbConfig,
  getEventBreadcrumbConfig,
  getListingBreadcrumbConfig,
} from 'components/Breadcrumbs/breadcrumb.helpers';
import DealTag from 'components/DealTag';
import DeliveryBadge from 'components/DeliveryFormat/DeliveryBadge/DeliveryBadge';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import JsonLD from 'components/JsonLD/JsonLD';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import { ACTIONS as T_ACTIONS } from 'components/Trackable/TrackingHelper';
import UrgencyMessage from 'components/UrgencyMessage/UrgencyMessage';
import { selectIsWebExclusivesV3Experiment } from 'experiments';
import { DealStarIcon } from 'icons';
import { Listing as ListingModel } from 'models';
import { getListingPageTitle } from 'modules/pageTitles';
import {
  getCurrencyPrefix,
  isDefaultAllInState,
  isFaceValueState,
} from 'pages/Event/helpers';
import { WIDTH_MOBILE_SEAT_MAP } from 'pages/Listing/constants';
import { deliveryFormatForListing } from 'store/datatypes/DELIVERY_FORMATS';
import {
  deliveryTypeFor,
  isThirdPartyDelivery,
} from 'store/datatypes/DELIVERY_TYPES';
import { updateLastVisitedListingId } from 'store/modules/app/app.ui';
import { selectFullEventById } from 'store/modules/data/FullEvents/selectors';
import { selectIsVenueAllInPrice } from 'store/modules/data/Listings/selectors';
import { isListingDetailsPage } from 'store/modules/history/history';
import { selectIsAllInPricing } from 'store/modules/listingsV3/selectors';
import { getAssociatedEventPathname } from 'store/modules/location';
import { MODALS, showModal } from 'store/modules/modals/modals';
import { extendedPurchaseSelector } from 'store/modules/purchase/purchase.selectors';
import { fetchDisclosures } from 'store/modules/resources/resource.actions';
import { getPerformerPath } from 'store/modules/resources/resource.paths';
import {
  allDisclosuresSelector,
  isPurchasableListingSelector,
  selectAllDeals,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import {
  selectUserDetails,
  userPromosForListingPriceSelector,
  userPromosForListingSavingsSelector,
} from 'store/modules/user/user.selectors';
import { fetchCompleteUserPurchases } from 'store/modules/userPurchases/actions';
import { userPurchaseCreditCard } from 'store/modules/userPurchases/creditCard/creditCard.selectors';
import {
  selectCompleteUserPurchases,
  selectIsInitialUserPurchase,
} from 'store/modules/userPurchases/userPurchases.selectors';
import colors from 'styles/colors.constants';
import { isObjectEmpty } from 'utils/objects';

import DetailsStack from './DetailsStack/DetailsStack';
import { getSeatRange } from './utils';

import styles from './Listing.module.scss';

@TrackPageView(({ listing, fullEvent, showAllInPricing, isPromoEligible }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.LISTING({
    fullEvent,
    listing,
    displayed_price: listing.totalPrice,
  }),
  payload: {
    all_in_filter: showAllInPricing,
    [PAYLOAD.PROMO_ELIGIBLE]: isPromoEligible,
    ...mapListingTrackingData(listing),
  },
}))
@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.LISTING(),
  payload: mapListingTrackingData(listing),
}))
@withAnalyticsContext
class Listing extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    listing: PropTypes.instanceOf(ListingModel),
    params: PropTypes.shape({
      listingId: PropTypes.string.isRequired,
      eventId: PropTypes.string.isRequired,
    }).isRequired,
    extendedPurchase: PropTypes.object,
    updateLastVisitedListingId: PropTypes.func.isRequired,
    isPurchasableListing: PropTypes.bool.isRequired,
    fullEvent: PropTypes.object,
    isListingFlow: PropTypes.bool.isRequired,
    performer: PropTypes.object,
    showModal: PropTypes.func.isRequired,
    fetchDisclosures: PropTypes.func.isRequired,
    allDisclosures: PropTypes.object,
    allDeals: PropTypes.object,
    priceWithPromoApplied: PropTypes.number,
    event: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    eventId: PropTypes.string, // Used in mapStateToProps
    selectedMetro: PropTypes.string,
    promoAmount: PropTypes.number,
    showAllInPricing: PropTypes.bool.isRequired,
    showFaceValuePricing: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isPromoEligible: PropTypes.bool, // Used in tracking
    seatRange: PropTypes.string,
    router: PropTypes.object.isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
    user: PropTypes.object,
    purchases: PropTypes.object,
    isWebExclusivesV3Experiment: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSuperbowlModal = this.handleSuperbowlModal.bind(this);
    this.handleAffirmBannerTracker = this.handleAffirmBannerTracker.bind(this);
    this.handlePriceBreakdownInfoClick =
      this.handlePriceBreakdownInfoClick.bind(this);

    this.state = {
      loading: false,
      isUpdatingPricing: false,
    };
  }

  componentDidMount() {
    const { listing, isPurchasableListing } = this.props;
    if (!isPurchasableListing) {
      this.validateRoute();
      return;
    }

    // listing has disclosures. let's fetch the list of disclosures so we know how to render things
    if (listing.disclosures.length > 0) {
      this.props.fetchDisclosures();
    }

    this.props.updateLastVisitedListingId(listing.id);
  }

  componentDidUpdate() {
    const { isListingFlow, fullEvent, listing, router } = this.props;

    if (!listing) {
      const redirectPath = fullEvent.getPath();
      router.navigate({ pathname: redirectPath }, { replace: true });
    }

    if (isListingFlow) {
      this.validateRoute();
    }
  }

  validateRoute() {
    const {
      fullEvent,
      isPurchasableListing,
      performer,
      location: { pathname },
      router,
    } = this.props;
    const performerPath = getPerformerPath(performer);

    if (!isPurchasableListing) {
      if (fullEvent.isValid()) {
        const eventPath = getAssociatedEventPathname(pathname);
        router.navigate({ pathname: eventPath }, { replace: true });
      } else if (performerPath) {
        router.navigate({ pathname: performerPath }, { replace: true });
      } else {
        router.navigate({ pathname: '/' }, { replace: true });
      }
    }
  }

  handleAffirmBannerTracker() {
    const { clickContext, analyticsContext } = this.props;
    if (analyticsContext) {
      const interaction = Click.INTERACTIONS.AFFIRM_BANNER();
      const pageSource = Click.SOURCE_PAGE_TYPES.LISTING();
      const trackProperties = new ClickTracker()
        .interaction({ ...pageSource, ...interaction })
        .json();
      analyticsContext.track(
        new Click(_merge({}, clickContext, trackProperties))
      );
    }
  }

  handlePriceBreakdownInfoClick() {
    const {
      extendedPurchase,
      listing,
      fullEvent,
      selectedMetro,
      promoAmount,
      analyticsContext,
      clickContext,
    } = this.props;

    if (analyticsContext) {
      const interaction = Click.INTERACTIONS.PRICE_BREAKDOWN();
      const pageSource = Click.SOURCE_PAGE_TYPES.LISTING();
      const trackProperties = new ClickTracker()
        .interaction({ ...pageSource, ...interaction })
        .json();
      analyticsContext.track(
        new Click(_merge({}, clickContext, trackProperties))
      );
    }

    this.props.showModal(MODALS.PRICE_BREAKDOWN, {
      currencyPrefix: getCurrencyPrefix(fullEvent, selectedMetro),
      seatCount: listing.quantity,
      seatFee: listing.fees,
      salesTax: listing.salesTax,
      prefeeSeatPrice: listing.prefeePrice,
      discount: extendedPurchase.discount,
      totalPrice: extendedPurchase.totalPrice,
      promoAmount,
    });
  }

  initiateCheckoutFlow() {
    this.setState({ loading: true }, () => {
      const { location } = this.props;
      let { pathname } = location;
      if (pathname.endsWith('/')) {
        pathname = pathname.slice(0, -1);
      }

      setTimeout(() => {
        this.setState({ loading: false });
        this.props.router.navigate({
          pathname: `${pathname}/checkout`,
          search: location.search,
        });
      }, 200);
    });
  }

  renderSeatMap() {
    const { fullEvent, listing } = this.props;
    const mapStyle = {
      backgroundImage: `url(${fullEvent.event.mapUrl}?width=${WIDTH_MOBILE_SEAT_MAP})`,
    };
    const markerStyle = {
      background: colors.gametimeGreenLight,
      top: `calc(${listing.seatMarker.top} - 18px)`,
      left: `calc(${listing.seatMarker.left} - 18px)`,
    };

    return (
      <div className={styles['seat-map-container']}>
        <div className={styles['seat-map']} style={mapStyle} />
        <div className={styles['seat-map-marker']} style={markerStyle} />
      </div>
    );
  }

  renderContinueButton() {
    const { loading, isUpdatingPricing } = this.state;
    const { listing, extendedPurchase, fullEvent } = this.props;

    return (
      <ThemedButtonLoader
        backgroundColor={colors.gametimeGreen}
        color={colors.white}
        mpActions={
          new PurchaseListing({
            action: T_ACTIONS.PURCHASE_STARTED,
            purchase_fees: extendedPurchase.fees,
            purchase_total_price: extendedPurchase.totalPrice,
            seat_count: listing.quantity,
            payload: mapListingTrackingData(listing),
          })
        }
        loading={loading}
        disabled={loading || isUpdatingPricing}
        onClick={() => {
          this.initiateCheckoutFlow();
        }}
        clickTracker={new ClickTracker()
          .interaction(
            Click.INTERACTIONS.CONTINUE_BUTTON(fullEvent.id, listing.id)
          )
          .payload(mapListingTrackingData(listing))}
      >
        <span>CONTINUE</span>
      </ThemedButtonLoader>
    );
  }

  renderMeta() {
    const { fullEvent, listing } = this.props;
    const { section, sectionGroup, row, viewUrl } = listing;

    const title = getListingPageTitle({
      headline: fullEvent.getMediumName('vs', false),
      alias: `${sectionGroup} ${section}`,
      row,
      formatted: fullEvent.datetimeFriendly,
    });

    const breadcrumbs = [
      HOMEPAGE_BREADCRUMB_CONFIG,
      getCategoryBreadcrumbConfig(fullEvent.event.category),
      getEventBreadcrumbConfig(fullEvent),
      getListingBreadcrumbConfig(listing, fullEvent, title),
    ];

    return (
      <div>
        <HeadTitle title={title} />
        <HeadImage src={viewUrl} />
        <JsonLD json={generateBreadcrumbSchema(breadcrumbs)} />
      </div>
    );
  }

  handleSuperbowlModal() {
    this.props.showModal(MODALS.SUPER_BOWL);
  }

  renderUrgencyMessaging(quantity) {
    return <UrgencyMessage type="listing" quantity={quantity} />;
  }

  renderTopValueBanner() {
    const { listing, allDeals, isWebExclusivesV3Experiment } = this.props;

    if (!listing?.dealType || !isWebExclusivesV3Experiment) {
      return null;
    }

    const deal = allDeals[listing.dealType];

    if (!deal?.valueMessage) {
      return null;
    }

    const message = (
      <p className={styles['inline-banner-message']}>
        <b className={styles['inline-banner-title']}>
          {deal.valueMessage.title}
        </b>{' '}
        {deal.valueMessage.subtitle}
      </p>
    );

    return (
      <div className={styles['inline-banner-container']}>
        <Alert
          message={message}
          icon={<DealStarIcon width={32} height={32} />}
          backgroundColor={colors.graphiteBlack}
          borderColor={colors.gametimeGreenLight}
          textColor={colors.gray200}
        />
      </div>
    );
  }

  render() {
    const {
      fullEvent,
      listing,
      allDisclosures,
      allDeals,
      showFaceValuePricing,
      showAllInPricing,
      selectedMetro,
      extendedPurchase,
      priceWithPromoApplied,
      seatRange,
      user,
      purchases,
    } = this.props;

    const currencyPrefix = getCurrencyPrefix(fullEvent, selectedMetro);

    const deal = allDeals[listing.dealType];

    if (isObjectEmpty(listing)) {
      return null;
    }
    const maxLotSize = listing.getMaxLotSize();

    return (
      <div className={styles['ticket-card']}>
        {this.renderMeta()}
        <div className={styles.card}>
          <motion.div
            className={styles['card-headers']}
            transition={{ duration: 0.5 }}
            variants={{
              on: { x: 'calc(-100% - 7px)' },
              off: { x: 0 },
            }}
            initial={false}
          >
            <div className={styles['card-header']}>
              <DeliveryBadge
                deliveryFormat={deliveryFormatForListing(listing)}
              />
              {deal && <DealTag deal={deal} />}
            </div>
          </motion.div>
          <div className={styles['card-sub-header']}>
            {this.renderSeatMap()}
          </div>
          <DetailsStack
            fullEvent={fullEvent}
            listing={listing}
            allDisclosures={allDisclosures}
            continueButton={this.renderContinueButton()}
            onPriceBreakdownInfoClick={this.handlePriceBreakdownInfoClick}
            showFaceValuePricing={showFaceValuePricing}
            showAllInPricing={showAllInPricing}
            currencyPrefix={currencyPrefix}
            isUpdatingPricing={this.state.isUpdatingPricing}
            priceWithPromoApplied={priceWithPromoApplied}
            extendedPurchase={extendedPurchase}
            onAffirmBannerClick={this.handleAffirmBannerTracker}
            seatRange={seatRange}
            maxLotSize={maxLotSize}
            user={user}
            purchases={purchases}
            handleSuperbowlModal={this.handleSuperbowlModal}
            topValueBanner={this.renderTopValueBanner()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { listing, location } = props;
  const eventId = props.eventId || props.params.eventId;
  const fullEvent = selectFullEventById(state, eventId);

  const isAllInPricingWithFlag = selectIsVenueAllInPrice(
    state,
    fullEvent.venueState
  );

  const showRegulatoryAllInPricing = isDefaultAllInState(fullEvent?.venueState);
  const showFaceValuePricing = isFaceValueState(fullEvent?.venueState);
  const isAllInPriceActive = selectIsAllInPricing(state);
  const showAllInPricing =
    showRegulatoryAllInPricing || isAllInPriceActive || isAllInPricingWithFlag;

  const isInitialUserPurchase = selectIsInitialUserPurchase(state);

  return {
    extendedPurchase: props.listing && extendedPurchaseSelector(state, props),
    creditCard: userPurchaseCreditCard(state),
    isPurchasableListing: isPurchasableListingSelector(state, props),
    isListingFlow: isListingDetailsPage(location.pathname),
    fullEvent,
    performer: fullEvent && fullEvent.getPrimaryPerformer(),
    prePurchaseStepsNeeded:
      props.listing && isThirdPartyDelivery(deliveryTypeFor(props.listing)),
    allDisclosures: allDisclosuresSelector(state),
    allDeals: selectAllDeals(state),
    priceWithPromoApplied: userPromosForListingPriceSelector(state),
    listing,
    selectedMetro: (selectUserMetro(state) || selectClosestMetro(state))?.name,
    promoAmount: userPromosForListingSavingsSelector(state),
    showAllInPricing,
    showFaceValuePricing,
    isPromoEligible: isInitialUserPurchase,
    seatRange: getSeatRange(listing.seats),
    user: selectUserDetails(state),
    purchases: selectCompleteUserPurchases(state),
    isWebExclusivesV3Experiment: selectIsWebExclusivesV3Experiment(state),
  };
};

const mapDispatchToProps = {
  updateLastVisitedListingId,
  showModal,
  fetchDisclosures,
};

const loader =
  ({ store: { getState, dispatch } }) =>
  async () => {
    const state = getState();
    const user = selectUserDetails(state);

    if (!user) {
      return null;
    }

    await dispatch(
      fetchCompleteUserPurchases({
        user_id: user.id,
        session_token: user.session_token,
      })
    );

    return null;
  };

const ListingWrapper = withRouter(
  withOutletContext(
    withAppContext(connect(mapStateToProps, mapDispatchToProps)(Listing))
  )
);

ListingWrapper.loader = loader;

export default ListingWrapper;
