import React from 'react';
import { useFormikContext } from 'formik';

import {
  AutocompleteAddressFields,
  useAddressAutocomplete,
} from '../../../../../components/Modals/AddCardModal/AddCardForm/AddCardForm.utils';

import Input from './Input';

export default function Address<
  Values extends { [key: string]: boolean | number | string },
>({
  className,
  id = 'street-address',
  label = 'Street Address',
  name,
  placeholder,
}: {
  className?: string;
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
}): React.JSX.Element {
  const { setValues } = useFormikContext<Values>();
  const inputRef = useAddressAutocomplete(
    (address: AutocompleteAddressFields): void => {
      setValues((prevState: Values) => ({ ...prevState, ...address }));
    }
  );

  return (
    <Input
      className={className}
      id={id}
      label={label}
      name={name ?? id}
      placeholder={placeholder}
      type="text"
      ref={inputRef}
    />
  );
}
