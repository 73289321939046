import React from 'react';

import { Gates } from '../../types/variants';

import useVariantService from './useVariantService';

type Props = {
  children?: React.ReactNode;
  gate: Gates;
  fallback?: React.ReactNode;
};

export default function Feature({
  children,
  gate,
  fallback = null,
}: Props): React.JSX.Element {
  const variantService = useVariantService();

  return <>{variantService.hasFeature(gate) ? children : fallback}</>;
}
