export const PURCHASE_STEPS = {
  // pre-purchase:
  USER_TRANSFER: 'user-transfer',
  USER_TEXT: 'user-text',
  SECURE: 'secure',
  // post-purchase:
  USER_PHONE: 'user-phone',
  USER_VERIFY: 'user-verify',
  USER_ADDRESS: 'user-address',
} as const;
