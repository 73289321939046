import { createAction } from '@reduxjs/toolkit';
import type { AsyncThunk } from 'store';

import {
  getVenues,
  GetVenuesParams,
  GetVenuesResponse,
} from 'services/venue/venue.service';

export const fetchVenuesRequest = createAction<GetVenuesParams>(
  'Venues/FetchVenuesDataRequest'
);

export const fetchVenuesSuccess = createAction<{
  response: GetVenuesResponse;
}>('Venues/FetchVenuesDataSuccess');

export const fetchVenuesFailure = createAction<unknown>(
  'Venues/FetchVenuesDataFailure'
);

export function fetchVenuesData(params: GetVenuesParams): AsyncThunk {
  return async (dispatch, _getState, { mobileApi }) => {
    dispatch(fetchVenuesRequest(params));

    try {
      const response = await getVenues(mobileApi, params);

      if (!response) {
        throw new Error('null response from GET Venues request');
      }

      dispatch(fetchVenuesSuccess({ response }));
    } catch (error) {
      dispatch(fetchVenuesFailure(error));
    }
  };
}

export const fetchVenueBySlug = (slug: string) => fetchVenuesData({ slug });
