import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';
import { FullEvent } from 'models';
import { NAME_TYPES } from 'models/FullEvent';

import styles from './EventInfo.module.scss';

const EventInfo = ({ fullEvent, overlay = false, venueNameSmall = false }) => {
  const { venue } = fullEvent;
  const performer = fullEvent.getPrimaryPerformer();
  const secondPerformer = fullEvent.getSecondaryPerformer();
  const isMatchup =
    !fullEvent.isNameOverridden(NAME_TYPES.SHORT) &&
    fullEvent.isSportsCategoryGroup() &&
    fullEvent.uniquePerformersList.length === 2;

  return (
    <div
      className={classNames(styles['event-info'], {
        [styles.overlay]: overlay,
      })}
    >
      <h1 className={styles['event-details']}>
        <div className={styles['event-name']}>
          {!isMatchup && (
            <Link
              to={performer.getPath()}
              className={styles['event-name-link']}
            >
              {fullEvent.getShortNameMarkup('@')}
            </Link>
          )}
          {isMatchup && (
            <>
              <Link
                to={secondPerformer.getPath()}
                className={styles['event-name-link']}
              >
                {secondPerformer.shortName}
              </Link>
              <span className={styles['event-name-separator']}>@</span>
              <Link
                to={performer.getPath()}
                className={styles['event-name-link']}
              >
                {performer.shortName}
              </Link>
            </>
          )}
        </div>
        <div className={styles.venue}>
          <Link
            to={venue.getPath()}
            className={classNames(styles['event-name-link'], {
              [styles['event-name-small']]: venueNameSmall,
            })}
          >
            {fullEvent.venueName}
          </Link>
        </div>
      </h1>
    </div>
  );
};

EventInfo.propTypes = {
  overlay: PropTypes.bool,
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  venueNameSmall: PropTypes.bool,
};

export default EventInfo;
