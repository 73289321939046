import { HttpClient } from 'services/HttpClient/HttpClient';
import { SearchVenue } from 'types';

export interface GetVenuesParams {
  slug?: string;
  page?: number;
  per_page?: number;
}

export interface GetVenuesResponse {
  results: SearchVenue[];
  more: boolean;
  page: number;
  per_page: number;
  total: number;
}

export const getVenues = (
  mobileApi: HttpClient,
  params: GetVenuesParams
): Promise<GetVenuesResponse | null> => {
  const searchParams = new URLSearchParams();

  if (params.slug) {
    searchParams.append('slug', params.slug);
  }

  if (params.page) {
    searchParams.append('page', params.page.toString());
  }

  if (params.per_page) {
    searchParams.append('per_page', params.per_page.toString());
  }

  return mobileApi.request({
    path: '/v1/venues',
    method: 'GET',
    searchParams,
  });
};
