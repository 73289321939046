import { SetRequired } from 'type-fest';

import { HttpClient } from 'services/HttpClient/HttpClient';
import { GetListingsV3RequestParams, GetListingsV3Response } from 'types';

/**
 * Parameters for fetching listings from the V3 endpoint, excluding the
 * `zListings` parameters. Some parameters are addionally marked as required
 * for our use cases even though MobileAPI doesn't technically require them.
 */
export type GetListingsV3Params = SetRequired<
  Omit<GetListingsV3RequestParams, `zListings${string}`>,
  'zoom' | 'all_in_pricing'
>;

export type GetListingsV3ZListings = Omit<
  GetListingsV3RequestParams,
  keyof GetListingsV3Params
>;

/**
 * Fetch listings from the V3 endpoint.
 *
 * For us to get an accurate view of Listings for the platform and to be able
 * to effectively cache the response with Fastly, this endpoint needs us to
 * distinguish between mobile web and desktop requests by setting the
 * `platform_type` header.
 */
export function getListingsV3({
  mobileApi,
  params: { eventId, ...params },
  zListings,
  isMobile,
}: {
  mobileApi: HttpClient;
  params: GetListingsV3Params;
  zListings: GetListingsV3ZListings;
  isMobile: boolean;
}) {
  if (!eventId) {
    throw new Error('eventId is required by getListingsV3');
  }

  const searchParams = new URLSearchParams();

  let paramKey: keyof typeof params;
  for (paramKey in params) {
    const value = params[paramKey];
    if (value !== undefined) {
      searchParams.set(paramKey, value.toString());
    }
  }

  let zListingKey: keyof GetListingsV3ZListings;
  for (zListingKey in zListings) {
    const value = zListings[zListingKey];
    if (value !== undefined) {
      searchParams.set(zListingKey, value.toString());
    }
  }

  return mobileApi.request<GetListingsV3Response>({
    path: `/v3/listings/${eventId}`,
    searchParams,
    headers: {
      platform_type: isMobile ? 'mobile_web' : 'desktop_web',
    },
  });
}
