import React from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  Click,
  ClickTracker,
  RedeemCode,
  SOURCE_PAGE_TYPE_NAMES,
  useAnalyticsContext,
} from 'analytics';
import { useAppDispatch } from 'store';
import {
  addUserPromoCode,
  fetchUserPromoCodesForListing,
} from 'store/modules/user/actions';
import { HttpError } from 'utils/errors';

import Form from '../Form';
import { FormSection } from '../Inputs';
import Input from '../Inputs/Input';

const PromoCodeFormValidationSchema = Yup.object().shape({
  promoCode: Yup.string().trim().required('Please enter a promo code.'),
});

interface PromoCodeFormProps {
  onSubmit: VoidFunction;
  eventId: string;
  listingId: string;
}

interface Values {
  promoCode: string;
}

export default function PromoCodeForm({
  onSubmit,
  eventId,
  listingId,
}: PromoCodeFormProps): React.JSX.Element {
  const analytics = useAnalyticsContext();
  const dispatch = useAppDispatch();

  async function handleSubmit(
    values: Values,
    { setFieldError, setSubmitting, resetForm }: FormikHelpers<Values>
  ) {
    const clickTracker = new ClickTracker()
      .targetPageType(Click.TARGET_PAGE_TYPES.REDEEM_CODE())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.REDEEM_CODE())
      .interaction(Click.INTERACTIONS.NONE())
      .payload({ code: values.promoCode });

    try {
      setSubmitting(true);
      analytics.track(new Click(clickTracker.json()));
      await dispatch(addUserPromoCode(values.promoCode));

      analytics.track(
        new RedeemCode({
          status: 'succeeded',
          code: values.promoCode,
          page_type: SOURCE_PAGE_TYPE_NAMES.REDEEM_CODE,
        })
      );

      const userPromosForListing = await dispatch(
        fetchUserPromoCodesForListing({ eventId, listingId })
      );

      if (!userPromosForListing?.user_promos?.length) {
        throw new Error('This promo code cannot be used on this order.');
      }

      resetForm({
        values: {
          promoCode: '',
        },
      });

      onSubmit();
    } catch (error: unknown) {
      if (error instanceof HttpError) {
        setFieldError(
          'promoCode',
          (error.body?.subtitle ?? error.message).toString()
        );

        setSubmitting(false);
        return;
      }

      if (error instanceof Error) {
        setFieldError('promoCode', error.message);
        setSubmitting(false);
        return;
      }

      setSubmitting(false);
    }
  }

  return (
    <Form
      theme="light"
      id="add-promo-form"
      initialValues={{ promoCode: '' }}
      submitText="Apply"
      onSubmit={handleSubmit}
      validationSchema={PromoCodeFormValidationSchema}
    >
      <FormSection title="Redeem Your Promo Code">
        <Input
          id="promo-code"
          label="Enter Promo Code"
          name="promoCode"
          type="text"
        />
      </FormSection>
    </Form>
  );
}
