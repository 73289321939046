import { combineReducers } from '@reduxjs/toolkit';

import collections from './Collections/dataReducer';
import eventsPageData from './eventsPageData/dataReducer';
import fullEvents from './FullEvents/dataReducer';
import listings from './Listings/dataReducer';
import performers from './Performers/dataReducer';
import performersInContext from './PerformersInContext/dataReducer';
import performersInContextV2 from './PerformersInContextV2/dataReducer';
import reviews from './Reviews/dataReducer';
import search from './Search/dataReducer';
import upsellEvents from './UpsellEvents/dataReducer';

export default combineReducers({
  fullEvents,
  performers,
  listings,
  performersInContext,
  performersInContextV2,
  eventsPageData,
  collections,
  upsellEvents,
  search,
  reviews,
});
