import React from 'react';

import { Click, FullEventClickTracker } from 'analytics';
import EventCell from 'components/Event/EventCell/EventCell';
import { FullEvent } from 'models';

interface ScheduleListProps {
  schedule: FullEvent[];
  eventId: string;
  onClose?: () => void;
}

function ScheduleList({ schedule, eventId, onClose }: ScheduleListProps) {
  const handleClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const performerSchedules = schedule.map((fullEvent) => {
    return (
      <EventCell
        key={fullEvent.id}
        fullEvent={fullEvent}
        onClick={handleClick}
        isActive={fullEvent.id === eventId}
        isSmall
        clickTracker={new FullEventClickTracker(fullEvent)
          .interaction(Click.INTERACTIONS.CELL())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.SCHEDULE())}
        isReplace
      />
    );
  });

  return <div>{performerSchedules}</div>;
}

export default ScheduleList;
