import React from 'react';

import styles from './UserEmailRow.module.scss';

interface UserEmailRowProps {
  userEmail: string;
}

const UserEmailRow = ({ userEmail }: UserEmailRowProps) => {
  return (
    <div className={styles['user-email']}>
      <span className={styles.email}>{userEmail}</span>
    </div>
  );
};

export default UserEmailRow;
