import React from 'react';
import classNames from 'classnames';

import { DealStarIcon } from '../../../../icons';
import { Deal as DealModel, Listing } from '../../../../models';
import { useAppSelector } from '../../../../store';
import { selectAllDeals } from '../../../../store/modules/resources/resource.selectors';

import styles from './Deal.module.scss';

export default function Deal({
  listing,
}: {
  listing: Listing;
}): React.JSX.Element | null {
  const allDeals = useAppSelector(selectAllDeals);
  /** @ts-expect-error -- `listing.dealType` may not be in `allDeals` and can return `undefined`, that `undefined` is handled below */
  const deal: DealModel | undefined = allDeals[listing.dealType];

  if (!deal?.valueMessage) {
    return null;
  }

  return (
    <aside className={classNames(styles.deal, deal.slug)}>
      <h2 className={styles.header}>{deal.valueMessage.title}</h2>

      <p className={styles.content}>{deal.valueMessage.subtitle}</p>

      <figure aria-hidden className={styles.icon}>
        <DealStarIcon height="auto" width="100%" />
      </figure>
    </aside>
  );
}
