import { Location as LocationType } from 'types';

export default class Location {
  readonly lat: LocationType['lat'];
  readonly lon: LocationType['lon'];

  constructor(location: LocationType) {
    this.lat = location.lat;
    this.lon = location.lon;
  }
}
