import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InfoSmallIcon from 'icons/InfoSmallIcon';
import { Deal, FullEvent, Listing } from 'models';
import {
  MODALS,
  showModal as showModalDispatch,
} from 'store/modules/modals/modals';
import colors from 'styles/colors.constants';

import styles from './DealsTag.module.scss';

/**
 * This currently only works for a zone deal carousel, if we decide to add
 * carousels that require a different deal type modal, we should refactor this.
 *
 * @deprecated legacy deals pattern, refactor or find something else to use...
 */
const DealsTag = ({ listing, fullEvent, showModal, deal }) => {
  const onClick = useCallback(() => {
    showModal(MODALS.ZONE_DEAL_INFO, {
      listing,
      fullEvent,
    });
  }, [showModal, listing, fullEvent]);

  return (
    <div className={styles['info-tag']}>
      <button
        aria-label={`See ${deal.title} details`}
        type="button"
        onClick={onClick}
        className={styles['info-button']}
      >
        <InfoSmallIcon fill={colors.white} />
      </button>
    </div>
  );
};

DealsTag.propTypes = {
  listing: PropTypes.instanceOf(Listing),
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  showModal: PropTypes.func.isRequired,
  deal: PropTypes.instanceOf(Deal).isRequired,
};

export default connect(null, { showModal: showModalDispatch })(DealsTag);
