import React from 'react';
import classNames from 'classnames';

import CheckmarkIndicator from 'components/CheckmarkIndicator/CheckmarkIndicator';

import { OptionalDictionary } from '../../../pages/CheckoutV3/Dictionaries';
import { useRadio } from '../Radio.context';

import styles from './RadioCard.module.scss';

interface RadioCardProps<T> {
  classNameList?: OptionalDictionary<
    | 'checkmark'
    | 'component'
    | 'content'
    | 'expanded-content-drawer'
    | 'expanded-content-overflow'
    | 'expanded-content'
    | 'header'
    | 'icon'
    | 'input'
    | 'label'
    | 'subtitle'
    | 'title-block'
    | 'title'
  >;
  expandedContent?: React.ReactNode;
  icon?: React.ReactNode;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  type?: 'checkbox' | 'radio';
  value: T;
  withCheckmark?: boolean;
}

function RadioCard<T>({
  classNameList,
  expandedContent,
  icon,
  subtitle,
  title,
  type = 'radio',
  value,
  withCheckmark = true,
}: RadioCardProps<T>) {
  const context = useRadio<T>();

  const checked = context.value === value;

  const handleChange = () => {
    context.onChange(value);
  };

  return (
    <div
      className={classNames(
        styles['radio-card'],
        classNameList?.component,
        checked
          ? classNameList?.component
            ? `${classNameList.component}-checked`
            : ''
          : ''
      )}
      data-testid={`${context.name}/${value}`}
    >
      <label className={classNames(styles['header'], classNameList?.header)}>
        <input
          checked={checked}
          className={classNames(styles['input-sr-only'], classNameList?.input)}
          name={context.name}
          onChange={handleChange}
          type={type}
          value={typeof value === 'string' ? value : JSON.stringify(value)}
        />
        <div className={classNames(styles.content, classNameList?.content)}>
          {icon && (
            <div className={classNames(styles.icon, classNameList?.icon)}>
              {icon}
            </div>
          )}
          <div
            className={classNames(
              styles['title-block'],
              classNameList?.['title-block']
            )}
          >
            <span className={classNames(styles.title, classNameList?.title)}>
              {title}
            </span>
            {subtitle && (
              <span
                className={classNames(styles.subtitle, classNameList?.subtitle)}
              >
                {subtitle}
              </span>
            )}
          </div>
        </div>
        {withCheckmark && (
          <div
            className={classNames(styles.checkmark, classNameList?.checkmark)}
          >
            <CheckmarkIndicator isSelected={checked} />
          </div>
        )}
      </label>
      {expandedContent && (
        <div
          data-testid={`${context.name}/${value}/expanded-content`}
          className={classNames(
            styles['expanded-content-drawer'],
            classNameList?.['expanded-content-drawer'],
            {
              [styles['is-expanded']]: checked,
            }
          )}
        >
          <div
            className={classNames(
              styles['expanded-content-overflow'],
              classNameList?.['expanded-content-overflow']
            )}
          >
            <div
              className={classNames(
                styles['expanded-content'],
                classNameList?.['expanded-content']
              )}
            >
              {expandedContent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RadioCard;
