import type { IpGeolocation } from 'types';

export const DEFAULT_IP_GEOLOCATION: IpGeolocation = {
  country: 'US',
  region: 'CA',
  city: 'San Francisco',
  zip: '94103',
  ll: [37.7758, -122.4128],
};

// acceptable values for account_type in /v1/external_account/{account_type}
export const EXTERNAL_ACCOUNT_TYPES = {
  MLB_TDC: 'MLB_TDC',
  MLB_TM: 'MLB_TM',
} as const;
