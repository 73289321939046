import React from 'react';
import { useOutletContext } from 'react-router-dom';

import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';

import OrderDetailContents from './OrderDetailContents';

function OrderDetails({
  showMap = true,
  showSeatView = true,
}: {
  showMap?: boolean;
  showSeatView?: boolean;
}) {
  const { listing, event } = useOutletContext<{
    listing: Listing;
    event: FullEvent;
  }>();

  return (
    <OrderDetailContents
      event={event}
      listing={listing}
      showMap={showMap}
      showSeatView={showSeatView}
    />
  );
}

export default OrderDetails;
