import * as React from 'react';

export default function Price({
  fill = 'currentColor',
  height = 16,
  width = 16,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          clipRule="evenodd"
          fillRule="evenodd"
          d="M8 1C4.13407 1 1 4.13408 1 8C1 11.8659 4.13407 15 8 15C11.8659 15 15 11.8659 15 8C15 4.13408 11.8659 1 8 1ZM8.4082 12.043V12.7251H7.65625V12.0537C5.9751 11.957 4.95996 11.1084 4.95459 9.79785H6.49072C6.52832 10.3296 6.98486 10.7271 7.65625 10.7969V8.79883L7.09229 8.66455C5.80322 8.36377 5.11035 7.62256 5.11035 6.5C5.11035 5.17871 6.05566 4.33008 7.65625 4.20117V3.4707H8.4082V4.20117C9.90137 4.34082 10.8682 5.21631 10.8896 6.45703H9.39111C9.375 5.96826 8.97217 5.56543 8.4082 5.48486V7.39697L8.98291 7.51514C10.3633 7.81055 11.04 8.5249 11.04 9.68506C11.04 11.0547 10.0894 11.9033 8.4082 12.043ZM8.4082 10.8022C9.10645 10.7593 9.51465 10.4102 9.51465 9.86768C9.51465 9.37354 9.16016 9.07275 8.4082 8.93311V10.8022ZM7.65625 5.47949C7.07617 5.51172 6.66797 5.87158 6.66797 6.34961C6.66797 6.80078 7.00635 7.1123 7.65625 7.24121V5.47949Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
