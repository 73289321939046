import React from 'react';
import Switch from 'ui/Switch/Switch';

import { useAppDispatch, useAppSelector } from 'store';
import { setIsListMapHarmonyEnabled } from 'store/modules/listingsV3/actions';
import { selectIsListMapHarmonyEnabled } from 'store/modules/listingsV3/selectors';

import styles from './ListMapHarmonyControl.module.scss';

interface ListMapHarmonyControlProps {
  handleListMapHarmonyToggleTracking: (isEnabled: boolean) => void;
}

function ListMapHarmonyControl({
  handleListMapHarmonyToggleTracking,
}: ListMapHarmonyControlProps) {
  const dispatch = useAppDispatch();
  const isListMapHarmonyEnabled = useAppSelector(selectIsListMapHarmonyEnabled);

  const handleToggle = () => {
    handleListMapHarmonyToggleTracking(!isListMapHarmonyEnabled);
    dispatch(setIsListMapHarmonyEnabled(!isListMapHarmonyEnabled));
  };

  return (
    <div className={styles.container}>
      <span className={styles.text}>Update as I move the map</span>
      <Switch checked={isListMapHarmonyEnabled} onChange={handleToggle} />
    </div>
  );
}

export default ListMapHarmonyControl;
