import React from 'react';
import classNames from 'classnames';

import { OptionalDictionary } from '../../../CheckoutV3/Dictionaries';

import styles from './CheckoutCard.module.scss';

interface Props {
  body: React.ReactNode;
  classNameList?: OptionalDictionary<
    | 'body'
    | 'component'
    | 'description'
    | 'header-button'
    | 'header'
    | 'title-row'
    | 'title'
  >;
  description?: React.ReactNode;
  headerButton?: React.ReactNode;
  noPadding?: boolean;
  title: string | React.ReactNode;
}

export default function CheckoutCard({
  body,
  classNameList,
  description,
  headerButton,
  noPadding = false,
  title,
}: Props) {
  return (
    <div
      className={classNames(styles['checkout-card'], classNameList?.component)}
      tabIndex={-1}
    >
      <div className={classNames(styles.header, classNameList?.header)}>
        <div
          className={classNames(
            styles['title-row'],
            classNameList?.['title-row']
          )}
        >
          <h2 className={classNames(styles.title, classNameList?.title)}>
            {title}
          </h2>
          {headerButton && (
            <div
              className={classNames(
                styles['header-button'],
                classNameList?.['header-button']
              )}
            >
              {headerButton}
            </div>
          )}
        </div>
        {description && (
          <span
            className={classNames(
              styles.description,
              classNameList?.description
            )}
          >
            {description}
          </span>
        )}
      </div>
      <div
        className={classNames(styles.body, classNameList?.body, {
          [styles['no-padding']]: noPadding,
        })}
      >
        {body}
      </div>
    </div>
  );
}
