import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';

import styles from './Sections.module.scss';

const CATEGORY_TO_CATGRP = {
  sports: (metro) => `/sport/${metro.id}`,
  music: (metro) => getMetroPerformersPathByMetro(metro, 'concerts'),
  shows: (metro) => getMetroPerformersPathByMetro(metro, 'shows'),
};

function Sections({ category, allMetros }) {
  return (
    <div className={styles['sections-container']}>
      <div className={styles['section-title']}>{category} </div>
      <div className={styles['section-cities']}>
        {allMetros.map((metro) => {
          return (
            <Link
              key={`${category}_${metro.city}`}
              className={styles['city-button']}
              to={CATEGORY_TO_CATGRP[category.toLowerCase()](metro)}
            >
              {`${category} in ${metro.city}`}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

Sections.propTypes = {
  category: PropTypes.string.isRequired,
  allMetros: PropTypes.array.isRequired,
};

export default Sections;
