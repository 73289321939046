/* breakpoints */
export const mqSize = {
  checkoutV3Md: 835,
  checkoutV3Sm: 481,
  lg: 1080,
  md: 768,
  sm: 576,
  xl: 1300,
};

export const device = {
  down: {
    checkoutV3Md: `(max-width: ${mqSize.checkoutV3Md - 1}px)`,
    checkoutV3Sm: `(max-width: ${mqSize.checkoutV3Sm - 1}px)`,
    lg: `(max-width: ${mqSize.lg - 1}px)`,
    md: `(max-width: ${mqSize.md - 1}px)`,
    sm: `(max-width: ${mqSize.sm - 1}px)`,
    xl: `(max-width: ${mqSize.xl - 1}px)`,
  },
  up: {
    checkoutV3Md: `(min-width: ${mqSize.checkoutV3Md}px)`,
    checkoutV3Sm: `(min-width: ${mqSize.checkoutV3Sm}px)`,
    lg: `(min-width: ${mqSize.lg}px)`,
    md: `(min-width: ${mqSize.md}px)`,
    sm: `(min-width: ${mqSize.sm}px)`,
    xl: `(min-width: ${mqSize.xl}px)`,
  },
};
