import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  mapListingTrackingData,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import MLBCollectionAlert from 'components/MLBCollectionAlert/MLBCollectionAlert';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import { ACTIONS as T_ACTIONS } from 'components/Trackable/TrackingHelper';
import UserNameEmailForm from 'components/UserNameEmailForm/UserNameEmailForm';
import { Listing } from 'models';
import { PURCHASE_CTA_TEXTS } from 'pages/Listing/constants';
import {
  selectUserDetails,
  selectUserExternalAccount,
} from 'store/modules/user/user.selectors';

import { INFO_COLLECTION_TEXT } from '../constants';

@connect((state, { isMLBInfoCollection }) => {
  const user = selectUserDetails(state);
  const externalAccount = selectUserExternalAccount(state);

  const userInfo = isMLBInfoCollection
    ? {
        firstName: externalAccount?.first_name,
        lastName: externalAccount?.last_name,
        email: externalAccount?.email,
      }
    : {
        firstName: user.tmmobile_first_name,
        lastName: user.tmmobile_last_name,
        email: user.tmmobile_email ?? user.email,
      };

  return {
    ...userInfo,
  };
})
@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.TRANSFER_TICKETS(),
  payload: mapListingTrackingData(listing),
}))
@TrackPageView(({ transactionId, listing }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.TRANSFER_TICKETS(transactionId),
  payload: mapListingTrackingData(listing),
}))
export default class PurchaseUserTransfer extends Component {
  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isMLBInfoCollection: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    listing: PropTypes.instanceOf(Listing).isRequired, // Used in tracking
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(form) {
    await this.props.onSubmit({
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim(),
      email: form.email.trim(),
    });
  }

  render() {
    const clickTracker = new ClickTracker().interaction(
      Click.INTERACTIONS.CONTINUE_BUTTON()
    );

    const { firstName, lastName, email, isMLBInfoCollection } = this.props;

    const infoCollectionText = isMLBInfoCollection
      ? INFO_COLLECTION_TEXT.MLB
      : INFO_COLLECTION_TEXT.DEFAULT;
    return (
      <div>
        <InfoHeader headerContent="Securing Your Tickets" isDarkTheme bold />
        <ThemedCard
          title={infoCollectionText.title}
          desc={infoCollectionText.desc}
        >
          {isMLBInfoCollection && <MLBCollectionAlert />}
          <UserNameEmailForm
            handleUserNameEmailFormSubmit={this.handleSubmit}
            ctaText={PURCHASE_CTA_TEXTS.DEFAULT}
            mpActions={{ action: T_ACTIONS.SUBMIT_TMM_TICKET }}
            clickTracker={clickTracker}
            firstName={firstName}
            lastName={lastName}
            email={email}
            isMLBInfoCollection={isMLBInfoCollection}
          />
        </ThemedCard>
      </div>
    );
  }
}
