import React from 'react';
import classNames from 'classnames';

import { Disclosure as Model } from '../../../../models';
import Tooltip from '../../../../ui/Tooltip/Tooltip';

import styles from './Attributes.module.scss';

export default function Disclosure({
  className,
  disclosure,
}: {
  className?: string;
  disclosure: Model;
}) {
  let content;

  if (!disclosure.description) {
    content = disclosure.title;
  } else {
    content = (
      <Tooltip
        className={classNames(className, styles.tooltip)}
        description={disclosure.description}
        tag="span"
        title={disclosure.title}
      />
    );
  }

  return (
    <p
      className={classNames(
        styles.disclosure,
        disclosure.description ? 'tooltip' : '',
        className
      )}
    >
      {content}
    </p>
  );
}
