import React from 'react';

import styles from './TicketsBlock.module.scss';

type Props = {
  seatRange?: string;
  quantity: number;
  isGeneralAdmission: boolean;
};

export default function TicketsBlock({
  seatRange,
  quantity,
  isGeneralAdmission,
}: Props) {
  return (
    <div className={styles.tickets}>
      <span className={styles['ticket-quantity']}>
        {quantity} {isGeneralAdmission ? 'Ticket' : 'Seat'}
        {quantity === 1 ? '' : 's Together'}
      </span>
      {seatRange && <span className={styles['seat-range']}>{seatRange}</span>}
    </div>
  );
}
