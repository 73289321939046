import React, { useState } from 'react';

import DisclosuresModal from 'components/Modals/DisclosuresModal/components/DisclosuresModal';
import { Disclosure as DisclosureType } from 'models';

import Disclosure from './Disclosure';

type Props = {
  disclosures?: string[];
  allDisclosures?: { [key: string]: DisclosureType | undefined };
};
export default function Disclosures({
  allDisclosures = {},
  disclosures = [],
}: Props) {
  const [selectedDisclosureKey, setSelectedDisclosureKey] = useState<
    string | undefined
  >(undefined);

  if (disclosures.length === 0) {
    return null;
  }

  const selectedDisclosure = selectedDisclosureKey
    ? allDisclosures[selectedDisclosureKey]
    : undefined;

  return (
    <>
      {disclosures.map((key) => {
        const disclosure = allDisclosures[key];
        if (
          !disclosure ||
          disclosure.bestDisplayType !== 'default' ||
          disclosure.isHighlightedDisplay
        ) {
          return null;
        }

        const { title, description, slug } = disclosure;

        return (
          <Disclosure
            key={key}
            title={title || key.replace('_', ' ')}
            onClick={
              description && slug
                ? () => setSelectedDisclosureKey(key)
                : undefined
            }
          />
        );
      })}
      {selectedDisclosure && (
        <DisclosuresModal
          show
          disclosure={selectedDisclosure}
          onHide={() => setSelectedDisclosureKey(undefined)}
        />
      )}
    </>
  );
}
