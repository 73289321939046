import { DeliveryFormat } from 'components/DeliveryFormat/delivery.format.constants';
import config from 'config/config';
import { ENV } from 'config/environments';
import { DELIVERY_FORMATS } from 'store/datatypes/DELIVERY_FORMATS';

import { MLB_BALLPARK_PERFORMER_SLUGS } from './constants';

export const getTicketURL = (
  id: string,
  performerSlug: string,
  deliveryFormat: DeliveryFormat,
  displayPDF: boolean
) => {
  const urlEnvMap = {
    [ENV.PRODUCTION]: 'shared-tickets',
    [ENV.PRE_PRODUCTION]: 'shared-tickets',
    [ENV.TESTING]: 'shared-tickets-testing',
    [ENV.QA]: 'shared-tickets-staging',
    [ENV.STAGING]: 'shared-tickets-staging',
    [ENV.DEVELOPMENT]: 'shared-tickets-staging',
  };

  const sharedTicketsSubDomain =
    urlEnvMap[config.env] || urlEnvMap[ENV.PRODUCTION];

  if (
    (deliveryFormat && deliveryFormat === DELIVERY_FORMATS.PRINT_ONLY) ||
    displayPDF
  ) {
    return `https://${sharedTicketsSubDomain}.gametime.co/ticket/original/${id}`;
  }

  return MLB_BALLPARK_PERFORMER_SLUGS.includes(performerSlug)
    ? `https://${sharedTicketsSubDomain}.gametime.co/tickets?ticket_id=${id}`
    : `https://${sharedTicketsSubDomain}.gametime.co/ticket/${id}`;
};
