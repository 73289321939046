import React from 'react';
import PropTypes from 'prop-types';

import Emoji from 'components/Emoji';
import VerifiedIcon from 'icons/VerifiedIcon';
import { isSuperBowl } from 'utils/superBowl';

import styles from './ListingPromoBanner.module.scss';

interface ListingPromoBannerProps {
  headline: string;
  subtitle: string;
  emoji: string;
  eventId: string;
}

function ListingPromoBanner({
  headline,
  subtitle,
  emoji,
  eventId,
}: ListingPromoBannerProps) {
  if (!headline) return null;

  return (
    <div className={styles['promo-banner']}>
      {isSuperBowl(eventId) ? (
        <VerifiedIcon width="30" height="30" />
      ) : (
        <Emoji emoji={emoji} isLarge />
      )}
      <div className={styles['promo-text']}>
        <h4 className={styles['promo-headline']}>{headline}</h4>
        <h5 className={styles['promo-subtitle']}>{subtitle}</h5>
      </div>
    </div>
  );
}

ListingPromoBanner.propTypes = {
  headline: PropTypes.string,
  subtitle: PropTypes.string,
  emoji: PropTypes.string,
  eventId: PropTypes.string,
};

export default ListingPromoBanner;
