export const SEAT_MAP_IMAGE_WIDTH = 2560;
export const SEAT_MAP_IMAGE_HEIGHT = 1936;

/* Config settings MapV1 */
export const SEATMAP_HEIGHT_RATIO =
  SEAT_MAP_IMAGE_HEIGHT / SEAT_MAP_IMAGE_WIDTH;
export const SEATMAP_WIDTH_RATIO = SEAT_MAP_IMAGE_WIDTH / SEAT_MAP_IMAGE_HEIGHT;

export const MAP_EVENT_TYPES = {
  drag: 'drag',
  wheel: 'wheel',
  touch: 'touch',
  click: 'click',
  areaZoom: 'area_zoom',
  reset: 'reset',
  doubleClick: 'double_click',
};

export const MAP_ZOOM_TYPES = {
  zoomIn: 'zoom_in',
  zoomOut: 'zoom_out',
};

export const MAP_SETUP = {
  minScale: 1,
  maxScale: 5,
  smooth: false,
  wheel: {
    step: 0.1,
  },
  velocityAnimation: {
    sensitivity: 1,
  },
};
