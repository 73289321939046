import React from 'react';

import SeatDescription from 'components/SeatDescription/SeatDescription';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import { Disclosure, FullEvent } from 'models';
import Listing, { ZONE_TICKET_DISCLOSURE } from 'models/Listing';
import { getDetailsDate } from 'pages/Listing/utils';
import { isSuperBowl } from 'utils/superBowl';

import Disclosures from './Disclosures';

import styles from './DetailsBlock.module.scss';

type Props = {
  fullEvent: FullEvent;
  listing: Listing;
  showSeats: boolean;
  allDisclosures: { [key: string]: Disclosure };
  handleSuperbowlModal: () => void;
};

export default function DetailsBlock({
  fullEvent,
  listing,
  showSeats,
  allDisclosures,
  handleSuperbowlModal,
}: Props) {
  const date = getDetailsDate(fullEvent);
  const time = fullEvent.getTime();

  return (
    <div className={styles['event-details']}>
      <div className={styles['datetime-seating']}>
        <span className={styles.datetime}>
          {`${date.replace(' ', ' ⋅ ')} ⋅ ${time}`}
        </span>
        <div className={styles.seating}>
          <span className={styles['seat-detail']}>
            {`Sec ${listing.section}`}
          </span>
          <span className={styles['seat-detail']}>{`Row ${listing.row}`}</span>
          {showSeats && (
            <SeatDescription
              seatCount={listing.quantity}
              isGeneralAdmission={listing.isGeneralAdmission}
            />
          )}
        </div>
      </div>
      {isSuperBowl(fullEvent.id) ? (
        listing.disclosures.includes(ZONE_TICKET_DISCLOSURE) && (
          <ZoneTag variant="text-info-icon" onClick={handleSuperbowlModal} />
        )
      ) : (
        <Disclosures
          allDisclosures={allDisclosures}
          disclosures={listing.disclosures}
        />
      )}
    </div>
  );
}
