import React from 'react';

import Link from 'components/Link/Link';
import Section from 'components/Section/Section';
import { FullEvent } from 'models';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';
import { Metro } from 'types';
import { DATE_FORMAT_M_D_YY } from 'utils/datetime';
import { formatPriceWithComma } from 'utils/number';

import styles from './SeoEventsTable.module.scss';

interface SeoEventsTableProps {
  performerEvents: FullEvent[];
  category: string;
  performerName: string;
  metros: Metro[];
}

const SeoEventsTable = ({
  performerEvents,
  category,
  performerName,
  metros,
}: SeoEventsTableProps) => {
  const categoryCopy = category === 'music' ? 'Tour Dates' : 'Schedule';
  const title = `${performerName} ${categoryCopy} and Ticket Prices`;

  const getMetroPath = (metroId: string) => {
    const metro = metros.find((metro) => metro.id === metroId);
    return getMetroPerformersPathByMetro(metro);
  };

  return (
    <Section headline={title}>
      <div className={styles['table-container']}>
        <table className={styles['seo-table']}>
          <thead>
            <tr>
              <th className={styles['seo-table-header']}>Date</th>
              <th className={styles['seo-table-header']}>Time</th>
              <th className={styles['seo-table-header']}>City</th>
              <th className={styles['seo-table-header']}>Venue</th>
              <th className={styles['seo-table-header']}>Lowest Price</th>
            </tr>
          </thead>
          <tbody>
            {performerEvents.map((fullEvent) => {
              const price = formatPriceWithComma(
                fullEvent.event.minPrice.prefee / 100
              );
              const metroPath = getMetroPath(fullEvent.venue.metro);

              return (
                <tr className={styles['table-body-row']} key={fullEvent.id}>
                  <td className={styles['seo-table-date']}>
                    {fullEvent.getDate(DATE_FORMAT_M_D_YY)}
                  </td>
                  <td className={styles['seo-table-time']}>
                    {fullEvent.getTime()}
                  </td>
                  <td className={styles['seo-table-city']}>
                    {metroPath !== '' ? (
                      <Link className={styles['seo-table-link']} to={metroPath}>
                        {fullEvent.venue.city}
                      </Link>
                    ) : (
                      fullEvent.venue.city
                    )}
                  </td>
                  <td className={styles['seo-table-venue']}>
                    <Link
                      className={styles['seo-table-link']}
                      to={fullEvent.venue.getPath()}
                    >
                      {fullEvent.venue.name}
                    </Link>
                  </td>
                  <td className={styles['seo-table-price']}>
                    {fullEvent.currencyPrefix}
                    {price}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Section>
  );
};

export default SeoEventsTable;
