import { LISTING } from './listing.actionTypes';

export const updateMapHarmony = (updating = true) => {
  return {
    type: LISTING.UPDATE_MAP_HARMONY,
    updating,
  };
};

export const setHoveredListingId = (hoveredListingId = '') => {
  return {
    type: LISTING.SET_HOVERED_LISTING_ID,
    hoveredListingId,
  };
};
