import React from 'react';

import AmericanExpressLogo from 'assets/american-express-logo.svg';
import ApplePayLogo from 'assets/apple-pay-logo.svg';
import DiscoverLogo from 'assets/discover-logo.svg';
import GooglePayLogo from 'assets/google-pay-logo.svg';
import MastercardLogo from 'assets/mastercard-logo.svg';
import VisaLogo from 'assets/visa-logo.svg';
import { isObjectEmpty } from 'utils/objects';

export const CARD_TYPES = {
  VISA: 'Visa',
  DISCOVER: 'Discover',
  MASTERCARD: 'MasterCard',
  AMEX: 'American Express',
  APPLEPAY: 'APLP',
  PAYPAL: 'PYPL',
  VENMO: 'VNMO',
  GOOGLEPAY: 'ANDP',
};

export const NON_TOKEN_PAYMENT_TYPES = [
  CARD_TYPES.APPLEPAY,
  CARD_TYPES.GOOGLEPAY,
  CARD_TYPES.PAYPAL,
  CARD_TYPES.VENMO,
];

export const PAYMENT_TYPES_CONFIG = {
  [CARD_TYPES.VISA]: {
    displayName: 'Visa',
    supported: true,
    icon: <img alt="Visa" src={VisaLogo} />,
  },
  [CARD_TYPES.MASTERCARD]: {
    displayName: 'Mastercard',
    supported: true,
    icon: <img alt="Mastercard" src={MastercardLogo} />,
  },
  [CARD_TYPES.DISCOVER]: {
    displayName: 'Discover',
    supported: true,
    icon: <img alt="Discover" src={DiscoverLogo} />,
  },
  [CARD_TYPES.AMEX]: {
    displayName: 'AMEX',
    supported: true,
    icon: <img alt="American Express" src={AmericanExpressLogo} />,
  },
  [CARD_TYPES.APPLEPAY]: {
    displayName: 'Apple Pay',
    supported: true,
    icon: <img alt="Apple Pay" src={ApplePayLogo} />,
  },
  [CARD_TYPES.PAYPAL]: {
    displayName: 'Paypal',
    supported: false,
  },
  [CARD_TYPES.VENMO]: {
    displayName: 'Venmo',
    supported: false,
  },
  [CARD_TYPES.GOOGLEPAY]: {
    displayName: 'Google Pay',
    supported: true,
    icon: (
      <img
        height="18px"
        style={{ borderRadius: 9 }}
        alt="Google Pay"
        src={GooglePayLogo}
      />
    ),
  },
};

export const isNonTokenBasedPaymentType = (defaultCardToken) =>
  NON_TOKEN_PAYMENT_TYPES.includes(defaultCardToken);

export const isSupportedPaymentType = (defaultCardToken) => {
  const config = PAYMENT_TYPES_CONFIG[defaultCardToken];

  if (isObjectEmpty(config)) {
    return false;
  }

  return config.supported;
};
