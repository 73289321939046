import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import Field from './Field';

import styles from '../Form.module.scss';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  icon?: React.ReactElement;
  id: string;
  label: string;
  type: Exclude<
    React.HTMLInputTypeAttribute,
    'button' | 'checkbox' | 'file' | 'hidden' | 'radio' | 'submit'
  >;
};

const Input = React.forwardRef<HTMLInputElement, Props>(
  (props, ref): React.JSX.Element => {
    const [field, meta] = useField({ name: props.name ?? props.id });

    return (
      <Field
        className={props.className ?? ''}
        error={meta.touched ? meta.error ?? '' : ''}
        icon={props.icon}
        id={props.id}
        label={props.label}
      >
        <input
          {...field}
          {...props}
          aria-errormessage={
            meta.touched && meta.error ? `error-${props.id}` : undefined
          }
          aria-invalid={!!(meta.touched && meta.error)}
          className={classNames(styles['form-control'], styles.input, {
            [styles['is-empty']]: !field.value,
          })}
          placeholder={props.placeholder || undefined}
          ref={ref}
        />
      </Field>
    );
  }
);

export default Input;
