import React from 'react';
import { useParams } from 'react-router-dom';

import BreadCrumbs from 'components/Breadcrumbs/Breadcrumbs';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import NotFoundWrapper from 'pages/NotFound/NotFound';
import { DataLoader } from 'routes/routes.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { CATEGORY_URLS } from 'store/modules/categories/category.helpers';
import { fetchPerformersData } from 'store/modules/performers/actions';
import {
  selectFetchPerformersData,
  selectIsPerformersLoading,
} from 'store/modules/performers/selectors';

import SitemapURLsList from '../../components/SitemapURLsList';
import { generateMatchupsURLsList } from '../helpers';

import styles from '../../Sitemap.module.scss';

const PERFORMERS_PER_PAGE = 50;

const PerformersMatchupsByCategorySitemap = () => {
  const { category = '' } = useParams();

  if (!(category in CATEGORY_URLS)) {
    return <NotFoundWrapper />;
  }

  const dispatch = useAppDispatch();
  const isPerformersLoading = useAppSelector(selectIsPerformersLoading);
  const performerData = useAppSelector(selectFetchPerformersData);
  const matchupsUrls = generateMatchupsURLsList(performerData.performers);
  const title = `${CATEGORY_URLS[category].title} Matchups`;
  const breadcrumbs = [
    { name: 'Gametime', url: '/' },
    { name: 'Sitemap', url: '/html-sitemap' },
    { name: 'Matchups', url: '/html-sitemap/matchups' },
  ];

  const handleClickPaginationButton = () => {
    dispatch(
      fetchPerformersData({
        page: performerData.page + 1,
        per_page: PERFORMERS_PER_PAGE,
        category,
      })
    );
  };

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={<MinimalHeader search showAccount showHamburger showCategories />}
      footer={<GTFooter />}
    >
      <HeadTitle title={title} />

      <div className={styles.container}>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <h1 className={styles.title}>{title}</h1>
        {matchupsUrls.length === 0 ? (
          <p>No matchups found for this category</p>
        ) : (
          <SitemapURLsList urlItems={matchupsUrls} />
        )}
        {performerData.more && (
          <PaginationButton
            onClick={handleClickPaginationButton}
            loading={isPerformersLoading}
            showSeparator
          />
        )}
      </div>
    </ContainerTemplate>
  );
};

const loader: DataLoader =
  ({ store }) =>
  async ({ params: { category = '' } }) => {
    if (!(category in CATEGORY_URLS)) {
      return null;
    }

    await store.dispatch(
      fetchPerformersData({ category, per_page: PERFORMERS_PER_PAGE, page: 1 })
    );
    return null;
  };

PerformersMatchupsByCategorySitemap.loader = loader;

export default PerformersMatchupsByCategorySitemap;
