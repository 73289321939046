import React from 'react';

import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import ConfirmationCart from 'pages/Order/components/ConfirmationCart/ConfirmationCart';

import { Purchase } from '../../types';

import {
  Attributes,
  Cart as CheckoutCart,
  Deal,
  ListingImages,
} from './components';

import styles from './OrderDetails.module.scss';

type Props = Readonly<{
  event: FullEvent;
  isCheckout?: boolean;
  listing: Listing;
  purchase?: Purchase;
  shouldHideDelivery?: boolean;
  showCart?: boolean;
  showMap?: boolean;
  showSeatView?: boolean;
  hideFaceValue?: boolean;
}>;

function Cart({
  event,
  listing,
  purchase,
}: Readonly<{
  event?: FullEvent;
  listing?: Listing;
  purchase?: Purchase;
}>): React.JSX.Element | null {
  if (!event) {
    return null;
  }

  if (purchase) {
    return (
      <ConfirmationCart
        currency={event.currencyPrefix}
        completePurchase={purchase}
      />
    );
  }

  if (listing) {
    return (
      <CheckoutCart
        currency={event.currencyPrefix}
        listing={listing}
        event={event}
      />
    );
  }

  return null;
}

function OrderDetailContents({
  event,
  listing,
  purchase,
  shouldHideDelivery = false,
  showCart = true,
  showMap = true,
  showSeatView = true,
  hideFaceValue = false,
}: Props): React.JSX.Element {
  return (
    <article className={styles.component}>
      <header className={styles.header}>
        <h2 className={styles.title}>{event.getName('@')}</h2>

        {!event.event.date_tbd && (
          <time className={styles.date} dateTime={event.event.datetimeUtc}>
            <span>{event.datetimeFriendly}</span>
            <span>{event.getTime()}</span>
          </time>
        )}

        <p className={styles.venue}>
          <span>{event.venue.name}</span>
          <span>{`${event.venue.city}, ${event.venue.state}`}</span>
        </p>
      </header>

      <h3 className={styles['summary-title']}>Order Summary</h3>

      <ListingImages
        className={styles.image}
        event={event}
        listing={listing}
        showMap={showMap}
        showSeatView={showSeatView}
      />

      {showCart && <Cart event={event} listing={listing} purchase={purchase} />}

      <Attributes
        event={event}
        listing={listing}
        shouldHideDelivery={shouldHideDelivery}
        hideFaceValue={hideFaceValue}
      />

      <Deal listing={listing} />
    </article>
  );
}

export default OrderDetailContents;
