import React from 'react';

import placeHolderImage from 'assets/image_placeholder.svg';
import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { Position } from 'types';

import styles from './ListingImages.module.scss';

/*
 * This is the standard pixel size for map images, which pinpoint
 * positioning from the backend is built from.
 */
const rawImageDimensions = { x: 2560, y: 1936 };

/*
 * This converts the given pinpoint pixel positions to percentages
 * from the original map image sizing.
 */
function convertMarkerPositionToPercentages(position: Position): Position {
  const calculated: Position = {
    x: 0,
    y: 0,
  };

  Object.keys(calculated).forEach((key): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    calculated[key] = (position[key] / rawImageDimensions[key]) * 100;
  });

  return calculated;
}

/*
 * Converts pinpoint positions to CSS with an offset to account
 * for the dimensions of the pinpoint marker.
 */
function getMarkerPositionStyles(position: Position): {
  left: string;
  top: string;
} {
  const styles = {
    left: '',
    top: '',
  };

  [
    ['left', 'x'],
    ['top', 'y'],
  ].forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ([to, from]: ['left' | 'top', 'x' | 'y']): void => {
      styles[to] = `calc(${position[from]}% - (var(--sizing-icon-12) / 2))`;
    }
  );

  return styles;
}

export default function SeatMap({
  event,
  listing,
}: {
  event: FullEvent;
  listing: Listing;
}): React.JSX.Element {
  const width = 450;
  const markerPosition = getMarkerPositionStyles(
    convertMarkerPositionToPercentages(listing.position)
  );

  if (!event.event.mapUrl) {
    return (
      <figure className={styles['image-container']}>
        <img className={styles.image} src={placeHolderImage} />
      </figure>
    );
  }

  return (
    <figure className={styles['image-container']}>
      <div className={styles['map-view']}>
        <span className={styles.pin} style={markerPosition} />
        <img
          alt={`${event.venueName} seating chart`}
          loading="lazy"
          src={`${event.event.mapUrl}?width=${width}`}
          width={width}
        />
      </div>
    </figure>
  );
}
