import { combineReducers } from '@reduxjs/toolkit';

import collections from './Collections/filterReducer';
import fullEvents from './FullEvents/filterReducer';
import performers from './Performers/filterReducer';
import performersInContext from './PerformersInContext/filterReducer';

export default combineReducers({
  collections,
  fullEvents,
  performersInContext,
  performers,
});
