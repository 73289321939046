import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import Checkbox from 'ui/Checkbox';

import {
  PAYLOAD,
  SOURCE_PAGE_TYPE_NAMES,
  useAnalyticsContext,
} from 'analytics';
import { EmailSubscriptionEvent } from 'analytics/events/EmailSubscription';
import { Listing } from 'models';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchMLBTeamLinksContent } from 'store/modules/content/mlb/actions';
import { selectMLBTeamLink } from 'store/modules/content/mlb/selectors';
import { MLB_LINK, MLB_PRIVACY_POLICY_LINK } from 'utils/mlb';

import styles from './MLBOptIn.module.scss';

interface MLBOptInProps {
  performerName: string;
  isOptedIn: boolean;
  onOptInChange: () => void;
}

const MLBOptIn = ({
  performerName,
  isOptedIn,
  onOptInChange,
}: MLBOptInProps) => {
  const analytics = useAnalyticsContext();
  const dispatch = useAppDispatch();
  const mlbTeamLink = useAppSelector((state) =>
    selectMLBTeamLink(state, performerName)
  );
  const { listing } = useOutletContext<{
    listing: Listing;
  }>();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchMLBTeamLinksContent());
    };

    fetchData();
  }, [fetchMLBTeamLinksContent]);

  const handleOptIn = () => {
    analytics.track(
      new EmailSubscriptionEvent({
        page_type: SOURCE_PAGE_TYPE_NAMES.CHECKOUT,
        event_id: listing.eventId,
        listing_id: listing.id,
        // @ts-expect-error analytics event payload is not typed
        payload: {
          [PAYLOAD.SUBSCRIPTION]: !isOptedIn ? 'subscribe' : 'unsubscribe',
        },
      })
    );

    onOptInChange();
  };

  const performerLink = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={mlbTeamLink}
      data-testid="mlb-team-link"
    >
      {performerName}
    </a>
  );

  const mlbLink = (
    <a target="_blank" rel="noopener noreferrer" href={MLB_LINK}>
      MLB
    </a>
  );

  const privacyPolicyLink = (
    <a target="_blank" rel="noopener noreferrer" href={MLB_PRIVACY_POLICY_LINK}>
      MLB's Privacy Policy
    </a>
  );

  return (
    <div className={styles['mlb-opt-in']}>
      <Checkbox isSelected={isOptedIn} onClick={handleOptIn} />
      <div>
        <p className={styles['opt-in-text']}>
          Receive special offers via email from the {performerLink} and{' '}
          {mlbLink}. You can unsubscribe at any time.
        </p>
        <p className={styles['opt-in-disclaimer']}>
          By purchasing a ticket, you direct us to share your information with
          the {performerLink} and {mlbLink}. The {performerName} and MLB will
          use your information (a) to fulfill and communicate with you regarding
          your purchase, (b) for internal analysis, and (c) as otherwise as set
          forth in {privacyPolicyLink}.
        </p>
      </div>
    </div>
  );
};

export default MLBOptIn;
