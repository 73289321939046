import React from 'react';
import { HostedFields } from 'braintree-web';
import { HostedFieldsHostedFieldsFieldName } from 'braintree-web/hosted-fields';

import {
  BraintreeTokenize,
  useBraintreeHostedFields,
} from '../../../../../helpers/Braintree';
import { CreditCardBackFillIcon } from '../../../../../icons';
import { PAYMENT_TYPES_CONFIG } from '../../../../../store/datatypes/PAYMENT_TYPES';
import { CreditCard } from '../../../../../types';
import Form from '../Form';
import { PaymentInput, paymentOptions, paymentStyles } from '../Inputs';

import styles from './VerifyCvvForm.module.scss';

function Icon({ card }: { card: CreditCard }): React.JSX.Element {
  const fallback = (
    <CreditCardBackFillIcon fill="var(--form-color-label, var(--color-text-default-tertiary, currentColor))" />
  );

  if (card.card_type === undefined) {
    return fallback;
  }

  const config = PAYMENT_TYPES_CONFIG[card.card_type];

  if (config.icon === undefined) {
    return fallback;
  }

  return config.icon;
}

function HelpText({ card }: { card: CreditCard }): React.JSX.Element {
  const tag = card.card_type?.toLowerCase() === 'mastercard' ? '' : ` card`;

  return (
    <>
      {`It looks like you're on a new device. Please confirm the CVV of your `}
      <span className={styles.bold}>{card.card_type}</span>
      {`${tag} ending in `}
      <span className={styles.bold}>{card.last_four}</span>
      {` to complete your purchase.`}
    </>
  );
}

export default function VerifyCvvForm({
  card,
  onSubmit: handleSubmit,
}: {
  card: CreditCard;
  onSubmit: (
    tokenize: BraintreeTokenize,
    setFieldError: (
      field: HostedFieldsHostedFieldsFieldName,
      message: string
    ) => void
  ) => void;
}): React.JSX.Element {
  const ref = React.useRef<HostedFields | null>(null);
  const id = 'verify-cvv-form-input';

  const {
    createHostedFields,
    fieldEmpty,
    fieldErrors,
    hostedFields,
    setFieldError,
  } = useBraintreeHostedFields();

  React.useEffect(() => {
    if (hostedFields) {
      return;
    }

    createHostedFields(
      {
        cvv: {
          selector: `#${id}`,
          placeholder: paymentOptions.cvv?.placeholder ?? '1234',
        },
      },
      paymentStyles
    ).then((instance: void | HostedFields): void => {
      if (!instance) {
        return;
      }

      ref.current = instance;
    });
  }, [createHostedFields]);

  return (
    <Form
      id="verify-cvv-form"
      initialValues={{}}
      submitText="Continue"
      onSubmit={() => {
        if (hostedFields?.validate()) {
          handleSubmit(hostedFields.tokenize, setFieldError);
        }
      }}
      validate={() => {
        hostedFields?.validate();
      }}
      theme="light"
    >
      <p id={`${id}-help-text`}>
        <HelpText card={card} />
      </p>

      <PaymentInput
        aria-describedby={`${id}-help-text`}
        empty={fieldEmpty.cvv}
        error={fieldErrors.cvv}
        icon={
          <>
            <Icon card={card} />
          </>
        }
        id={id}
        label="CVV"
        onLabelClick={(): void => {
          ref.current?.focus('cvv');
        }}
      />
    </Form>
  );
}
