import React, { useEffect, useState } from 'react';

import BreadCrumbs from 'components/Breadcrumbs/Breadcrumbs';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { DataLoader } from 'routes/routes.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchPerformersData } from 'store/modules/performers/actions';
import {
  selectFetchPerformersData,
  selectIsPerformersLoading,
  selectPerformersParams,
} from 'store/modules/performers/selectors';

import SitemapURLsList from '../components/SitemapURLsList';
import { SitemapURLItem } from '../components/SitemapURLsList/SitemapURLsList';
import { SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS } from '../constants';

import { generateMatchupsURLsList } from './helpers';

import styles from '../Sitemap.module.scss';

const LIMIT_PERFORMERS_PER_CATEGORY = 50;
const LAST_SPORT_CATEGORY = SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS.at(-1);

const PerformersMatchupsSitemap = () => {
  const dispatch = useAppDispatch();
  const performersData = useAppSelector(selectFetchPerformersData);
  const performersParams = useAppSelector(selectPerformersParams);
  const isPerformersLoading = useAppSelector(selectIsPerformersLoading);
  const [isGeneratingMatchups, setIsGeneratingMatchups] = useState(false);
  const [matchupsSitemapURLs, setMatchupsSitemapURLs] = useState<
    SitemapURLItem[]
  >([]);
  const isLastCategory = performersParams.category === LAST_SPORT_CATEGORY;

  const title = 'Matchups Sitemap';
  const breadcrumbs = [
    { name: 'Gametime', url: '/' },
    { name: 'Sitemap', url: '/html-sitemap' },
  ];

  useEffect(() => {
    if (!isPerformersLoading) {
      if (performersData.performers.length > 0) {
        setIsGeneratingMatchups(true);

        // Every time we get a new page of performers, we generate the matchups URLs, we need to have all the possible matchups
        const matchupsURLs = generateMatchupsURLsList(
          performersData.performers
        );

        // Filter the current matchups to avoid duplicates
        const currentMatchups = matchupsSitemapURLs.filter(
          (url) => url.category !== performersParams.category
        );

        setMatchupsSitemapURLs([...currentMatchups, ...matchupsURLs]);
        setIsGeneratingMatchups(false);
      }
    }
  }, [
    performersData.performers,
    isPerformersLoading,
    performersParams.category,
  ]);

  const handleClickPaginationButton = () => {
    const { category = '' } = performersParams;
    const currentIndex =
      SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS.indexOf(category);
    const nextCategory =
      SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS[currentIndex + 1];

    dispatch(
      fetchPerformersData({
        page: 1,
        per_page: LIMIT_PERFORMERS_PER_CATEGORY,
        category: nextCategory,
      })
    );
  };

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={<MinimalHeader search showAccount showHamburger showCategories />}
      footer={<GTFooter />}
    >
      <HeadTitle title={title} />

      <div className={styles.container}>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <h1 className={styles.title}>{title}</h1>
        <SitemapURLsList urlItems={matchupsSitemapURLs} />
        {(performersData.more || !isLastCategory) && (
          <PaginationButton
            onClick={handleClickPaginationButton}
            loading={isPerformersLoading || isGeneratingMatchups}
            showSeparator
          />
        )}
      </div>
    </ContainerTemplate>
  );
};

const loader: DataLoader =
  ({ store }) =>
  async () => {
    await store.dispatch(
      fetchPerformersData({
        page: 1,
        per_page: LIMIT_PERFORMERS_PER_CATEGORY,
        category: SPORT_CATEGORIES_FOR_SITEMAPS_WITH_MATCHUPS[0],
      })
    );

    return null;
  };

PerformersMatchupsSitemap.loader = loader;

export default PerformersMatchupsSitemap;
