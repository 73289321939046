import React, { useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTransformContext } from 'react-zoom-pan-pinch';
import PropTypes from 'prop-types';

import { MAP_EVENT_TYPES } from '../../../SeatMap.constants';

const Observer = ({ listing, children }) => {
  const ref = useRef();
  const context = useTransformContext();
  const { ref: inViewRef, inView } = useInView({
    trackVisibility: true,
    delay: 1000,
    initialInView: true,
    fallbackInView: true,
  });

  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  const isReset =
    context.transformState?.interaction?.eventType === MAP_EVENT_TYPES.reset;
  listing.isVisibleInMap = isReset ? true : inView;
  return <div ref={setRefs}>{children}</div>;
};

const SeatMapPinHead = ({ listing, children, observeMapHarmony = false }) => {
  if (observeMapHarmony && typeof window !== 'undefined') {
    return <Observer listing={listing}>{children}</Observer>;
  }

  return <>{children}</>;
};

SeatMapPinHead.propTypes = {
  listing: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  observeMapHarmony: PropTypes.bool,
};

Observer.propTypes = {
  ...SeatMapPinHead.propTypes,
};

export default SeatMapPinHead;
