import React from 'react';

import { FullEvent, Listing } from '../../../../models';
import { useSeatDescription } from '../../../SeatDescription/useSeatDescription';

import styles from './ListingImages.module.scss';

export default function SeatView({
  listing,
  event,
}: {
  listing: Listing;
  event: FullEvent;
}) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore -- Unpacking `listing` passes more properties than method expects */
  const seatDescription = useSeatDescription({
    ...listing,
    seatCount: listing.quantity,
  });

  return (
    <figure className={styles['image-container']}>
      <img
        alt={`View of ${event.venueName} from ${seatDescription.title}`}
        src={listing.viewUrl}
        className={styles['seat-view']}
      />
    </figure>
  );
}
