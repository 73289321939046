import { Performer } from 'models';

import { SitemapURLItem } from '../components/SitemapURLsList/SitemapURLsList';

export const generateMatchupsURLsList = (
  performers: Performer[]
): SitemapURLItem[] => {
  return performers.flatMap((primaryPerformer, primaryPerformerIndex) =>
    performers.slice(primaryPerformerIndex + 1).map((secondaryPerformer) => ({
      path: primaryPerformer.getMatchupPath(secondaryPerformer),
      name: `${primaryPerformer.name} vs. ${secondaryPerformer.name} Matchup Tickets`,
      category: primaryPerformer.category,
    }))
  );
};
