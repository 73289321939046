import { createSelector } from 'reselect';

import { Performer } from 'models';
import { RootState } from 'store/store';

const selectPerformersState = (state: RootState) => state.performers;

export const selectFetchPerformersData = createSelector(
  selectPerformersState,
  (performersState) => {
    const fetchPerformersData = performersState.fetchPerformersData;
    return {
      ...fetchPerformersData,
      performers: fetchPerformersData.performers.map(
        (performer) => new Performer(performer)
      ),
    };
  }
);

export const selectIsPerformersLoading = (state: RootState) =>
  selectPerformersState(state).status === 'loading';

export const selectPerformersParams = (state: RootState) =>
  selectPerformersState(state).params;

export const selectPerformersGroupedByCategory = createSelector(
  [
    (state: RootState) => selectPerformersState(state).performersByCategory,
    (_: RootState, categories: string[]) => categories,
  ],
  (performersByCategory, categories) => {
    return categories.map((category) => {
      const performers =
        category in performersByCategory ? performersByCategory[category] : [];

      return {
        category,
        performers: performers.map((performer) => new Performer(performer)),
      };
    });
  }
);
