import React from 'react';

import styles from './SubmitButton.module.scss';

interface SubmitButtonProps {
  /** The text that the button should display, i.e. "Submit" or "Confirm" */
  children: React.ReactNode;
  /** Whether or not the button is disabled; used primarily to disable on first load of the page */
  isDisabled?: boolean;
  /** ID of the form the button submits; used when the button is not a child of the form component */
  form?: string;
}

/**
 * A button used to submit forms. Since this is meant to be used only in forms,
 * there is no need to pass in an onClick handler since all form logic should
 * live in the onSubmit of the form itself.
 */
const SubmitButton = ({
  children,
  isDisabled = false,
  form = '',
}: SubmitButtonProps) => {
  // Prevent the form from submitting when the user clicks the button while loading
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isDisabled) {
      e.preventDefault();
    }
  };

  return (
    <button
      type="submit"
      className={styles['submit-button']}
      disabled={isDisabled}
      data-testid="button"
      onClick={(e) => handleClick(e)}
      form={form}
    >
      {children}
    </button>
  );
};

export default SubmitButton;
