import { createSelector, createStructuredSelector } from 'reselect';

import {
  selectIsCaliforniaVenueAllInPriceEnabled,
  selectIsColoradoVenueAllInPriceEnabled,
  selectIsMarylandVenueAllInPriceEnabled,
} from 'featureFlags';
import { CANADIAN_PROVINCES } from 'pages/Event/Event.constants';
import { RootState } from 'store';

export const updatingMapHarmonySelector = (state: RootState) => {
  return !!state.data.listings.isUpdatingMapHarmony;
};

export const hoveredListingIdSelector = (state: RootState) => {
  return state.data.listings.hoveredListingId as string | undefined;
};

const selectAllInPriceByState = createStructuredSelector<{
  [state: string]: (s: RootState) => boolean;
}>({
  CA: selectIsCaliforniaVenueAllInPriceEnabled,
  MD: selectIsMarylandVenueAllInPriceEnabled,
  CO: selectIsColoradoVenueAllInPriceEnabled,
  MN: () => true,
});

/**
 * This will soon be replaced by compliance data fetched from backend
 */
export const selectIsVenueAllInPrice = createSelector(
  [selectAllInPriceByState, (_: RootState, venueState: string) => venueState],
  (allInPriceByState, venueState) => {
    return (
      CANADIAN_PROVINCES.includes(venueState) || !!allInPriceByState[venueState]
    );
  }
);
