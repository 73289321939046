import React from 'react';

import Checkout from '../pages/Checkout/Checkout';
import CheckoutV3 from '../pages/CheckoutV3/CheckoutV3';
import { DataLoader } from '../routes/routes.utils';
import { useVariantService } from '../services/variants';
import { fetchDisclosures } from '../store/modules/resources/resource.actions';

function CheckoutExperiment() {
  const variantService = useVariantService();

  switch (variantService.getExperiment('checkout_v3').theme) {
    case 'dark':
    case 'light':
      return <CheckoutV3 />;

    default:
      return <Checkout />;
  }
}

const loader: DataLoader =
  ({ store: { dispatch } }) =>
  async () => {
    await dispatch(fetchDisclosures());

    return null;
  };

CheckoutExperiment.loader = loader;

export default CheckoutExperiment;
