import * as React from 'react';

export default function Perks({
  fill = 'currentColor',
  height = 17,
  width = 16,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 17"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5.81217 6.65479L7.89551 2.46729L10.1663 6.65479H5.81217ZM7.49967 15.5923L0.437174 7.65479H7.49967V15.5923ZM8.49967 15.5923V7.65479H15.5622L8.49967 15.5923ZM11.2913 6.65479L8.87467 2.15479H12.9824C13.5966 2.15479 14.1488 2.52926 14.376 3.09992L15.7913 6.65479H11.2913ZM0.208008 6.65479L1.62337 3.09992C1.85058 2.52926 2.40274 2.15479 3.01697 2.15479H6.93717L4.68717 6.65479H0.208008Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
