import React from 'react';
import {
  createRoutesFromElements,
  LoaderFunction,
  Route,
} from 'react-router-dom';

import { selectIsHomepageRedesignV1Experiment } from 'experiments';
import Sitemap from 'pages/Sitemap';
import MetrosSitemap from 'pages/Sitemap/MetrosSitemap';
import PerformersMatchupsSitemap from 'pages/Sitemap/PerformersMatchupsSitemap';
import PerformersMatchupsByCategorySitemap from 'pages/Sitemap/PerformersMatchupsSitemap/PerformersMatchupsByCategorySitemap';
import PerformersSitemap from 'pages/Sitemap/PerformersSitemap';
import PerformersByCategorySitemap from 'pages/Sitemap/PerformersSitemap/PerformersByCategorySitemap';
import VenuesSitemap from 'pages/Sitemap/VenuesSitemap';

import CheckoutLogin from '../components/Login/CheckoutLogin/CheckoutLogin';
import CheckoutExperiment from '../experiments/CheckoutExperiment';
import App from '../pages/App/App';
import Blog from '../pages/Blog/Blog';
import Post from '../pages/Blog/Post';
import CategoryPerformers from '../pages/CategoryPerformers/CategoryPerformers';
import Collection from '../pages/Collection/Collection';
import ContentPage from '../pages/ContentPageCMS/ContentPage';
import Event from '../pages/Event/Event';
import GenrePerformers from '../pages/GenrePerformers/GenrePerformers';
import Healthcheck from '../pages/Healthcheck/Healthcheck';
import Home from '../pages/Home/Home';
import HomeV1 from '../pages/HomeV1/HomeV1';
import Listing from '../pages/Listing/Listing';
import Login from '../pages/Login/Login';
import MainMetro from '../pages/MainMetro/MainMetro';
import MetroPerformers from '../pages/MetroPerformers/MetroPerformers';
import MyAccount from '../pages/MyAccount/MyAccount';
import MyTickets from '../pages/MyTickets/MyTickets';
import NotFound from '../pages/NotFound/NotFound';
import Order from '../pages/Order/Order';
import Performer from '../pages/Performer/Performer';
import Picks from '../pages/Picks/Picks';
import Purchase from '../pages/Purchase/Purchase';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Search from '../pages/Search/Search';
import SportsByMetro from '../pages/SportsByMetro/SportsByMetro';
import About from '../pages/StaticPages/About/About';
import Careers from '../pages/StaticPages/Careers/Careers';
import PromoCodes from '../pages/StaticPages/PromoCodes/PromoCodes';
import WhyGametime from '../pages/StaticPages/WhyGametime/WhyGametime';
import Unavailable from '../pages/Unavailable/Unavailable';
import Venue from '../pages/Venue/Venue';

import ProtectedCheckoutRoute from './ProtectedCheckoutRoute';
import {
  DataLoader,
  DataLoaderContext,
  dataLoaderWrapper,
} from './routes.utils';

function getRoutes(context: DataLoaderContext) {
  const state = context.store.getState();

  const isHomepageRedesignV1Experiment =
    selectIsHomepageRedesignV1Experiment(state);

  const HomeComponent = isHomepageRedesignV1Experiment ? HomeV1 : Home;
  const MetroPerformersComponent = isHomepageRedesignV1Experiment
    ? HomeV1
    : MetroPerformers;
  /**
   * Components that are written in vanilla JavaScript will not have their
   * loader inferred by TypeScript. This function will check for the existence
   * of a loader. If it exists, it will inject the loader context into the
   * loader, wrap it in a try/catch block, and return the curried loader
   * function.
   */
  function getTypedRouteLoader<T>(component: T): LoaderFunction | undefined {
    const loader = (component as T & { loader?: DataLoader }).loader;

    if (!loader) {
      return undefined;
    }

    return dataLoaderWrapper(loader(context));
  }

  return createRoutesFromElements(
    <Route>
      <Route path="/healthcheck" element={<Healthcheck />} />
      <Route path="/unavailable" element={<Unavailable />} />
      <Route path="/" element={<App />}>
        <Route
          index
          element={<HomeComponent />}
          loader={getTypedRouteLoader(HomeComponent)}
        />
        <Route
          path="search"
          element={<Search />}
          loader={getTypedRouteLoader(Search)}
        />
        <Route
          path="login"
          element={<Login />}
          loader={getTypedRouteLoader(Login)}
        />
        <Route
          path="order/:transactionId"
          element={<Order />}
          loader={getTypedRouteLoader(Order)}
        />
        <Route
          path="blog/:slug/*"
          element={<Post />}
          loader={getTypedRouteLoader(Post)}
        />
        <Route
          path="blog"
          element={<Blog />}
          loader={getTypedRouteLoader(Blog)}
        />
        <Route
          path="my-tickets"
          element={<MyTickets />}
          loader={getTypedRouteLoader(MyTickets)}
        />
        <Route
          path="my-account"
          element={<MyAccount />}
          loader={getTypedRouteLoader(MyAccount)}
        />
        <Route
          path="c/:categoryId"
          element={<CategoryPerformers />}
          loader={getTypedRouteLoader(CategoryPerformers)}
        />
        <Route
          path="/concert-tickets/genre/:genre"
          element={<GenrePerformers />}
          loader={getTypedRouteLoader(GenrePerformers)}
        />
        <Route
          path=":collectionDescriptorSlug?/:metro/collection/:slug/:view?"
          element={<Collection />}
          loader={getTypedRouteLoader(Collection)}
        />
        <Route
          path=":performerTicketSlug?/performers/:slug/:matchupSlug?/:parkingSlug?"
          element={<Performer />}
          loader={getTypedRouteLoader(Performer)}
        />
        <Route
          path=":venueTicketSlug?/venues/:slug"
          element={<Venue />}
          loader={getTypedRouteLoader(Venue)}
        />
        <Route
          path=":metroTicketSlug?/metros/:metroId/:categoryGroupId?"
          element={<MetroPerformersComponent />}
          loader={getTypedRouteLoader(MetroPerformersComponent)}
        />
        <Route
          path=":categoryPrefix?/:eventName?/:eventDetails?/events/:eventId"
          element={<Event />}
          loader={getTypedRouteLoader(Event)}
          shouldRevalidate={({
            defaultShouldRevalidate,
            currentParams,
            nextParams,
          }) => {
            // re-run loader when navigating to/from listing page or between
            // listings
            return (
              defaultShouldRevalidate ||
              currentParams.listingId !== nextParams.listingId
            );
          }}
        >
          <Route path="listings/:listingId">
            <Route
              index
              element={<Listing />}
              loader={getTypedRouteLoader(Listing)}
            />
            <Route
              element={<ProtectedCheckoutRoute />}
              loader={getTypedRouteLoader(ProtectedCheckoutRoute)}
            >
              <Route path="buy" element={<Purchase />} />
              <Route
                path="checkout"
                element={<CheckoutExperiment />}
                loader={getTypedRouteLoader(CheckoutExperiment)}
              />
            </Route>
            <Route
              path="checkout/login"
              element={<CheckoutLogin />}
              loader={getTypedRouteLoader(CheckoutLogin)}
            />
          </Route>
        </Route>
        <Route
          path="events-near-me"
          element={<MainMetro />}
          loader={getTypedRouteLoader(MainMetro)}
        />
        <Route
          path="/sport/:metroId"
          element={<SportsByMetro />}
          loader={getTypedRouteLoader(SportsByMetro)}
        />
        <Route
          path="/about"
          element={<About />}
          loader={getTypedRouteLoader(About)}
        />
        <Route
          path="/careers"
          element={<Careers />}
          loader={getTypedRouteLoader(Careers)}
        />
        <Route
          path="/why-gametime"
          element={<WhyGametime />}
          loader={getTypedRouteLoader(WhyGametime)}
        />
        <Route
          path="/promo-codes"
          element={<PromoCodes />}
          loader={getTypedRouteLoader(PromoCodes)}
        />
        <Route
          path="/html-sitemap"
          element={<Sitemap />}
          loader={getTypedRouteLoader(Sitemap)}
        />
        <Route
          path="/html-sitemap/performers"
          element={<PerformersSitemap />}
          loader={getTypedRouteLoader(PerformersSitemap)}
        />
        <Route
          path="/html-sitemap/performers/:category"
          element={<PerformersByCategorySitemap />}
          loader={getTypedRouteLoader(PerformersByCategorySitemap)}
        />
        <Route
          path="/html-sitemap/matchups"
          element={<PerformersMatchupsSitemap />}
          loader={getTypedRouteLoader(PerformersMatchupsSitemap)}
        />
        <Route
          path="/html-sitemap/matchups/:category"
          element={<PerformersMatchupsByCategorySitemap />}
          loader={getTypedRouteLoader(PerformersMatchupsByCategorySitemap)}
        />
        <Route
          path="/html-sitemap/venues"
          element={<VenuesSitemap />}
          loader={getTypedRouteLoader(VenuesSitemap)}
        />
        <Route
          path="/html-sitemap/metros"
          element={<MetrosSitemap />}
          loader={getTypedRouteLoader(MetrosSitemap)}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/picks"
          element={<Picks />}
          loader={getTypedRouteLoader(Picks)}
        />

        {/* Path must be the same as the slug from ButterCMS API */}
        <Route
          path="/terms-of-service"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />
        <Route
          path="/terms-sms"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />
        <Route
          path="/privacy-policy"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />
        <Route
          path="/share-codes"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />
        <Route
          path="/price-guarantee"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />
        <Route
          path="/gametime-guarantee"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />
        <Route
          path="/cookies-policy"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />
        <Route
          path="/press"
          element={<ContentPage />}
          loader={getTypedRouteLoader(ContentPage)}
        />

        <Route
          path="*"
          element={<NotFound />}
          loader={getTypedRouteLoader(NotFound)}
        />
      </Route>
    </Route>
  );
}

export default getRoutes;
