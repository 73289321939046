import React from 'react';
import { FormikHelpers } from 'formik';

import { UserModel } from '../../../../../types';
import { FormSubmitError } from '../../../../../utils/errors';
import { UpdateQuoteParams } from '../../../../Checkout/components/InsuranceOptions/InsuranceOptions.utils';
import Form from '../Form';
import Input from '../Inputs/Input';
import * as validators from '../validators';

import styles from './InsuranceForm.module.scss';

export default function InsuranceForm({
  user,
  postalCode,
  onSubmit: handleSubmit,
}: {
  user: Pick<UserModel, `${'first' | 'last'}_name`>;
  postalCode: string;
  onSubmit: (
    values: UpdateQuoteParams,
    helpers?: FormikHelpers<UpdateQuoteParams>
  ) => Promise<void>;
}): React.JSX.Element {
  return (
    <Form
      className={styles.form}
      id="insurance-form"
      initialValues={{
        firstName: user.first_name ?? '',
        lastName: user.last_name ?? '',
        zipCode: postalCode,
      }}
      submitText="Confirm"
      onSubmit={async (
        values: UpdateQuoteParams,
        helpers: FormikHelpers<UpdateQuoteParams>
      ) => {
        try {
          await handleSubmit(values, helpers);
        } catch (error) {
          if (!(error instanceof FormSubmitError)) {
            helpers.setErrors({
              zipCode: 'An unexpected error occurred, please try again.',
            });

            return;
          }

          if ('zipCode' in error.errors) {
            /*
             * API error states, "user location was not found", which is not
             * a very intuitive message to an end user. We'll update it below
             * to give a more descriptive and actionable error message.
             */
            error.errors.zipCode = 'Please enter a valid postal code.';
          }

          helpers.setErrors(error.errors);
        }
      }}
      theme="light"
      validationSchema={validators.schema({
        firstName: validators.string({ label: 'first name', required: true }),
        lastName: validators.string({ label: 'last name', required: true }),
        zipCode: validators.postalCode({
          country: 'us',
          label: 'zip code',
          required: true,
        }),
      })}
    >
      <Input
        className={styles['field-group']}
        id="insurance-first-name"
        label="First Name"
        name="firstName"
        required
        type="text"
      />

      <Input
        className={styles['field-group']}
        id="insurance-last-name"
        label="Last Name"
        name="lastName"
        required
        type="text"
      />

      <Input
        autoComplete="postal-code"
        className={styles['field-group']}
        id="insurance-postal-code"
        inputMode="text"
        label="Postal Code"
        maxLength={10}
        name="zipCode"
        required
        type="text"
      />
    </Form>
  );
}
