import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import withNavigationProps from 'hoc/withNavigationProps';
import withRouter from 'hoc/withRouter';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import { LoginEvent, SessionStart, withAnalyticsContext } from 'analytics';
import ConnectedAppSpinner from 'components/ConnectedAppSpinner/ConnectedAppSpinner';
import ConnectedAppSubmittingOverlay from 'components/ConnectedAppSubmittingOverlay/ConnectedAppSubmittingOverlay';
import HeadCanonicalPath from 'components/Head/CanonicalPath';
import { OG_TYPES } from 'components/Head/constants';
import HeadOGType from 'components/Head/HeadOGType';
import HeadRobots from 'components/Head/Robots';
import ScrollToTop from 'components/ScrollToTop';
import Spinner from 'components/Spinner';
import config from 'config/config';
import { domain as productionDomain } from 'config/production.config';
import { isAllowedAccess } from 'helpers/IpHelper';
import { appConfigDomainSelector } from 'store/modules/app/app.selectors';
import {
  selectUserDetails,
  selectUserIPGeolocation,
} from 'store/modules/user/user.selectors';
import { incrementSiteVisitCount } from 'store/modules/userPreference/userPreference';

import {
  VariantContextPropType,
  withVariantContext,
} from '../../services/variants';

import 'rc-tooltip/assets/bootstrap.css';
import styles from './App.module.scss';

/**
 * Sets user identity for Sentry error reports if user exists, otherwise
 * sets user identity to null.
 */
function setSentryUser(user) {
  if (user && Object.keys(user).length > 0) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
  } else {
    Sentry.setUser(null);
  }
}
@withAppContext
@connect(
  (state, props) => {
    const { pathname } = props.location;

    return {
      pathname,
      isProductionDomain: productionDomain === appConfigDomainSelector(state),
      isAllowedAccess: isAllowedAccess(selectUserIPGeolocation(state)),
      user: selectUserDetails(state),
    };
  },
  {
    incrementSiteVisitCount,
  }
)
@withAnalyticsContext
class App extends Component {
  static propTypes = {
    incrementSiteVisitCount: PropTypes.func.isRequired,
    pathname: PropTypes.string,
    isProductionDomain: PropTypes.bool,
    isAllowedAccess: PropTypes.bool,
    user: PropTypes.object,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
        isIOS: PropTypes.bool,
        isAndroid: PropTypes.bool,
      }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    variantContext: VariantContextPropType,
    showRouteChangeLoader: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (__CLIENT__) {
      this.props.analyticsContext.track(new SessionStart({}));

      // If user logged in using magic link, track login event
      const { name, attributes } = config.cookiesConfig.MAGIC_LINK_LOGIN;
      const magicLinkLoginResult = Cookies.get(name);
      if (magicLinkLoginResult) {
        this.props.analyticsContext.track(
          new LoginEvent({
            status: magicLinkLoginResult,
            provider: 'magic_link',
          })
        );
        Cookies.remove(name, { path: attributes.path });
      }
    }

    if (!this.props.isAllowedAccess) {
      this.props.router.navigate(
        { pathname: '/unavailable' },
        { replace: true }
      );
      return;
    }

    setSentryUser(this.props.user);

    this.props.incrementSiteVisitCount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      setSentryUser(this.props.user);
    }
  }

  render() {
    const {
      pathname,
      isProductionDomain,
      appContext: {
        state: { isMobile, isIOS, isAndroid },
      },
      showRouteChangeLoader,
    } = this.props;

    const isCheckoutRedesignExperimentLightTheme =
      this.props.variantContext.getExperiment('checkout_v3').theme === 'light';

    const dataType = classNames({
      mobile: isMobile,
      ios: isIOS,
      android: isAndroid,
    });

    return (
      <div
        className={classNames(styles.app, {
          [styles['theme-light']]: isCheckoutRedesignExperimentLightTheme,
        })}
      >
        <ScrollToTop />
        <HeadOGType type={OG_TYPES.WEBSITE} />
        <Helmet
          {...config.app.head}
          htmlAttributes={{ 'data-type': dataType }}
        />
        {isProductionDomain && <HeadCanonicalPath path={pathname} />}
        <HeadRobots
          noindex={!isProductionDomain}
          nofollow={!isProductionDomain}
          noarchive={!isProductionDomain}
        />
        <Outlet />
        {showRouteChangeLoader && <Spinner isFullscreen />}
        <ConnectedAppSpinner />
        <ConnectedAppSubmittingOverlay />
      </div>
    );
  }
}

export default withVariantContext(withRouter(withNavigationProps(App)));
