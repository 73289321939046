import React, { useState } from 'react';
import {
  redirect,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import Button from 'ui/Button/Button';
import CheckoutContainer from 'ui/Layout/CheckoutContainer/CheckoutContainer';

import { Click, useAnalyticsContext, View } from 'analytics';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import OnLoadTracker from 'components/Trackable/OnLoadTracker';
import { ACTIONS as T_ACTIONS } from 'components/Trackable/TrackingHelper';
import { selectIsSMSLoginEnabled } from 'featureFlags';
import { PhoneSendIcon } from 'icons';
import { Listing } from 'models';
import { DataLoader } from 'routes/routes.utils';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { isUserLoggedIn } from 'store/modules/user/user.selectors';
import { URLSearchParamsInit } from 'utils/url';

import { useVariantService } from '../../../services/variants';
import { showNotification } from '../../Notifications/Notifications';
import {
  DISPLAY_KEYS,
  DISPLAY_TEXT,
  DISPLAY_TEXT_V3,
  EmailForm,
  EmailFormV3,
  MagicLinkSuccess,
  SMSVerificationForm,
} from '../components';
import SMSVerificationFormV3 from '../components/SMSVerificationFormV3';
import { LOGIN_STEPS, selectCheckoutMagicLinkParams, useLogin } from '../Login';

import styles from './CheckoutLogin.module.scss';

function getCheckoutPathname(eventId: string, listingId: string) {
  return `/events/${eventId}/listings/${listingId}/checkout`;
}

/**
 * Gets the search params from the current location, and returns a new
 * URLSearchParams object with only the search params that are safe to
 * forward to the checkout page.
 *
 * Sets zoom to max to give the best chance of finding the listing in
 * curation after the redirect.
 */
function getCheckoutSearchParams(source: URLSearchParamsInit) {
  const sourceParams = new URLSearchParams(source);
  const targetParams = new URLSearchParams({ zoom: '10' });

  const algoliaSearchParams = [
    'queryId',
    'resultPosition',
    'searchIndex',
    'searchSessionId',
  ];

  for (const key of algoliaSearchParams) {
    const value = sourceParams.get(key);
    if (value) {
      targetParams.set(key, value);
    }
  }

  return targetParams;
}

const typedSelectIsSMSLoginEnabled = (state: RootState) =>
  selectIsSMSLoginEnabled(state) as boolean;

// TODO: is this still relevant? this is the only component that uses
// OnLoadTracker, which references analyticsDeprecated...
const onLoad = { action: T_ACTIONS.SENT_MAGIC_LINK };
const MagicLinkConfirmation = OnLoadTracker(onLoad)(MagicLinkSuccess);

function CheckoutLogin() {
  const analytics = useAnalyticsContext();
  const variantService = useVariantService();

  const { listing } = useOutletContext<{ listing: Listing }>();

  const navigate = useNavigate();
  const location = useLocation();

  const { eventId, listingId } = useParams();

  const magicLinkParams = useAppSelector((state) =>
    selectCheckoutMagicLinkParams(state, location)
  );
  const isSMSLoginEnabled = useAppSelector(typedSelectIsSMSLoginEnabled);
  const isCheckoutRedesignExperiment = ![undefined, 'control'].includes(
    variantService.getExperiment('checkout_v3').theme
  );

  const handleLoginSuccess = () => {
    if (!eventId || !listingId) {
      // theoretically this should never happen, but there is no way for
      // React Router or TypeScript to guarantee that we are rendering a
      // component in a route context that has specific route parameters
      return navigate('/', { replace: true });
    }
    const searchParams = getCheckoutSearchParams(location.search);
    const redirectPathname = getCheckoutPathname(eventId, listingId);
    navigate(`${redirectPathname}?${searchParams}`, { replace: true });
  };

  const {
    state,
    reset,
    clearErrors,
    handleGenerateMagicLink,
    handleGenerateSMSCode,
    handleSMSCodeLogin,
    handleEmailLogin,
  } = useLogin({
    dispatch: useAppDispatch(),
    magicLinkParams,
    sourcePageTypeCallback: Click.SOURCE_PAGE_TYPES.ADD_EMAIL,
    analytics,
    isSMSLoginEnabled,
    onLoginSuccess: handleLoginSuccess,
  });

  const [displayKey, setDisplayKey] = useState(DISPLAY_KEYS.NEW);
  const [isPasscodeResent, setIsPasscodeResent] = useState(false);

  const displayText = DISPLAY_TEXT[displayKey];
  const displayTextV3 = DISPLAY_TEXT_V3[displayKey];

  if (isCheckoutRedesignExperiment) {
    switch (state.step) {
      case LOGIN_STEPS.MAGIC_LINK_SUCCESS: {
        return (
          <CheckoutContainer hideDetailsOnMobile>
            <div className={styles['magic-link-confirmation']}>
              <MagicLinkConfirmation
                isCheckout
                email={state.email}
                className="left-align"
              />
            </div>
          </CheckoutContainer>
        );
      }
      case LOGIN_STEPS.SMS_CODE: {
        return (
          <CheckoutContainer hideDetailsOnMobile>
            <PhoneSendIcon fill="currentColor" />
            <h2 className={styles['login-title']}>Verify Your Account</h2>
            <p className={styles['login-subtitle']}>
              We sent a confirmation code to{' '}
              <span className={styles.bold}>{`XXX-XXX-${state.phone}`}</span>
              <br className={styles.break} />
              Enter your 6-digit confirmation code:
            </p>
            <SMSVerificationFormV3
              email={state.email}
              handleSMSCodeLogin={handleSMSCodeLogin}
              hasError={state.hasInvalidCodeError}
              isResent={isPasscodeResent}
            />
            <div className={styles['sms-login-options']}>
              <Button
                onClick={() => {
                  handleGenerateSMSCode(state.email);
                  setIsPasscodeResent(true);
                  showNotification({
                    autoClose: 3000,
                    message:
                      'Your one time passcode has been resent to your phone.',
                    title: 'Your passcode has been resent',
                  });
                }}
                buttonClass="text"
              >
                Resend Code
              </Button>
              <div className={styles['vertical-divider']} />
              <Button
                onClick={() => {
                  handleGenerateMagicLink(state.email);
                }}
                buttonClass="text"
              >
                Log In Via Email
              </Button>
            </div>
          </CheckoutContainer>
        );
      }
      case LOGIN_STEPS.USER_EMAIL:
      default: {
        return (
          <CheckoutContainer>
            <PhoneSendIcon fill="currentColor" />
            <h2 className={styles['login-title']}>{displayTextV3.title}</h2>
            <p className={styles['login-subtitle']}>{displayTextV3.desc}</p>
            <EmailFormV3
              handleEmailLogin={handleEmailLogin}
              hasError={state.hasError}
            />
            {displayKey === DISPLAY_KEYS.NEW && (
              <span className={styles['log-in-container']}>
                Have an account?
                <Button
                  onClick={() => setDisplayKey(DISPLAY_KEYS.RETURNING)}
                  buttonClass="text"
                >
                  Log in
                </Button>
              </span>
            )}
            <hr className={styles.divider} />
          </CheckoutContainer>
        );
      }
    }
  }

  switch (state.step) {
    case LOGIN_STEPS.MAGIC_LINK_SUCCESS: {
      return (
        <div className={styles['magic-link-confirmation']}>
          <MagicLinkConfirmation isCheckout email={state.email} />
        </div>
      );
    }
    case LOGIN_STEPS.SMS_CODE: {
      return (
        <>
          <InfoHeader
            headerContent="Sign In"
            isDarkTheme
            bold
            showBack
            onBack={reset}
          />

          <ThemedCard
            title={`${DISPLAY_TEXT.SMS_CODE.title}${state.phone}.`}
            desc={DISPLAY_TEXT.SMS_CODE.desc}
          >
            <SMSVerificationForm
              email={state.email}
              hasInvalidCodeError={state.hasInvalidCodeError}
              isSubmitting={state.isSubmitting}
              clearErrors={clearErrors}
              handleGenerateSMSCode={handleGenerateSMSCode}
              handleSMSCodeLogin={handleSMSCodeLogin}
              handleGenerateMagicLink={handleGenerateMagicLink}
              nextAllowedTokenRequestTime={state.nextAllowedTokenRequestTime}
              listing={listing}
            />
          </ThemedCard>
        </>
      );
    }
    case LOGIN_STEPS.USER_EMAIL:
    default: {
      return (
        <>
          <InfoHeader
            headerContent="Enter Email"
            isDarkTheme
            bold
            showBack
            onBack={() => navigate(-1)}
          />
          <ThemedCard title={displayText.title} desc={displayText.desc}>
            {state.hasError && (
              <div className={styles['error-message']}>
                {DISPLAY_TEXT.ERROR.title}
              </div>
            )}
            <EmailForm
              ctaText="Continue"
              pageTypeCallback={View.PAGE_TYPES.ADD_EMAIL}
              isSubmitting={state.isSubmitting}
              handleEmailLogin={handleEmailLogin}
              listing={listing}
            />
            {displayKey === DISPLAY_KEYS.NEW && (
              <button
                type="button"
                onClick={() => setDisplayKey(DISPLAY_KEYS.RETURNING)}
                className={styles['existing-user-text']}
              >
                I Already Have An Account
              </button>
            )}
          </ThemedCard>
        </>
      );
    }
  }
}

/**
 * If user is already logged in when this route is matched, redirect to the
 * checkout page.
 */
const loader: DataLoader =
  ({ store }) =>
  async ({ params: { eventId, listingId }, request }) => {
    if (isUserLoggedIn(store.getState())) {
      if (!eventId || !listingId) {
        return redirect('/');
      }

      const requestUrl = new URL(request.url);
      const searchParams = getCheckoutSearchParams(requestUrl.search);
      const redirectPathname = getCheckoutPathname(eventId, listingId);
      return redirect(`${redirectPathname}?${searchParams}`);
    } else {
      return null;
    }
  };

CheckoutLogin.loader = loader;

export default CheckoutLogin;
