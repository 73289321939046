import React from 'react';
import classNames from 'classnames';

import { CircleInfoIcon } from '../../icons';

import styles from './Tooltip.module.scss';

/**
 * Note: This is not cryptographic in any way, it's only
 * function is to convert the title to a unique-enough ID
 * to be used in the HTML.
 */
function hash(input: string): string {
  let value = 0;

  for (let index = 0; index < input.length; index++) {
    // eslint-disable-next-line no-bitwise -- bitwise operation
    value = (value << 5) - value + input.charCodeAt(index);
    // eslint-disable-next-line no-bitwise -- bitwise operation
    value |= 0;
  }
  return value.toString(16);
}

type Props<Element extends React.ElementType> = {
  className?: string;
  description: string;
  tag?: Element;
  title: string;
} & React.ComponentPropsWithRef<Element>;

export default function Tooltip<Element extends React.ElementType = 'p'>({
  className = '',
  description,
  tag: Tag = 'p',
  title,
}: Props<Element>): React.JSX.Element {
  const id = `tooltip-${hash(title)}`;

  return (
    <Tag className={(classNames(className), styles.tooltip)}>
      <button className={styles.button} type="button" aria-label="more info">
        <span aria-describedby={id} className={styles.title}>
          {title}
        </span>
        <figure className={styles.icon}>
          <CircleInfoIcon
            aria-hidden
            height="1em"
            fill="currentColor"
            width="1em"
          />
        </figure>
      </button>

      <span className={styles.description} id={id} role="tooltip">
        {description}
      </span>
    </Tag>
  );
}
