const getDescriptionText = (seatCount, row) => {
  const multipleSeats = seatCount > 1;
  if (/GA/i.test(row)) {
    return multipleSeats ? `${seatCount} Tickets` : `${seatCount} Ticket`;
  }
  return multipleSeats ? `${seatCount} Seats Together` : `${seatCount} Ticket`;
};

const getSeatNumber = (ticket = {}, row) => {
  if (/GA/i.test(row)) return `Ticket ${ticket.seat}`;
  return `Seat ${ticket.seat}`;
};

const getTitleText = (sectionGroup, section, row) => {
  switch (sectionGroup) {
    case 'General Admission':
      return sectionGroup;
    case 'Pit':
      return `${sectionGroup}, ${row}`;
    case 'Floor GA':
      return `Floor, ${row}`;
    default:
      return `${sectionGroup} ${section}, Row ${row}`;
  }
};
/**
 * ideally this hook would receive a completePurchase object
 * these updates were made as a bug fix and to get completePurchase to
 * the usage of the hook would take a more refactoring than the scope of this task
 */
export function useSeatDescription({
  seatCount = 0,
  row,
  sectionGroup,
  section,
  ticket,
}) {
  const description = getDescriptionText(seatCount, row);
  const title = getTitleText(sectionGroup, section, row);
  const seat = getSeatNumber(ticket, row);

  return { description, title, seat };
}
