import React from 'react';

import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import { CheckCircleFilledIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './EmailVerificationModal.module.scss';

interface EmailVerificationModalProps {
  handleConfirmation: VoidFunction;
  handleFix: VoidFunction;
  email: string;
}

const EmailVerificationModal = ({
  handleConfirmation,
  handleFix,
  email,
}: EmailVerificationModalProps) => (
  <GTUniversalModal show>
    <div className={styles['modal']}>
      <div className={styles['modal-header']}>
        <CheckCircleFilledIcon fill={colors.black} />
      </div>
      <span className={styles['modal-subtitle']}>
        Is this email address correct?
      </span>
      <div className={styles['modal-text-body']}>
        <span className={styles['modal-text']}>
          Make sure your email address is correct -- your tickets will be
          associated with this address.
        </span>
        <span>Email: {email}</span>
      </div>
      <div className={styles['modal-buttons']}>
        <button className={styles['modal-cancel-button']} onClick={handleFix}>
          Let Me Fix It
        </button>
        <button
          className={styles['modal-submit-button']}
          role="button"
          onClick={handleConfirmation}
        >
          Confirm
        </button>
      </div>
    </div>
  </GTUniversalModal>
);

export default EmailVerificationModal;
