import React from 'react';

import PurchaseHeading from 'pages/PurchaseV3/components/PurchaseHeading';

import LoadingIndicator from './components/LoadingIndicator';

import styles from './PurchaseSecure.module.scss';

function PurchaseSecure() {
  return (
    <section className={styles['purchase-secure']} role="status">
      <LoadingIndicator />
      <PurchaseHeading
        heading={
          <span>
            Hang tight!
            <br />
            We're securing your tickets
          </span>
        }
        subheading="Please don't refresh the page - your order is processing."
      />
    </section>
  );
}

export default PurchaseSecure;
