import React from 'react';
import classNames from 'classnames';

import styles from 'components/OrderDetails/components/Cart/Cart.module.scss';
import LineItem from 'components/OrderDetails/components/Cart/LineItem';
import LineItemContainer from 'components/OrderDetails/components/Cart/LineItemContainer';
import {
  getInsuranceStatus,
  getInsuranceTotalPrice,
  getIsInsuranceOptInStatus,
} from 'pages/Checkout/components/InsuranceOptions/InsuranceOptions.utils';
import { InsuranceInfo, Purchase } from 'types';

import { CartContainer } from '../../../../components/OrderDetails/components/Cart';
import { DiscountIcon } from '../../../../icons';

function centsToDollars(value: number): number {
  return value / 100;
}

function calculateLineItems(purchase: Readonly<Purchase>): Readonly<{
  feePerTicket: number;
  insurance: number;
  pricePerTicket: number;
  promo: number;
  tax: number;
  total: number;
}> {
  const count = purchase.tickets.length;

  const insuranceStatus = getIsInsuranceOptInStatus(
    purchase.insurance_info?.status
  );

  const insurance = insuranceStatus
    ? getInsuranceTotalPrice(purchase.insurance_info as InsuranceInfo)
    : 0;

  return {
    feePerTicket: centsToDollars(
      purchase.charge.fees / purchase.tickets.length
    ),
    insurance,
    pricePerTicket: centsToDollars(
      // prettier-ignore
      (purchase.charge.total_amount - purchase.charge.fees - purchase.charge.sales_tax) / count
    ),
    promo: centsToDollars(
      purchase.charge.total_amount - purchase.charge.discounted_amount
    ),
    tax: centsToDollars(purchase.charge.sales_tax),
    total: centsToDollars(purchase.charge.adjusted_amount) + insurance,
  };
}

export default function ConfirmationCart({
  currency = '$',
  className,
  completePurchase: purchase,
}: {
  currency?: string;
  className?: string;
  completePurchase: Purchase;
}): React.JSX.Element {
  const classes = classNames(className, styles.component);
  const pricing = calculateLineItems(purchase);
  const insuranceStatus = getInsuranceStatus(purchase.insurance_info?.status);

  const showInsuranceTag =
    insuranceStatus?.label === 'pending' ||
    insuranceStatus?.label === 'cancelled';

  return (
    <>
      <CartContainer className={classes} tag="dl">
        <LineItem
          currency={currency}
          quantity={purchase.tickets.length}
          title="Tickets"
          value={pricing.pricePerTicket}
        />

        <LineItem
          currency={currency}
          quantity={purchase.tickets.length}
          title="Fees"
          value={pricing.feePerTicket}
        />

        {pricing.tax > 0 && (
          <LineItem currency={currency} title="Sales Tax" value={pricing.tax} />
        )}

        {pricing.promo > 0 && (
          <LineItemContainer>
            <div className={styles['promo-tag']}>
              <DiscountIcon fill="currentColor" height={16} width={16} />
              <span>{purchase.charge.promo_code}</span>
            </div>

            <span className={styles['promo-savings']}>
              {`-${currency + pricing.promo}`}
            </span>
          </LineItemContainer>
        )}

        <LineItem
          currency={currency}
          title="Total"
          value={pricing.total}
          titleClass="is-bold"
        />
      </CartContainer>

      {pricing.insurance ? (
        <div className={styles['insurance-container']}>
          <LineItem
            currency={currency}
            title="XCover Ticket Protection"
            value={pricing.insurance}
            showInsuranceTag={showInsuranceTag}
            insuranceLabel={insuranceStatus?.label}
            tagClass={styles[insuranceStatus?.label || '']}
            titleClass="title-override"
          />
          {insuranceStatus?.label && (
            <div className={styles['insurance-description']}>
              <span className={styles['description-text']}>
                {insuranceStatus.message}
              </span>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}
