import React from 'react';
import classNames from 'classnames';

import WarningCircleFillIcon from 'icons/WarningCircleFillIcon';
import colors from 'styles/colors.constants';

import { BraintreeAllowedStyles } from '../../helpers/Braintree';

import styles from './BraintreeHostedFieldGroup.module.scss';

interface BraintreeHostedFieldGroupProps {
  fieldId: string;
  label: string;
  hint?: React.ReactNode;
  isFocused?: boolean;
  error?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const hostedFieldStyles = {
  input: styles['braintree-hosted-field-input'],
} as BraintreeAllowedStyles;

export function BraintreeHostedFieldGroup({
  fieldId,
  label,
  hint,
  isFocused,
  error,
  startIcon,
  endIcon,
}: BraintreeHostedFieldGroupProps) {
  return (
    <div className={styles['field-group']}>
      <div className={styles['label-row']}>
        <label className={styles.label} htmlFor={fieldId}>
          {label}
        </label>
        {hint && <span className={styles.hint}>{hint}</span>}
      </div>
      <div
        data-testid="braintree-hosted-field-row"
        className={classNames(styles['field-row'], {
          [styles.focused]: isFocused,
          [styles.invalid]: !!error,
        })}
      >
        {startIcon && <span className={styles['start-icon']}>{startIcon}</span>}
        <div
          className={styles.field}
          id={fieldId}
          data-testid="braintree-hosted-field-root"
        />
        {endIcon && <span className={styles['end-icon']}>{endIcon}</span>}
      </div>
      {error && (
        <div className={styles['error-row']}>
          <WarningCircleFillIcon fill={colors.softRed} width="16" height="16" />
          <span className={styles.error} aria-live="polite">
            {error}
          </span>
        </div>
      )}
    </div>
  );
}
