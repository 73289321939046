import { createAction } from '@reduxjs/toolkit';
import type { AsyncThunk } from 'store';

import {
  getPerformers,
  GetPerformersParams,
} from 'services/performer/performer.service';
import { GetPerformersResponse } from 'types';

export const fetchPerformersRequest = createAction<GetPerformersParams>(
  'Performers/FetchPerformersDataRequest'
);
export const fetchPerformersSuccess = createAction<{
  response: GetPerformersResponse;
}>('Performers/FetchPerformersDataSuccess');

export const fetchPerformersFailure = createAction<unknown>(
  'Performers/FetchPerformersDataFailure'
);

export const fetchPerformersByCategoryRequest =
  createAction<GetPerformersParams>(
    'Performers/fetchPerformersByCategoryRequest'
  );

export const fetchPerformersByCategorySuccess = createAction<{
  response: GetPerformersResponse;
  category: string;
}>('Performers/fetchPerformersByCategorySuccess');

export const fetchPerformersByCategoryFailure = createAction<unknown>(
  'Performers/fetchPerformersByCategoryFailure'
);

export function fetchPerformersData(params: GetPerformersParams): AsyncThunk {
  return async (dispatch, _getState, { mobileApi }) => {
    dispatch(fetchPerformersRequest(params));

    try {
      const response = await getPerformers(mobileApi, params);

      if (!response) {
        throw new Error('null response from GET Performers request');
      }

      dispatch(fetchPerformersSuccess({ response }));
    } catch (error) {
      dispatch(fetchPerformersFailure(error));
    }
  };
}

export function fetchPerformersByCategory(
  params: GetPerformersParams
): AsyncThunk {
  return async (dispatch, _getState, { mobileApi }) => {
    dispatch(fetchPerformersByCategoryRequest(params));

    try {
      if (!params.category) {
        throw new Error('category is required');
      }
      const response = await getPerformers(mobileApi, params);

      if (!response) {
        throw new Error('null response from GET Performers request');
      }

      dispatch(
        fetchPerformersByCategorySuccess({
          response,
          category: params.category,
        })
      );
    } catch (error) {
      dispatch(fetchPerformersByCategoryFailure(error));
    }
  };
}
