import * as React from 'react';

export default function Paypal({
  height = 46,
  width = 12,
  fill,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 46 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>PayPal</title>
      <path
        d="M14.7106 2.39941C14.6175 2.39941 14.5383 2.46704 14.5238 2.55913L13.4455 9.39746C13.4416 9.42243 13.4431 9.44797 13.45 9.47229C13.4569 9.49662 13.469 9.51917 13.4854 9.53839C13.5018 9.5576 13.5222 9.57303 13.5452 9.58361C13.5681 9.59419 13.5931 9.59967 13.6184 9.59967H14.8972C14.9902 9.59967 15.0695 9.53191 15.0841 9.43996L15.4008 7.43183C15.4152 7.33988 15.4945 7.27211 15.5877 7.27211H16.7503C18.1087 7.27211 19.2619 6.28095 19.4725 4.93787C19.6849 3.58344 18.6268 2.40235 17.1271 2.39941H14.7106ZM15.9537 3.92599H16.8839C17.6498 3.92599 17.8992 4.37787 17.8203 4.8776C17.7415 5.37839 17.3541 5.74727 16.6133 5.74727H15.6665L15.9537 3.92599ZM22.0734 4.39084C21.7505 4.39204 21.379 4.4582 20.9618 4.63194C20.005 5.03076 19.5455 5.855 19.3504 6.4559C19.3504 6.4559 18.7293 8.28921 20.1325 9.29641C20.1325 9.29641 21.4339 10.2661 22.8988 9.23667L22.8736 9.39746C22.8697 9.42241 22.8712 9.44792 22.8781 9.47223C22.885 9.49654 22.897 9.51907 22.9134 9.53828C22.9298 9.55749 22.9502 9.57293 22.9731 9.58353C22.996 9.59412 23.021 9.59963 23.0463 9.59967H24.2602C24.3534 9.59967 24.4325 9.53191 24.4471 9.43996L25.1855 4.75704C25.1895 4.73207 25.188 4.70653 25.1811 4.68219C25.1743 4.65784 25.1622 4.63528 25.1458 4.61603C25.1294 4.59679 25.109 4.58133 25.0861 4.57072C25.0631 4.56011 25.0381 4.5546 25.0128 4.55456H23.7989C23.7057 4.55456 23.6265 4.62232 23.612 4.71427L23.5723 4.96634C23.5723 4.96634 23.0423 4.38723 22.0734 4.39084ZM22.1131 5.86997C22.2525 5.86997 22.38 5.88921 22.4944 5.92597C23.0182 6.0941 23.3152 6.5973 23.2292 7.14274C23.1232 7.81434 22.5725 8.30886 21.8661 8.30886C21.7269 8.30886 21.5993 8.28974 21.4848 8.25299C20.9612 8.08485 20.6623 7.58165 20.7482 7.03622C20.8542 6.36461 21.4067 5.86997 22.1131 5.86997Z"
        fill={fill ?? '#003087'}
      />
      <path
        d="M32.1911 2.39941C32.0979 2.39941 32.0188 2.46704 32.0042 2.55913L30.9259 9.39746C30.922 9.42243 30.9236 9.44797 30.9305 9.47229C30.9373 9.49662 30.9494 9.51917 30.9659 9.53839C30.9823 9.5576 31.0027 9.57303 31.0257 9.58361C31.0486 9.59419 31.0736 9.59967 31.0989 9.59967H32.3777C32.4707 9.59967 32.55 9.53191 32.5645 9.43996L32.8813 7.43183C32.8957 7.33988 32.975 7.27211 33.0681 7.27211H34.2308C35.5892 7.27211 36.7422 6.28095 36.9527 4.93787C37.1652 3.58344 36.1072 2.40235 34.6075 2.39941H32.1911ZM33.4342 3.92599H34.3644C35.1302 3.92599 35.3796 4.37787 35.3008 4.8776C35.2219 5.37839 34.8347 5.74727 34.0938 5.74727H33.147L33.4342 3.92599ZM39.5537 4.39084C39.2308 4.39204 38.8593 4.4582 38.4422 4.63194C37.4853 5.03076 37.0258 5.855 36.8307 6.4559C36.8307 6.4559 36.2099 8.28921 37.6131 9.29641C37.6131 9.29641 38.9142 10.2661 40.3793 9.23667L40.3541 9.39746C40.3501 9.42243 40.3517 9.44797 40.3586 9.47229C40.3655 9.49662 40.3775 9.51917 40.394 9.53839C40.4104 9.5576 40.4308 9.57303 40.4538 9.58361C40.4767 9.59419 40.5017 9.59967 40.527 9.59967H41.7408C41.834 9.59967 41.9131 9.53191 41.9277 9.43996L42.6664 4.75704C42.6703 4.73204 42.6688 4.70648 42.662 4.68212C42.6551 4.65776 42.643 4.63518 42.6266 4.61593C42.6101 4.59669 42.5897 4.58123 42.5667 4.57064C42.5438 4.56004 42.5187 4.55456 42.4934 4.55456H41.2795C41.1863 4.55456 41.107 4.62232 41.0926 4.71427L41.0529 4.96634C41.0529 4.96634 40.5226 4.38723 39.5537 4.39084ZM39.5934 5.86997C39.7328 5.86997 39.8603 5.88921 39.9748 5.92597C40.4985 6.0941 40.7955 6.5973 40.7096 7.14274C40.6036 7.81434 40.0528 8.30886 39.3464 8.30886C39.2072 8.30886 39.0797 8.28974 38.9651 8.25299C38.4415 8.08485 38.1426 7.58165 38.2286 7.03622C38.3346 6.36461 38.8871 5.86997 39.5934 5.86997Z"
        fill={fill ?? '#0070E0'}
      />
      <path
        d="M25.7613 4.55482C25.6651 4.55482 25.5966 4.64878 25.626 4.74033L26.9569 8.87032L25.7535 10.8166C25.6951 10.9111 25.763 11.0331 25.8741 11.0331H27.2964C27.3371 11.0331 27.3771 11.0225 27.4125 11.0025C27.4479 10.9825 27.4776 10.9538 27.4986 10.9189L31.2156 4.77C31.2727 4.67551 31.2045 4.55469 31.0942 4.55469H29.672C29.6309 4.55472 29.5905 4.56545 29.5549 4.58581C29.5192 4.60618 29.4894 4.63548 29.4685 4.67083L28.0054 7.141L27.2627 4.68914C27.2385 4.60935 27.1649 4.55469 27.0816 4.55469L25.7613 4.55482Z"
        fill={fill ?? '#003087'}
      />
      <path
        d="M44.0469 2.39941C43.9539 2.39941 43.8746 2.46718 43.86 2.55913L42.7815 9.39719C42.7775 9.42219 42.779 9.44775 42.7859 9.47211C42.7927 9.49647 42.8048 9.51905 42.8213 9.5383C42.8377 9.55755 42.8581 9.573 42.8811 9.58359C42.9041 9.59419 42.9291 9.59967 42.9544 9.59967H44.2332C44.3262 9.59967 44.4055 9.53191 44.42 9.43996L45.4983 2.60163C45.5023 2.57667 45.5007 2.55116 45.4939 2.52686C45.487 2.50255 45.4749 2.48001 45.4585 2.4608C45.4421 2.44159 45.4217 2.42616 45.3988 2.41556C45.3759 2.40496 45.3509 2.39946 45.3257 2.39941H44.0469Z"
        fill={fill ?? '#0070E0'}
      />
      <path
        d="M4.04986 2.39941C3.96748 2.39948 3.88783 2.42892 3.82522 2.48245C3.7626 2.53598 3.72112 2.61008 3.70824 2.69144L3.14062 6.29151C3.16709 6.12337 3.31196 5.99948 3.48224 5.99948H5.14554C6.81954 5.99948 8.23999 4.77829 8.49955 3.12341C8.51886 2.99986 8.52981 2.87514 8.5323 2.75012C8.10688 2.52705 7.60715 2.39941 7.05958 2.39941H4.04986Z"
        fill={fill ?? '#001C64'}
      />
      <path
        d="M8.5311 2.75C8.52861 2.87503 8.51766 2.99974 8.49835 3.12329C8.23879 4.77818 6.81821 5.99936 5.14434 5.99936H3.48104C3.3109 5.99936 3.16589 6.12312 3.13943 6.29139L2.61751 9.59955L2.2906 11.6749C2.28422 11.715 2.28661 11.756 2.29761 11.7951C2.30862 11.8342 2.32798 11.8704 2.35434 11.9013C2.38071 11.9321 2.41345 11.9569 2.45033 11.9739C2.4872 11.9909 2.52733 11.9997 2.56793 11.9997H4.3733C4.45568 11.9996 4.53533 11.9702 4.59795 11.9166C4.66056 11.8631 4.70203 11.789 4.71492 11.7077L5.19045 8.69152C5.20334 8.61011 5.24485 8.53597 5.30753 8.48244C5.3702 8.42891 5.44992 8.39949 5.53234 8.39949H6.59528C8.26927 8.39949 9.68973 7.17831 9.94928 5.52342C10.1336 4.34888 9.54205 3.28007 8.5311 2.75Z"
        fill={fill ?? '#0070E0'}
      />
      <path
        d="M1.99815 0C1.82801 0 1.683 0.123762 1.65654 0.291763L0.239815 9.27535C0.212951 9.44589 0.344736 9.60012 0.517415 9.60012H2.6183L3.13994 6.29196L3.70756 2.69189C3.72044 2.61053 3.76192 2.53643 3.82454 2.4829C3.88715 2.42937 3.9668 2.39993 4.04918 2.39986H7.0589C7.6066 2.39986 8.1062 2.52764 8.53162 2.75057C8.56075 1.2439 7.31751 0 5.60823 0H1.99815Z"
        fill={fill ?? '#003087'}
      />
    </svg>
  );
}
