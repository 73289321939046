import type { RootState } from 'store';

import { ABAssignmentSelector } from 'store/modules/user/user.selectors';

import { experiments } from './experiments';

const {
  INSURANCE_WEB_PILOT,
  PERCENTAGE_VS_DOLLARS,
  PERFORMER_INLINE_PRICING_MWEB,
  APP_REVIEWS,
  NO_NFL_AUTOZOOM_DESKTOP,
  MLB_TEAM_LOGOS_PERFORMER,
  WEB_EXCLUSIVES_V3,
  HOMEPAGE_REDESIGN_V1,
  GALLERY_VIEW_V3,
  CHECKOUT_REDESIGN,
  EVENT_NAMES_NON_SPORTS_SEARCH,
} = experiments;

export const selectIsInsuranceWebPilotExperiment = (state: RootState) =>
  ABAssignmentSelector(state, INSURANCE_WEB_PILOT.name) ===
  INSURANCE_WEB_PILOT.variant;

export const selectIsPercentageVsDollarsExperiment = (state: RootState) =>
  ABAssignmentSelector(state, PERCENTAGE_VS_DOLLARS.name) ===
  PERCENTAGE_VS_DOLLARS.variant;

/**
 * Used in OmnibarControls and ListingCard
 */
export const selectIsPerformerInlinePricingMobileExperiment = (
  state: RootState
) => {
  return (
    ABAssignmentSelector(state, PERFORMER_INLINE_PRICING_MWEB.name) ===
    PERFORMER_INLINE_PRICING_MWEB.variant
  );
};

export const selectIsAppReviewsExperiment = (state: RootState) => {
  return ABAssignmentSelector(state, APP_REVIEWS.name) === APP_REVIEWS.variant;
};

export const selectIsNoNFLAutozoomDesktopExperiment = (state: RootState) => {
  return (
    ABAssignmentSelector(state, NO_NFL_AUTOZOOM_DESKTOP.name) ===
    NO_NFL_AUTOZOOM_DESKTOP.variant
  );
};

export const selectIsMLBTeamLogosPerformerExperiment = (state: RootState) => {
  return (
    ABAssignmentSelector(state, MLB_TEAM_LOGOS_PERFORMER.name) ===
    MLB_TEAM_LOGOS_PERFORMER.variant
  );
};

export const selectIsWebExclusivesV3Experiment = (state: RootState) => {
  return (
    ABAssignmentSelector(state, WEB_EXCLUSIVES_V3.name) ===
    WEB_EXCLUSIVES_V3.variant
  );
};

export const selectIsHomepageRedesignV1Experiment = (state: RootState) => {
  return (
    ABAssignmentSelector(state, HOMEPAGE_REDESIGN_V1.name) ===
    HOMEPAGE_REDESIGN_V1.variant
  );
};

export const selectIsGalleryViewV3Experiment = (state: RootState) => {
  return (
    ABAssignmentSelector(state, GALLERY_VIEW_V3.name) ===
    GALLERY_VIEW_V3.variant
  );
};

export const selectIsCheckoutRedesignExperiment = (state: RootState) => {
  const assignment = ABAssignmentSelector(state, CHECKOUT_REDESIGN.name);
  return (
    assignment === CHECKOUT_REDESIGN.variant1 ||
    assignment === CHECKOUT_REDESIGN.variant2
  );
};

export const selectIsCheckoutRedesignExperimentLightTheme = (
  state: RootState
) => {
  return (
    ABAssignmentSelector(state, CHECKOUT_REDESIGN.name) ===
    CHECKOUT_REDESIGN.variant1
  );
};

export const selectIsCheckoutRedesignExperimentDarkTheme = (
  state: RootState
) => {
  return (
    ABAssignmentSelector(state, CHECKOUT_REDESIGN.name) ===
    CHECKOUT_REDESIGN.variant2
  );
};

export const selectIsShowEventNamesForNonSportsExperiment = (
  state: RootState
) => {
  return (
    ABAssignmentSelector(state, EVENT_NAMES_NON_SPORTS_SEARCH.name) ===
    EVENT_NAMES_NON_SPORTS_SEARCH.variant
  );
};
