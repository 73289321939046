/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface PreloadedImageProps {
  src: string;
  type?: string;
}

const PreloadedImage = ({ src, type = 'image' }: PreloadedImageProps) => {
  return (
    <Helmet>
      <link
        fetchPriority="high"
        rel="preload"
        as="image"
        href={src}
        type={type}
      />
    </Helmet>
  );
};

export default PreloadedImage;
