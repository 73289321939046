import React from 'react';
import { useParams } from 'react-router-dom';

import BreadCrumbs from 'components/Breadcrumbs/Breadcrumbs';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import NotFoundWrapper from 'pages/NotFound/NotFound';
import SitemapURLsList from 'pages/Sitemap/components/SitemapURLsList';
import { getPerformersSitemapURLItems } from 'pages/Sitemap/helpers';
import { DataLoader } from 'routes/routes.utils';
import { useAppSelector } from 'store';
import { CATEGORY_URLS } from 'store/modules/categories/category.helpers';
import { fetchPerformersData } from 'store/modules/performers/actions';
import { selectFetchPerformersData } from 'store/modules/performers/selectors';

import styles from '../../Sitemap.module.scss';

const PerformersByCategorySitemap = () => {
  const { category = '' } = useParams();

  if (!(category in CATEGORY_URLS)) {
    return <NotFoundWrapper />;
  }

  const { performers = [] } = useAppSelector(selectFetchPerformersData);
  const title = `${CATEGORY_URLS[category].title} Sitemap`;
  const breadcrumbs = [
    { name: 'Gametime', url: '/' },
    { name: 'Sitemap', url: '/html-sitemap' },
    { name: 'Performers', url: '/html-sitemap/performers' },
  ];

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={<MinimalHeader search showAccount showHamburger showCategories />}
      footer={<GTFooter />}
    >
      <HeadTitle title={title} />

      <div className={styles.container}>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <h1 className={styles.title}>{title}</h1>
        {performers.length > 0 && (
          <SitemapURLsList
            urlItems={getPerformersSitemapURLItems(performers)}
          />
        )}
      </div>
    </ContainerTemplate>
  );
};

const loader: DataLoader =
  ({ store }) =>
  async ({ params: { category = '' } }) => {
    if (!(category in CATEGORY_URLS)) {
      return null;
    }

    await store.dispatch(fetchPerformersData({ category }));

    return null;
  };

PerformersByCategorySitemap.loader = loader;

export default PerformersByCategorySitemap;
