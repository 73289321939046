import _shuffle from 'lodash/shuffle';

import { PerformerClickTracker } from 'analytics/ClickTracker';
import colors from 'styles/colors.constants';

import { CATEGORIES } from '../categories/category.helpers';
import { getPerformerPath } from '../resources/resource.paths';

export const PERFORMERS = {
  ATP_US_OPEN: 'atpusopen',
  BNP_PARIBAS: 'atpindianwells',
  F1_GRAND_PRIX: 'autof1grandprixus',
  AMA_SUPERCROSS: 'automonsterenergyama',
  INDY_500: 'autoindy500',
  BELMONT_STAKES: 'horsesbelmont',
  KENTUCKY_DERBY: 'horseskentuckyderby',
  PREAKNESS: 'horsespreakness',
  PGA_US_OPEN: 'pgausopen',
  WRESTLE_MANIA: 'wreswrestlemania',
  ROYAL_RUMBLE: 'wresroyalrumble',
  WWE_RAW: 'wreswweraw',
  WWE_SD: 'wressmackdown',
  WWE_NXT: 'wreswwenxt',
  AEW: 'wresaewwrestling',
};

export const PERFORMERS_URLS = {
  [PERFORMERS.ATP_US_OPEN]: {
    id: PERFORMERS.ATP_US_OPEN,
    title: 'US Open Tennis',
    desc: 'US Open',
    url: 'us-open-tennis-championship-tickets/performers/atpusopen',
    emoji: '🎾',
  },
  [PERFORMERS.BNP_PARIBAS]: {
    id: PERFORMERS.BNP_PARIBAS,
    title: 'Indian Wells (BNP Paribas Open)',
    shortTitle: 'Indian Wells tennis',
    desc: 'BNP Paribas Open',
    url: 'bnp-paribas-open-tickets/performers/atpindianwells',
    emoji: '🎾',
  },
  [PERFORMERS.F1_GRAND_PRIX]: {
    id: PERFORMERS.F1_GRAND_PRIX,
    title: 'F1 US Grand Prix Race',
    shortTitle: 'F1 US Grand Prix',
    desc: 'Formula 1',
    url: 'f-1-us-grand-prix-tickets/performers/autof1grandprixus',
    emoji: '🏁',
  },
  [PERFORMERS.AMA_SUPERCROSS]: {
    id: PERFORMERS.AMA_SUPERCROSS,
    title: 'AMA Supercross',
    desc: 'Supercross',
    url: 'ama-supercross-tickets/performers/automonsterenergyama',
  },
  [PERFORMERS.INDY_500]: {
    id: PERFORMERS.INDY_500,
    title: 'Indianapolis 500',
    desc: 'Indy 500',
    url: 'indianapolis-500-tickets/performers/autoindy500',
    emoji: '🏁',
  },
  [PERFORMERS.BELMONT_STAKES]: {
    id: PERFORMERS.BELMONT_STAKES,
    title: 'Belmont Stakes',
    desc: 'Belmont Stakes',
    url: 'belmont-stakes-tickets/performers/horsesbelmont',
    emoji: '🐎',
  },
  [PERFORMERS.KENTUCKY_DERBY]: {
    id: PERFORMERS.KENTUCKY_DERBY,
    title: 'Kentucky Derby',
    desc: 'Kentucky Derby',
    url: 'kentucky-derby-tickets/performers/horseskentuckyderby',
    emoji: '🐎',
  },
  [PERFORMERS.PREAKNESS]: {
    id: PERFORMERS.PREAKNESS,
    title: 'Preakness',
    desc: 'Preakness',
    url: 'preakness-tickets/performers/horsespreakness',
    emoji: '🐎',
  },
  [PERFORMERS.PGA_US_OPEN]: {
    id: PERFORMERS.PGA_US_OPEN,
    title: 'US Open Golf',
    shortTitle: 'US Open',
    desc: 'US Open golf',
    url: 'us-open-tickets/performers/pgausopen',
    emoji: '⛳️',
  },
  [PERFORMERS.WRESTLE_MANIA]: {
    id: PERFORMERS.WRESTLE_MANIA,
    title: 'WrestleMania',
    desc: 'WWE WrestleMania',
    url: 'wrestle-mania-tickets/performers/wreswrestlemania',
  },
  [PERFORMERS.ROYAL_RUMBLE]: {
    id: PERFORMERS.ROYAL_RUMBLE,
    title: 'Royal Rumble',
    desc: 'WWE Royal Rumble',
    url: 'wwe-royal-rumble-tickets/performers/wresroyalrumble',
  },
  [PERFORMERS.WWE_RAW]: {
    id: PERFORMERS.WWE_RAW,
    title: 'WWE RAW',
    desc: 'WWE Monday Night RAW',
    url: 'wwe-raw-tickets/performers/wreswweraw',
  },
  [PERFORMERS.WWE_SD]: {
    id: PERFORMERS.WWE_SD,
    title: 'WWE Smackdown',
    desc: 'WWE Friday Night SD',
    url: 'wwe-smackdown-tickets/performers/wressmackdown',
  },
  [PERFORMERS.WWE_NXT]: {
    id: PERFORMERS.WWE_NXT,
    title: 'WWE NXT',
    desc: 'NXT & Takeover PPV',
    url: 'wwe-nxt-takeover-tickets/performers/wreswwenxt',
  },
  [PERFORMERS.AEW]: {
    id: PERFORMERS.AEW,
    title: 'AEW',
    desc: 'All Elite Wrestling',
    url: 'all-elite-wrestling-tickets/performers/wresaewwrestling',
  },
};

const MLB_PERFORMERS = {
  mlblad: 'mlblad',
  mlbnyy: 'mlbnyy',
  mlbsfg: 'mlbsfg',
  mlbhou: 'mlbhou',
  mlbphi: 'mlbphi',
};

const POPULAR_PERFORMERS_BY_CATEGORY = {
  [CATEGORIES.MLB]: [
    MLB_PERFORMERS.mlblad,
    MLB_PERFORMERS.mlbnyy,
    MLB_PERFORMERS.mlbsfg,
    MLB_PERFORMERS.mlbhou,
    MLB_PERFORMERS.mlbphi,
  ],
};

export const generatePerformerLinks = (performers = []) =>
  performers.map((performer) => ({
    title: performer.name,
    to: getPerformerPath(performer),
    clickTracker: new PerformerClickTracker(performer),
  }));

export const generatePopularPerformerLinks = (category, performers) => {
  if (!performers) return [];

  const popularPerformers = performers.filter((performer) => {
    return POPULAR_PERFORMERS_BY_CATEGORY[category]?.includes(performer.slug);
  });
  return generatePerformerLinks(popularPerformers);
};

export const getPerformerPriceButtonColors = (performer) => {
  if (!performer)
    return {
      backgroundColor: colors.gametimeGreenLight,
      color: colors.black,
    };

  return {
    backgroundColor: performer.primaryColor || colors.white,
    color: performer.contrastColor || colors.black,
  };
};

/**
 * Generates an array of top pick performers.
 *
 * This function takes an array of performers and a base performer. It filters out the base performer from the array,
 * shuffles the last 15 performers, and then returns the first 5 performers from the shuffled array.
 */
export const generateTopPicksPerformers = (
  performersInContext,
  basePerformer = {}
) => {
  if (!performersInContext) return [];

  const filteredPerformers = performersInContext.filter((performer) => {
    return performer.performer.id !== basePerformer.id;
  });

  const shuffledPerformers = _shuffle(filteredPerformers.slice(-15));
  return shuffledPerformers.slice(0, 5).map((performer) => {
    return performer.performer;
  });
};

export const getPerformerContentSlug = (slug, matchupSlug, isMatchupPage) => {
  return isMatchupPage
    ? [slug, matchupSlug].sort((a, b) => a - b).join('-')
    : slug;
};
