import React from 'react';
import PropTypes from 'prop-types';

import { Click, FullEventClickTracker } from 'analytics';
import CollectionCard from 'components/CollectionCard/CollectionCard';
import { dataToJsonLD } from 'components/JsonLD/helpers';
import DetailSubHead from 'components/OrderDetail/DetailSubHead';

import styles from './UpsellSection.module.scss';

function UpsellSection({ upsellEvents }) {
  const events = upsellEvents.map((fullEvent, index) => {
    const interaction = new FullEventClickTracker(fullEvent).interaction(
      Click.INTERACTIONS.TILE(),
      {
        section_index: 0,
        item_index: index,
        section_name: "Don't miss out",
        get_in_price: fullEvent.getPrice(),
      }
    );

    return (
      <div
        key={`upsell-section-${fullEvent.id}`}
        className={styles['upsell-card']}
      >
        <CollectionCard
          source={fullEvent}
          clickTracker={interaction}
          lazyLoad={false}
          jsonLD={dataToJsonLD(fullEvent)}
        />
      </div>
    );
  });

  return (
    <div>
      <DetailSubHead text="don't miss out!" />
      <div className={styles['order-upsell']}>{events}</div>
    </div>
  );
}

UpsellSection.propTypes = {
  upsellEvents: PropTypes.array,
};

export default UpsellSection;
