import { Location } from 'react-router-dom';

import config from 'config/config';
import { RootState } from 'store';
import { selectListingQuantity } from 'store/modules/listingsV3/selectors';
import {
  getAssociatedListingPathname,
  selectAlgoliaSearchParams,
} from 'store/modules/location';
import { searchStringToQueryObject } from 'utils/url';

export const selectCheckoutMagicLinkParams = (
  state: RootState,
  location: Location
) => {
  const quantity = selectListingQuantity(state);
  const query = searchStringToQueryObject(location.search);
  const searchParams = selectAlgoliaSearchParams(query);
  if (quantity) {
    searchParams.set(
      config.cookiesConfig.USER_EXTRAS.parameters.SEAT_COUNT.query,
      quantity.toString()
    );
  }

  const listingPath = getAssociatedListingPathname(location.pathname);
  const params = {
    pathname: `${listingPath}/checkout`,
    search: Object.fromEntries(searchParams),
  };

  return params;
};
