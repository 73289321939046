import React from 'react';
import classNames from 'classnames';

import styles from './PromoCodeSection.module.scss';

interface PromoCodeSectionProps {
  openPromoCodeModal: () => void;
  isDisabled?: boolean;
}

const PromoCodeSection = ({
  openPromoCodeModal,
  isDisabled = false,
}: PromoCodeSectionProps) => {
  return (
    <div className={classNames(styles['promo-code-card'])}>
      <button
        className={styles['add-button']}
        onClick={openPromoCodeModal}
        disabled={isDisabled}
      >
        Add Promo Code +
      </button>
    </div>
  );
};

export default PromoCodeSection;
