import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { TrackPageView, View } from 'analytics';
import GTInfoModal from 'components/GTInfoModal/GTInfoModal';
import UserPromoCodeForm from 'components/UserPromoCodeForm/UserPromoCodeForm';
import { ChevronIcon, CircleCheckFillIcon } from 'icons';
import {
  setFormSubmitted as setFormSubmittedDispatch,
  setFormSubmitting as setFormSubmittingDispatch,
} from 'store/modules/app/app.ui';
import { fetchUserPromoCodes as fetchUserPromoCodesDispatch } from 'store/modules/user/actions';
import colors from 'styles/colors.constants';

import ActivePromos from './components/ActivePromos/ActivePromos';
import { CODE_TYPE } from './constants';

import styles from './PromoCodes.module.scss';

const PromoCodes = ({
  activePromos = [],
  fetchUserPromoCodes,
  setFormSubmitting,
  setFormSubmitted,
  goBack,
}) => {
  const [showModal, setShowModal] = useState(false);

  const DISMISS_BACKDROP_TIMEOUT = 1e3; // Timeout for when to auto-dismiss the backdrop

  /**
   * Sets the backdrop and submitting state of the loading indicator to the specified
   * value. Used for when the form is loading and both the backdrop and loading indicator
   * need to be shown to the user.
   */
  const handleLoading = (isLoading) => {
    setFormSubmitting(isLoading);
  };

  /**
   * When the promo code form has successfully submitted, set the state of the
   * loading spinner to loaded while fetching the user's active promo codes. While
   * the promo codes are being fetched, the backdrop remains active until the request
   * is completed, at which point the backdrop is dismissed after the specified timeout.
   */
  const handleRedemptionSuccess = async (codeType) => {
    setFormSubmitting(false);

    if (codeType !== CODE_TYPE.CREDIT) {
      setFormSubmitted(true);

      try {
        await fetchUserPromoCodes();

        setTimeout(() => {
          setFormSubmitted(false);
        }, DISMISS_BACKDROP_TIMEOUT);
      } catch (err) {
        console.error(err);
        setFormSubmitted(false);
      }
    } else setShowModal(true);
  };

  return (
    <div className={styles['promo-codes']}>
      <div className={styles['header-container']}>
        <button className={styles['header-left']} onClick={() => goBack()}>
          <ChevronIcon
            width={24}
            height={24}
            direction="left"
            color={colors.white}
          />
        </button>
        <span className={styles['header-title']}>Promo Codes</span>
      </div>
      <h2 className={styles.heading}>Add Promo Code</h2>
      <UserPromoCodeForm
        onLoading={handleLoading}
        onSuccess={handleRedemptionSuccess}
      />
      {activePromos.length ? (
        <ActivePromos activePromos={activePromos} />
      ) : null}
      <GTInfoModal
        icon={<CircleCheckFillIcon fill={colors.black} />}
        title="Credit Code Applied"
        buttonText="okay"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <span className={styles['modal-text']}>
          Your credit has been applied to your account. Please download the iOS
          or Android app to use your credit.
        </span>
      </GTInfoModal>
    </div>
  );
};

PromoCodes.propTypes = {
  activePromos: PropTypes.array,
  fetchUserPromoCodes: PropTypes.func.isRequired,
  setFormSubmitting: PropTypes.func.isRequired,
  setFormSubmitted: PropTypes.func.isRequired,
  goBack: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserPromoCodes: () => dispatch(fetchUserPromoCodesDispatch()),
  setFormSubmitting: (isSubmitting) =>
    dispatch(setFormSubmittingDispatch(isSubmitting)),
  setFormSubmitted: (isSubmitted) =>
    dispatch(setFormSubmittedDispatch(isSubmitted)),
});

const getEventState = ({ activePromos }) => {
  return View.PAGE_TYPES.REDEEM_CODE(JSON.stringify(activePromos));
};

export default connect(
  null,
  mapDispatchToProps
)(TrackPageView(getEventState)(PromoCodes));
