import React from 'react';
import classNames from 'classnames';

import LockIcon from 'icons/LockIcon';
import WarningCircleFillIcon from 'icons/WarningCircleFillIcon';
import colors from 'styles/colors.constants';

import styles from './TextInput.module.scss';

type InputElementProps = React.InputHTMLAttributes<HTMLInputElement>;

interface TextInputError {
  hasError: boolean;
  message: string;
}

interface TextInputInfo {
  hasInfo: boolean;
  infoMessage: string;
}

interface TextInputProps extends InputElementProps {
  /**
   * Input element ID, also used for attributing label element, hints, and
   * errors to the input element
   */
  id: string;
  /**
   * Additional class names to add to the input group container
   */
  className?: string;
  /**
   * autoComplete is updated in later React InputHTMLAttributes types, this list
   * limits the options to the ones we use in our app.
   *
   * see: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts#L3353
   */
  autoComplete?:
    | 'on'
    | 'off'
    | 'email'
    | 'one-time-code'
    | 'address-line-1'
    | 'address-line-2'
    | 'postal-code'
    | 'tel';
  /**
   * className for the input element
   */
  inputClassName?: InputElementProps['className'];
  /**
   * The label text
   */
  label?: React.ReactNode;
  /**
   * className for the label input
   */
  labelClassName?: string;
  /**
   * Whether or not the field is required and needs a red asterisk
   */
  required?: boolean;
  /**
   * Hint text to accompany the label
   */
  hint?: string;
  /**
   * Icon to appear on start of input
   */
  startIcon?: React.ReactNode;
  /**
   * Icon to appear at end of input
   */
  endIcon?: React.ReactNode;
  /**
   * Object containing input error state and message
   */
  error?: TextInputError;
  /**
   * Object containing input info state and message
   */
  info?: TextInputInfo;
  /**
   * Boolean for setting light theme (white background)
   */
  light?: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      label,
      labelClassName,
      inputClassName,
      required = false,
      hint,
      startIcon,
      endIcon,
      error,
      info,
      light,

      // input element props
      id,
      disabled,
      type = 'text',
      autoComplete = 'on',
      maxLength = 254,
      onChange,
      ...props
    },
    ref
  ) => {
    const hintId = `${id}-hint`; // used for a11y to associate input with label
    const errorId = `${id}-error`; // used for a11y to associate input with error message
    const infoId = `${id}-info`;
    const { hasError, message } = error || {};
    const { hasInfo, infoMessage } = info || {};

    const inputProps: InputElementProps = {
      ...props,
      'aria-describedby': `${errorId} ${hintId}`,
      'aria-required': required,
      id,
      disabled,
      className: classNames(styles.input, inputClassName),
      type,
      autoComplete,
      maxLength,
      onInput: onChange,
      onChange: onChange,
      size: 1, // CSS flexbox will handle sizing
    };

    return (
      <div
        className={classNames(
          styles['text-input'],
          {
            [styles.light]: light,
          },
          className
        )}
      >
        <div className={styles['label-container']}>
          <label
            className={classNames(styles.label, labelClassName)}
            htmlFor={id}
          >
            {label}
          </label>
          {hint && (
            <span id={hintId} className={styles.hint}>
              <LockIcon fill={colors.gray400} />
              {hint}
            </span>
          )}
        </div>
        <div
          className={classNames(styles['input-container'], {
            [styles.error]: hasError,
            [styles.disabled]: disabled,
          })}
        >
          {startIcon && <div className={styles['start-icon']}>{startIcon}</div>}
          <input ref={ref} {...inputProps} />
          {endIcon && <div className={styles['end-icon']}>{endIcon}</div>}
        </div>
        <div
          className={classNames(styles['error-container'], {
            [styles.hidden]: !hasError,
          })}
        >
          <WarningCircleFillIcon fill={colors.softRed} width="16" height="16" />
          <span id={errorId} className={styles.error} aria-live="polite">
            {message}
          </span>
        </div>
        <div
          className={classNames(styles['info-container'], {
            [styles.hidden]: !hasInfo,
          })}
        >
          <span id={infoId} className={styles.info} aria-live="polite">
            {infoMessage}
          </span>
        </div>
      </div>
    );
  }
);

export default TextInput;
