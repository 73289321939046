import React from 'react';
import { FormikHelpers } from 'formik';

import PurchaseHeading from 'pages/PurchaseV3/components/PurchaseHeading';

import {
  Form,
  FormattedAddress,
  Input,
  Validators,
} from '../../../CheckoutV3/components/Forms';

const UserAddressFormValidationSchema = Validators.schema({
  address: Validators.string({ label: 'address line 1', required: true }),
  address2: Validators.string({ label: 'address line 2', required: false }),
  name: Validators.string({ label: 'name', required: true }),
});

interface PurchaseUserAddressProps {
  onSubmit: (form: Values) => void;
}

interface Values {
  name: string;
  address: string;
  address2: string;
}

function PurchaseUserAddress({ onSubmit }: PurchaseUserAddressProps) {
  const onSubmitHandler = async (
    form: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    try {
      setSubmitting(true);
      await onSubmit(form);
    } catch (err) {
      console.error('Error submitting user address form: ', err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PurchaseHeading
        heading="Purchased! Verify Billing Address"
        subheading="Before delivering your tickets, we need to verify your billing address."
      />
      <Form
        id="purchase-user-address-form"
        initialValues={{ name: '', address: '', address2: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={UserAddressFormValidationSchema}
      >
        <Input id="user-name" name="name" label="Name" required type="text" />

        <FormattedAddress id="address" name="address" />

        <Input
          id="user-address2"
          name="address2"
          label="Address Line 2"
          type="text"
        />
      </Form>
    </>
  );
}

export default PurchaseUserAddress;
