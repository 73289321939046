import React from 'react';
import classNames from 'classnames';

import DeliveryIcon from 'components/DeliveryFormat/DeliveryIcon/DeliveryIcon';

import { selectIsMLBIntegrationEnabled } from '../../../../featureFlags';
import {
  Disclosure as DisclosureIcon,
  Perks as PerksIcon,
  Price as PriceIcon,
  Seats as SeatIcon,
} from '../../../../icons';
import MLBIcon from '../../../../icons/MLBIcon';
import {
  Disclosure as DisclosureModel,
  FullEvent,
  Listing,
} from '../../../../models';
import { isDefaultAllInState } from '../../../../pages/Event/helpers';
import { useAppSelector } from '../../../../store';
import { deliveryFormatForListing } from '../../../../store/datatypes/DELIVERY_FORMATS';
import {
  allDisclosuresSelector,
  DisclosureSelectorResponse,
} from '../../../../store/modules/resources/resource.selectors';
import { getExternalAccountType } from '../../../../store/modules/user/utils';
import { isMLBEvent } from '../../../../utils/mlb';
import { DELIVERY_DISPLAY_DATA as deliveryContent } from '../../../DeliveryFormat/delivery.format.constants';
import { useSeatDescription } from '../../../SeatDescription/useSeatDescription';

import Attribute from './Attribute';
import Disclosure from './Disclosures';

import styles from './Attributes.module.scss';

function getDeliveryFormat(
  listing: Listing
): Omit<(typeof deliveryContent)[number], 'title'> & { title: string } {
  const key = deliveryFormatForListing(listing);
  const content = deliveryContent[key];

  return { ...content, title: content.title.join(' ') };
}

function isMlbVerified(
  event: FullEvent,
  listing: Listing,
  isEnabled: boolean
): boolean {
  if (!isEnabled) {
    return false;
  }

  if (!isMLBEvent(event)) {
    return false;
  }

  const accountType = getExternalAccountType(listing.ticketType);

  if (accountType === undefined) {
    return false;
  }

  return accountType.startsWith('MLB_');
}

export function getFaceValue(event: FullEvent, listing: Listing): string {
  const price = listing.getFaceValue();

  if (!Number(price)) {
    return 'Face value pricing is not available from the supplier.';
  }

  return `Prices are set by sellers and may vary from face value. Face value: ${event.currencyPrefix}${price} per ticket.`;
}

type Attributes = Map<'disclosures' | 'perks', DisclosureModel[]>;

function getAttributesForListing(
  listing: Listing,
  attributeList: DisclosureSelectorResponse
): Attributes {
  const categorized: Attributes = new Map([
    ['disclosures', []],
    ['perks', []],
  ]);

  Object.values(attributeList).forEach((attribute) => {
    if (!listing.disclosures.includes(attribute.slug)) {
      return;
    }

    if (!attribute.title) {
      return;
    }

    const key = attribute.isHighlightedDisplay ? 'perks' : 'disclosures';
    const category = categorized.get(key) ?? [];
    category.push(attribute);
    categorized.set(key, category);
  });

  return categorized;
}

export default function Attributes({
  className,
  event,
  listing,
  shouldHideDelivery,
  hideFaceValue,
}: {
  className?: string;
  event: FullEvent;
  listing: Listing;
  shouldHideDelivery?: boolean;
  hideFaceValue: boolean;
}): React.JSX.Element {
  /* @ts-expect-error -- Unpacking `listing` passes more properties than method expects */
  const seatDescription = useSeatDescription({
    ...listing,
    seatCount: listing.quantity,
  });

  const disclosureList = useAppSelector(allDisclosuresSelector);
  const isMlbEnabled = useAppSelector(selectIsMLBIntegrationEnabled);
  const attributeList = getAttributesForListing(listing, disclosureList);

  return (
    <ul className={classNames(className, styles['attribute-list'])}>
      <Attribute icon={<SeatIcon />}>
        <p className={styles.title}>{seatDescription.title}</p>
        <p className={styles['seat-info-description']}>
          {seatDescription.description}
        </p>
      </Attribute>

      {isMlbVerified(event, listing, isMlbEnabled) && (
        <Attribute icon={<MLBIcon height="auto" width="16" />}>
          <span className={styles.title}>Verified MLB Tickets</span>
        </Attribute>
      )}

      {Array.from(attributeList).map(([key, list]) => {
        if (!list.length) {
          return null;
        }

        return (
          <Attribute
            className={styles[`${key}-list`]}
            icon={key === 'disclosures' ? <DisclosureIcon /> : <PerksIcon />}
            key={key}
          >
            <ul className={classNames(styles.disclosures, styles[key] ?? '')}>
              {list.map((attribute) => (
                <li key={attribute.slug}>
                  <Disclosure
                    className={classNames(styles.disclosure, styles.title)}
                    disclosure={attribute}
                  />
                </li>
              ))}
            </ul>
          </Attribute>
        );
      })}
      {!shouldHideDelivery && (
        <Attribute
          icon={
            <DeliveryIcon
              deliveryFormat={deliveryFormatForListing(listing)}
              iconProps={{ fill: 'currentColor', height: 16, width: 16 }}
            />
          }
        >
          <span className={styles.title}>
            {getDeliveryFormat(listing).orderDetailTitle}
          </span>
        </Attribute>
      )}
      {isDefaultAllInState(event.venueState) && !hideFaceValue && (
        <Attribute icon={<PriceIcon />}>
          {getFaceValue(event, listing)}
        </Attribute>
      )}
    </ul>
  );
}
