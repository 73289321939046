import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import {
  PatchUpdatePasswordRequestPayload,
  PostCodeResponse,
  PutUserResponse,
  UserModel,
} from 'types';

interface UpdatePasswordRequestParams
  extends PatchUpdatePasswordRequestPayload {
  userId: string;
}

export interface PostCodeRequest {
  user: UserModel;
  code: string;
}

const httpClient = new HttpClient(config.MOBILE_API_URL);

export function updatePassword({
  userId,
  token,
  password,
}: UpdatePasswordRequestParams) {
  return httpClient.request<null, PatchUpdatePasswordRequestPayload>({
    path: `/v1/user/${userId}/update-password`,
    method: 'PATCH',
    body: {
      token,
      password,
    },
  });
}

export function putUser(mobileApi: HttpClient, user: UserModel) {
  return mobileApi.request<PutUserResponse>({
    path: `/v1/user/${user.id}`,
    method: 'PUT',
    body: user,
    authUser: user,
  });
}

export function postUserPromoCode(
  mobileApi: HttpClient,
  { user, code }: PostCodeRequest
) {
  return mobileApi.request<PostCodeResponse>({
    path: `/v1/user/${user.id}/code`,
    method: 'POST',
    headers: { session_token: user.session_token },
    body: { code },
  });
}
