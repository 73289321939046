import { Position as PositionType } from 'types';

export default class Position {
  readonly x: PositionType['x'];
  readonly y: PositionType['y'];

  constructor(position: PositionType) {
    this.x = position.x;
    this.y = position.y;
  }
}
