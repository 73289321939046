import { useCallback, useEffect, useMemo, useState } from 'react';
import { callback } from 'braintree-web';
import { create as braintreeDataCollectorCreate } from 'braintree-web/data-collector';
import {
  create as braintreeHostedFieldsCreate,
  HostedFieldAttributeOptions,
  HostedFieldFieldOptions,
  HostedFields,
  HostedFieldsHostedFieldsFieldData as FieldData,
  HostedFieldsHostedFieldsFieldName as FieldName,
  HostedFieldsTokenizePayload,
} from 'braintree-web/hosted-fields';

import config from 'config/config';
import { useAppDispatch } from 'store';
import { saveUserBraintreeData } from 'store/modules/userPurchases/actions';

type CamelToKebab<T extends string> = T extends `${infer First}${infer Rest}`
  ? `${First extends Capitalize<First> ? '-' : ''}${Lowercase<First>}${CamelToKebab<Rest>}`
  : T;

type ConvertKeysToKebabCase<T> = {
  [K in keyof T as CamelToKebab<string & K>]: T[K] extends object
    ? ConvertKeysToKebabCase<T[K]>
    : T[K];
};

export type BraintreeTokenize = (
  billingAddress?: BraintreeBillingAddress
) => Promise<HostedFieldsTokenizePayload>;
export interface BraintreeBillingAddress {
  firstName: string;
  lastName: string;
  streetAddress: string;
  extendedAddress?: string;
  locality: string;
  region: string;
  postalCode: string;
  countryCodeAlpha2: string;
}

/**
 * Braintree uses kebab cased keys a la CSS rather than
 * camelCased keys a la CSS-in-JS with only a specific
 * subset of properties
 *
 * @see {@link https://braintree.github.io/braintree-web/3.92.1/module-braintree-web_hosted-fields.html#~styleOptions}
 */
export type BraintreeAllowedStyles = {
  [key: string]: Partial<
    Pick<
      ConvertKeysToKebabCase<React.CSSProperties>,
      | '-moz-appearance'
      | '-moz-box-shadow'
      | '-moz-osx-font-smoothing'
      | '-moz-transition'
      | '-webkit-appearance'
      | '-webkit-box-shadow'
      | '-webkit-font-smoothing'
      | '-webkit-transition'
      | 'appearance'
      | 'box-shadow'
      | 'color'
      | 'direction'
      | 'font-family'
      | 'font-size-adjust'
      | 'font-size'
      | 'font-stretch'
      | 'font-style'
      | 'font-variant-alternates'
      | 'font-variant-caps'
      | 'font-variant-east-asian'
      | 'font-variant-ligatures'
      | 'font-variant-numeric'
      | 'font-variant'
      | 'font-weight'
      | 'font'
      | 'letter-spacing'
      | 'line-height'
      | 'margin-bottom'
      | 'margin-left'
      | 'margin-right'
      | 'margin-top'
      | 'margin'
      | 'opacity'
      | 'outline'
      | 'padding-bottom'
      | 'padding-left'
      | 'padding-right'
      | 'padding-top'
      | 'padding'
      | 'text-align'
      | 'text-shadow'
      | 'transition'
    > & {
      [key in
        | '-moz-tap-highlight-color'
        | '-webkit-tap-highlight-color']: React.CSSProperties['WebkitTapHighlightColor'];
    }
  >;
};

export type HostedFieldsHandler = {
  clear: HostedFields['clear'];
  getState: HostedFields['getState'];
  removeAttribute(
    options: Omit<HostedFieldAttributeOptions, 'value'>,
    callback?: callback
  ): void;
  setAttribute(options: HostedFieldAttributeOptions, callback?: callback): void;
  tokenize: BraintreeTokenize;
  validate(): boolean;
};

export function getFieldErrorMessage(fieldName: FieldName, field: FieldData) {
  let error = undefined;

  switch (fieldName) {
    case 'number': {
      if (field.isEmpty) {
        error = 'Credit card number is required';
      } else if (!field.isValid) {
        error = 'Please check if you typed the correct card number';
      }
      break;
    }
    case 'expirationDate': {
      if (field.isEmpty || !field.isValid) {
        error = 'Please enter a valid card expiry date';
      }
      break;
    }
    case 'cvv': {
      if (field.isEmpty || !field.isValid) {
        error = 'Please enter a valid 3-4 digit security code';
      }
      break;
    }
    default: {
      break;
    }
  }

  return error;
}

export function useBraintreeHostedFields() {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<
    HostedFields | undefined
  >();

  const [fieldFocus, setFieldFocus] = useState<
    Partial<Record<FieldName, boolean>>
  >({});

  const [fieldEmpty, setFieldEmpty] = useState<
    Partial<Record<FieldName, boolean>>
  >({});

  const [fieldErrors, setFieldErrors] = useState<
    Partial<Record<FieldName, string>>
  >({});

  const [fieldTouched, setFieldTouched] = useState<
    Partial<Record<FieldName, string>>
  >({});

  const setFieldError = useCallback(
    (fieldName: FieldName, errorMessage?: string) =>
      setFieldErrors((fieldErrorsState) => ({
        ...fieldErrorsState,
        [fieldName]: errorMessage,
      })),
    []
  );

  /**
   * Creates a Braintree Hosted Fields instance and sets the value in state.
   *
   * @see: {@link https://braintree.github.io/braintree-web/current/module-braintree-web_hosted-fields.html#.create}
   * @see: {@link https://braintree.github.io/braintree-web/current/HostedFields.html}
   */
  const createHostedFields = useCallback(
    (fields: HostedFieldFieldOptions, styles: BraintreeAllowedStyles) =>
      braintreeHostedFieldsCreate({
        authorization: config.BRAINTREE_CLIENT_TOKEN,
        fields,
        styles,
      })
        .then((hostedFieldsInstance) => {
          hostedFieldsInstance.on('focus', (event) => {
            setFieldFocus((prevState) => ({
              ...prevState,
              [event.emittedBy]: true,
            }));
          });

          hostedFieldsInstance.on('blur', (event) => {
            /*
             * The hosted fields blur event has the potential to fire
             * on focus as well as on blur. This can lead to input
             * validation occurring on focus as well as blur which is
             * not ideal. To combat this we need to do a few things:
             *
             *   1. Use the hosted field internal state rather than
             *      relying on the emitted event. This has a more
             *      reliable accounting of the current state of the
             *      fields.
             *
             *   2. Set a slight timeout to allow the hosted fields
             *      to keep itself synchronized.
             *
             *   3. Return early if the field on which the blur event
             *      occurred still has focus.
             */
            setTimeout(() => {
              const key = event.emittedBy;
              const state = hostedFieldsInstance.getState();
              const field = state.fields[key];

              if (field.isFocused) {
                return;
              }

              setFieldEmpty((prevState) => ({
                ...prevState,
                [key]: field.isEmpty,
              }));

              setFieldFocus((prevState) => ({
                ...prevState,
                [key]: false,
              }));

              setFieldTouched((prevState) => ({
                ...prevState,
                [key]: true,
              }));

              setFieldError(key, getFieldErrorMessage(key, field));
            }, 200);
          });

          hostedFieldsInstance.on('validityChange', (event) => {
            const key = event.emittedBy;
            const field = event.fields[key];

            setFieldError(
              key,
              field.isValid ? undefined : getFieldErrorMessage(key, field)
            );
          });

          setHostedFieldsInstance(hostedFieldsInstance);

          return hostedFieldsInstance;
        })
        .catch((error) => {
          console.error('Error instantiating Braintree Hosted Fields', error);
        }),
    [setFieldError]
  );

  useEffect(() => {
    if (!hostedFieldsInstance) {
      return;
    }

    // only return cleanup function if hosted fields instance exists
    return () => {
      hostedFieldsInstance.teardown();
    };
  }, [hostedFieldsInstance]);

  const dispatch = useAppDispatch();

  const hostedFields: HostedFieldsHandler | undefined = useMemo(() => {
    if (!hostedFieldsInstance) {
      return;
    }

    return {
      getState: hostedFieldsInstance.getState,

      /**
       * Validates fields found in Braintree Hosted Fields instance state,
       * returning true if all fields are valid or false if any field
       * contains an error.
       */
      validate() {
        const fieldsState = hostedFieldsInstance.getState().fields;

        const fieldErrors: Partial<Record<FieldName, string>> = {};
        let fieldName: FieldName;
        for (fieldName in fieldsState) {
          const fieldData = fieldsState[fieldName];
          const errorMessage = getFieldErrorMessage(fieldName, fieldData);
          if (errorMessage) {
            fieldErrors[fieldName] = errorMessage;
          }
        }

        setFieldErrors(fieldErrors);

        return Object.keys(fieldErrors).length === 0;
      },

      /**
       * Tokenize a payment method with hosted field instance data and
       * return the response. Also has the side effect of dispatching an
       * action to save Braintree user device data. This async function
       * should be called after using validateFields.
       */
      async tokenize(billingAddress?: BraintreeBillingAddress) {
        const [payload] = await Promise.all([
          hostedFieldsInstance.tokenize({ billingAddress }),
          braintreeDataCollectorCreate({
            // @ts-expect-error authorization field missing in @types/braintree-web
            authorization: config.BRAINTREE_CLIENT_TOKEN,
          })
            .then((dataCollector) => {
              dispatch(saveUserBraintreeData(dataCollector.deviceData));
            })
            .catch(console.error),
        ]);

        return payload;
      },

      /**
       * Set an attribute on a form field.
       *
       * @param {Object} options
       * @param {string} options.field - The name of the field. Must be a valid
       *   field name included in the hosted fields options.
       * @param {string} options.attribute - Supported attributes are
       *   aria-invalid, aria-required, disabled, and placeholder.
       * @param {string} options.value — the value to set for the attribute.
       * @param {function} [callback] — optional callback invoked after the
       *   field attribute is set. Includes an error if one occurred. If the
       *   attribute is set successfully, no data is returned.
       */
      setAttribute:
        hostedFieldsInstance.setAttribute.bind(hostedFieldsInstance),

      /**
       * Remove an attribute from a form field.
       *
       * @param {Object} options
       * @param {string} options.field - The name of the field. Must be a valid
       *   field name included in the hosted fields options.
       * @param {string} options.attribute - Supported attributes are
       *   aria-invalid, aria-required, disabled, and placeholder.
       * @param {function} [callback] — optional callback invoked after the
       *   field attribute is removed. Includes an error if one occurred. If the
       *   attribute is removed successfully, no data is returned.
       */
      removeAttribute:
        hostedFieldsInstance.removeAttribute.bind(hostedFieldsInstance),

      /**
       * Clear the value from a specified form field.
       *
       * @param {string} field - The name of the field. Must be a valid
       *   field name included in the hosted fields options.
       * @param {function} [callback] — optional callback invoked after the
       *   field attribute is removed. Includes an error if one occurred. If the
       *   attribute is removed successfully, no data is returned.
       */
      clear: hostedFieldsInstance.clear.bind(hostedFieldsInstance),
    };
  }, [hostedFieldsInstance, dispatch]);

  return {
    createHostedFields,
    fieldEmpty,
    fieldErrors,
    fieldFocus,
    fieldTouched,
    hostedFields,
    setFieldError,
  };
}
