import appConfig from './config';
import { envIsLikeDev } from './environments';

export type Providers = 'statsig';

type Config = {
  debug: boolean;
  provider: Providers;
  statsig?: {
    credentials: {
      client_token: string;
    };
  };
};

const config: Config = {
  debug: envIsLikeDev(appConfig.env),
  provider: 'statsig',
  statsig: {
    credentials: {
      client_token: appConfig.STATSIG_CLIENT_TOKEN,
    },
  },
};

export default config;
