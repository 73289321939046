import React from 'react';

import Image from 'components/Image/Image';
import ImagePan from 'components/ImagePan/ImagePan';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import { device, useMediaQuery } from 'hooks';
import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';

import styles from './ListingHero.module.scss';

interface ListingHeroProps {
  fullEvent: FullEvent;
  listing: Listing;
  showZoneTag: boolean;
}

function ListingHero({ fullEvent, listing, showZoneTag }: ListingHeroProps) {
  const isMobile = useMediaQuery(device.down.md);

  const imagePropsForOriginListing = listing.getImageOptions(fullEvent.venue);

  return (
    <div className={styles['listing-hero']}>
      <div className={styles.image}>
        {!isMobile ? (
          <ImagePan imageProps={imagePropsForOriginListing} />
        ) : (
          <Image {...imagePropsForOriginListing} />
        )}
      </div>
      {showZoneTag && (
        <div className={styles['zone-tag-container']}>
          <ZoneTag />
        </div>
      )}
    </div>
  );
}

export default ListingHero;
