export const PAGE_TITLES = {
  'terms-of-service': 'Terms of Service',
  'privacy-policy': 'Privacy Policy',
  'share-codes': 'Share Codes',
  'price-guarantee': 'Price Guarantee',
  'gametime-guarantee': 'Gametime Guarantee',
  'cookies-policy': 'Cookies Policy',
  'terms-sms': 'SMS Terms',
  press: 'Gametime United Press Releases',
} as const;

export const PAGE_DESCRIPTIONS = {
  'terms-of-service':
    'This page explains the terms by which you may use our mobile applications, mobile website, website and software provided on or in connection with the service (collectively the “Service”).',
  'privacy-policy':
    'Protecting your privacy is important to us here at Gametime. This Privacy Policy describes how Gametime United, Inc. collects, uses, discloses and safeguards the personal information you provide on Gametime website.',
  'share-codes':
    "At Gametime.co you will get a personal share code that you can share with friends and family to give them $5 off their first booking. You'll get $5 in credits when they purchase.",
  'price-guarantee':
    "Gametime works around the clock to get you the best prices anywhere. If you do find a lower price elsewhere we'll add 110% of the difference between the price you found and our price in Gametime credit to your account.",
  'gametime-guarantee':
    "Gametime is proud to offer the most flexible customer service policy in the ticketing industry, so you can rest assured we've got your back. Buy with confidence and ease.",
  'cookies-policy':
    'Protecting your privacy is important to us here at Gametime. This Cookies Policy describes how Gametime United, Inc. uses cookies to enhance your browsing experiences on Gametime website.',
  'terms-sms':
    'This page explains the Gametime SMS Terms of Service which govern the provision, receipt, and delivery of text messages by or on behalf of Gametime United, Inc.',
  press:
    'See Gametime United press releases and stay up-to-date on the latest news from Gametime.',
} as const;

export const DEFAULT_PAGE_TITLE = 'Gametime';
export const DEFAULT_PAGE_DESCRIPTION =
  'Get the cheapest tickets to the most popular events in sports, music, and theater, with seat view photos and the best prices - guaranteed!';
