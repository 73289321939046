import { Action } from '@reduxjs/toolkit';

import {
  GetVenuesParams,
  GetVenuesResponse,
} from 'services/venue/venue.service';
import { RequestStatus } from 'types';

import {
  fetchVenuesFailure,
  fetchVenuesRequest,
  fetchVenuesSuccess,
} from './actions';

export interface VenuesState {
  status: RequestStatus;
  params: Partial<GetVenuesParams>;
  fetchVenuesData: GetVenuesResponse;
  error?: unknown;
}

export const initialState: VenuesState = {
  params: {},
  fetchVenuesData: {
    results: [],
    total: 0,
    more: false,
    page: 0,
    per_page: 0,
  },
  status: 'idle',
  error: undefined,
};

export default function venuesReducer(
  state: VenuesState = initialState,
  action: Action
): VenuesState {
  if (fetchVenuesRequest.match(action)) {
    const params = action.payload;
    const { page = -1 } = state.params;

    if (page && params.page === page + 1) {
      return {
        ...state,
        params: action.payload,
        status: 'loading',
        error: undefined,
      };
    } else {
      return {
        ...state,
        params: action.payload,
        status: 'loading',
        error: undefined,
        fetchVenuesData: initialState.fetchVenuesData,
      };
    }
  }

  if (fetchVenuesSuccess.match(action)) {
    const response = action.payload.response;
    return {
      ...state,
      fetchVenuesData: {
        ...response,
        results:
          response.page === 1
            ? response.results
            : [...state.fetchVenuesData.results, ...response.results],
      },
      status: 'success',
    };
  }

  if (fetchVenuesFailure.match(action)) {
    return {
      ...state,
      status: 'failure',
      error: action.payload,
    };
  }

  return state;
}
