import React from 'react';
import classNames from 'classnames';
import { map, omit } from 'lodash';
import { RadioCard, RadioCardButton, RadioGroup } from 'ui/Radio';

import AffirmLogoBlueCircle from 'assets/affirm-color-logo.svg';
import GooglePayLogo from 'assets/google-pay-logo.svg';
import AffirmPromoMessage from 'components/Affirm/AffirmPromoMessage';
import Spinner from 'components/Spinner/Spinner';
import CreditCardFrontFillIcon from 'icons/CreditCardFrontFillIcon';
import PayPalCardIcon from 'icons/PayPalCardIcon';
import {
  CARD_TYPES,
  NON_TOKEN_PAYMENT_TYPES,
  PAYMENT_TYPES_CONFIG,
} from 'store/datatypes/PAYMENT_TYPES';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import colors from 'styles/colors.constants';
import { CreditCard } from 'types';

import { OptionalDictionary } from '../../../CheckoutV3/Dictionaries';

import styles from './PaymentMethods.module.scss';

interface Props {
  addCardTitle: string;
  adjustedTotal: number;
  classNameList?: {
    component?: OptionalDictionary<
      | 'affirm-icon'
      | 'google-pay-icon'
      | 'affirm-promo-message'
      | 'card-desc'
      | 'card-exp'
      | 'container'
      | 'lastfour'
      | 'paypal-icon'
      | 'star'
    >;
    radio?: OptionalDictionary<
      | 'checkmark'
      | 'component'
      | 'content'
      | 'expanded-content-drawer'
      | 'expanded-content-overflow'
      | 'expanded-content'
      | 'header'
      | 'icon'
      | 'input'
      | 'label'
      | 'subtitle'
      | 'title-block'
      | 'title'
    >;
    radioButton?: OptionalDictionary<
      'add-icon' | 'content' | 'icon' | 'radio-card-button' | 'title'
    >;
  };
  creditCards: CreditCard[];
  handleShowAddCardModal: VoidFunction;
  iconColor?: string;
  addIconColor?: string;
  isApplePayAvailable: boolean;
  isGooglePayAvailable: boolean;
  isLoading: boolean;
  onSelectPaymentMethod: (paymentMethod: string) => void;
  selectedPaymentMethod?: string;
  showCreditCardOptions?: boolean;
  withCheckmark?: boolean;
}

const applePayConfig = PAYMENT_TYPES_CONFIG[CARD_TYPES.APPLEPAY];
const googlePayConfig = PAYMENT_TYPES_CONFIG[CARD_TYPES.GOOGLEPAY];

function getCreditCardIcons(): (React.JSX.Element | undefined)[] {
  return map(
    omit(PAYMENT_TYPES_CONFIG, NON_TOKEN_PAYMENT_TYPES),
    (config, key) => {
      if (!config.icon) {
        return undefined;
      }

      return <React.Fragment key={key}>{config.icon}</React.Fragment>;
    }
  ).filter(Boolean);
}

function PaymentMethods({
  addCardTitle,
  adjustedTotal,
  classNameList,
  creditCards,
  handleShowAddCardModal,
  iconColor = colors.white,
  addIconColor = colors.white,
  isApplePayAvailable,
  isGooglePayAvailable,
  isLoading,
  onSelectPaymentMethod,
  selectedPaymentMethod,
  showCreditCardOptions = false,
  withCheckmark = true,
}: Props) {
  if (isLoading) {
    return <Spinner style={{ height: 120 }} />;
  }

  const showAffirmPay = adjustedTotal >= 50 && adjustedTotal < 20000;
  if (!showAffirmPay && selectedPaymentMethod === PURCHASE_TYPE.AFFIRM_PAY) {
    onSelectPaymentMethod(PURCHASE_TYPE.CREDIT_CARD_ON_FILE);
  }

  return (
    <RadioGroup
      className={classNameList?.component?.container}
      name="payment-method"
      onChange={onSelectPaymentMethod}
      value={selectedPaymentMethod}
    >
      {isApplePayAvailable && (
        <RadioCard
          classNameList={classNameList?.radio}
          icon={applePayConfig.icon}
          title={applePayConfig.displayName}
          value={`${PURCHASE_TYPE.APPLE_PAY}:${CARD_TYPES.APPLEPAY}`}
          withCheckmark={withCheckmark}
        />
      )}
      {isGooglePayAvailable && (
        <RadioCard
          classNameList={classNameList?.radio}
          icon={
            <img
              className={classNameList?.component?.['google-pay-icon']}
              height="18px"
              style={{ borderRadius: 9 }}
              alt="Google Pay"
              src={GooglePayLogo}
            />
          }
          title={googlePayConfig.displayName}
          value={`${PURCHASE_TYPE.GOOGLE_PAY}:${CARD_TYPES.GOOGLEPAY}`}
          withCheckmark={withCheckmark}
        />
      )}
      {creditCards.map((card) => {
        if (!card.card_type) {
          return null;
        }

        const cardConfig = PAYMENT_TYPES_CONFIG[card.card_type];
        return (
          <RadioCard
            classNameList={classNameList?.radio}
            icon={cardConfig.icon}
            key={card.token}
            subtitle={
              <span
                className={classNames(
                  styles['card-exp'],
                  classNameList?.component?.['card-exp']
                )}
              >
                Expires {card.expiration_month}/{card.expiration_year}
              </span>
            }
            title={
              <span
                className={classNames(
                  styles['card-desc'],
                  classNameList?.component?.['card-desc']
                )}
              >
                {cardConfig.displayName}
                <span
                  className={classNames(
                    styles.star,
                    classNameList?.component?.star
                  )}
                />
                <span
                  className={classNames(
                    styles.lastfour,
                    classNameList?.component?.lastfour
                  )}
                >
                  {card.last_four}
                </span>
              </span>
            }
            value={`${PURCHASE_TYPE.CREDIT_CARD_ON_FILE}:${card.token}`}
            withCheckmark={withCheckmark}
          />
        );
      })}
      {showAffirmPay && (
        <RadioCard
          classNameList={classNameList?.radio}
          icon={
            <span
              className={classNames(
                styles['affirm-icon'],
                classNameList?.component?.['affirm-icon']
              )}
            >
              <img src={AffirmLogoBlueCircle} alt="Affirm Logo" />
            </span>
          }
          subtitle={
            <span
              className={classNames(
                styles['affirm-promo-message'],
                classNameList?.component?.['affirm-promo-message']
              )}
            >
              <AffirmPromoMessage total={adjustedTotal} />
            </span>
          }
          title="Pay Over Time"
          value={PURCHASE_TYPE.AFFIRM_PAY}
          withCheckmark={withCheckmark}
        />
      )}
      <RadioCard
        classNameList={classNameList?.radio}
        icon={
          <PayPalCardIcon
            className={classNameList?.component?.['paypal-icon']}
          />
        }
        title="Pay with PayPal"
        value={PURCHASE_TYPE.PAYPAL_PAY}
        withCheckmark={withCheckmark}
      />
      <RadioCardButton
        addIconColor={addIconColor}
        classNameList={classNameList?.radioButton}
        icon={
          showCreditCardOptions ? (
            getCreditCardIcons()
          ) : (
            <CreditCardFrontFillIcon fill={iconColor} />
          )
        }
        onClick={handleShowAddCardModal}
        title={addCardTitle}
      />
    </RadioGroup>
  );
}

export default PaymentMethods;
