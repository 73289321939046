import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Outlet, redirect } from 'react-router-dom';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import { InsuranceProvider } from 'contexts/InsuranceContext';
import withNavigationProps from 'hoc/withNavigationProps';
import withRouter from 'hoc/withRouter';
import _throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  FullEventClickTracker,
  Hover,
  HoverTracker,
  ListingClickTracker,
  mapListingTrackingData,
  NoListingsFound,
  PAYLOAD,
  PerformerClickTracker,
  PinsRendered,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
  ZoomEvent,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import DealsSlider from 'components/Deals/DealsSlider/DealsSlider';
import {
  hasSeenSBModal,
  isListingZoneDeal,
  setSBModalSeen,
} from 'components/Deals/helpers';
import EnsurePath from 'components/EnsurePath/EnsurePath';
import PreloadedImage from 'components/Head/PreloadedImage';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import { SUGGESTED_SEAT_COUNT_NOTIFICATION } from 'components/Notifications/constants';
import { showNotification } from 'components/Notifications/Notifications';
import {
  selectIsGalleryViewV3Experiment,
  selectIsWebExclusivesV3Experiment,
} from 'experiments';
import { mqSize } from 'hooks/useMediaQuery';
import { FullEvent } from 'models';
import Listing from 'models/Listing';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { GalleryViewCard } from 'pages/Event/components/GalleryView';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import OmnibarOptions from 'pages/Event/components/OmnibarOptions/OmnibarOptions';
import NotFound from 'pages/NotFound/NotFound';
import { REDIRECT_PERMANENT_STATUS } from 'server/redirects/constants';
import {
  currentLocationSelector,
  setServerRedirectPath,
  showAppSpinner,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import {
  isGTPicksFilterSelector,
  setGTPicksFilter,
} from 'store/modules/app/app.ui';
import {
  eventsPageDataSelector,
  updateEventsPageData,
} from 'store/modules/data/eventsPageData';
import {
  fetchFullEventById,
  fetchFullEventsByPrimaryPerformerId as fetchFullEventsByPrimaryPerformerIdDispatch,
} from 'store/modules/data/FullEvents/actions';
import {
  selectFullEventById,
  selectFullEventsByPrimaryPerformerId,
} from 'store/modules/data/FullEvents/selectors';
import {
  setHoveredListingId,
  updateMapHarmony as updateMapHarmonyDispatch,
} from 'store/modules/data/Listings/actions';
import {
  hoveredListingIdSelector,
  selectIsVenueAllInPrice,
} from 'store/modules/data/Listings/selectors';
import { makeGetSelectPerformersByCategory } from 'store/modules/data/Performers/selectors';
import { setNewViewedEvent } from 'store/modules/data/Search/actions';
import { selectSearchTestData } from 'store/modules/data/Search/selectors';
import {
  isBuyRoute,
  isCheckoutPage,
  isEventPage,
  isEventPagePreListings,
  isEventSubRoute,
  isListingDetailsPage,
  isListingPage,
  isPerformerPage,
  shouldShowListingDetailsOverlay,
} from 'store/modules/history/history';
import {
  fetchListingsV3,
  setZoomLevel as setZoomLevelDispatch,
} from 'store/modules/listingsV3/actions';
import {
  selectAvailableLots,
  selectDisplayedListingV3Deals,
  selectDisplayedV3Listings,
  selectHasInventory,
  selectHighestPriceListingV3,
  selectIsAllInPricing,
  selectIsListMapHarmonyEnabled,
  selectListingById,
  selectListingQuantity,
  selectListingsParams,
  selectListingV3DisplayGroups,
  selectZoomLevel,
} from 'store/modules/listingsV3/selectors';
import { DEFAULT_LISTING_QUANTITY } from 'store/modules/listingsV3/utils';
import { MODALS, showModal } from 'store/modules/modals/modals';
import {
  fetchDeals,
  fetchDisclosures,
  fetchMetros,
} from 'store/modules/resources/resource.actions';
import { getPerformerPath } from 'store/modules/resources/resource.paths';
import {
  allDisclosuresSelector,
  selectAllDeals,
} from 'store/modules/resources/resource.selectors';
import { fetchUserPromoCodesForListing } from 'store/modules/user/actions';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import {
  getPreferenceSortOrder,
  SORT_IDS,
  SORT_ORDER,
  userPreferenceSeatCountSelector,
  userPreferenceShowAllInPriceSelector,
  userPreferredSortIdSelector,
} from 'store/modules/userPreference/user.preference.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { MOBILE_VIEW } from 'types/event';
import { formatDate, isPastDate } from 'utils/datetime';
import { isSuperBowl } from 'utils/superBowl';
import { addQuery } from 'utils/url';

import { EventProvider } from '../../contexts/EventContext';
import {
  VariantContextPropType,
  withVariantContext,
} from '../../services/variants';

import EventBar from './components/EventBar';
import EventHeader from './components/EventHeader';
import EventMeta from './components/EventMeta';
import InvalidEvent from './components/InvalidEvent';
import ListingDetails from './components/ListingDetailContainer/ListingDetails';
import ListingsMapView from './components/ListingsMapView/ListingsMapView';
import { MAP_EVENT_TYPES } from './components/ListingsMapView/SeatMap.constants';
import MapListSwitch from './components/MapListSwitch';
import NoInventory from './components/NoInventory';
import { EVENTBAR_VIEWS } from './Event.constants';
import { getLastRoute, getPageViewType, isCanadianProvince } from './helpers';

import styles from './Event.module.scss';

const isPurchaseRoute = (pathname = '') => pathname.includes('buy');

const isListingRoute = (props) => {
  const currentPath = props.location.pathname;
  return currentPath.includes('listings');
};

const propTypes = {
  children: PropTypes.node,
  fullEvent: PropTypes.instanceOf(FullEvent),
  displayedListings: PropTypes.arrayOf(PropTypes.instanceOf(Listing))
    .isRequired,
  listingsInDisplayGroups: PropTypes.arrayOf(
    PropTypes.shape({
      deal: PropTypes.string,
      slug: PropTypes.string.isRequired,
      sort_order: PropTypes.number.isRequired,
      title: PropTypes.string,
      type: PropTypes.oneOf(['carousel', 'list', 'deals_list']).isRequired,
      listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)).isRequired,
    })
  ).isRequired,
  listing: PropTypes.instanceOf(Listing),
  schedule: PropTypes.array,
  user: PropTypes.object,
  quantity: PropTypes.number.isRequired,
  showFullHeader: PropTypes.bool,
  sortId: PropTypes.string,
  showSuggestedSeatCount: PropTypes.bool,
  updateUserPreference: PropTypes.func,
  availableSeatCounts: PropTypes.array,
  location: PropTypes.object.isRequired,
  performersByCategory: PropTypes.array,
  noInventory: PropTypes.bool,
  query: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  zoomLevel: PropTypes.number,
  getListings: PropTypes.func.isRequired,
  isAllInPriceActive: PropTypes.bool,
  showAllInPrice: PropTypes.bool,
  showSuperBowlModal: PropTypes.bool.isRequired,
  fetchFullEventsByPrimaryPerformerId: PropTypes.func,
  updateMapHarmony: PropTypes.func,
  isListMapHarmonyEnabled: PropTypes.bool.isRequired,
  setHoveredListingId: PropTypes.func,
  hoveredListingId: PropTypes.string,
  dealsTracking: PropTypes.shape({
    dealTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  eventPageData: PropTypes.shape({
    eventPageRequestsStartTime: PropTypes.number,
    eventPageRequestsFinishTime: PropTypes.number,
  }),
  appContext: PropTypes.shape({
    state: PropTypes.shape({
      isMobile: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  analyticsContext: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  searchTestData: PropTypes.shape({
    testId: PropTypes.string,
    variantId: PropTypes.string,
  }),
  router: PropTypes.object.isRequired,
  lastRouteLocation: PropTypes.object,
  setServerRedirectPath: PropTypes.func.isRequired,
  isWebExclusivesV3Experiment: PropTypes.bool.isRequired,
  isGTPicksFilterActive: PropTypes.bool.isRequired,
  setGTPicksFilter: PropTypes.func.isRequired,
  isGalleryViewV3Experiment: PropTypes.bool.isRequired,
  mapCarouselDisplayGroup: PropTypes.shape({
    deal: PropTypes.string,
    slug: PropTypes.string.isRequired,
    sort_order: PropTypes.number.isRequired,
    title: PropTypes.string,
    type: PropTypes.oneOf(['carousel', 'list', 'deals_list']).isRequired,
    listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)).isRequired,
  }),
  listingsRequestParams: PropTypes.shape({
    sort_order: PropTypes.string,
    deals_filter: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    eventId: PropTypes.string.isRequired,
    all_in_pricing: PropTypes.bool.isRequired,
    zoom: PropTypes.number.isRequired,
  }).isRequired,
  setZoomLevel: PropTypes.func.isRequired,
  showAppSpinner: PropTypes.func.isRequired,
  variantContext: VariantContextPropType,
};

const mapPropsToPathname = (props) => {
  const { fullEvent } = props;

  if (!fullEvent || !fullEvent.isValid()) {
    return null;
  }

  if (isListingRoute(props)) {
    return location.pathname;
  }

  return fullEvent.getPath();
};

@withAppContext
@EnsurePath(mapPropsToPathname)
@TrackPageView(
  ({
    fullEvent,
    listing,
    quantity,
    sortId,
    query,
    isListMapHarmonyEnabled,
    algoliaFields,
    dealsTracking,
    searchTestData,
    ...props
  }) => {
    const { isMobile } = props.appContext.state;
    const mode = getPageViewType({
      query,
      isMobile,
      isGalleryViewV3Experiment: props.isGalleryViewV3Experiment,
    });

    return View.PAGE_TYPES.EVENT({
      fullEvent,
      listing,
      quantity,
      sort: sortId,
      isListingRoute: isListingRoute(props),
      mode,
      dealsTracking,
      payload: {
        harmony_enabled: isListMapHarmonyEnabled,
        [PAYLOAD.QUERY_ID]: algoliaFields?.queryId,
        [PAYLOAD.RESULT_POSITION]: algoliaFields?.resultPosition,
        [PAYLOAD.SEARCH_INDEX]: algoliaFields?.searchIndex,
        [PAYLOAD.SEARCH_SESSION_ID]: algoliaFields?.searchSessionId,
        [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
        [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
      },
    });
  }
)
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.EVENT(),
}))
@withAnalyticsContext
class EventPage extends Component {
  isFirstUpdate = true;
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    const { fullEvent } = props;
    this.goBackEventRoutes = this.goBackEventRoutes.bind(this);
    this.goToPerformer = this.goToPerformer.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleListingSelection = this.handleListingSelection.bind(this);
    this.handlePinHover = this.handlePinHover.bind(this);
    this.handleListingHover = this.handleListingHover.bind(this);
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleOmnibarOptionsClose = this.handleOmnibarOptionsClose.bind(this);
    this.openSuperBowlModal = this.openSuperBowlModal.bind(this);
    this.handleOmnibarControls = this.handleOmnibarControls.bind(this);
    this.handleListingsTouchStart = this.handleListingsTouchStart.bind(this);
    this.handleListingsTouchMove = this.handleListingsTouchMove.bind(this);
    this.handleZoomLevelChange = this.handleZoomLevelChange.bind(this);
    this.handleEventUpdate = this.handleEventUpdate.bind(this);
    this.throttledPusherFetch = _throttle(this.handleEventUpdate, 10000);
    this.handleMobileViewChange = this.handleMobileViewChange.bind(this);
    this.handleWindowFocus = this.handleWindowFocus.bind(this);
    this.handleWindowBlur = this.handleWindowBlur.bind(this);
    this.initiateCheckoutFlow = this.initiateCheckoutFlow.bind(this);
    this.navigateToNextPath = this.navigateToNextPath.bind(this);
    this.handleMapInteraction = this.handleMapInteraction.bind(this);
    this.handleListMapHarmonyToggleTracking =
      this.handleListMapHarmonyToggleTracking.bind(this);
    this.handleListingClose = this.handleListingClose.bind(this);
    this.handleClearGalleryViewMobile =
      this.handleClearGalleryViewMobile.bind(this);
    this.handleTouchInteractionStart =
      this.handleTouchInteractionStart.bind(this);
    this.handleTouchInteractionEnd = this.handleTouchInteractionEnd.bind(this);

    let sidebarView = isListingRoute(props)
      ? EVENTBAR_VIEWS.LISTING_PURCHASE
      : EVENTBAR_VIEWS.LISTINGS;

    if (fullEvent && !fullEvent.isValid()) {
      sidebarView = EVENTBAR_VIEWS.EXPIRED;
    }

    this.state = {
      showOmnibarModal: false,
      sidebarView,
      displayKey: null,
      isScrollingToTop: true,
      galleryViewListing: null,
      isMobileMapAnimating: false,
    };
  }

  shouldSetViewedEvent() {
    const id = this.props.fullEvent?.event?.id || null;
    if (id) {
      setNewViewedEvent(id);
    }
  }

  componentDidMount() {
    const {
      showSuggestedSeatCount,
      noInventory,
      fullEvent,
      lastRouteLocation,
      location,
      showSuperBowlModal,
      fetchFullEventsByPrimaryPerformerId,
      mapCarouselDisplayGroup,
      isGalleryViewV3Experiment,
      listingsRequestParams,
    } = this.props;

    if (noInventory) {
      this.props.getListings(listingsRequestParams);
    }

    if (
      showSuggestedSeatCount &&
      isEventPage(location.pathname) &&
      !noInventory &&
      !fullEvent?.isParkingEvent()
    ) {
      showNotification(SUGGESTED_SEAT_COUNT_NOTIFICATION);
      this.props.updateUserPreference({ seatCount: this.props.quantity });
    }

    this.shouldSetViewedEvent();
    this.prevListingsTouchPageY = null;
    if (typeof window !== 'undefined' && fullEvent) {
      const channel = window.pusher?.subscribe(fullEvent.id);
      channel?.bind('event_update', this.throttledPusherFetch);

      window.addEventListener('focus', this.handleWindowFocus);
      window.addEventListener('blur', this.handleWindowBlur);
    }
    const lastRoute = getLastRoute(lastRouteLocation);

    if (
      isEventPage(location.pathname) &&
      lastRoute &&
      typeof window !== 'undefined'
    ) {
      window.sessionStorage.setItem('GTRootRoute', JSON.stringify(lastRoute));
    }

    if (typeof window !== 'undefined' && showSuperBowlModal) {
      // requires localstorage
      setSBModalSeen(fullEvent?.id);
      // disabling for now, will be re-enabled when we have a new Super Bowl event
      // this.props.showModal(MODALS.SUPER_BOWL);
    }

    if (fullEvent) {
      fetchFullEventsByPrimaryPerformerId(fullEvent.getPrimaryPerformer().id);
    }

    // If there's only one listing in the map view carousel, we should show it as focused card instead
    if (
      isGalleryViewV3Experiment &&
      mapCarouselDisplayGroup &&
      mapCarouselDisplayGroup.listings.length === 1
    ) {
      this.setState({
        galleryViewListing: mapCarouselDisplayGroup.listings[0],
        isMobileMapAnimating: false,
      });
    }
  }

  // Attempts to get user's root route due to issues w/ deep linking and router implementation
  getRootRoute() {
    if (typeof window === 'undefined') return '/';
    const stored = window.sessionStorage.getItem('GTRootRoute');
    if (!stored) {
      return '/';
    }
    return JSON.parse(stored);
  }

  handleEventUpdate() {
    const { location, getListings, listingsRequestParams } = this.props;

    if (isEventPage(location.pathname)) {
      // no await needed, we want this to run in the background
      getListings(listingsRequestParams);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined' && window.pusher) {
      window.pusher.allChannels().forEach((channel) => {
        window.pusher.unsubscribe(channel.name);
      });
    }

    this.throttledPusherFetch.cancel();

    this.props.setGTPicksFilter(false);

    if (typeof window !== 'undefined') {
      window.removeEventListener('focus', this.handleWindowFocus);
      window.removeEventListener('blur', this.handleWindowBlur);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      noInventory,
      sortId,
      fullEvent,
      fetchFullEventsByPrimaryPerformerId,
      showSuggestedSeatCount,
      analyticsContext,
      location,
      listing,
      isGTPicksFilterActive,
      appContext,
      isGalleryViewV3Experiment,
      mapCarouselDisplayGroup,
    } = this.props;

    if (!fullEvent || !isEventSubRoute(location.pathname)) return;

    const isDifferentEventId =
      prevProps.fullEvent && fullEvent.id !== prevProps.fullEvent.id;

    const isDifferentQuantity = this.props.quantity !== prevProps.quantity;

    // TODO: handle in the loader
    if (isDifferentEventId) {
      if (isGTPicksFilterActive) {
        this.props.setGTPicksFilter(false);
      }
    }

    if (
      prevProps.fullEvent?.getPrimaryPerformer().id !==
      fullEvent.getPrimaryPerformer().id
    ) {
      fetchFullEventsByPrimaryPerformerId(fullEvent.getPrimaryPerformer().id);
    }

    if (
      showSuggestedSeatCount &&
      isEventPage(prevProps.location.pathname) &&
      !noInventory &&
      !fullEvent.isParkingEvent() &&
      isDifferentEventId
    ) {
      showNotification(SUGGESTED_SEAT_COUNT_NOTIFICATION);
      this.props.updateUserPreference({ seatCount: this.props.quantity });
    }

    if (isDifferentEventId && typeof window !== 'undefined' && window.pusher) {
      window.pusher.unsubscribe(prevProps.fullEvent.id);
      const channel = window.pusher.subscribe(fullEvent.id);
      channel?.bind('event_update', this.throttledPusherFetch);
    }

    const isListing = isListingRoute(this.props);
    const isInValid = !fullEvent.isValid();

    let sidebarView = isListing
      ? EVENTBAR_VIEWS.LISTING_PURCHASE
      : EVENTBAR_VIEWS.LISTINGS;

    if (isInValid) {
      sidebarView = EVENTBAR_VIEWS.EXPIRED;
    }

    if (
      sidebarView !== this.state.sidebarView &&
      (sidebarView !== EVENTBAR_VIEWS.LISTING_PURCHASE ||
        (sidebarView === EVENTBAR_VIEWS.LISTING_PURCHASE && listing))
    ) {
      this.setState({ sidebarView });
    }

    if (
      isEventPage(prevProps.location.pathname) &&
      noInventory &&
      (this.isFirstUpdate || isDifferentEventId)
    ) {
      const event = new Date(fullEvent.event.datetimeLocal);

      if (!isPastDate(event)) {
        const formattedDate = formatDate(event, 'M/d/yy');
        analyticsContext.track(
          new NoListingsFound({
            performer_name: fullEvent.getPrimaryPerformer().name,
            event_date: formattedDate,
          })
        );
      }
    }

    if (sortId !== prevProps.sortId || isDifferentEventId) {
      this.shouldSetViewedEvent();
    }

    if (appContext.state.isMobile && isDifferentEventId) {
      this.handleClearGalleryViewMobile();
    }

    if (
      isGalleryViewV3Experiment &&
      isDifferentQuantity &&
      mapCarouselDisplayGroup &&
      mapCarouselDisplayGroup.listings.length === 1
    ) {
      this.setState({
        galleryViewListing: mapCarouselDisplayGroup.listings[0],
        isMobileMapAnimating: false,
      });
    }

    if (this.isFirstUpdate) {
      this.isFirstUpdate = false;
    }
  }

  getBackClickTracker() {
    const { fullEvent, query } = this.props;

    if (query && query.selector) {
      return;
    }

    if (isListingRoute(this.props)) {
      return new FullEventClickTracker(fullEvent);
    }

    return new PerformerClickTracker(fullEvent.getPrimaryPerformer());
  }

  openSuperBowlModal() {
    this.props.showModal(MODALS.SUPER_BOWL);
  }

  goToListingDetails(listing) {
    const { fullEvent, location } = this.props;

    const searchParams = new URLSearchParams(location.search);

    if (isListingPage(location.pathname)) {
      this.props.router.navigate(
        {
          pathname: listing.getPath(fullEvent),
          search: searchParams.toString(),
        },
        { replace: true }
      );
    } else {
      this.props.router.navigate({
        pathname: listing.getPath(fullEvent),
        search: searchParams.toString(),
      });
    }
  }

  goToPerformer() {
    const { fullEvent, lastRouteLocation } = this.props;
    const lastRoute = getLastRoute(lastRouteLocation);
    const canGoBack = isPerformerPage(lastRoute);
    if (canGoBack) {
      this.props.router.navigate(-1);
    } else {
      this.props.router.navigate(fullEvent.getPrimaryPerformer().getPath());
    }
  }

  handleMapHarmony() {
    const {
      updateMapHarmony,
      isListMapHarmonyEnabled,
      appContext: {
        state: { isMobile },
      },
    } = this.props;
    const isDeviceLargeUp =
      typeof window !== 'undefined' && window.innerWidth >= mqSize.lg;

    if (!isMobile && isListMapHarmonyEnabled && isDeviceLargeUp) {
      updateMapHarmony();
    }
  }

  goBackEventRoutes(steps = 1) {
    const { analyticsContext } = this.props;
    const clickTracker = this.getBackClickTracker();

    analyticsContext.track(new Click(clickTracker.json()));

    this.props.router.navigate(-steps);
  }

  handleListingSelectionTracking(listing, tracking) {
    const { zoomLevel, analyticsContext } = this.props;
    const { isAllInPriceActive, price, listingIndex, isListingOverlay } =
      tracking;
    const listingSelectionTracker = new ListingClickTracker(
      listing,
      isAllInPriceActive,
      price,
      isListingOverlay
    )
      .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT())
      .interaction(Click.INTERACTIONS.SEAT_MAP_PIN())
      .payload({
        item_index: listingIndex,
        zoom_level: zoomLevel,
        ...mapListingTrackingData(listing),
      });

    analyticsContext.track(new Click(listingSelectionTracker.json()));
  }

  handleListingSelection(seatMapPin) {
    const {
      setHoveredListingId,
      appContext: {
        state: { isMobile },
      },
    } = this.props;
    const { listing, tracking } = seatMapPin;
    this.handleListingSelectionTracking(listing, tracking);
    const isGalleryListingFirstClick =
      this.state.galleryViewListing?.id !== listing.id;

    if (isMobile) {
      if (isGalleryListingFirstClick) {
        this.setState({
          galleryViewListing: listing,
          isMobileMapAnimating: false,
        });
        return;
      }
      this.setState({
        isMobileMapAnimating: false,
      });
    }

    setHoveredListingId(listing.id);
    this.goToListingDetails(listing);
  }

  handleBack() {
    const {
      query,
      location: { pathname },
      appContext: {
        state: { isMobile },
      },
      lastRouteLocation,
      analyticsContext,
    } = this.props;
    const lastRoute = getLastRoute(lastRouteLocation);

    const { view = MOBILE_VIEW.LIST } = query;

    const clickTracker = this.getBackClickTracker();

    analyticsContext.track(new Click(clickTracker.json()));

    if (isMobile) {
      this.handleClearGalleryViewMobile();
    }

    if (isEventPage(pathname) && isListingPage(lastRoute)) {
      return this.props.router.navigate(this.getRootRoute());
    }

    if (query && query.selector) {
      return this.props.router.navigate({
        pathname,
      });
    }

    if (view === MOBILE_VIEW.MAP) {
      return this.props.router.navigate(pathname, { replace: true });
    }

    return this.props.router.navigate(-1);
  }

  async handleChangeQuantity(quantity) {
    const { updateUserPreference, listingsRequestParams, appContext } =
      this.props;

    updateUserPreference({ seatCount: quantity });

    this.props.showAppSpinner(true);
    await this.props.getListings({ ...listingsRequestParams, quantity });
    this.props.showAppSpinner(false);

    // TODO: filter option control should be moved to the event header
    // components with the upcoming refactor
    this.handleOmnibarOptionsClose();

    if (appContext.state.isMobile) {
      setTimeout(() => this.validateGalleryViewListing(), 500);
    }
  }

  async handleSortChange(sortId) {
    const { listingsRequestParams, updateUserPreference } = this.props;
    updateUserPreference({ sortId });
    // TODO: fix sort order options, this function is receiving a label but
    // should be receiving a sort order key
    const sortIdToSortOrder = {
      [SORT_IDS.DISCOUNT]: [SORT_ORDER.DISCOUNT],
      [SORT_IDS.PRICEL]: [SORT_ORDER.PRICEL],
      [SORT_IDS.PRICEH]: [SORT_ORDER.PRICEH],
    };

    this.props.showAppSpinner(true);
    await this.props.getListings({
      ...listingsRequestParams,
      sort_order: sortIdToSortOrder[sortId],
    });
    this.props.showAppSpinner(false);

    this.handleOmnibarOptionsClose();
  }

  handleAllInPrice(all_in_pricing) {
    const { updateUserPreference, getListings, listingsRequestParams } =
      this.props;

    updateUserPreference({ showAllInPrice: all_in_pricing });
    getListings({ ...listingsRequestParams, all_in_pricing });
  }

  handleGTPicksFilter() {
    this.props.setGTPicksFilter(!this.props.isGTPicksFilterActive);
  }

  /**
   * TODO: this functionality should be moved into a selector and handled when
   * data actually changes, not called by event handlers when data is expected
   * to change...
   */
  validateGalleryViewListing() {
    const { displayedListings } = this.props;
    const { galleryViewListing } = this.state;
    if (!galleryViewListing) return;

    if (
      !displayedListings.some((listing) => listing.id === galleryViewListing.id)
    ) {
      this.handleClearGalleryViewMobile();
    }
  }

  handleOmnibarControls(id, params) {
    const {
      query,
      appContext: {
        state: { isMobile },
      },
      fullEvent,
      analyticsContext,
      isGalleryViewV3Experiment,
    } = this.props;
    const { displayKey } = this.state;
    const isActive = displayKey !== id;
    const pageViewType = getPageViewType({
      query,
      isMobile,
      isGalleryViewV3Experiment,
    });

    if (id === 'allInPricing') {
      const allInTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.ALL_IN_FILTER(), {
          event_id: fullEvent.id,
          action: params.isAllInPriceActive ? 'enable' : 'disable',
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(allInTracker.json()));

      return this.handleAllInPrice(params.isAllInPriceActive);
    }

    if (id === 'gtPicksFilter') {
      const gtPicksTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.GT_PICKS_FILTER(), {
          event_id: fullEvent.id,
          action: params.isGTPicksFilterActive ? 'enable' : 'disable',
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(gtPicksTracker.json()));

      return this.handleGTPicksFilter();
    }

    if (isActive) {
      const omniTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.OMNIBAR(false), {
          event_id: fullEvent.id,
          quantity: this.props.quantity,
          sort: this.props.sortId,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(omniTracker.json()));
    }

    this.setState({
      showOmnibarModal: isActive,
      displayKey: isActive ? id : null,
    });
  }

  handleOmnibarOptionsClose(isCancel = false) {
    const { displayKey } = this.state;
    const { fullEvent, analyticsContext } = this.props;

    if (isCancel) {
      const omniTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.CANCEL())
        .sourcePageType(Click.SOURCE_PAGE_TYPES.OMNIBAR_PAGES(displayKey))
        .targetPageType(Click.TARGET_PAGE_TYPES.EVENT(fullEvent.id));

      analyticsContext.track(new Click(omniTracker.json()));
    }

    this.setState({ showOmnibarModal: false, displayKey: null });
  }

  handlePinHover({ listing, tracking }) {
    const {
      query,
      fullEvent,
      isAllInPriceActive,
      zoomLevel,
      analyticsContext,
      isGalleryViewV3Experiment,
    } = this.props;
    const pageViewType = getPageViewType({
      query,
      isMobile: false,
      isGalleryViewV3Experiment,
    });
    const tracker = new HoverTracker()
      .interaction(
        Hover.INTERACTIONS.HOVER_CARDS({
          eventId: fullEvent.id,
          listingId: listing.id,
          allInPricing: isAllInPriceActive,
          displayedPrice: tracking.price,
        })
      )
      .payload({
        [PAYLOAD.ITEM_INDEX]: tracking.listingIndex,
        [PAYLOAD.ZOOM_LEVEL]: zoomLevel,
        ...mapListingTrackingData(listing),
      })
      .sourcePageType(
        Hover.SOURCE_PAGE_TYPES.EVENT({
          pageViewType,
          isListingDetails: tracking.isListingDetails,
        })
      );
    analyticsContext.track(new Hover(tracker.json()));
  }

  handleListingHover(hoveredListing) {
    const {
      appContext: {
        state: { isMobile },
      },
      setHoveredListingId,
      hoveredListingId,
    } = this.props;

    // Don't update the state if the user hightlights over the same listing
    if (hoveredListingId === hoveredListing.id) {
      return;
    }

    if (!isMobile) {
      setHoveredListingId(hoveredListing?.id);
    }
  }

  handleListingsTouchStart(e) {
    this.prevListingsTouchPageY = e.touches[0].pageY;
  }

  handleListingsTouchMove(e) {
    const { isScrollingToTop: prevIsScrollingToTop } = this.state;
    const nextIsScrollingToTop =
      this.prevListingsTouchPageY < e.touches[0].pageY;
    if (prevIsScrollingToTop !== nextIsScrollingToTop) {
      this.setState({
        isScrollingToTop: nextIsScrollingToTop,
      });
    }
    this.prevListingsTouchPageY = e.touches[0].pageY;
  }

  isCheckout(pathname) {
    return (
      pathname.endsWith('checkout') ||
      pathname.endsWith('checkout/') ||
      pathname.includes('checkout/login')
    );
  }

  getMobileEventView() {
    const defaultView =
      this.props.isGalleryViewV3Experiment && !this.props.noInventory
        ? MOBILE_VIEW.MAP
        : MOBILE_VIEW.LIST;
    const view = this.props.query?.view?.toLowerCase() ?? defaultView;
    return {
      type: view,
      isList: view === MOBILE_VIEW.LIST,
      isMap: view === MOBILE_VIEW.MAP,
    };
  }

  updateMobileEventView({ view, withoutHistory = false }) {
    const { fullEvent, location } = this.props;
    const newRoute = addQuery(fullEvent.getPath(), location.search, {
      view,
    });
    if (withoutHistory) {
      this.props.router.navigate(newRoute, { replace: true });
    } else {
      this.props.router.navigate(newRoute);
    }
  }

  handleMobileViewChange() {
    const mobileEventView = this.getMobileEventView();
    if (mobileEventView.isList) {
      if (
        this.props.appContext.state.isMobile &&
        this.state.galleryViewListing
      ) {
        this.handleClearGalleryViewMobile();
      }
      this.updateMobileEventView({
        view: MOBILE_VIEW.MAP,
        withoutHistory: true,
      });
    } else {
      this.updateMobileEventView({
        view: MOBILE_VIEW.LIST,
        withoutHistory: true,
      });
    }
  }

  handleTrackingClearGalleryViewMobile() {
    const {
      query,
      appContext: {
        state: { isMobile },
      },
      fullEvent,
      analyticsContext,
      isGalleryViewV3Experiment,
    } = this.props;

    const pageViewType = getPageViewType({
      query,
      isMobile,
      isGalleryViewV3Experiment,
    });
    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.FOCUSED_CARD_CANCEL(), {
        event_id: fullEvent.id,
      })
      .payload({
        listing_id: this.state.galleryViewListing?.id,
      })
      .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType));
    analyticsContext.track(new Click(tracker.json()));
  }

  handleClearGalleryViewMobile(track = false) {
    if (track) {
      this.handleTrackingClearGalleryViewMobile();
    }
    this.setState({ galleryViewListing: null, isMobileMapAnimating: false });
  }

  shouldPersistListings() {
    const {
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
    } = this.props;
    if (isMobile) return false;

    return (
      (isEventPagePreListings(location.pathname) ||
        isListingDetailsPage(location.pathname)) &&
      shouldShowListingDetailsOverlay(location, lastRouteLocation)
    );
  }

  renderInlineChildren() {
    const { children, listing, fullEvent, location, user, lastRouteLocation } =
      this.props;

    const childProps = {
      event: fullEvent,
    };

    if (!isListingRoute(this.props) || !listing) {
      return null;
    }

    const isDeviceLargeUp =
      typeof window !== 'undefined' && window.innerWidth >= mqSize.lg;

    return (
      <ListingDetails
        shouldAnimate={isDeviceLargeUp}
        handleTracking={this.getBackClickTracker()}
        onBack={this.goBackEventRoutes}
        listing={listing}
        user={user}
        fullEvent={fullEvent}
        childProps={childProps}
        isZoneDeal={isListingZoneDeal(listing)}
        isPurchaseRoute={isPurchaseRoute(location.pathname)}
        isCheckout={this.isCheckout(location.pathname)}
        isHarmonyPlusOverlay={
          isDeviceLargeUp &&
          shouldShowListingDetailsOverlay(location, lastRouteLocation)
        }
      >
        {children}
      </ListingDetails>
    );
  }

  renderSidebar(isCheckout) {
    const {
      fullEvent,
      listingsInDisplayGroups,
      schedule,
      sortId,
      noInventory,
      performersByCategory,
      allDisclosures,
      allDeals,
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
      isAllInPriceActive,
      isWebExclusivesV3Experiment,
    } = this.props;
    const { sidebarView } = this.state;
    const primaryPerformer = fullEvent.getPrimaryPerformer();

    if (noInventory && sidebarView === EVENTBAR_VIEWS.LISTINGS) {
      return <NoInventory primaryPerformer={primaryPerformer} />;
    }

    return (
      <EventBar
        key={fullEvent.id}
        onListingHover={this.handleListingHover}
        onListingsTouchStart={
          isMobile ? this.handleListingsTouchStart : () => {}
        }
        onListingsTouchMove={isMobile ? this.handleListingsTouchMove : () => {}}
        isCheckout={isCheckout}
        schedule={schedule}
        relatedPerformers={performersByCategory.slice(0, 10)}
        sortId={sortId}
        fullEvent={fullEvent}
        listingsInDisplayGroups={listingsInDisplayGroups}
        sidebarView={sidebarView}
        allDisclosures={allDisclosures}
        allDeals={allDeals}
        isHarmonyPlusOverlay={
          !isMobile &&
          shouldShowListingDetailsOverlay(location, lastRouteLocation)
        }
        isAllInPriceActive={isAllInPriceActive}
        isWebExclusivesV3Experiment={isWebExclusivesV3Experiment}
      >
        {this.renderInlineChildren()}
      </EventBar>
    );
  }

  /**
   * set zoom level when zoom changes and re-fetch listings when zoom level
   * increases to next step. once we reach max zoom (10), we will have all
   * curated listings and zoom level configurations.
   *
   * Right now we only expect this function to be called with zoom levels
   * 0, 5, 9, and 10. If we need to support more zoom levels, we will need to
   * update the map to emit events with the new zoom levels.
   *
   * @param {number} nextZoomLevel
   */
  async handleZoomLevelChange(nextZoomLevel) {
    const {
      getListings,
      listingsRequestParams,
      appContext,
      setZoomLevel,
      zoomLevel,
    } = this.props;

    if (nextZoomLevel === zoomLevel) {
      return;
    }

    if (nextZoomLevel > listingsRequestParams.zoom) {
      await getListings({ ...listingsRequestParams, zoom: nextZoomLevel });
    }

    setZoomLevel(nextZoomLevel);

    if (appContext.state.isMobile) {
      this.validateGalleryViewListing();
    }
  }

  mapTracking(seatMap) {
    const {
      fullEvent,
      isAllInPriceActive,
      quantity,
      displayedListings = [],
      isListMapHarmonyEnabled,
      eventPageData = {
        eventPageRequestsFinishTime: 0,
        eventPageRequestsStartTime: 0,
      },
      analyticsContext,
    } = this.props;

    // Exit early if there are no listings
    if (displayedListings.length < 1) return;

    // Exit early if the first listing is not the current event
    if (displayedListings[0].eventId !== fullEvent.id) return;

    const { zoomType, zoomLevel, interaction } = seatMap;
    const map_type = 'listing_pins';

    const baseTracking = {
      event_id: fullEvent.id,
      metro: fullEvent.venue.metro,
      performer_id: fullEvent.getPrimaryPerformer().id,
      venue_id: fullEvent.venue.id,
    };

    const commonTracking = {
      all_in_pricing: isAllInPriceActive,
      quantity,
      map_type,
    };

    const getListingsRendered = () =>
      displayedListings.map((listing) => ({
        price: isAllInPriceActive ? listing.totalPrice : listing.prefeePrice,
        id: listing.id,
      }));

    /**
     * @param page_load - initial map load tracking
     */
    if (interaction === 'page_load') {
      const renderedListings = getListingsRendered();
      const backendLatencyMs = Math.round(
        eventPageData.eventPageRequestsFinishTime -
          eventPageData.eventPageRequestsStartTime
      );
      const firstRenderMs = Math.round(
        seatMap.lastPinRenderMs - eventPageData.eventPageRequestsStartTime
      );
      const areaZoomTracking = {
        ...baseTracking,
        payload: {
          ...commonTracking,
          backend_latency_ms: backendLatencyMs,
          map_image_render_ms: seatMap.mapImageRenderMs,
          first_pin_render_ms: firstRenderMs,
          harmony_enabled: isListMapHarmonyEnabled,
          listings: renderedListings,
          listings_length: renderedListings.length,
        },
      };

      return analyticsContext.track(new PinsRendered(areaZoomTracking));
    }

    /**
     * @param seatMap - tracking: [click, double_click, wheel, drag, touch, reset]
     */
    const mapTracking = {
      ...baseTracking,
      payload: {
        timestamp: Date.now(),
        interaction,
        map_type,
        zoom_type: zoomType,
        zoom_level: zoomLevel,
        harmony_enabled: isListMapHarmonyEnabled,
      },
    };

    /**
     * @param pan - zoom_type: [drag]
     */
    if (interaction === MAP_EVENT_TYPES.drag) {
      mapTracking.payload.zoom_type = MAP_EVENT_TYPES.drag;
    }

    analyticsContext.track(new ZoomEvent(mapTracking));
  }

  handleTouchInteractionEnd() {
    this.setState({ isMobileMapAnimating: false });
  }

  handleMapInteraction(seatMap) {
    const { zoomLevel, zoomType } = seatMap;
    this.mapTracking(seatMap);

    if (zoomType) {
      this.handleZoomLevelChange(zoomLevel);
    }
  }

  handleWindowFocus() {
    const { fullEvent } = this.props;

    const channelExist = window.pusher?.channel(fullEvent.id);

    if (!channelExist) {
      this.handleEventUpdate();
      const channel = window.pusher?.subscribe(fullEvent.id);
      channel?.bind('event_update', this.throttledPusherFetch);
    }
  }

  handleWindowBlur() {
    const { fullEvent } = this.props;

    const channelExist = window.pusher?.channel(fullEvent.id);

    if (channelExist) {
      window.pusher.unsubscribe(fullEvent.id);
    }
  }

  initiateCheckoutFlow() {
    const { location } = this.props;
    let { pathname } = location;

    if (pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }

    setTimeout(() => {
      this.navigateToNextPath(`${pathname}/checkout`);
    }, 200);
  }

  navigateToNextPath(nextPath) {
    const { location } = this.props;

    this.props.router.navigate({
      pathname: nextPath,
      search: location.search,
    });
  }

  handleListMapHarmonyToggleTracking(listMapHarmonyToggleIsOn) {
    const { fullEvent, analyticsContext } = this.props;

    analyticsContext.track(
      new Click({
        interaction: listMapHarmonyToggleIsOn
          ? Click.INTERACTIONS.HARMONY_TOGGLE_ENABLED()
          : Click.INTERACTIONS.HARMONY_TOGGLE_DISABLED(),
        sourcePageType: Click.SOURCE_PAGE_TYPES.EVENT(),
        targetPageType: Click.TARGET_PAGE_TYPES.EVENT(fullEvent.id),
        metro: fullEvent.venue.metro,
      })
    );
  }

  handleTouchInteractionStart() {
    this.setState({ isMobileMapAnimating: true });
  }

  handleListingClose() {
    this.goBackEventRoutes(2);
  }

  renderPreloadedDealIcons() {
    const { allDeals } = this.props;

    if (!allDeals) {
      return null;
    }

    return Object.values(allDeals).map((deal) => {
      return (
        <PreloadedImage
          key={deal.slug}
          src={deal.iconURL}
          type="image/svg+xml"
        />
      );
    });
  }

  render() {
    const {
      displayedListings = [],
      listing,
      fullEvent,
      appContext: {
        state: { isMobile },
      },
      location: { pathname },
      showFullHeader,
      schedule,
      quantity,
      sortId,
      availableSeatCounts,
      performersByCategory,
      isAllInPriceActive,
      noInventory,
      hoveredListingId,
      setHoveredListingId,
      allDisclosures,
      allDeals,
      isGTPicksFilterActive,
      mapCarouselDisplayGroup,
      isGalleryViewV3Experiment,
    } = this.props;
    const {
      isScrollingToTop,
      sidebarView,
      displayKey,
      showOmnibarModal,
      galleryViewListing,
      isMobileMapAnimating,
    } = this.state;

    const isCheckoutRedesignExperiment = ![undefined, 'control'].includes(
      this.props.variantContext.getExperiment('checkout_v3').theme
    );

    if (!fullEvent) {
      return <NotFound />;
    }

    const isCheckout = isCheckoutPage(pathname);

    // Allows Checkout V3 to take full page and not render in Sidebar
    if (isCheckoutRedesignExperiment && (isCheckout || isBuyRoute(pathname))) {
      return (
        <InsuranceProvider listing={listing} event={fullEvent}>
          <Outlet context={{ listing, event: fullEvent }} />
        </InsuranceProvider>
      );
    }

    const mobileEventView = this.getMobileEventView();
    const isListingDetails = isListingRoute(this.props);
    const showSidebarControls = sidebarView === EVENTBAR_VIEWS.LISTINGS;
    const shouldRenderMap = !isMobile || mobileEventView.isMap;

    const isListingFlow = isListingDetailsPage(pathname);
    const isPostListingFlow = isCheckout || isBuyRoute(pathname);

    const persistListings = this.shouldPersistListings();
    const isValidMobileEventView =
      isMobile && fullEvent.isValid() && !noInventory && !isListingDetails;

    const isGalleryViewMobileListingPresent =
      !!galleryViewListing &&
      displayedListings.some((listing) => listing.id === galleryViewListing.id);

    const eventHeaderProps = {
      fullEvent,
      quantity,
      sortId,
      isAllInPriceActive,
      noInventory,
      view: mobileEventView.type,
      isListing: isListingDetails,
      persistListings,
      showSidebarControls,
      isPurchaseRoute: isPurchaseRoute(pathname),
      onBack: this.handleBack,
      handleBackTracking: this.getBackClickTracker(),
      handleOpenSBModal: this.openSuperBowlModal,
      handleOmnibarControls: this.handleOmnibarControls,
      isGTPicksFilterActive,
    };

    const minimalHeaderProps =
      (mobileEventView.isList || (mobileEventView.isMap && !isMobile)) &&
      showFullHeader
        ? {
            search: true,
            showCategories: true,
            showAccount: true,
            showHamburger: true,
            hideMobileHeader: isListingFlow && isMobile,
          }
        : { hideMobileHeader: true };

    const isAllInEvent = isCanadianProvince(fullEvent.venueState);
    const showRegulatoryAllInPricing = isAllInEvent && isListingDetails;
    const showSinglePin = (isListingDetails && isMobile) || isPostListingFlow;

    if (!fullEvent.isValid() && fullEvent.daysSinceExpiration >= 30) {
      const redirectPath = getPerformerPath(fullEvent.getPrimaryPerformer());
      this.props.setServerRedirectPath(redirectPath, REDIRECT_PERMANENT_STATUS);
    }

    const hasFloatingCarousel =
      isGalleryViewV3Experiment &&
      mobileEventView.isMap &&
      mapCarouselDisplayGroup &&
      mapCarouselDisplayGroup.listings.length > 1;

    const showMapListSwitch =
      !isMobileMapAnimating ||
      (isMobileMapAnimating && isGalleryViewV3Experiment);

    return (
      <EventProvider
        fullEvent={fullEvent}
        activeListing={listing}
        displayedListings={displayedListings}
      >
        <ContainerTemplate
          header={
            <MinimalHeader
              {...minimalHeaderProps}
              isEventPage
              hiddenByScroll={!isScrollingToTop}
            />
          }
          showFooter={false}
        >
          <EventMeta />
          {this.renderPreloadedDealIcons()}
          <div
            className={classNames(
              styles['event-page'],
              styles[mobileEventView.type],
              {
                [styles.listing]: isListingDetails,
              }
            )}
          >
            {!fullEvent.isValid() && (
              <div className={styles['fade-map-image']} />
            )}

            <EventHeader {...eventHeaderProps} variant="top" />

            <main className={styles.main}>
              <section
                className={classNames(styles['map-section'], {
                  [styles.listing]: isListingDetails,
                })}
              >
                {shouldRenderMap && (
                  <div className={styles['map-view']}>
                    {!fullEvent.isValid() && (
                      <InvalidEvent
                        schedule={schedule}
                        eventId={fullEvent.id}
                        performersByCategory={performersByCategory}
                      />
                    )}
                    <ListingsMapView
                      key={fullEvent.id}
                      showSinglePin={showSinglePin}
                      onPinHover={this.handlePinHover}
                      handleMapInteraction={this.handleMapInteraction}
                      handleListingSelection={this.handleListingSelection}
                      onListingClose={this.handleListingClose}
                      highlightedListingId={
                        isMobile ? galleryViewListing?.id : hoveredListingId
                      }
                      isEventPage={isEventPage(pathname)}
                      isListingDetailsPage={isListingDetails}
                      clearHoveredListing={() => {
                        setHoveredListingId();
                      }}
                      isAllInPrice={
                        isAllInPriceActive || showRegulatoryAllInPricing
                      }
                      isListingFlow={isListingFlow}
                      initiateCheckoutFlow={this.initiateCheckoutFlow}
                      handleListMapHarmonyToggleTracking={
                        this.handleListMapHarmonyToggleTracking
                      }
                      isCheckout={isCheckout}
                      allDisclosures={allDisclosures}
                      onListingHover={this.handleListingHover}
                      isHarmonyPlusOverlay={!isMobile}
                      onTouchInteractionStart={this.handleTouchInteractionStart}
                      onTouchInteractionEnd={this.handleTouchInteractionEnd}
                      hasGalleryViewMobileListing={
                        isGalleryViewMobileListingPresent || hasFloatingCarousel
                      }
                      isGalleryViewV3Experiment={isGalleryViewV3Experiment}
                      isAnimating={isMobileMapAnimating}
                    />
                  </div>
                )}
              </section>
              <aside className={styles['event-sidebar']}>
                <EventHeader {...eventHeaderProps} variant="sidebar" />

                <div
                  className={classNames(styles['event-sidebar-content'], {
                    [styles['event-sidebar-checkout']]: isCheckout,
                  })}
                >
                  {this.renderSidebar(isCheckout)}
                </div>
              </aside>
            </main>

            {isValidMobileEventView && (
              <div
                className={classNames(styles['bottom-navigation'], {
                  [styles['floating-carousel']]: isGalleryViewV3Experiment,
                  [styles['is-animating']]: isMobileMapAnimating,
                })}
              >
                {mobileEventView.isMap && isGalleryViewMobileListingPresent && (
                  <GalleryViewCard
                    onClose={() => this.handleClearGalleryViewMobile(true)}
                    isVisible={isGalleryViewMobileListingPresent}
                    isAnimating={isMobileMapAnimating}
                    isGalleryViewV3Experiment={isGalleryViewV3Experiment}
                  >
                    <ListingCard
                      isGalleryView
                      listingIndex={1}
                      fullEvent={fullEvent}
                      listing={galleryViewListing}
                      allDisclosures={allDisclosures}
                      allDeals={allDeals}
                      className={styles['gallery-view-card']}
                      isFocusedCard
                    />
                  </GalleryViewCard>
                )}
                {hasFloatingCarousel && !isGalleryViewMobileListingPresent && (
                  <DealsSlider
                    header={mapCarouselDisplayGroup.title}
                    listings={mapCarouselDisplayGroup.listings}
                    fullEvent={fullEvent}
                    dealType={mapCarouselDisplayGroup.deal}
                    onListingHover={this.handleListingHover}
                    allDisclosures={allDisclosures}
                    allDeals={allDeals}
                    isCarouselFloating
                  />
                )}
                {showMapListSwitch && (
                  <MapListSwitch
                    onClick={this.handleMobileViewChange}
                    view={mobileEventView}
                    isGalleryViewV3Experiment={isGalleryViewV3Experiment}
                  />
                )}
              </div>
            )}
          </div>

          <OmnibarOptions
            schedule={schedule}
            fullEvent={fullEvent}
            seatCount={quantity}
            sortId={sortId}
            availableSeatCounts={availableSeatCounts}
            handleSeatsChange={this.handleChangeQuantity}
            handleSortChange={this.handleSortChange}
            displayKey={displayKey}
            showOmnibarOptions={showOmnibarModal}
            onClose={this.handleOmnibarOptionsClose}
          />
        </ContainerTemplate>
      </EventProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    params: { eventId, listingId },
    location,
    query,
  } = props;
  // allDisclosures is a dictionary of every disclosure we support so that we can enrich the disclosures coming with listings
  const allDisclosures = allDisclosuresSelector(state);
  // allDeals is a dict with an up-to-date list of deals we support
  const allDeals = selectAllDeals(state);
  const fullEvent = selectFullEventById(state, eventId);
  if (!fullEvent) {
    return {};
  }

  const schedule =
    selectFullEventsByPrimaryPerformerId(
      state,
      fullEvent.getPrimaryPerformer().id
    ) || [];
  const preferredSeatCount = userPreferenceSeatCountSelector(state);
  const sortId = userPreferredSortIdSelector(state);
  const selectPerformersByCategory = makeGetSelectPerformersByCategory();
  const performersByCategory = selectPerformersByCategory(
    state,
    fullEvent.getPrimaryPerformer().category
  );

  const isGTPicksFilterActive = isGTPicksFilterSelector(state);

  const quantity = selectListingQuantity(state);

  const showSuperBowlModal = isSuperBowl(eventId) && !hasSeenSBModal(eventId);
  const showFullHeader =
    isEventPagePreListings(location.pathname) ||
    isListingDetailsPage(location.pathname);

  const algoliaFields = {
    queryId: query?.queryId,
    resultPosition: query?.resultPosition,
    searchIndex: query?.searchIndex,
    searchSessionId: query?.searchSessionId,
  };

  const listingsRequestParams = selectListingsParams(state);
  if (!listingsRequestParams) {
    // listings and params should exist by this point if listings were
    // successfully fetched
    return {};
  }

  const listingsInDisplayGroups = selectListingV3DisplayGroups(state);
  const displayedListings = selectDisplayedV3Listings(state);
  const dealTypes = selectDisplayedListingV3Deals(state);

  const highestPriceListing = selectHighestPriceListingV3(state);
  fullEvent.highPrice = highestPriceListing?.price;

  const firstCarouselDisplayGroup = listingsInDisplayGroups.find(
    (displayGroup) =>
      displayGroup.type === 'carousel' && displayGroup.listings.length > 0
  );

  const firstListDisplayGroup = listingsInDisplayGroups.find(
    (displayGroup) =>
      displayGroup.type === 'list' && displayGroup.listings.length > 0
  );

  const mapCarouselGroup =
    firstCarouselDisplayGroup ||
    firstListDisplayGroup ||
    listingsInDisplayGroups[0];

  const mapCarouselDisplayGroup = mapCarouselGroup && {
    ...mapCarouselGroup,
    listings:
      mapCarouselGroup.type === 'carousel'
        ? mapCarouselGroup.listings
        : mapCarouselGroup.listings.slice(0, 6),
  };

  return {
    eventPageData: eventsPageDataSelector(state),
    fullEvent,
    listingsInDisplayGroups,
    displayedListings,
    listing: selectListingById(state, listingId),
    quantity,
    schedule,
    user: selectUserDetails(state),
    showFullHeader,
    sortId,
    showSuggestedSeatCount: quantity !== preferredSeatCount,
    availableSeatCounts: selectAvailableLots(state),
    noInventory: !selectHasInventory(state),
    performersByCategory: performersByCategory || [],
    query,
    allDisclosures,
    allDeals,
    isAllInPriceActive: selectIsAllInPricing(state),
    showSuperBowlModal,
    listingId,
    isListMapHarmonyEnabled: selectIsListMapHarmonyEnabled(state),
    algoliaFields,
    hoveredListingId: hoveredListingIdSelector(state),
    dealsTracking: {
      dealTypes,
    },
    searchTestData: selectSearchTestData(state),
    isWebExclusivesV3Experiment: selectIsWebExclusivesV3Experiment(state),
    isGTPicksFilterActive,
    isGalleryViewV3Experiment: selectIsGalleryViewV3Experiment(state),
    mapCarouselDisplayGroup,
    zoomLevel: selectZoomLevel(state),
    listingsRequestParams,
  };
};

const mapDispatchToProps = {
  updateUserPreference,
  showModal,
  showAppSpinner,
  fetchFullEventsByPrimaryPerformerId:
    fetchFullEventsByPrimaryPerformerIdDispatch,
  updateMapHarmony: updateMapHarmonyDispatch,
  setHoveredListingId,
  setServerRedirectPath,
  setGTPicksFilter,
  getListings: fetchListingsV3,
  setZoomLevel: setZoomLevelDispatch,
};

const loader =
  ({ store: { dispatch, getState } }) =>
  async ({ params: { eventId, listingId }, request }) => {
    const location = new URL(request.url);
    const requestStartTime = Date.now();

    const preListingsPromises = [
      dispatch(fetchMetros()),
      dispatch(fetchDisclosures()),
      dispatch(fetchDeals()),
    ];

    if (!selectFullEventById(getState(), eventId)) {
      preListingsPromises.push(dispatch(fetchFullEventById(eventId)));
    }

    // ensure that the event exists before fetching listings
    await Promise.all(preListingsPromises);

    const fullEvent = selectFullEventById(getState(), eventId);

    if (!fullEvent) {
      return null;
    }

    if (fullEvent.isValid() && !currentLocationSelector(getState())) {
      dispatch(updateCurrentLocation(fullEvent.venue.metro));
    }

    // always fetch listings if no listingId (Event page), and ensure listing
    // exists if listingId is present
    if (!listingId || !selectListingById(getState(), listingId)) {
      const state = getState();
      // if we are re-fetching listings for the same event, use the stored
      // params
      const { isMobile } = appConfigSelector(state);
      const storedParams = selectListingsParams(state);
      const defaultParams = {
        eventId,
        quantity:
          userPreferenceSeatCountSelector(state) || DEFAULT_LISTING_QUANTITY,
        zoom: !listingId && isMobile ? 5 : 10,
        // check if user preferences are set to show all in pricing. if not, set
        // whether to show all in pricing based on state/province regulations.
        // from here, the user is allowed to change the all in pricing setting on
        // the event page.
        all_in_pricing:
          selectIsVenueAllInPrice(state, fullEvent.venueState) ||
          userPreferenceShowAllInPriceSelector(state),
        sort_order: getPreferenceSortOrder(state),
      };

      await dispatch(
        fetchListingsV3(
          eventId === storedParams?.eventId ? storedParams : defaultParams
        )
      );
    }

    // if listingId is present but still not in the curated listings after
    // fetching, redirect back to view other listings for the event
    if (listingId && !selectListingById(getState(), listingId)) {
      return redirect(`${fullEvent.getPath()}${location.search}`);
    }

    if (listingId && selectUserDetails(getState())) {
      await dispatch(
        fetchUserPromoCodesForListing({
          eventId,
          listingId,
        })
      );
    }

    dispatch(
      updateEventsPageData({
        eventPageRequestsStartTime: requestStartTime,
        eventPageRequestsFinishTime: Date.now(),
      })
    );

    return null;
  };

const EventWrapper = withVariantContext(
  withRouter(
    withNavigationProps(connect(mapStateToProps, mapDispatchToProps)(EventPage))
  )
);

EventWrapper.loader = loader;

export default EventWrapper;
