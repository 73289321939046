import * as React from 'react';

export default function DeliveryMethod({
  fill = 'currentColor',
  height = 12,
  width = 16,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0 11C0 11.5523 0.447715 12 1 12H15C15.5523 12 16 11.5523 16 11V1C16 0.447715 15.5523 0 15 0H1C0.447715 0 0 0.447715 0 1V11ZM2.21967 6.53018C1.92678 6.23729 1.92678 5.76241 2.21967 5.46952L5.21967 2.46952L6.28033 3.53018L4.56048 5.25003H11.4394L9.71967 3.5303L10.7803 2.46964L13.7803 5.46964C14.0732 5.76254 14.0732 6.23741 13.7803 6.5303L10.7803 9.5303L9.71967 8.46964L11.4393 6.75003H4.56084L6.28033 8.46952L5.21967 9.53018L2.21967 6.53018Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}
