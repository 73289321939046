import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { groupBy } from 'lodash';

import { SelectArrow } from '../../../../../icons';

import Field from './Field';

import styles from '../Form.module.scss';

function OptionGroup({
  group,
  options: optionCollection,
}: {
  group?: string | null;
  options: {
    label: string;
    value: number | string;
  }[];
}): React.JSX.Element {
  const options = optionCollection.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  if (!group || group === 'undefined') {
    return <>{options}</>;
  }

  return <optgroup label={group}>{options}</optgroup>;
}

export default function Select({
  className,
  id,
  label,
  name,
  options: optionCollection,
  onChange: handleChange,
}: {
  className?: string;
  id: string;
  label: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  options: { group?: string; label: string; value: number | string }[];
}): React.JSX.Element {
  const [field, meta, helpers] = useField({ name: name ?? id });
  const ref = React.useRef<HTMLSelectElement>(null);

  return (
    <Field
      className={className ?? ''}
      error={meta.touched ? meta.error ?? '' : ''}
      icon={<SelectArrow fill="currentColor" height={10} width={10} />}
      id={id}
      onLabelClick={(event) => {
        /*
         * Prevent the browser from focusing the select element so as not
         * to disrupt the transient user activation in order to show the
         * select options. We will focus afterwards.
         */
        event.preventDefault();

        try {
          ref.current?.showPicker();
        } catch {
          /*
           * No need to catch, if `showPicker isn't available we
           * simply fall back to focusing the select element
           */
        } finally {
          ref.current?.focus();
        }
      }}
      label={label}
    >
      <select
        {...field}
        aria-errormessage={
          meta.touched && meta.error ? `error-${id}` : undefined
        }
        aria-invalid={!!(meta.touched && meta.error)}
        className={classNames(styles['form-control'], styles.select)}
        id={id}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          if (handleChange) {
            handleChange(event);
          }

          helpers.setValue(event.target.value);
        }}
        ref={ref}
      >
        {Object.entries(groupBy(optionCollection, 'group')).map(
          ([key, group]) => (
            <OptionGroup key={key} group={key} options={group} />
          )
        )}
      </select>
    </Field>
  );
}
