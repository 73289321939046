import { Action } from '@reduxjs/toolkit';

import type { GetPerformersParams } from 'services/performer/performer.service';
import { GetPerformersResponse, Performer, RequestStatus } from 'types';

import {
  fetchPerformersByCategoryFailure,
  fetchPerformersByCategoryRequest,
  fetchPerformersByCategorySuccess,
  fetchPerformersFailure,
  fetchPerformersRequest,
  fetchPerformersSuccess,
} from './actions';

export interface PerformersState {
  status: RequestStatus;
  params: Partial<GetPerformersParams>;
  fetchPerformersData: GetPerformersResponse;
  performersByCategory: Record<string, Performer[]>;
  error?: unknown;
}

export const initialState: PerformersState = {
  params: {},
  fetchPerformersData: {
    performers: [],
    total: 0,
    more: false,
    page: 0,
    per_page: 0,
  },
  performersByCategory: {},
  status: 'idle',
  error: undefined,
};

export default function performersReducer(
  state: PerformersState = initialState,
  action: Action
): PerformersState {
  if (fetchPerformersRequest.match(action)) {
    return {
      ...state,
      params: action.payload,
      status: 'loading',
      error: undefined,
    };
  }

  if (fetchPerformersSuccess.match(action)) {
    const response = action.payload.response;
    return {
      ...state,
      fetchPerformersData: {
        ...response,
        performers:
          response.page === 1
            ? response.performers
            : [...state.fetchPerformersData.performers, ...response.performers],
      },
      status: 'success',
    };
  }

  if (fetchPerformersFailure.match(action)) {
    return {
      ...initialState,
      status: 'failure',
      error: action.payload,
    };
  }

  if (fetchPerformersByCategoryRequest.match(action)) {
    return {
      ...state,
      status: 'loading',
      error: undefined,
    };
  }

  if (fetchPerformersByCategorySuccess.match(action)) {
    const response = action.payload.response;
    const category = action.payload.category;
    return {
      ...state,
      performersByCategory: {
        ...state.performersByCategory,
        [category]: response.performers,
      },
      status: 'success',
    };
  }

  if (fetchPerformersByCategoryFailure.match(action)) {
    return {
      ...initialState,
      status: 'failure',
      error: action.payload,
    };
  }

  return state;
}
