import React, { useState } from 'react';
import classNames from 'classnames';
import { FormikHelpers } from 'formik';
import { RadioCard, RadioGroup } from 'ui/Radio';

import GTAnimationModal from 'components/GTAnimationModal';
import Spinner from 'components/Spinner/Spinner';
import { CheckShieldIcon, GroupIcon } from 'icons';
import { UserModel } from 'types';

import Dialog from '../../../../ui/Dialog';
import { InsuranceForm } from '../../../CheckoutV3/components/Forms';
import { OptionalDictionary } from '../../../CheckoutV3/Dictionaries';
import CheckoutCard from '../CheckoutCard';

import InsuranceDataCollectionForm from './InsuranceDataCollectionForm';
import { Insurance, UpdateQuoteParams } from './InsuranceOptions.utils';

import styles from './InsuranceOptions.module.scss';

const defaultLabels = {
  no: "No, I don't want insurance",
  yes: 'Yes, make my purchase reimbursable',
  viewDetails: 'View Wording',
};

interface InsuranceOptionsProps {
  classNameList?: {
    component?: OptionalDictionary<
      | 'container'
      | 'coverage-list'
      | 'group-icon'
      | 'icon'
      | 'insurance-options'
      | 'modal-body-heading'
      | 'modal-body'
      | 'placeholder'
      | 'policy-wording-link'
      | 'price'
      | 'quote'
      | 'radio-group'
      | 'recommended-tab'
      | 'recommended-text'
      | 'social-proof'
      | 'terms-and-conditions'
      | 'title'
    >;
    header?: OptionalDictionary<
      | 'body'
      | 'component'
      | 'description'
      | 'header-button'
      | 'header'
      | 'title-row'
      | 'title'
    >;
    radio?: OptionalDictionary<
      | 'checkmark'
      | 'component'
      | 'content'
      | 'expanded-content-drawer'
      | 'expanded-content-overflow'
      | 'expanded-content'
      | 'header'
      | 'icon'
      | 'input'
      | 'label'
      | 'subtitle'
      | 'title-block'
      | 'title'
    >;
  };
  description?: React.ReactNode;
  disclaimer?: string;
  insurance: Insurance;
  labels?: OptionalDictionary<keyof typeof defaultLabels>;
  user: UserModel;
  withCheckmark?: boolean;
  withShieldIcon?: boolean;
}

function ModalWindow({
  children,
  isCheckoutV3 = false,
  isOpen,
  onClose,
}: {
  children?: React.ReactNode;
  isCheckoutV3?: boolean;
  isOpen: boolean;
  onClose: () => void;
}): React.JSX.Element {
  const title = 'Make My Purchase Reimbursable';

  if (isCheckoutV3) {
    return (
      <Dialog title={title} isOpen={isOpen} onClose={onClose}>
        {children}
      </Dialog>
    );
  }

  return (
    <GTAnimationModal
      className="insurance-data-modal"
      headerTheme="dark"
      headerTitle={title}
      onHide={onClose}
      show={isOpen}
    >
      {children}
    </GTAnimationModal>
  );
}

function InsuranceInfoForm({
  insurance,
  isCheckoutV3 = false,
  onSubmit: handleSubmit,
  user,
}: {
  insurance: Insurance;
  isCheckoutV3?: boolean;
  onSubmit: (
    params: UpdateQuoteParams,
    helpers?: FormikHelpers<UpdateQuoteParams>
  ) => Promise<void>;
  user: UserModel;
}): React.JSX.Element {
  if (isCheckoutV3) {
    return (
      <InsuranceForm
        onSubmit={handleSubmit}
        postalCode={insurance.quote?.details.postal_code ?? ''}
        user={user}
      />
    );
  }

  return (
    <InsuranceDataCollectionForm
      onSubmit={handleSubmit}
      user={user}
      zipCode={insurance.quote?.details.postal_code ?? ''}
    />
  );
}

export default function InsuranceOptions({
  classNameList,
  description = "Reimbursement up to 100% if you can't attend due to illness, injury, involuntary unemployment, and more. See terms for details.",
  insurance,
  labels = defaultLabels,
  user,
  withCheckmark = true,
  withShieldIcon = true,
}: InsuranceOptionsProps) {
  const [showDataCollectionModal, setShowDataCollectionModal] = useState(false);

  const handleOptInChange = (isSelected: boolean) => {
    insurance.trackOptInChange(isSelected);

    // show the data collection modal before opting in unless already updated
    if (isSelected && !insurance.quote?.isUpdated) {
      return setShowDataCollectionModal(isSelected);
    }

    insurance.setIsSelected(isSelected);
  };

  const handleSubmit = async (params: UpdateQuoteParams) => {
    return insurance.handleDataCollectionFormSubmit(params).then(() => {
      insurance.setIsSelected(true);
      setShowDataCollectionModal(false);
    });
    // don't catch here, handle errors in the form
  };

  const coverageList = (
    <div
      className={classNames(
        styles['coverage-list'],
        classNameList?.component?.['coverage-list']
      )}
    >
      <span>You can be covered for:</span>
      <ul>
        <li>
          Illness or injury to you, another ticket holder, or close relative
        </li>
        <li>Public transit delays caused by severe weather</li>
        <li>Involuntary unemployment, jury duty, and more</li>
      </ul>
    </div>
  );

  if (insurance.isLoadingEligibility) {
    return <Spinner />;
  }

  if (!insurance.eligibility?.eligible || !insurance.quote) {
    return null;
  }

  return (
    <>
      <CheckoutCard
        classNameList={classNameList?.header}
        title={
          <span
            className={classNames(styles.title, classNameList?.header?.title)}
          >
            <span>Make My Purchase Reimbursable</span>
            {withShieldIcon && (
              <CheckShieldIcon
                className={classNames(
                  styles.icon,
                  classNameList?.component?.icon
                )}
              />
            )}
          </span>
        }
        description={description}
        body={
          <div className={classNames(styles['insurance-options'])}>
            <RadioGroup<boolean>
              className={classNameList?.component?.['radio-group']}
              name="insurance-options"
              onChange={handleOptInChange}
              value={insurance.isSelected}
            >
              <div>
                <div
                  className={classNames(
                    styles['recommended-tab'],
                    classNameList?.component?.['recommended-tab']
                  )}
                >
                  <span
                    className={classNames(
                      styles['recommended-text'],
                      classNameList?.component?.['recommended-text']
                    )}
                  >
                    Recommended
                  </span>
                </div>
                <RadioCard
                  classNameList={classNameList?.radio}
                  value
                  title={
                    <span
                      className={classNames(
                        styles['radio-card-title'],
                        classNameList?.radio?.title
                      )}
                    >
                      {labels.yes || defaultLabels.yes}
                    </span>
                  }
                  subtitle={
                    <div
                      className={classNames(
                        styles.quote,
                        classNameList?.component?.quote
                      )}
                    >
                      Insure for{' '}
                      {insurance.isLoadingQuote ? (
                        <div
                          className={classNames(
                            styles.placeholder,
                            classNameList?.component?.placeholder
                          )}
                        />
                      ) : (
                        <>
                          <span
                            className={classNames(
                              styles.price,
                              classNameList?.component?.price
                            )}
                          >
                            ${insurance.quote.unitPrice.toFixed(2)}
                          </span>
                          /ticket
                        </>
                      )}
                    </div>
                  }
                  expandedContent={coverageList}
                  withCheckmark={withCheckmark}
                />
              </div>
              <RadioCard
                classNameList={classNameList?.radio}
                value={false}
                title={
                  <span
                    className={classNames(
                      styles['radio-card-title'],
                      classNameList?.radio?.title
                    )}
                  >
                    {labels.no || defaultLabels.no}
                  </span>
                }
                withCheckmark={withCheckmark}
              />
            </RadioGroup>
            <span
              className={classNames(
                styles['social-proof'],
                classNameList?.component?.['social-proof']
              )}
            >
              <GroupIcon
                className={classNames(
                  styles['group-icon'],
                  classNameList?.component?.['group-icon']
                )}
              />
              29,840 people chose XCover protection this month
            </span>
            <span
              className={classNames(
                styles['terms-and-conditions'],
                classNameList?.component?.['terms-and-conditions']
              )}
            >
              {insurance.quote.details.disclaimer}{' '}
              <a
                className={classNames(
                  styles['policy-wording-link'],
                  classNameList?.component?.['policy-wording-link']
                )}
                href={insurance.quote.details.pds_url}
              >
                {labels.viewDetails || defaultLabels.viewDetails}
              </a>
            </span>
          </div>
        }
      />
      <ModalWindow
        isCheckoutV3={!withShieldIcon}
        isOpen={showDataCollectionModal}
        onClose={() => setShowDataCollectionModal(false)}
      >
        <div
          className={classNames(
            styles['modal-body'],
            classNameList?.component?.['modal-body']
          )}
        >
          <h2
            className={classNames(
              styles['modal-body-heading'],
              classNameList?.component?.['modal-body-heading']
            )}
          >
            Confirm Your Information
          </h2>
          {coverageList}
          <InsuranceInfoForm
            isCheckoutV3={!withShieldIcon}
            onSubmit={handleSubmit}
            user={user}
            insurance={insurance}
          />
        </div>
      </ModalWindow>
    </>
  );
}
