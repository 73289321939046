const ms = require('ms');

const defaultCookieAttributes = {
  path: '/',
};

module.exports = {
  port: 8080,
  cookiesConfig: {
    WEB_USER_ID: {
      name: 'gt_id',
      newUserCookie: 'newUserCookie',
      attributes: { ...defaultCookieAttributes, maxAge: ms('5 year') },
    },
    WEB_SESSION_ID: {
      name: 'gt_sid',
      attributes: { ...defaultCookieAttributes, maxAge: ms('30 minutes') },
    },
    AUTH: {
      name: 'gt_a',
      attributes: { ...defaultCookieAttributes, maxAge: ms('30 days') },
    },
    MAGIC_TOKEN: {
      query: 'token', // query param for magic token
      name: 'mt',
      attributes: { ...defaultCookieAttributes },
    },
    MAGIC_LINK_LOGIN: {
      name: 'mll',
      attributes: { ...defaultCookieAttributes },
    },
    BANNER_DISMISSED: {
      name: 'gt_bd',
      attributes: {
        ...defaultCookieAttributes,
        maxAge: ms('30 minutes'),
      },
    },
    USER_EXTRAS: {
      name: 'gt_ue', // user-extras
      attributes: {
        ...defaultCookieAttributes,
        expires: new Date(Date.now() + parseInt(ms('5 years'))),
      },
      parameters: {
        LAST_VISITED_METRO: {
          name: 'lastVisitedMetro',
          defaultValue: '',
        },
        EMAIL: {
          name: 'email',
          defaultValue: '',
        },
        ZIP: {
          name: 'zip',
          defaultValue: '',
        },
        PHONE: {
          name: 'phone',
          defaultValue: '',
        },
        SEAT_COUNT: {
          query: 'seat_count',
          name: 'seatCount',
          defaultValue: 2,
        },
        SITE_VISIT_COUNT: {
          name: 'siteVisitCount',
          defaultValue: 1,
        },
        SHOW_ALL_IN_PRICE: {
          query: 'pricedisplay',
          name: 'showAllInPrice',
          defaultValue: false,
        },
        SURVEY_RESOLVED: {
          name: 'surveyResolved',
          defaultValue: false,
        },
      },
    },
    INTERNAL: {
      name: 'gt_internal',
      attributes: {
        ...defaultCookieAttributes,
        maxAge: ms('30 days'),
        domain: '.gametime.co',
      },
    },
    SAVED_EXPERIMENTS: {
      name: 'gt_experiments',
      attributes: {
        ...defaultCookieAttributes,
      },
    },
    SAVED_FEATURE_FLAGS: {
      name: 'gt_feature_flags',
      attributes: {
        ...defaultCookieAttributes,
      },
    },
  },
  app: {
    title: 'Gametime', // will be overwritten per page
    description:
      'Get the cheapest tickets to the most popular events in sports, music, and theater in 60+ cities, with seat view photos and the best prices - guaranteed!',
    head: {
      titleTemplate: '%s | Gametime',
      meta: [
        {
          name: 'description',
          content:
            'Get the cheapest tickets to the most popular events in sports, music, and theater in 60+ cities, with seat view photos and the best prices - guaranteed!',
        },
        { charset: 'utf-8' },
        { 'http-equiv': 'Content-Language', content: 'en' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1',
        },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'application-name', content: 'Gametime' },
        { name: 'apple-mobile-web-app-title', content: 'Gametime' },
        {
          name: 'google-site-verification',
          content: 'Q9Kc1RFSykmuI-JdxHtvu-hf1fmAwus-9ITqGvhcDKQ',
        },
      ],
    },
  },
  AFFIRM_API_KEY: 'Z7J5ANQV7J8LWSM6',
  AFFIRM_CDN: 'https://cdn1-sandbox.affirm.com',
  BRAINTREE_CLIENT_TOKEN: 'sandbox_std8c243_fpxqth3ffb95ccs7',
  BUTTER_CMS_TOKEN: 'c1e1ec8bd07a57f4bd36db67e23744634f957fad',
  DATADOG_APP_ID: '64472cb3-d189-43fd-9fac-610bac2adfab',
  DATADOG_CLIENT_TOKEN: 'pub1dcaa16c8efa3e6a43f49bec771a898a',
  FORTER_EU: '22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv',
  FORTER_SITE_ID: '80926dda1ad5',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBT2o3Tjm-h4FvIrB3AxJkXhGKE2kZ3bto',
  GOOGLE_MERCHANT_ID: '01776311464695072257',
  GOOGLE_RE_CAPTCHA_KEY: '6LcFD6siAAAAAOc14lmyWEwJCOR8Ooq3ZervIhmP',
  GOOGLE_TAG_MANAGER_ID: 'GTM-M4LR4S4',
  MPARTICLE_API_KEY: 'f1b63951fb249443aa3add3775bd52ca',
  PUSHER_CLUSTER: 'mt1',
  STATSIG_CLIENT_TOKEN: 'client-8ryONeQgbuIFBeKidVDrD0vK7VWwYHSkPU17imY8lc3',
  APP_STORE_URL:
    'https://apps.apple.com/us/app/gametime-last-minute-tickets/id630687854',
  PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.gametime.gametime',
  appleAppAssociationConstants: {
    iosAppId: 'XRYNDA78J5',
    universalLinkSuffix: '.alpha',
    paths: [
      '/dl/',
      '/ldl/*',
      'NOT /*/buy/*',
      'NOT /*/checkout/*',
      '/*/events/*',
      '/*/metros/*',
      '/',
    ],
  },
};
