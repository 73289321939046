import React from 'react';
import { useMaskito } from '@maskito/react';
import { useFormikContext } from 'formik';

import { maskitoPhoneOptions, normalizePhoneForMaskito } from 'utils/phone';

import Input from './Input';

export default function PhoneNumber<
  Values extends { [key: string]: boolean | number | string },
>({
  className,
  id,
  label = 'Phone Number',
  name,
  placeholder = '(555) 555-5555',
}: {
  className?: string;
  id: string;
  label?: string;
  name?: string;
  placeholder?: string;
}): React.JSX.Element {
  const { handleBlur, setFieldValue, values } = useFormikContext<Values>();
  const key = name ?? id;

  const ref = useMaskito({
    options: maskitoPhoneOptions,
  });

  return (
    <Input
      className={className}
      id={id}
      label={label}
      name={key}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        handleBlur(event);
        const normalizedValue = normalizePhoneForMaskito(event.target.value);
        setFieldValue(key, normalizedValue);
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const normalizedValue = normalizePhoneForMaskito(event.target.value);
        setFieldValue(key, normalizedValue);
      }}
      placeholder={placeholder}
      ref={ref}
      type="tel"
      value={normalizePhoneForMaskito(values[key] as string)}
    />
  );
}
