import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useInsurance } from 'contexts/InsuranceContext';
import Dialog from 'ui/Dialog';

import { PromoCodeForm } from 'pages/CheckoutV3/components/Forms';
import { extendedPurchaseSelector } from 'store/modules/purchase/purchase.selectors';
import { formatPriceWithComma } from 'utils/number';

import { DiscountIcon, PriceTag } from '../../../../icons';
import { FullEvent, Listing } from '../../../../models';
import { useAppSelector } from '../../../../store';
import {
  isBuyRoute,
  isOrderRoute,
} from '../../../../store/modules/history/history';
import {
  selectBestUserPromoForListing,
  selectUserDetails,
  userPromosForListingSavingsSelector,
} from '../../../../store/modules/user/user.selectors';
import Attribute from '../Attributes/Attribute';
import UrgencyMessage from '../UrgencyMessage';

import LineItem from './LineItem';
import LineItemContainer from './LineItemContainer';

import styles from './Cart.module.scss';

export function Container<Element extends React.ElementType>({
  children,
  className,
  listing,
  tag: Tag,
}: {
  className?: string;
  children?: React.ReactNode;
  tag: Element;
  listing?: Listing;
} & React.ComponentPropsWithRef<Element>): React.JSX.Element {
  return (
    <Tag className={className}>
      {listing && <UrgencyMessage listing={listing} />}
      {children}
    </Tag>
  );
}

export default function Cart({
  currency = '$',
  className,
  listing,
  event,
}: {
  currency?: string;
  className?: string;
  listing: Listing;
  event: FullEvent;
}): React.JSX.Element {
  const location = useLocation();
  const insurance = useInsurance();

  const user = useAppSelector(selectUserDetails);
  const userPromosForListingSavings = useAppSelector(
    userPromosForListingSavingsSelector
  );
  const bestUserPromoForListing = useAppSelector(selectBestUserPromoForListing);
  const extendedPurchase = useAppSelector((state) =>
    extendedPurchaseSelector(state, {
      listing,
    })
  );

  const classes = classNames(className, styles.component);
  const [isPromoCodeModalOpen, setIsPromoCodeModalOpen] = useState(false);

  const tax = listing.salesTax;
  const adjustedTotal = insurance.adjustedTotalWithInsurance;
  const shouldShowAddPromoCodeButton =
    !isBuyRoute(location.pathname) && !isOrderRoute(location.pathname);

  // Update the insurance total price after a user submits a promo code
  useEffect(() => {
    insurance.setAdjustedTotal(
      extendedPurchase.totalPrice - userPromosForListingSavings
    );
  }, [userPromosForListingSavings]);

  if (!user) {
    return (
      <Container
        className={classNames(classes, styles.unauthenticated)}
        listing={listing}
        tag="section"
      >
        <Attribute icon={<PriceTag />}>
          <p className={styles['ticket-price']}>
            <span className={styles.price}>
              {currency + listing.totalPrice}
            </span>
            <span className={styles.tag}> each</span>
          </p>
          <p className={styles.disclaimer}>Includes fees</p>
        </Attribute>
      </Container>
    );
  }

  return (
    <Container className={classes} listing={listing} tag="dl">
      <LineItem
        currency={currency}
        quantity={listing.quantity}
        title="Tickets"
        value={listing.prefeePrice}
      />

      <LineItem
        currency={currency}
        quantity={listing.quantity}
        title="Fees"
        value={listing.fees}
      />

      {tax > 0 ? (
        <LineItem
          currency={currency}
          title="Sales Tax"
          value={tax * listing.quantity}
        />
      ) : (
        ''
      )}

      {insurance.isBookingInsurance && insurance.quote?.totalPrice && (
        <LineItem
          currency={currency}
          title="XCover Ticket Protection"
          value={insurance.quote.totalPrice}
        />
      )}

      {userPromosForListingSavings > 0 ? (
        <LineItemContainer>
          <div className={styles['promo-tag']}>
            <DiscountIcon fill="currentColor" height={16} width={16} />
            <span>{bestUserPromoForListing.promo_details.code}</span>
          </div>
          <span className={styles['promo-savings']}>
            {`-${currency + formatPriceWithComma(userPromosForListingSavings, true)}`}
          </span>
        </LineItemContainer>
      ) : (
        shouldShowAddPromoCodeButton && (
          <LineItemContainer>
            <button
              className={styles.promo}
              onClick={() => setIsPromoCodeModalOpen(true)}
            >
              Add Promo Code
            </button>
          </LineItemContainer>
        )
      )}

      <LineItem
        titleClass="is-bold"
        currency={currency}
        title="Total"
        value={adjustedTotal}
      />

      <Dialog
        isOpen={isPromoCodeModalOpen}
        title="Add Promo"
        onClose={() => setIsPromoCodeModalOpen(false)}
        isBottomDrawerOnMobile={false}
      >
        <PromoCodeForm
          eventId={event.id}
          listingId={listing.id}
          onSubmit={() => setIsPromoCodeModalOpen(false)}
        />
      </Dialog>
    </Container>
  );
}
