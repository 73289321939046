import React from 'react';

export default ({fill = '#FFFFFF', width = 24, height = 24}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M4 21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V3C20 1.89543 19.1046 1 18 1H6C4.89543 1 4 1.89543 4 3V21ZM17.5 19C17.7761 19 18 18.7761 18 18.5V5.5C18 5.22386 17.7761 5 17.5 5H6.5C6.22386 5 6 5.22386 6 5.5V18.5C6 18.7761 6.22386 19 6.5 19H17.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M16.3535 9.56066C16.5488 9.75592 16.5488 10.0725 16.3535 10.2678L11.7071 14.9142C11.3165 15.3047 10.6834 15.3047 10.2928 14.9142L7.6464 12.2678C7.45114 12.0725 7.45114 11.7559 7.6464 11.5607L8.35351 10.8536C8.54877 10.6583 8.86535 10.6583 9.06061 10.8536L11 12.7929L14.9393 8.85355C15.1346 8.65829 15.4511 8.65829 15.6464 8.85355L16.3535 9.56066Z"
    />
  </svg>
);
