/**
 * Deals are special attributes that can come in the listings and can add additional
 * visual elements or change the way they are displayed in the event page. We fetch the
 * latest available deal types from the backend and map those deals into the model
 * described in this file.
 *
 * Endpoint: `/v1/deals`
 */
import { Deal as DealType } from 'types';

export default class Deal {
  slug: DealType['slug'];
  title: DealType['title'];
  color: DealType['color'];
  topText?: DealType['top_text'];
  unlockDuration?: DealType['unlock_duration'];
  unlockType?: DealType['unlock_type'];
  iconURL?: DealType['icon_url'];
  position?: DealType['position'];
  colorGradient?: DealType['color_gradient'];
  valueMessage?: DealType['value_message'];

  constructor(deal: DealType) {
    this.slug = deal.slug;
    this.title = deal.title;
    this.color = deal.color;
    this.topText = deal.top_text || '';
    this.unlockDuration = deal.unlock_duration || 0;
    this.unlockType = deal.unlock_type || '';
    this.iconURL = deal.icon_url || '';
    this.position = deal.position || 0;
    this.colorGradient = deal.color_gradient;
    this.valueMessage = deal.value_message;
  }
}
