import { createAction } from '@reduxjs/toolkit';
import type { AsyncThunk } from 'store';

import {
  PostCodeRequest,
  postUserPromoCode,
  putUser,
} from 'services/user/user.service';
import { PostCodeResponse, PutUserResponse, UserModel } from 'types';

/**
 * PUT /v1/user/:id
 */

export const updateUserRequest = createAction<UserModel>(
  'User/UpdateUserRequest'
);
export const updateUserSuccess = createAction<PutUserResponse>(
  'User/UpdateUserSuccess'
);
export const updateUserFailure = createAction<unknown>(
  'User/UpdateUserFailure'
);

export function updateUser(user: UserModel): AsyncThunk {
  return async (dispatch, _, { mobileApi }) => {
    dispatch(updateUserRequest(user));

    try {
      const response = await putUser(mobileApi, user);

      if (!response) {
        throw new Error('null response from PUT User request');
      }

      dispatch(updateUserSuccess(response));
    } catch (error) {
      dispatch(updateUserFailure(error));
    }
  };
}

/**
 * POST /v1/user/:id/:code
 */

export const addUserPromoCodeRequest = createAction<PostCodeRequest>(
  'User/AddUserPromoCodeRequest'
);
export const addUserPromoCodeSuccess = createAction<PostCodeResponse>(
  'User/AddUserPromoCodeSuccess'
);
export const addUserPromoCodeFailure = createAction<unknown>(
  'User/AddUserPromoCodeFailure'
);

export function addUserPromoCode(requestParams: PostCodeRequest): AsyncThunk {
  return async (dispatch, _, { mobileApi }) => {
    dispatch(addUserPromoCodeRequest(requestParams));

    try {
      const response = await postUserPromoCode(mobileApi, requestParams);

      if (!response) {
        throw new Error('null response from POST User Promo Code request');
      }

      dispatch(addUserPromoCodeSuccess(response));
    } catch (error) {
      dispatch(addUserPromoCodeFailure(error));
    }
  };
}
