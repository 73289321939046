import React from 'react';
import classNames from 'classnames';

import styles from '../Form.module.scss';

type Props = Readonly<{
  children: React.ReactElement;
  icon?: React.ReactElement;
  id: string;
  label: string;
  onLabelClick?: (
    event: React.MouseEvent<HTMLLabelElement | HTMLDivElement, MouseEvent>
  ) => void;
}>;

function isFrameField(element: React.ReactElement): boolean {
  return element.type.toString() === 'iframe';
}

function isFormField(element: React.ReactElement): boolean {
  return ['input', 'select', 'textarea'].includes(element.type.toString());
}

function canHaveLabel(element: React.ReactElement): boolean {
  return isFrameField(element) || isFormField(element);
}

function InputWrapper({
  children,
  icon,
  id,
  label,
  onLabelClick: handleLabelClick,
}: Props): React.JSX.Element {
  const descendants = (
    <>
      <span className={styles['input-label']} id={`${id}-label`}>
        {label}
      </span>

      {children}

      {!!icon && (
        <span aria-hidden className={styles.icon}>
          {icon}
        </span>
      )}
    </>
  );

  if (!canHaveLabel(children)) {
    return (
      <div className={styles['input-group']} onClick={handleLabelClick}>
        {descendants}
      </div>
    );
  }

  return (
    <label
      className={styles['input-group']}
      htmlFor={id}
      onClick={handleLabelClick}
    >
      {descendants}
    </label>
  );
}

export default function Field({
  children,
  className,
  error,
  icon,
  id,
  onLabelClick: handleLabelClick,
  label,
}: Props &
  Readonly<{
    className?: string;
    error?: string;
  }>): React.JSX.Element {
  return (
    <section className={classNames(styles['field-group'], className)}>
      <InputWrapper
        icon={icon}
        id={id}
        onLabelClick={handleLabelClick}
        label={label}
      >
        {children}
      </InputWrapper>

      {!!error && (
        <p className={styles.error} id={`error-${id}`}>
          {error}
        </p>
      )}
    </section>
  );
}
