import { ValueOf } from 'type-fest';

import { Performer } from 'models';

export const CONTENT_TEMPLATES = {
  CHEAP: 'cheap',
  PRICE: 'price',
  TODAY: 'today',
} as const;

export function getHeroTitle({
  performer,
  matchup,
  contentTemplate,
}: {
  performer: Performer;
  matchup?: Performer;
  contentTemplate?: ValueOf<typeof CONTENT_TEMPLATES>;
}) {
  const heroTitle = matchup?.shortName
    ? `${performer.shortName} vs. ${matchup.shortName} Tickets`
    : `${performer.displayName} Tickets`;

  switch (contentTemplate) {
    case CONTENT_TEMPLATES.CHEAP: {
      return `Cheap ${heroTitle}`;
    }
    case CONTENT_TEMPLATES.PRICE: {
      return `Best Prices for ${heroTitle}`;
    }
    case CONTENT_TEMPLATES.TODAY: {
      return `${heroTitle} for Today's Game`;
    }
    default: {
      return heroTitle;
    }
  }
}
