import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

interface ButtonProps {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  buttonClass?: 'text' | 'affirm' | 'apple-pay' | 'google-pay' | 'secondary';
  onClick: React.HTMLAttributes<HTMLButtonElement>['onClick'];
  disabled?: boolean;
}

function Button({
  children,
  type = 'button',
  buttonClass,
  onClick,
  disabled,
}: ButtonProps) {
  return (
    <button
      className={classNames(styles.button, buttonClass && styles[buttonClass])}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
