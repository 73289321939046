import React from 'react';
import Button from 'ui/Button/Button';

import { GooglePay } from '../../../../../icons';

interface GooglePayPurchaseButtonProps {
  onClick: () => void;
}

function GooglePayPurchaseButton({ onClick }: GooglePayPurchaseButtonProps) {
  return (
    <Button onClick={onClick} buttonClass="secondary">
      Buy with <GooglePay height={24} fill="currentColor" width={60} />
    </Button>
  );
}

export default GooglePayPurchaseButton;
