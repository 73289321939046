import { Listing } from 'types';

import { EXTERNAL_ACCOUNT_TYPES } from './user.constants';

/**
 * Returns external account type based on ticket type, if no external account
 * type exist for the ticket type, return undefined.
 */
export function getExternalAccountType(ticketType: Listing['ticket_type']) {
  switch (ticketType) {
    case 'tdc': {
      return EXTERNAL_ACCOUNT_TYPES.MLB_TDC;
    }
    case 'tm-official': {
      return EXTERNAL_ACCOUNT_TYPES.MLB_TM;
    }
    default: {
      return;
    }
  }
}
