import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  Click,
  ClickTracker,
  RedeemCode,
  SOURCE_PAGE_TYPE_NAMES,
  useAnalyticsContext,
} from 'analytics';
import SimpleButton, { TYPES } from 'components/Buttons/SimpleButton';
import TextInput from 'components/Inputs/TextInput';
import DiscountIcon from 'icons/DiscountIcon';
import { useAppDispatch } from 'store';
import { addUserPromoCode } from 'store/modules/user/actions';
import { HttpError } from 'utils/errors';

import styles from './UserPromoCodeForm.module.scss';

const UserPromoCodeFormValidationSchema = Yup.object().shape({
  promoCode: Yup.string().trim().required('Please enter a promo code.'),
});

interface UserPromoCodeFormProps {
  onLoading: (loading: boolean) => void;
  onSuccess: (codeType: 'credit' | 'promo') => void;
  errorMessage?: string;
}

interface Values {
  promoCode: string;
}

const UserPromoCodeForm = ({
  onLoading,
  onSuccess,
  errorMessage = '',
}: UserPromoCodeFormProps) => {
  const analytics = useAnalyticsContext();
  const dispatch = useAppDispatch();

  const clickTracker = new ClickTracker()
    .targetPageType(Click.TARGET_PAGE_TYPES.REDEEM_CODE())
    .sourcePageType(Click.SOURCE_PAGE_TYPES.REDEEM_CODE())
    .interaction(Click.INTERACTIONS.NONE());

  const onSubmitHandler = async (
    values: Values,
    { setFieldError, setSubmitting, resetForm }: FormikHelpers<Values>
  ) => {
    analytics.track(
      new Click(clickTracker.payload({ code: values.promoCode }).json())
    );
    onLoading(true);

    try {
      const addUserPromoCodeResponse = await dispatch(
        addUserPromoCode(values.promoCode)
      );

      analytics.track(
        new RedeemCode({
          status: 'succeeded',
          code: values.promoCode,
          page_type: SOURCE_PAGE_TYPE_NAMES.REDEEM_CODE,
        })
      );

      setSubmitting(false);
      resetForm({
        values: {
          promoCode: '',
        },
      });

      onSuccess(addUserPromoCodeResponse.code_type);
    } catch (error) {
      analytics.track(
        new RedeemCode({
          status: 'failed',
          code: values.promoCode,
          page_type: SOURCE_PAGE_TYPE_NAMES.REDEEM_CODE,
        })
      );

      if (
        error instanceof HttpError &&
        typeof error.body?.subtitle === 'string'
      ) {
        setFieldError(
          'promoCode',
          error.body.subtitle ||
            'There was an error applying the promo code to your account.'
        );
      }

      onLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ promoCode: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={UserPromoCodeFormValidationSchema}
    >
      {({
        touched,
        errors,
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit} className={styles['promo-codes']}>
            <TextInput
              id="promo-code"
              name="promoCode"
              label="Promo Code"
              placeholder="Enter Promo Code"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError:
                  (!!errors.promoCode && !!touched.promoCode) || !!errorMessage,
                message: errors.promoCode || errorMessage,
              }}
              value={values.promoCode}
              startIcon={<DiscountIcon />}
              autoComplete="off"
            />
            <SimpleButton
              text="redeem"
              className={styles.submit}
              type={TYPES.GREEN_MEDIUM}
              disabled={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserPromoCodeForm;
