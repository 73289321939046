import * as React from 'react';

export default function Seats({
  fill = 'currentColor',
  height = 16,
  width = 16,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.00006 9.49999L4.00006 8H8.00006H12.0001L12.0001 9.49999H4.00006ZM2.00006 11.7964V14.9961C2.00006 15.5484 2.44778 15.9961 3.00006 15.9961H4.00006V16H5.50006C5.7762 16 6.00006 15.7761 6.00006 15.5V14.5C6.00006 14.2239 5.7762 14 5.50006 14H4.00006V12.7236C5.42441 12.9422 6.43694 12.9998 7.99994 13H8.00016C9.56317 12.9998 10.5757 12.9422 12.0001 12.7236L12.0001 14H10.5001C10.2239 14 10.0001 14.2239 10.0001 14.5V15.5C10.0001 15.7761 10.2239 16 10.5001 16H12.0001L12.0001 15.9961H13.0001C13.5523 15.9961 14.0001 15.5484 14.0001 14.9961V11.7964L14.6364 11.2714C14.9619 11.0029 15.0838 10.559 14.9412 10.1619C14.7985 9.76481 14.422 9.49999 14.0001 9.49999V2.99612C14.0001 2.77975 13.9299 2.56921 13.8001 2.39612L13.0009 2.9955C13.8001 2.39612 13.7997 2.39567 13.7994 2.39522L13.7987 2.39427L13.7971 2.39224L13.7936 2.38762L13.7848 2.37621C13.7782 2.36765 13.7699 2.3572 13.76 2.34499C13.7403 2.32059 13.7141 2.28912 13.6813 2.25163C13.6157 2.17669 13.5235 2.0774 13.4034 1.96218C13.1635 1.7319 12.8108 1.43642 12.3363 1.14472C11.3807 0.557212 9.95079 -0.00216196 7.99895 6.28287e-06C6.04836 0.00217303 4.61957 0.561205 3.66457 1.14718C3.19036 1.43815 2.8378 1.73265 2.59788 1.96215C2.47784 2.07698 2.3856 2.17592 2.32 2.2506C2.28719 2.28796 2.26098 2.31932 2.24124 2.34365C2.23137 2.35581 2.22311 2.36623 2.21644 2.37476L2.20763 2.38615L2.20411 2.39075L2.20258 2.39278L2.20186 2.39372C2.20152 2.39418 2.20119 2.39462 2.99924 2.9955L2.20119 2.39462C2.07065 2.56799 2.00006 2.77911 2.00006 2.99612V9.49999H2.00005C1.57813 9.49999 1.2016 9.76481 1.05894 10.1619C0.916289 10.559 1.03821 11.0029 1.36367 11.2714L2.00006 11.7964ZM8.35361 1.85355C8.15835 1.65829 7.84177 1.65829 7.64651 1.85355L6.85361 2.64644C6.65835 2.8417 6.65835 3.15829 6.85362 3.35355L7.64651 4.14644C7.84177 4.3417 8.15835 4.3417 8.35362 4.14644L9.14651 3.35355C9.34177 3.15829 9.34177 2.8417 9.14651 2.64644L8.35361 1.85355Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
