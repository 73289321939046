import React from 'react';
import PropTypes from 'prop-types';

import iosStoreBadge from 'assets/download-on-the-app-store-badge.svg';
import playStoreBadge from 'assets/get-it-on-google-play-badge.svg';
import config from 'config/config';

import styles from './AppBadgesRow.module.scss';

export const ALT_TEXT_IOS = 'Get the Gametime app on the Apple App Store';
export const ALT_TEXT_ANDROID = 'Get the Gametime app on Google Play';

const AppBadgesRow = ({ messaging }) => (
  <div className={styles['app-banner']}>
    {messaging && <p className={styles['app-banner-title']}>{messaging}</p>}
    <div className={styles['app-badges-container']}>
      <a href={config.APP_STORE_URL} target="_blank" rel="noopener noreferrer">
        <img src={iosStoreBadge} alt={ALT_TEXT_IOS} />
      </a>
      <a href={config.PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
        <img src={playStoreBadge} alt={ALT_TEXT_ANDROID} />
      </a>
    </div>
  </div>
);

AppBadgesRow.propTypes = {
  messaging: PropTypes.string,
};

export default AppBadgesRow;
