import React from 'react';

import BreadCrumbs from 'components/Breadcrumbs/Breadcrumbs';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { DataLoader } from 'routes/routes.utils';
import { useAppSelector } from 'store';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectAllMetros } from 'store/modules/resources/resource.selectors';

import SitemapURLsList from '../components/SitemapURLsList';
import { getMetrosSitemapURLItems } from '../helpers';

import styles from '../Sitemap.module.scss';

const MetrosSitemap = () => {
  const metros = useAppSelector(selectAllMetros);
  const title = 'Major Cities and Metropolitan Areas Sitemap';
  const breadcrumbs = [
    { name: 'Gametime', url: '/' },
    { name: 'Sitemap', url: '/html-sitemap' },
  ];

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={<MinimalHeader search showAccount showHamburger showCategories />}
      footer={<GTFooter />}
    >
      <HeadTitle title={title} />

      <div className={styles.container}>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <h1 className={styles.title}>{title}</h1>
        {metros.length > 0 && (
          <SitemapURLsList urlItems={getMetrosSitemapURLItems(metros)} />
        )}
      </div>
    </ContainerTemplate>
  );
};

const loader: DataLoader =
  ({ store }) =>
  async () => {
    await store.dispatch(fetchMetros());
    return null;
  };

MetrosSitemap.loader = loader;

export default MetrosSitemap;
