import React, { useState } from 'react';
import classNames from 'classnames';
import { FormikHelpers } from 'formik';
import Mailcheck from 'mailcheck';

import { UserIcon } from 'icons';
import MLBIcon from 'icons/MLBIcon';
import FormSection from 'pages/CheckoutV3/components/Forms/Inputs/FormSection';
// import EmailVerificationModal from 'components/Modals/EmailVerificationModal/EmailVerificationModal';
import PurchaseHeading from 'pages/PurchaseV3/components/PurchaseHeading';
import { useAppSelector } from 'store';
import {
  selectUserDetails,
  selectUserExternalAccount,
} from 'store/modules/user/user.selectors';

import { Form, Input, Validators } from '../../../CheckoutV3/components/Forms';
import MLBCollectionAlertV2 from '../../components/MLBCollectionAlertV2';

import styles from './PurchaseUserTransfer.module.scss';

const UserNameEmailFormValidationSchema = Validators.schema({
  firstName: Validators.string({ label: 'first name', required: true }),
  lastName: Validators.string({ label: 'last name', required: true }),
  email: Validators.email({ label: 'email address', required: true }),
});

interface PurchaseUserTransferProps {
  onSubmit: (form: Values) => void;
  isMLBInfoCollection: boolean;
}

interface Values {
  firstName: string;
  lastName: string;
  email: string;
}

function PurchaseUserTransfer({
  onSubmit,
  isMLBInfoCollection,
}: PurchaseUserTransferProps) {
  Mailcheck.defaultDomains.push('gametime.co');

  //   const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [, setShowEmailVerificationModal] = useState(false);

  function getUserDetails(): Values {
    const user = useAppSelector(selectUserDetails);
    const externalAccount = useAppSelector(selectUserExternalAccount);

    if (isMLBInfoCollection) {
      return {
        firstName: externalAccount?.first_name || '',
        lastName: externalAccount?.last_name || '',
        email: externalAccount?.email || '',
      };
    }

    return {
      firstName: user.tmmobile_first_name || '',
      lastName: user.tmmobile_last_name || '',
      email: user.tmmobile_email ?? (user.email || ''),
    };
  }

  async function handleSubmit(
    form: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) {
    try {
      setShowEmailVerificationModal(false);
      setSubmitting(true);
      await onSubmit(form);
    } catch {
      setSubmitting(false);
    }
  }

  const initialValues = getUserDetails();
  const heading = isMLBInfoCollection
    ? 'MLB Account Information'
    : 'Verify Your Contact Information';
  const subheading = isMLBInfoCollection
    ? "We'll use this information to deliver your tickets from MLB to the Gametime app."
    : 'Due to restrictions set by the venue or the seller, these tickets will be delivered outside of Gametime to your contact email.';

  return (
    <>
      {isMLBInfoCollection ? (
        <div className={styles['mlb-marketplace']}>
          <MLBIcon height="18" width="32" />
          <span className={styles['marketplace-text']}>
            Authorized Ticket Marketplace of MLB
          </span>
        </div>
      ) : (
        <UserIcon fill="currentColor" />
      )}
      <PurchaseHeading heading={heading} subheading={subheading} />
      {isMLBInfoCollection && <MLBCollectionAlertV2 />}
      <Form
        id="user-transfer-form"
        initialValues={{
          firstName: initialValues.firstName,
          lastName: initialValues.lastName,
          email: initialValues.email,
        }}
        onSubmit={handleSubmit}
        submitText="Continue"
        validationSchema={UserNameEmailFormValidationSchema}
        className={styles.form}
      >
        <FormSection classNameList={{ fieldset: styles.fieldset }}>
          <Input
            id="transfer-purchase-first-name"
            label="First Name"
            name="firstName"
            required
            type="text"
            className={classNames(styles['first-last'], styles['cols-2'])}
          />
          <Input
            id="transfer-purchase-last-name"
            label="Last Name"
            name="lastName"
            required
            type="text"
            className={classNames(styles['first-last'], styles['cols-2'])}
          />
        </FormSection>
        <Input
          id="transfer-purchase-email"
          name="email"
          label="Email Address"
          type="email"
        />

        {/* TODO: Fix verification modal */}
        {/* {showEmailVerificationModal && (
          <EmailVerificationModal
            email={values.email}
            handleFix={() => setShowEmailVerificationModal(false)}
            handleConfirmation={() => {
              onSubmit(values);
            }}
          />
        )} */}
      </Form>
    </>
  );
}

export default PurchaseUserTransfer;
