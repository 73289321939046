// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

interface GuaranteeShieldProps
  extends Pick<React.SVGProps<SVGSVGElement>, 'height' | 'width'> {
  isDark?: boolean;
}

export default function GuaranteeShield({
  height = 70,
  width = 73,
  isDark = false,
}: GuaranteeShieldProps) {
  return (
    <svg
      aria-hidden="true"
      className="icon"
      data-testid="guarantee-shield-icon"
      focusable="false"
      height={height}
      role="img"
      viewBox="0 0 70 73"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      {isDark ? (
        <>
          <g filter="url(#a)">
            <path
              fill="#F5F0FF"
              fillRule="evenodd"
              d="M12.115 13.108A4.572 4.572 0 0 0 9 17.447v13.412c0 7.467 1.887 14.91 6.223 21.264 4.345 6.37 11.07 11.53 20.544 14.54.368.116.764.116 1.132 0 19.085-6.064 26.768-20.758 26.768-35.418V17.447a4.572 4.572 0 0 0-3.115-4.34L37.774 5.486a4.538 4.538 0 0 0-2.881 0l-22.778 7.623Z"
              clipRule="evenodd"
            />
          </g>
          <path
            fill="url(#b)"
            fillRule="evenodd"
            d="M13.124 13.956a4.401 4.401 0 0 0-2.985 4.178V31.05c0 7.19 1.808 14.357 5.963 20.476 4.164 6.133 10.61 11.103 19.688 14 .354.113.732.113 1.085 0 18.29-5.838 25.653-19.988 25.653-34.105V18.134a4.401 4.401 0 0 0-2.986-4.178L37.714 6.615a4.33 4.33 0 0 0-2.761 0l-21.83 7.34Z"
            clipRule="evenodd"
          />
          <path
            fill="url(#c)"
            fillOpacity={0.75}
            fillRule="evenodd"
            d="M13.124 13.956a4.401 4.401 0 0 0-2.985 4.178V31.05c0 7.19 1.808 14.357 5.963 20.476 4.164 6.133 10.61 11.103 19.688 14 .354.113.732.113 1.085 0 18.29-5.838 25.653-19.988 25.653-34.105V18.134a4.401 4.401 0 0 0-2.986-4.178L37.714 6.615a4.33 4.33 0 0 0-2.761 0l-21.83 7.34Z"
            clipRule="evenodd"
            style={{ mixBlendMode: 'overlay' }}
          />
          <g filter="url(#d)">
            <path
              fill="#E2DFFF"
              fillRule="evenodd"
              d="M48.932 28.625a.788.788 0 0 1-.015 1.125l-16.2 15.441a.394.394 0 0 1-.543 0l-9.091-8.666a.788.788 0 0 1-.015-1.125l2.195-2.204a.787.787 0 0 1 1.101-.015l6.082 5.797 13.19-12.572c.31-.295.799-.289 1.1.015l2.196 2.204Z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <filter
              id="a"
              width={71.766}
              height={79.05}
              x={0.45}
              y={-3.3}
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx={-1.8} dy={1.8} />
              <feGaussianBlur stdDeviation={3.375} />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0.128313 0 0 0 0 0.0924165 0 0 0 0 0.361637 0 0 0 0.45 0" />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4032_52598"
              />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx={-1.8} dy={3.6} />
              <feGaussianBlur stdDeviation={2.7} />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0.16338 0 0 0 0 0.000874085 0 0 0 0 0.61027 0 0 0 0.08 0" />
              <feBlend
                in2="effect1_dropShadow_4032_52598"
                result="effect2_dropShadow_4032_52598"
              />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx={1.8} dy={-1.8} />
              <feGaussianBlur stdDeviation={3.375} />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0.121721 0 0 0 0 0.0444681 0 0 0 0 0.198975 0 0 0 0.42 0" />
              <feBlend
                in2="effect2_dropShadow_4032_52598"
                result="effect3_dropShadow_4032_52598"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect3_dropShadow_4032_52598"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx={-1.125} dy={1.125} />
              <feGaussianBlur stdDeviation={1.125} />
              <feComposite
                in2="hardAlpha"
                k2={-1}
                k3={1}
                operator="arithmetic"
              />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
              <feBlend in2="shape" result="effect4_innerShadow_4032_52598" />
              <feGaussianBlur
                result="effect5_foregroundBlur_4032_52598"
                stdDeviation={0.169}
              />
            </filter>
            <filter
              id="d"
              width={35.324}
              height={28.111}
              x={17.213}
              y={23.939}
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx={-1.125} dy={2.25} />
              <feGaussianBlur stdDeviation={2.25} />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4032_52598"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_4032_52598"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy={0.563} />
              <feComposite
                in2="hardAlpha"
                k2={-1}
                k3={1}
                operator="arithmetic"
              />
              <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0" />
              <feBlend in2="shape" result="effect2_innerShadow_4032_52598" />
            </filter>
            <linearGradient
              id="b"
              x1={36.333}
              x2={36.333}
              y1={6.389}
              y2={65.611}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A67CFF" />
              <stop offset={1} stopColor="#505CD4" />
            </linearGradient>
            <pattern
              id="c"
              width={21.989}
              height={19.452}
              patternContentUnits="objectBoundingBox"
            >
              <use xlinkHref="#e" transform="scale(.02147 .019)" />
            </pattern>
          </defs>
        </>
      ) : (
        <>
          <g filter="url(#filter0_dddf_4435_17614)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.115 13.3686C8.25474 13.9912 7 15.739 7 17.7078V31.1201C7 38.5869 8.88705 46.0293 13.2225 52.3841C17.5677 58.7533 24.2939 63.9135 33.7668 66.9229C34.1355 67.04 34.5305 67.04 34.8991 66.9229C53.9839 60.8601 61.6667 46.166 61.6667 31.506V17.7078C61.6667 15.739 60.4119 13.9912 58.5517 13.3686L35.7739 5.74546C34.8388 5.4325 33.8278 5.4325 32.8927 5.74546L10.115 13.3686Z"
              fill="white"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1248 14.2162C9.3421 14.8157 8.13965 16.4989 8.13965 18.3947V31.3102C8.13965 38.5006 9.94807 45.6673 14.1029 51.7867C18.2671 57.92 24.7129 62.8892 33.7912 65.787C34.1445 65.8998 34.523 65.8998 34.8763 65.787C53.1659 59.9488 60.5285 45.7989 60.5285 31.6819V18.3947C60.5285 16.4989 59.3261 14.8157 57.5434 14.2162L35.7147 6.87544C34.8185 6.57407 33.8497 6.57407 32.9535 6.87544L11.1248 14.2162Z"
            fill="url(#paint0_linear_4435_17614)"
          />
          <g filter="url(#filter1_di_4435_17614)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.9319 28.8854C47.2436 29.1984 47.237 29.7065 46.9173 30.0112L30.7177 45.4518C30.5656 45.5968 30.3265 45.5968 30.1744 45.4518L21.0827 36.7861C20.763 36.4814 20.7564 35.9733 21.0681 35.6603L23.2633 33.4565C23.5654 33.1531 24.0546 33.1467 24.3645 33.4422L30.446 39.2387L43.6355 26.6673C43.9454 26.3718 44.4346 26.3782 44.7367 26.6815L46.9319 28.8854Z"
              fill="#F6F5FF"
            />
          </g>
          <defs>
            <filter
              id="filter0_dddf_4435_17614"
              x="0.475"
              y="-0.114258"
              width="69.0667"
              height="76.125"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1.125" dy="1.125" />
              <feGaussianBlur stdDeviation="2.25" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.128313 0 0 0 0 0.0924165 0 0 0 0 0.361637 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4435_17614"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1.125" dy="3.6" />
              <feGaussianBlur stdDeviation="2.7" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.16338 0 0 0 0 0.000874085 0 0 0 0 0.61027 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_4435_17614"
                result="effect2_dropShadow_4435_17614"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1.125" dy="1.125" />
              <feGaussianBlur stdDeviation="3.375" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.876547 0 0 0 0 0.753092 0 0 0 0 1 0 0 0 0.42 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_4435_17614"
                result="effect3_dropShadow_4435_17614"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_4435_17614"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.16875"
                result="effect4_foregroundBlur_4435_17614"
              />
            </filter>
            <filter
              id="filter1_di_4435_17614"
              x="15.2136"
              y="24.2002"
              width="35.3229"
              height="28.1104"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1.125" dy="2.25" />
              <feGaussianBlur stdDeviation="2.25" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4435_17614"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4435_17614"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="0.5625" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect2_innerShadow_4435_17614"
              />
            </filter>
            <linearGradient
              id="paint0_linear_4435_17614"
              x1="34.3341"
              y1="6.64941"
              x2="34.3341"
              y2="65.8716"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A67CFF" />
              <stop offset="1" stopColor="#505CD4" />
            </linearGradient>
          </defs>
        </>
      )}
    </svg>
  );
}
