import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';
import { useSeatDescription } from 'components/SeatDescription/useSeatDescription';
import ChevronIcon from 'icons/ChevronIcon';
import colors from 'styles/colors.constants';

import { getTicketURL } from './helpers';

import styles from './TicketCard.module.scss';

const TicketCard = ({
  ticket,
  sectionGroup,
  section,
  row,
  primaryPerformerSlug,
  clickTracker,
  deliveryFormat,
}) => {
  const { title, seat } = useSeatDescription({
    sectionGroup,
    section,
    row,
    ticket,
  });
  return (
    <Link
      className={styles['ticket-card']}
      to={getTicketURL(
        ticket.id,
        primaryPerformerSlug,
        deliveryFormat,
        ticket.type === 'url' ||
          (ticket.type_info?.display_pdf_first && ticket.url)
      )}
      target="_blank"
      rel="noopener noreferrer"
      clickTracker={clickTracker}
    >
      <div className={styles['ticket-card-description']}>
        <span className={styles['ticket-card-title']}>{title}</span>
        {!!ticket.seat && ticket.seat !== '*' && (
          <span className={styles['ticket-card-subtitle']}>{seat}</span>
        )}
      </div>
      <ChevronIcon
        width={12}
        height={12}
        color={colors.gray200}
        direction="right"
      />
    </Link>
  );
};

TicketCard.propTypes = {
  ticket: PropTypes.object.isRequired,
  sectionGroup: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
  primaryPerformerSlug: PropTypes.string.isRequired,
  clickTracker: PropTypes.object,
  deliveryFormat: PropTypes.string,
};

export default TicketCard;
