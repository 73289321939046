import React from 'react';
import { HostedFieldFieldOptions } from 'braintree-web';
import {
  HostedFields,
  HostedFieldsHostedFieldsCard,
} from 'braintree-web/hosted-fields';
import classNames from 'classnames';

import { CreditCardBackFillIcon, CreditCardsBarIcon } from 'icons';

import {
  BraintreeAllowedStyles,
  useBraintreeHostedFields,
} from '../../../../../helpers/Braintree';
import { OptionalDictionary } from '../../../Dictionaries';

import Field from './Field';

import styles from '../Form.module.scss';

const fieldIdDictionary = {
  cardNumber: 'add-card-modal-cc-number',
  cvv: 'add-card-modal-cvv',
  expiration: 'add-card-modal-expiration-date',
};

export const options: HostedFieldFieldOptions = {
  number: {
    selector: `#${fieldIdDictionary.cardNumber}`,
    maxCardLength: 16,
    supportedCardBrands: {
      visa: true,
      mastercard: true,
      'american-express': true,
      discover: true,
      jcb: false,
    },
  },
  cvv: {
    selector: `#${fieldIdDictionary.cvv}`,
  },
  expirationDate: {
    selector: `#${fieldIdDictionary.expiration}`,
  },
};

export const styleOptions: BraintreeAllowedStyles = {
  input: {
    'font-family': "'Inter', sans-serif",
    'font-size': '1em',
    'font-weight': 500,
    'line-height': 20 / 16,
    color: 'var(--form-color-text, #313136)',
    outline: 'none',
    padding: '0',
  },
  '::placeholder': {
    color: 'var(--form-color-placeholder, #C4C7CF)',
  },
};

export function PaymentInput({
  classNameList,
  id,
  empty = true,
  error,
  icon,
  label,
  onLabelClick: handleLabelClick,
}: {
  classNameList?: OptionalDictionary<'field' | 'input'>;
  empty?: boolean;
  error?: string;
  icon?: React.ReactElement;
  id: string;
  label: string;
  onLabelClick?: () => void;
}): React.JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Field
      className={classNameList?.field}
      error={error}
      icon={icon}
      id={id}
      label={label}
      onLabelClick={handleLabelClick}
    >
      <div
        className={classNames(
          styles['form-control'],
          styles.payment,
          classNameList?.input,
          empty ? styles['payment-empty'] : styles['payment-filled']
        )}
        id={id}
        data-testid="braintree-hosted-field-root"
        ref={ref}
      />
    </Field>
  );
}

const PaymentInputs = React.forwardRef<
  unknown,
  {
    classNameList?: OptionalDictionary<'field' | 'input'>;
  }
>(({ classNameList }, ref): React.JSX.Element => {
  const [cardType, setCardType] = React.useState<
    HostedFieldsHostedFieldsCard['type'] | undefined
  >();

  const internalRef = React.useRef<HostedFields | null>(null);

  const {
    createHostedFields,
    fieldEmpty,
    fieldErrors,
    fieldTouched,
    hostedFields,
  } = useBraintreeHostedFields();

  React.useImperativeHandle(ref, () => hostedFields);

  React.useEffect(() => {
    if (hostedFields) {
      return;
    }

    createHostedFields(options, styleOptions).then(
      (instance: void | HostedFields): void => {
        if (!instance) {
          return;
        }

        instance.on('cardTypeChange', (event) => {
          setCardType(
            event.cards.length === 1 ? event.cards[0].type : undefined
          );
        });

        internalRef.current = instance;
      }
    );
  }, [createHostedFields]);

  return (
    <>
      <PaymentInput
        classNameList={classNameList}
        empty={fieldEmpty.number}
        error={fieldTouched.number ? fieldErrors.number : undefined}
        icon={<CreditCardsBarIcon cardType={cardType} />}
        id={fieldIdDictionary.cardNumber}
        label="Credit Card Number"
        onLabelClick={(): void => {
          internalRef.current?.focus('number');
        }}
      />

      <PaymentInput
        classNameList={classNameList}
        empty={fieldEmpty.expirationDate}
        error={
          fieldTouched.expirationDate ? fieldErrors.expirationDate : undefined
        }
        id={fieldIdDictionary.expiration}
        label="Expiration"
        onLabelClick={(): void => {
          internalRef.current?.focus('expirationDate');
        }}
      />

      <PaymentInput
        classNameList={classNameList}
        empty={fieldEmpty.cvv}
        error={fieldTouched.cvv ? fieldErrors.cvv : undefined}
        icon={
          <CreditCardBackFillIcon fill="var(--form-color-label, var(--color-text-default-tertiary, currentColor))" />
        }
        id={fieldIdDictionary.cvv}
        label="CVV"
        onLabelClick={(): void => {
          internalRef.current?.focus('cvv');
        }}
      />
    </>
  );
});

export default PaymentInputs;
