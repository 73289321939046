import { CATEGORY_URLS } from 'store/modules/categories/category.helpers';

export const SORT_IDS = {
  DISCOUNT: 'discount',
  PRICEL: 'price (low to high)',
  PRICEH: 'price (high to low)',
};

export const SORT_OPTIONS = [
  SORT_IDS.PRICEL,
  SORT_IDS.PRICEH,
  SORT_IDS.DISCOUNT,
];

export const SORT_ORDER = {
  DISCOUNT: 'discount',
  PRICEL: 'low_to_high',
  PRICEH: 'high_to_low',
};

const DEFAULT_USER_PREFERRED_SORT_ID = SORT_IDS.PRICEL;

export const userPreferenceSelector = (state) => state.userPreference;

export const userPreferenceSiteVisitSelector = (state) =>
  userPreferenceSelector(state).siteVisitCount;

export const userPreferredSortIdSelector = (state) => {
  const { sortId } = userPreferenceSelector(state);

  return sortId || DEFAULT_USER_PREFERRED_SORT_ID;
};

export const lastVisitedCategorySelector = (state) => {
  const lastVisitedCategoryId =
    userPreferenceSelector(state).lastVisitedCategory;
  return CATEGORY_URLS[lastVisitedCategoryId];
};

/**
 * @param {import('store').RootState} state
 * @returns {boolean}
 */
export const userPreferenceShowAllInPriceSelector = (state) =>
  !!userPreferenceSelector(state).showAllInPrice;

export const userPreferencePostPurchaseSurveySelector = (state) =>
  userPreferenceSelector(state).surveyResolved;

/**
 * @param {import('store').RootState} state
 * @returns {import('types').GetListingsV3RequestParams['sort_order']}
 */
export const getPreferenceSortOrder = (state) => {
  let { sortId } = userPreferenceSelector(state);
  sortId = sortId || DEFAULT_USER_PREFERRED_SORT_ID;

  // default sort order query param
  let sortOrderQueryParam = SORT_ORDER.PRICEL;

  if (sortId === SORT_IDS.PRICEH) {
    sortOrderQueryParam = SORT_ORDER.PRICEH;
  } else if (sortId === SORT_IDS.DISCOUNT) {
    sortOrderQueryParam = SORT_ORDER.DISCOUNT;
  }

  return sortOrderQueryParam;
};

/**
 * @param {import('store').RootState} state
 * @returns {number}
 */
export const userPreferenceSeatCountSelector = (state) =>
  parseInt(userPreferenceSelector(state).seatCount, 10);
