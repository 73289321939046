import React from 'react';

import BreadCrumbs from 'components/Breadcrumbs/Breadcrumbs';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { DataLoader } from 'routes/routes.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchVenuesData } from 'store/modules/venues/actions';
import {
  selectFetchVenuesData,
  selectIsVenuesLoading,
} from 'store/modules/venues/selectors';

import SitemapURLsList from '../components/SitemapURLsList';
import { getVenuesSitemapURLItems } from '../helpers';

import styles from '../Sitemap.module.scss';

const VENUES_PER_PAGE = 100;

const VenuesSitemap = () => {
  const dispatch = useAppDispatch();
  const venuesData = useAppSelector(selectFetchVenuesData);
  const isVenuesLoading = useAppSelector(selectIsVenuesLoading);
  const title = 'Venues Sitemap';
  const breadcrumbs = [
    { name: 'Gametime', url: '/' },
    { name: 'Sitemap', url: '/html-sitemap' },
  ];

  const handleClickPaginationButton = () => {
    dispatch(
      fetchVenuesData({
        page: venuesData.page + 1,
        per_page: VENUES_PER_PAGE,
      })
    );
  };

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={<MinimalHeader search showAccount showHamburger showCategories />}
      footer={<GTFooter />}
    >
      <HeadTitle title={title} />

      <div className={styles.container}>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <h1 className={styles.title}>{title}</h1>
        {venuesData.results.length === 0 && (
          <p className={styles['no-results']}>No venues found.</p>
        )}
        {venuesData.results.length > 0 && (
          <SitemapURLsList
            urlItems={getVenuesSitemapURLItems(venuesData.results)}
          />
        )}
        {venuesData.more && (
          <PaginationButton
            onClick={handleClickPaginationButton}
            loading={isVenuesLoading}
            showSeparator
          />
        )}
      </div>
    </ContainerTemplate>
  );
};

const loader: DataLoader =
  ({ store }) =>
  async () => {
    await store.dispatch(
      fetchVenuesData({ page: 1, per_page: VENUES_PER_PAGE })
    );

    return null;
  };

VenuesSitemap.loader = loader;

export default VenuesSitemap;
