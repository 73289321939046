import { ListingV3, SpotV3 } from 'types';
import { formatUrl } from 'utils/format';

import FullEvent from './FullEvent';
import Price from './Price';
import Spot from './Spot';
import Venue from './Venue';

export const ZONE_TICKET_DISCLOSURE = 'zone_ticket';

export default class Listing {
  public readonly id: ListingV3['id'];
  public readonly eventId: ListingV3['event_id'];

  // TODO: update name to availableLots
  public readonly lotsList: ListingV3['available_lots'];
  /**
   * Ticket quantities ordered from lowest to highest.
   */
  public readonly ticketQuantities: ListingV3['available_lots'];

  public readonly price: Price;
  public readonly signature: ListingV3['signature'];
  public readonly displaySavings: ListingV3['display_savings'];

  public readonly deliveryType: ListingV3['delivery_type'];
  public readonly transferType: ListingV3['transfer_type'];
  public readonly ticketType: ListingV3['ticket_type'];
  public readonly source: ListingV3['source'];

  public readonly spot: Spot;
  public readonly position: SpotV3['position'];
  public readonly section: SpotV3['section'];
  public readonly sectionGroup: SpotV3['section_group'];
  public readonly row: SpotV3['row'];
  public readonly viewUrl: SpotV3['view_url'];
  public readonly seatMarker: { top: string; left: string };

  public readonly seats: ListingV3['seats'];
  public readonly disclosures: ListingV3['disclosures'];

  /**
   * Whether the listing is visible in the map view, used for list/map harmony
   */
  _isVisibleInMap: boolean;

  constructor(
    public readonly data: ListingV3,
    public readonly quantity: number
  ) {
    this.id = data.id;
    this.eventId = data.event_id;

    this.lotsList = data.available_lots;
    this.ticketQuantities = data.available_lots.slice().sort((a, b) => a - b);

    this.price = new Price(data.price);
    this.signature = data.signature;
    this.displaySavings = data.display_savings;

    this.deliveryType = data.delivery_type;
    this.transferType = data.transfer_type;
    this.ticketType = data.ticket_type;
    this.source = data.source;

    // update to SpotV3?
    this.spot = new Spot({
      position: data.spot.position,
      disclosures: data.disclosures,
      row: data.spot.row,
      section: data.spot.section,
      section_group: data.spot.section_group,
      view_url: data.spot.view_url,
    });
    this.position = data.spot.position;
    this.row = data.spot.row;
    this.section = this._formatSectionName(data.spot.section);
    this.sectionGroup = data.spot.section_group;
    this.viewUrl = data.spot.view_url;
    this.seatMarker = {
      top: `${75 * (this.position.y / 2400) + 30}%`,
      left: `${90 * (this.position.x / 3200) + 30}%`,
    };

    this.seats = data.seats;
    this.disclosures = data.disclosures;

    this._isVisibleInMap = false;
  }

  // TODO: rename to getPathname
  public getPath(event?: FullEvent) {
    const eventPath = event?.getPath() || `/events/${this.eventId}`;
    return `${eventPath}/listings/${this.id}`;
  }

  getImageOptions(venue?: Venue) {
    const alt = `Seat view from ${this.sectionGroup}${
      venue ? ` at ${venue.name}` : ''
    }`;
    return {
      seoPrefix: venue ? formatUrl(venue.name) : '',
      seoPostfix: formatUrl(this.sectionGroup),
      alt,
      src: this.viewUrl,
    };
  }

  /**
   * prefee price in dollars
   */
  get prefeePrice() {
    return this.price.prefee / 100;
  }

  /**
   * total price in dollars
   */
  get totalPrice() {
    return this.price.total / 100;
  }

  /**
   * sales tax in dollars, will always return 0 for events in states without
   * sales tax
   */
  get salesTax() {
    return this.price.salesTax / 100;
  }

  /**
   * seat fees in dollars
   */
  get fees() {
    return this.totalPrice - this.prefeePrice - this.salesTax;
  }

  getFaceValue() {
    const faceValue = (this.price.faceValue ?? 0) / 100;

    // the method on the old Listing model returned either string (.toFixed(2))
    // or number (integer). Since a formatted string is expected by the caller,
    // we'll just return a string for both cases here.
    return faceValue.toFixed(Number.isInteger(faceValue) ? 0 : 2);
  }

  // rename to getMaxQuantity
  public getMaxLotSize() {
    // ticketQuantities is sorted in the constructor, the last element is the
    // highest quantity available
    return this.ticketQuantities.at(-1) ?? 0;
  }

  public isDiscounted() {
    return !!this.displaySavings.amount;
  }

  public getDiscountPercent() {
    return this.displaySavings.percent ?? 0;
  }

  public getSavingsAmount() {
    return (this.displaySavings.amount ?? 0) / 100;
  }

  get dealType() {
    return this.data.deal;
  }

  get hasZoneTicketDisclosure() {
    return this.disclosures.includes(ZONE_TICKET_DISCLOSURE);
  }

  /**
   * The ListingV2 version had a default value of true, but TS says no default
   * params on setters, so we need to make sure this is OK. Or better yet,
   * let's remove this from the model entirely...
   */
  public set isVisibleInMap(isVisible: boolean) {
    this._isVisibleInMap = isVisible;
  }

  public get isVisibleInMap() {
    return this._isVisibleInMap;
  }

  /**
   * Remove underscores and hyphens from section names and capitalize the first
   * letter of each word.
   */
  _formatSectionName(originalSectionName: string) {
    return originalSectionName
      .split(/[\s-_]/)
      .filter(Boolean)
      .map((word) => {
        if (/^sro$/i.test(word)) {
          return word.toUpperCase();
        }

        return word.charAt(0) + word.slice(1).toLowerCase();
      })
      .join(' ');
  }

  public get isGeneralAdmission() {
    const GENERAL_ADMISSIONS_SUBSTRINGS = [
      'general',
      'standing',
      'lawn',
      'floor',
    ];

    const GENERAL_ADMISSIONS_EXACT_MATCHES = ['ga', 'sro'];

    const lowerCaseSection = this.section.toLowerCase();

    return (
      GENERAL_ADMISSIONS_EXACT_MATCHES.includes(lowerCaseSection) ||
      GENERAL_ADMISSIONS_SUBSTRINGS.some((substring) =>
        lowerCaseSection.includes(substring)
      )
    );
  }
}
