import React from 'react';

type SVGProps = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'className'
>;
type Props = SVGProps & {
  primaryColor?: string;
  secondaryColor?: string;
};

export default function LogoLarge({
  width = '160',
  height = '40',
  className,
  primaryColor = '#fff',
  secondaryColor = '#fff',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 160 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)" fill={primaryColor}>
        <path d="M26.6018 8.43555H23.2491C22.9781 8.43555 22.7241 8.61986 22.6733 8.88796L16.8992 31.827C16.7976 32.1956 17.0854 32.5642 17.4749 32.5642H20.6414C20.9292 32.5642 21.2002 32.3632 21.2679 32.0783L22.0976 28.6768C22.1484 28.459 22.3347 28.3082 22.5548 28.3082H27.313C27.5331 28.3082 27.7194 28.459 27.7702 28.6768L28.6168 32.0783C28.6846 32.3632 28.9385 32.5642 29.2433 32.5642H32.3759C32.7654 32.5642 33.0533 32.1956 32.9517 31.827L27.1944 8.88796C27.1267 8.61986 26.8897 8.43555 26.6018 8.43555ZM22.9612 24.0354L24.8577 14.7023H25.027L26.8897 24.0522C26.9235 24.27 26.7711 24.4543 26.551 24.4543H23.2829C23.0797 24.4543 22.9273 24.2532 22.9612 24.0354Z" />
        <path d="M36.135 31.961V9.03876C36.135 8.70364 36.4059 8.43555 36.7446 8.43555H39.5893C39.8264 8.43555 40.0296 8.5696 40.1312 8.78742L44.6015 18.4221H44.7708L49.2412 8.78742C49.3428 8.5696 49.546 8.43555 49.783 8.43555H52.6278C52.9664 8.43555 53.2374 8.70364 53.2374 9.03876V31.961C53.2374 32.2961 52.9664 32.5642 52.6278 32.5642H49.4274C49.0888 32.5642 48.8178 32.2961 48.8178 31.961V18.5897H48.6485L46.1594 23.7003C46.0578 23.9014 45.8546 24.0354 45.6175 24.0354H43.7379C43.5178 24.0354 43.3146 23.9181 43.213 23.717L40.5207 18.5897H40.3513V31.961C40.3513 32.2961 40.0804 32.5642 39.7417 32.5642H36.7615C36.389 32.5642 36.135 32.2961 36.135 31.961Z" />
        <path d="M99.3629 31.961V9.03876C99.3629 8.70364 99.6338 8.43555 99.9725 8.43555H102.817C103.054 8.43555 103.258 8.5696 103.359 8.77067L107.948 18.3886H108.117L112.435 8.78742C112.537 8.5696 112.74 8.43555 112.977 8.43555H115.822C116.16 8.43555 116.431 8.70364 116.431 9.03876V31.961C116.431 32.2961 116.16 32.5642 115.822 32.5642H112.621C112.283 32.5642 112.012 32.2961 112.012 31.961V18.5897H111.843L109.32 23.717C109.218 23.9181 109.015 24.0522 108.778 24.0522H106.898C106.678 24.0522 106.475 23.9349 106.356 23.7338L103.698 18.6065H103.528V31.961C103.528 32.2961 103.258 32.5642 102.919 32.5642H99.9386C99.6338 32.5642 99.3629 32.2961 99.3629 31.961Z" />
        <path d="M94.8249 32.5642H91.4721C91.1335 32.5642 90.8625 32.2961 90.8625 31.961V9.03876C90.8625 8.70364 91.1335 8.43555 91.4721 8.43555H94.8249C95.1635 8.43555 95.4345 8.70364 95.4345 9.03876V31.961C95.4175 32.2961 95.1466 32.5642 94.8249 32.5642Z" />
        <path d="M87.2051 9.03876C87.2051 8.70364 86.9341 8.43555 86.5955 8.43555H73.7433C73.4047 8.43555 73.1337 8.70364 73.1337 9.03876V11.8873C73.1337 12.2224 73.4047 12.4905 73.7433 12.4905H77.5702C77.7564 12.4905 77.9088 12.6413 77.9088 12.8256V32.0616C77.9088 32.3464 78.1459 32.5642 78.4168 32.5642H81.9389C82.2268 32.5642 82.4469 32.3296 82.4469 32.0616V12.8256C82.4469 12.6413 82.5993 12.4905 82.7855 12.4905H86.5616C86.9003 12.4905 87.1712 12.2224 87.1712 11.8873V9.03876H87.2051Z" />
        <path d="M61.687 28.2077V22.6447C61.687 22.4604 61.8394 22.3096 62.0257 22.3096H68.5787C68.8666 22.3096 69.0867 22.075 69.0867 21.8069V18.8913C69.0867 18.6065 68.8497 18.3887 68.5787 18.3887H62.0257C61.8394 18.3887 61.687 18.2379 61.687 18.0535V12.7921C61.687 12.6078 61.8394 12.457 62.0257 12.457H69.4931C69.781 12.457 70.0011 12.2224 70.0011 11.9543V8.95501C70.0011 8.67016 69.764 8.45233 69.4931 8.45233H57.64C57.3521 8.45233 57.132 8.68692 57.132 8.95501V32.0616C57.132 32.3464 57.3691 32.5643 57.64 32.5643H69.8656C70.1535 32.5643 70.3736 32.3297 70.3736 32.0616V29.0622C70.3736 28.7774 70.1366 28.5596 69.8656 28.5596H62.0257C61.8394 28.5428 61.687 28.392 61.687 28.2077Z" />
        <path d="M124.915 28.2077V22.6447C124.915 22.4603 125.067 22.3095 125.254 22.3095H131.705C132.044 22.3095 132.315 22.0414 132.315 21.7063V18.9751C132.315 18.64 132.044 18.3719 131.705 18.3719H125.254C125.067 18.3719 124.915 18.2211 124.915 18.0368V12.7921C124.915 12.6078 125.067 12.457 125.254 12.457H132.636C132.975 12.457 133.246 12.1889 133.246 11.8538V9.03876C133.246 8.70364 132.975 8.43555 132.636 8.43555H120.953C120.614 8.43555 120.343 8.70364 120.343 9.03876V31.961C120.343 32.2961 120.614 32.5642 120.953 32.5642H133.009C133.348 32.5642 133.618 32.2961 133.618 31.961V29.1292C133.618 28.7941 133.348 28.526 133.009 28.526H125.254C125.067 28.5428 124.915 28.392 124.915 28.2077Z" />
        <path d="M7.75532 20.2989V22.695C7.75532 23.0302 8.02625 23.2983 8.36491 23.2983H10.1767C10.363 23.2983 10.5323 23.4491 10.5323 23.6501V26.2138C10.5323 27.6883 9.26236 28.9283 7.77225 28.9283H7.43359C5.94349 28.9283 4.63965 27.6883 4.63965 26.2138V24.3874V23.315V14.6521C4.63965 13.1776 5.94349 11.9879 7.43359 11.9879H7.77225C9.38089 11.9879 10.363 13.0771 10.363 14.4343V14.6689C10.363 15.004 10.6339 15.2721 10.9726 15.2721H14.2237C14.5624 15.2721 14.8333 15.004 14.8333 14.6689V14.25C14.8503 10.7983 12.1748 8 7.77225 8H7.43359C3.03101 8 0 10.9491 0 14.5684V26.2473C0 29.9672 3.03101 33 7.43359 33H7.77225C12.1748 33 14.8503 30.0007 14.8503 26.3143V20.2989C14.8503 19.9638 14.5793 19.6957 14.2407 19.6957H8.36491C8.02625 19.679 7.75532 19.9638 7.75532 20.2989Z" />
        <path
          fill={secondaryColor}
          d="M152.905 8.43561H139.867C139.342 8.43561 139.02 8.98856 139.274 9.44097L144.947 19.0757L150.128 12.3398C150.179 12.2727 150.28 12.256 150.348 12.2895L150.365 12.3063C150.45 12.3565 150.467 12.4571 150.433 12.5408L145.759 20.4664L145.776 20.5L139.257 31.5589C138.986 32.0113 139.325 32.5643 139.85 32.5643H152.888L160 20.5L152.905 8.43561Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="160" height="40" fill="#FFFFFF" />
        </clipPath>
      </defs>
    </svg>
  );
}
