import React from 'react';
import classNames from 'classnames';

import styles from './Attributes.module.scss';

export default function Attribute({
  children,
  className = '',
  icon,
}: {
  children: React.ReactNode;
  className?: string;
  icon: React.JSX.Element;
}): React.JSX.Element {
  return (
    <li className={classNames(styles.attribute, className)}>
      <figure className={styles.icon}>{icon}</figure>
      <div className={styles.content}>{children}</div>
    </li>
  );
}
