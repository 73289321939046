import React from 'react';

import { TRACK, TrackPageView, View } from 'analytics';
import EmailOpenIcon from 'icons/EmailOpenIcon';

import styles from './MagicLinkSuccess.module.scss';

interface MagicLinkSuccessProps {
  isCheckout?: boolean;
  email: string;
}

function MagicLinkSuccess({
  isCheckout = false,
  email,
}: MagicLinkSuccessProps) {
  return (
    <div className={styles.container}>
      <EmailOpenIcon width="80" height="80" />
      <h2 className={styles.title}>Check Your Email</h2>
      <p className={styles.description}>
        We've sent an email to <span className={styles.email}>{email}</span>{' '}
        with a link to log in{isCheckout && ' and complete checkout'}.
      </p>
    </div>
  );
}
const getEventState = () => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.MAGIC_LINK(),
});

export default TrackPageView(getEventState)(MagicLinkSuccess);
