import React, { useMemo } from 'react';

import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import { Performer, Venue } from 'models';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { DataLoader } from 'routes/routes.utils';
import { useAppSelector } from 'store';
import {
  getCategoryName,
  getCategoryPath,
} from 'store/modules/categories/category.helpers';
import { fetchPerformersByCategory } from 'store/modules/performers/actions';
import { selectPerformersGroupedByCategory } from 'store/modules/performers/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectAllMetros } from 'store/modules/resources/resource.selectors';
import { fetchVenuesData } from 'store/modules/venues/actions';
import { selectFetchVenues } from 'store/modules/venues/selectors';
import { Metro } from 'types';

import SitemapURLsList from './components/SitemapURLsList';
import { SitemapURLItem } from './components/SitemapURLsList/SitemapURLsList';
import { PERFORMERS_CATEGORIES_FOR_SITEMAPS } from './constants';
import {
  addMatchupsLink,
  addMoreTicketsLink,
  getMetrosSitemapURLItems,
  getPerformersSitemapURLItems,
  getVenuesSitemapURLItems,
} from './helpers';

import styles from './Sitemap.module.scss';

const MAX_NUMBER_OF_ELEMENTS_PER_SECTION = 40;

const getPerformerURLItems = (category: string, performers: Performer[]) => {
  const sitemapURLItems = getPerformersSitemapURLItems(performers);

  addMoreTicketsLink({
    sitemapURLItems,
    name: `More ${category.toUpperCase()} tickets`,
    path: `/html-sitemap/performers/${category}`,
  });

  addMatchupsLink({
    sitemapURLItems,
    category,
  });

  return sitemapURLItems;
};
const getVenuesURLItems = (venues: Venue[]) => {
  const venuesSitemapURLItems = getVenuesSitemapURLItems(venues);
  addMoreTicketsLink({
    sitemapURLItems: venuesSitemapURLItems,
    name: 'More Venues',
    path: '/html-sitemap/venues',
  });
  return venuesSitemapURLItems;
};

const getMetrosURLItems = (metros: Metro[]) => {
  const metrosSitemapURLItems = getMetrosSitemapURLItems(
    metros.slice(0, MAX_NUMBER_OF_ELEMENTS_PER_SECTION)
  );
  addMoreTicketsLink({
    sitemapURLItems: metrosSitemapURLItems,
    name: 'More Cities',
    path: '/html-sitemap/metros',
  });
  return metrosSitemapURLItems;
};

interface PerformerURLItems {
  [category: string]: SitemapURLItem[];
}

const Sitemap = () => {
  const venues = useAppSelector(selectFetchVenues);
  const metros = useAppSelector(selectAllMetros);
  const categoryPerformers = useAppSelector((state) =>
    selectPerformersGroupedByCategory(state, PERFORMERS_CATEGORIES_FOR_SITEMAPS)
  );

  const performerURLItems: PerformerURLItems = useMemo(
    () =>
      categoryPerformers.reduce((acc, { category, performers }) => {
        acc[category] =
          performers.length > 0
            ? getPerformerURLItems(category, performers)
            : [];
        return acc;
      }, {} as PerformerURLItems),
    [categoryPerformers]
  );

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={<MinimalHeader search showAccount showHamburger showCategories />}
      footer={<GTFooter />}
    >
      <HeadTitle title="Sitemap" />
      <div className={styles.container}>
        <h1 className={styles.title}>Sitemap</h1>
        {categoryPerformers.map((categoryPerformer) => {
          const { category, performers } = categoryPerformer;

          if (performers.length === 0) {
            return null;
          }

          return (
            <React.Fragment key={category}>
              <h2 className={styles.subtitle}>
                <a href={getCategoryPath(category)}>
                  {getCategoryName(category)}
                </a>
              </h2>
              <SitemapURLsList urlItems={performerURLItems[category]} />
            </React.Fragment>
          );
        })}

        {venues.length > 0 && (
          <>
            <h2 className={styles.subtitle}>Venues</h2>
            <SitemapURLsList urlItems={getVenuesURLItems(venues)} />
          </>
        )}

        {metros.length > 0 && (
          <>
            <h2 className={styles.subtitle}>
              Major Cities and Metropolitan Areas
            </h2>
            <SitemapURLsList urlItems={getMetrosURLItems(metros)} />
          </>
        )}
      </div>
    </ContainerTemplate>
  );
};

const loader: DataLoader =
  ({ store }) =>
  async () => {
    const fetchDataPromises = PERFORMERS_CATEGORIES_FOR_SITEMAPS.map(
      (category) =>
        store.dispatch(
          fetchPerformersByCategory({
            category,
            per_page: MAX_NUMBER_OF_ELEMENTS_PER_SECTION,
          })
        )
    );
    fetchDataPromises.push(store.dispatch(fetchMetros()));
    fetchDataPromises.push(
      store.dispatch(
        fetchVenuesData({
          per_page: MAX_NUMBER_OF_ELEMENTS_PER_SECTION,
        })
      )
    );

    await Promise.all(fetchDataPromises);

    return null;
  };

Sitemap.loader = loader;

export default Sitemap;
