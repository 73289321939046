import { createSelector } from 'reselect';

import { Venue } from 'models';
import { RootState } from 'store/store';
import { Venue as VenueType } from 'types';

const selectVenuesState = (state: RootState) => state.venues;

export const selectFetchVenuesData = createSelector(
  selectVenuesState,
  (venuesState) => {
    const fetchVenuesData = venuesState.fetchVenuesData;
    return {
      ...fetchVenuesData,
      results: fetchVenuesData.results.map((venue) => new Venue(venue)),
    };
  }
);

export const selectFetchVenues = createSelector(
  selectFetchVenuesData,
  (fetchVenuesData) => fetchVenuesData.results
);

export const selectVenueBySlug = createSelector(
  selectVenuesState,
  (_, slug: string) => slug,
  (venuesState, slug) => {
    if (!slug) {
      return null;
    }
    const venue = venuesState.fetchVenuesData.results.find(
      (venue: VenueType) => venue.slug === slug
    );

    return venue ? new Venue(venue) : null;
  }
);

export const selectIsVenuesLoading = (state: RootState) =>
  selectVenuesState(state).status === 'loading';
