import * as React from 'react';

export default function ApplePay({
  fill = 'currentColor',
  height = 61,
  width = 24,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 61 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Buy with Apple Pay</title>
      <path
        clipRule="evenodd"
        d="M11.4985 3.095C12.1816 2.26191 12.6449 1.14306 12.5227 0C11.5233 0.0484187 10.3034 0.643209 9.59734 1.47724C8.96332 2.19118 8.40228 3.35655 8.54825 4.45167C9.67033 4.54661 10.7914 3.90482 11.4985 3.095ZM12.5095 4.6658C10.8804 4.57086 9.4951 5.56772 8.71704 5.56772C7.9385 5.56772 6.74684 4.71327 5.45786 4.73653C3.78059 4.76026 2.22448 5.68544 1.37295 7.15699C-0.377801 10.101 0.91117 14.4677 2.61375 16.8649C3.44046 18.0512 4.437 19.3571 5.74982 19.3106C6.99013 19.2631 7.47672 18.5268 8.98418 18.5268C10.4911 18.5268 10.9291 19.3106 12.2424 19.2868C13.6039 19.2631 14.4554 18.1001 15.2821 16.9124C16.2305 15.5605 16.6188 14.2551 16.6436 14.1834C16.6188 14.1592 14.0175 13.1861 13.9936 10.2667C13.9688 7.82203 16.0358 6.65951 16.1331 6.58736C14.9658 4.90314 13.1421 4.71327 12.5095 4.6658ZM24.7456 10.7598H27.996C30.4625 10.7598 31.8663 9.46434 31.8663 7.21856C31.8663 4.97327 30.4625 3.6897 28.0087 3.6897H24.7456V10.7598ZM28.7555 1.35767C32.2969 1.35767 34.7634 3.7392 34.7634 7.20636C34.7634 10.6859 32.2463 13.0797 28.6669 13.0797H24.746V19.1629H21.9131V1.35767H28.7555ZM44.0661 14.3261V13.3264L40.9169 13.5239C39.1462 13.635 38.2231 14.2763 38.2231 15.3994C38.2231 16.485 39.1842 17.1885 40.6892 17.1885C42.6117 17.1885 44.0661 15.9913 44.0661 14.3261ZM35.4658 15.4736C35.4658 13.1908 37.2492 11.8831 40.5375 11.6856L44.0663 11.4758V10.4889C44.0663 9.04488 43.0926 8.25546 41.3599 8.25546C39.9303 8.25546 38.8934 8.97082 38.6783 10.0693H36.1232C36.1996 7.76178 38.4253 6.08374 41.4358 6.08374C44.6736 6.08374 46.7858 7.7371 46.7858 10.3038V19.163H44.1675V17.0283H44.1042C43.3583 18.4224 41.7136 19.2982 39.9303 19.2982C37.2998 19.2982 35.4658 17.7688 35.4658 15.4736ZM49.1932 23.9255V21.7913C49.3703 21.8155 49.8 21.8402 50.0277 21.8402C51.2797 21.8402 51.9881 21.3223 52.4183 19.9893L52.6713 19.1999L47.8774 6.24414H50.8369L54.1763 16.7567H54.2396L57.5786 6.24414H60.4626L55.4916 19.8541C54.3535 22.9756 53.0504 24 50.2934 24C50.0783 24 49.3825 23.9753 49.1932 23.9255Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}
