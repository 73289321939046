import React from 'react';
import classNames from 'classnames';
import {
  Form as FormikForm,
  Formik,
  FormikConfig,
  FormikErrors,
  FormikHelpers,
  FormikState,
  FormikValues,
} from 'formik';
import { debounce, throttle } from 'lodash';
import * as Yup from 'yup';

import styles from './Form.module.scss';

type Props<Values extends FormikValues> = {
  autoSubmitWhenValid?: boolean;
  children?: React.ReactNode;
  className?: string;
  id: string;
  initialValues: Values;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  submitText?: string;
  theme?: 'dark' | 'light';
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
} & (
  | { validate?: never; validationSchema: Yup.Schema<Values> }
  | {
      validate: FormikConfig<Values>['validate'];
      validationSchema?: never;
    }
);

function useFormState<Values extends FormikValues>({
  autoSubmitWhenValid,
  submitForm,
  validationSchema,
  values,
  validateForm,
}: {
  autoSubmitWhenValid: boolean;
  submitForm: () => void;
  validationSchema?: Yup.Schema<Values>;
  values: FormikState<Values>['values'];
  validateForm?: (
    values: FormikState<Values>['values']
  ) => Promise<FormikErrors<Values>>;
}) {
  const validateFormState = React.useCallback(
    debounce(async () => {
      if (autoSubmitWhenValid) {
        if (validateForm) {
          const errors = await validateForm(values);
          if (Object.keys(errors).length === 0) {
            await submitForm();
          }
          return;
        }

        if (validationSchema) {
          try {
            await validationSchema.validate(values, { abortEarly: false });
            await submitForm();
          } catch {
            // No need to do anything
          }
        }
      }
    }, 300),
    [values, validationSchema, autoSubmitWhenValid, submitForm, validateForm]
  );

  React.useEffect(() => {
    validateFormState();
    return () => validateFormState.cancel();
  }, [validateFormState]);
}

export default function Form<Values extends FormikValues>({
  autoSubmitWhenValid = false,
  children,
  className,
  id,
  initialValues,
  onSubmit,
  submitText = 'Submit',
  theme,
  validate,
  validateOnBlur = false,
  validateOnChange = false,
  validationSchema,
}: Props<Values>): React.JSX.Element {
  return (
    <Formik
      id={id}
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        try {
          await onSubmit(values, helpers);
        } finally {
          helpers.setSubmitting(false);
        }
      }}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      validationSchema={validationSchema}
      validate={validate}
    >
      {({ values, isSubmitting, submitForm, validateForm }) => {
        const throttledSubmitForm = React.useMemo(
          () =>
            throttle((): void => {
              submitForm();
            }, 500),
          [submitForm]
        );

        useFormState({
          autoSubmitWhenValid,
          submitForm: throttledSubmitForm,
          validationSchema,
          values,
          validateForm,
        });

        return (
          <FormikForm
            className={classNames(
              className,
              styles.form,
              theme ? styles[`theme-${theme}`] : ''
            )}
            id={id}
            key={id}
          >
            {children}
            <button
              className={styles.submit}
              disabled={isSubmitting}
              form={id}
              type="submit"
            >
              {submitText}
            </button>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
