import React from 'react';
import Button from 'ui/Button/Button';

import AffirmWhiteLogo from 'assets/affirm-white-logo.svg';

interface AffirmPurchaseButtonProps {
  handleClick: () => void;
}

function AffirmPurchaseButton({ handleClick }: AffirmPurchaseButtonProps) {
  return (
    <Button onClick={handleClick} buttonClass="affirm">
      <span>Check out with</span>
      <img src={AffirmWhiteLogo} alt="affirm" height="24" />
    </Button>
  );
}

export default AffirmPurchaseButton;
