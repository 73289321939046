import { useStatsigClient } from '@statsig/react-bindings';

import config, { Providers } from '../../config/variants';
import { StatsigDriver, VariantDriverContract } from '../../drivers/variants';

const driverInstances: Map<Providers, VariantDriverContract> = new Map();

export default function useVariantService<Provider extends Providers>(
  driver?: Provider
): VariantDriverContract {
  const provider = driver ?? config.provider;

  switch (provider) {
    case 'statsig':
      const { client } = useStatsigClient();

      if (!driverInstances.has(provider)) {
        driverInstances.set(provider, new StatsigDriver(client));
      }

      return driverInstances.get(provider) as VariantDriverContract;
    default:
      throw new Error(`Variant driver '${provider}' does not exist.`);
  }
}
