import React from 'react';

import styles from '../Cart.module.scss';

export default function LineItemContainer({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className={styles['line-item']} data-testid="checkout-cart-line-item">
      {children}
    </div>
  );
}
