import React from 'react';
import classNames from 'classnames';

import { ChevronIcon } from '../../../../icons';
import { FullEvent, Listing } from '../../../../models';

import SeatMap from './SeatMap';
import SeatView from './SeatView';

import styles from './ListingImages.module.scss';

function Toggle(): React.JSX.Element {
  return (
    <label className={styles['toggle-label']} htmlFor="toggle-view">
      <span className={classNames(styles.icon, styles.prev)}>
        <ChevronIcon
          color="currentcolor"
          direction="left"
          height={12}
          width={12}
        />
      </span>

      <span className={classNames(styles.icon, styles.next)}>
        <ChevronIcon
          color="currentcolor"
          direction="right"
          height={12}
          width={12}
        />
      </span>

      <span className="visually-hidden">Toggle View</span>
      <input
        className={classNames(styles['toggle-input'], 'visually-hidden')}
        id="toggle-view"
        type="checkbox"
      />
    </label>
  );
}

function ListingImages({
  className,
  event,
  listing,
  showMap = true,
  showSeatView = true,
}: {
  className?: string;
  event: FullEvent;
  listing: Listing;
  showMap?: boolean;
  showSeatView?: boolean;
}): React.JSX.Element {
  return (
    <div className={classNames(className, styles.component)}>
      {showMap && showSeatView && <Toggle />}
      {showMap && <SeatMap event={event} listing={listing} />}
      {showSeatView && <SeatView event={event} listing={listing} />}
    </div>
  );
}

export default ListingImages;
