import React from 'react';

export default function PriceTag({
  fill = 'currentColor',
  height = 16,
  width = 16,
}: Pick<React.SVGProps<SVGSVGElement>, 'fill' | 'height' | 'width'>) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="16/16-sell-yes">
        <path
          clipRule="evenodd"
          d="M0.706956 8.29289C0.316432 8.68342 0.316431 9.31658 0.706955 9.70711L6.29274 15.2929C6.68327 15.6834 7.31643 15.6834 7.70696 15.2929L14.707 8.29289C14.8945 8.10536 14.9998 7.851 14.9998 7.58579V2C14.9998 1.44772 14.5521 1 13.9998 1H8.41406C8.14885 1 7.89449 1.10536 7.70696 1.29289L0.706956 8.29289ZM11.8534 3.35355C11.6581 3.15829 11.3416 3.15829 11.1463 3.35355L10.3534 4.14645C10.1581 4.34171 10.1581 4.65829 10.3534 4.85355L11.1463 5.64645C11.3416 5.84171 11.6581 5.84171 11.8534 5.64645L12.6463 4.85355C12.8416 4.65829 12.8416 4.34171 12.6463 4.14645L11.8534 3.35355Z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
