import * as React from 'react';

export default function Disclosure({
  fill = 'currentColor',
  height = 17,
  width = 16,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 17"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5.375 13.0298H10.625V11.7173H5.375V13.0298ZM5.375 10.4048H10.625V9.09228H5.375V10.4048ZM4.0574 15.6548C3.69622 15.6548 3.38802 15.5263 3.13281 15.2692C2.8776 15.0122 2.75 14.7032 2.75 14.3423V2.96729C2.75 2.60635 2.87852 2.29736 3.13555 2.04033C3.39258 1.7833 3.70156 1.65479 4.0625 1.65479H9.75L13.25 5.15479V14.3423C13.25 14.7032 13.1214 15.0122 12.8643 15.2692C12.6071 15.5263 12.298 15.6548 11.9368 15.6548H4.0574ZM8.875 6.02979H11.9375L8.875 2.96729V6.02979Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
