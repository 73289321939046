import { NameOverride as NameOverrideType } from 'types';

export default class NameOverride {
  readonly name: NameOverrideType['name'];
  readonly mediumName: NameOverrideType['medium_name'];
  readonly shortName: NameOverrideType['short_name'];
  readonly abbrev: NameOverrideType['abbrev'];

  constructor(nameOverride: NameOverrideType) {
    this.name = nameOverride.name;
    this.mediumName = nameOverride.medium_name;
    this.shortName = nameOverride.short_name;
    this.abbrev = nameOverride.abbrev;
  }
}
