import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useFormikContext } from 'formik';

import config from 'config/config';
import HomeFillIcon from 'icons/HomeFillIcon';

import Input from './Input';

export default function FormattedAddress({
  id,
  label = 'Address',
  placeholder,
  name,
}: {
  id: string;
  label?: string;
  name?: string;
  placeholder?: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setFieldValue } = useFormikContext();
  const key = name ?? id;

  useEffect(() => {
    const loader = new Loader({
      apiKey: config.GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    if (inputRef.current === null) {
      return;
    }

    loader.load().then((google) => {
      // fields restricts what's returned in JSON from the API, types restricts the results
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current as HTMLInputElement,
        {
          componentRestrictions: { country: ['us', 'ca'] },
          fields: ['address_component', 'formatted_address'],
        }
      );

      /**
       * Fires when a user selects one of the suggestions from the autocomplete
       * dropdown, which then sets the value in the <TextInput /> if the result is valid.
       * If not, the value in the component stays the same.
       */
      autocomplete.addListener('place_changed', async () => {
        setFieldValue(key, (await autocomplete.getPlace()).formatted_address);
      });
    });
  }, [setFieldValue]);

  return (
    <Input
      id={id}
      label={label}
      name={name}
      placeholder={placeholder}
      ref={inputRef}
      required
      icon={<HomeFillIcon />}
      type="text"
    />
  );
}
