import * as React from 'react';

export default function GooglePay({
  fill = 'currentColor',
  height = 60,
  width = 24,
}: Pick<
  React.SVGProps<SVGSVGElement>,
  'fill' | 'height' | 'width'
>): React.JSX.Element {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 60 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Buy with Google Pay</title>
      <g>
        <path
          d="M28.3795 3.52562V9.58987H32.0876C32.9717 9.58987 33.7018 9.29064 34.2801 8.69219C34.8735 8.09483 35.1697 7.38171 35.1697 6.5572C35.1697 5.74907 34.8735 5.04468 34.2801 4.44623C33.7018 3.83139 32.9717 3.52452 32.0886 3.52452H28.3784V3.52562H28.3795ZM28.3795 11.7249V18.76H26.1631V1.39062H32.0398C33.5326 1.39062 34.8008 1.89298 35.8445 2.8944C36.9044 3.89692 37.4349 5.11785 37.4349 6.5572C37.4349 8.0293 36.9044 9.25897 35.8445 10.244C34.8171 11.2312 33.5478 11.7238 32.0398 11.7238H28.3795V11.7249ZM39.673 15.1212C39.673 15.7033 39.9181 16.1881 40.4085 16.5769C40.8978 16.9646 41.4717 17.159 42.1302 17.159C43.0621 17.159 43.8909 16.8106 44.6221 16.1161C45.3533 15.4204 45.7178 14.6035 45.7178 13.6666C45.0268 13.1162 44.0634 12.842 42.8278 12.842C41.9273 12.842 41.1788 13.0605 40.5767 13.4973C39.9746 13.9341 39.673 14.4736 39.673 15.1212ZM42.5403 6.48512C44.1784 6.48512 45.4694 6.92632 46.4187 7.80652C47.3647 8.68891 47.8388 9.89783 47.8388 11.4344V18.76H45.7189V17.1109H45.6224C44.7078 18.4684 43.4874 19.1476 41.962 19.1476C40.6613 19.1476 39.5731 18.76 38.6987 17.9835C37.8243 17.2081 37.3861 16.2362 37.3861 15.0731C37.3861 13.8435 37.8471 12.8661 38.7703 12.1377C39.6936 11.4103 40.926 11.0456 42.4676 11.0456C43.7824 11.0456 44.8673 11.288 45.7178 11.7729V11.2629C45.7178 10.4875 45.4119 9.82903 44.8022 9.28627C44.2152 8.75332 43.45 8.46313 42.6596 8.47377C41.4228 8.47377 40.4443 9.00015 39.7218 10.0518L37.7701 8.81341C38.8463 7.26158 40.4356 6.48512 42.5403 6.48512ZM60.0001 6.8728L52.6057 23.9997H50.3188L53.0635 18.0075L48.1989 6.8728H50.6073L54.1234 15.4128H54.1711L57.5917 6.8728H60.0001Z"
          fill={fill}
        />
        <path
          d="M19.4236 10.2078C19.4236 9.50557 19.365 8.8274 19.2533 8.17871H9.9082V12.0228H15.2609C15.1514 12.6365 14.9196 13.2216 14.5796 13.7428C14.2395 14.264 13.7982 14.7105 13.2821 15.0555V17.5519H16.476C18.3463 15.8144 19.4236 13.2459 19.4236 10.2078Z"
          fill="#4285F4"
        />
        <path
          d="M9.90819 19.963C12.5824 19.963 14.8335 19.0784 16.476 17.5539L13.2821 15.0574C12.3925 15.6602 11.248 16.0129 9.90819 16.0129C7.32403 16.0129 5.13043 14.258 4.34607 11.8926H1.05566V14.4644C1.87988 16.1169 3.14421 17.5062 4.70745 18.4772C6.2707 19.4481 8.07131 19.9625 9.90819 19.963Z"
          fill="#34A853"
        />
        <path
          d="M4.34599 11.8909C3.93262 10.6515 3.93262 9.3102 4.34599 8.07084V5.49902H1.05559C0.359545 6.8894 -0.0020399 8.42502 8.65619e-06 9.98196C8.65619e-06 11.5939 0.380797 13.1162 1.05559 14.4638L4.34599 11.892V11.8909Z"
          fill="#FABB05"
        />
        <path
          d="M9.90819 3.9511C11.3684 3.9511 12.6779 4.45673 13.7096 5.44723V5.44941L16.5378 2.60021C14.8205 0.989412 12.5813 0 9.90928 0C8.07221 0.000200514 6.27137 0.514519 4.70791 1.48551C3.14446 2.45649 1.87996 3.84591 1.05566 5.49856L4.34607 8.07037C5.13043 5.70605 7.32403 3.9511 9.90819 3.9511Z"
          fill="#E94235"
        />
      </g>
    </svg>
  );
}
