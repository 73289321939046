import React from 'react';

import AffirmBlackLogo from 'assets/affirm-black-logo.svg';

import { UserModel } from '../../../../../types';
import Form from '../Form';
import { FormSection } from '../Inputs';
import Input from '../Inputs/Input';
import * as Validators from '../validators';

import styles from './AffirmCheckoutForm.module.scss';

export default function AffirmCheckoutForm({
  user,
  onSubmit,
}: {
  user: Pick<UserModel, `${'first' | 'last'}_name`>;
  onSubmit: (formData: { [key in `${'first' | 'last'}Name`]: string }) => void;
}): React.JSX.Element {
  return (
    <Form
      className={styles.form}
      id="affirm-checkout"
      initialValues={{
        firstName: user.first_name ?? '',
        lastName: user.last_name ?? '',
      }}
      onSubmit={onSubmit}
      submitText="Continue"
      theme="light"
      validationSchema={Validators.schema({
        firstName: Validators.string({ label: 'first name', required: true }),
        lastName: Validators.string({ label: 'last name', required: true }),
      })}
    >
      <img src={AffirmBlackLogo} alt="Affirm Logo" />

      <FormSection title="Please enter your name to continue.">
        <Input
          id="affirm-checkout-first-name"
          label="First Name"
          name="firstName"
          required
          type="text"
        />
        <Input
          id="affirm-checkout-last-name"
          label="Last Name"
          name="lastName"
          required
          type="text"
        />
      </FormSection>
    </Form>
  );
}
