import React from 'react';
import PropTypes from 'prop-types';

import TicketPrice from 'components/TicketPrice/TicketPrice';
import { Listing } from 'models';

import styles from './PrimaryPrice.module.scss';

function PrimaryPrice({ listing, isAllInPriceActive }) {
  const isDiscounted = listing.isDiscounted();

  if (!isDiscounted) return null;

  const getPrimaryPrice = () => {
    const savings = listing.getSavingsAmount();

    if (isAllInPriceActive) {
      return listing.totalPrice + savings;
    }

    return listing.prefeePrice + savings;
  };

  return (
    <span className={styles['primary-price']}>
      <TicketPrice price={getPrimaryPrice()} />
    </span>
  );
}

PrimaryPrice.propTypes = {
  listing: PropTypes.instanceOf(Listing),
  isAllInPriceActive: PropTypes.bool.isRequired,
};

export default PrimaryPrice;
